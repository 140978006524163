import { v4 as uuid } from 'uuid';
import { Component, OnInit, OnDestroy, Input, SimpleChanges, TemplateRef, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { EnvironmentData } from 'src/app/service/environments.service';
import { Router } from '@angular/router';
import { FileService, UniversalUser } from '../service/shared.service';
import { CommonService } from '../service/common.service';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { ImagepdfviewerComponent } from '../shared/imagepdfviewer/imagepdfviewer.component';
import { User } from '../models/user.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatSnackBar } from '@angular/material';
import { ExportService } from '../service/export.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'api-ticketing',
  templateUrl: './ticketing.component.html',
  styleUrls: ['./ticketing.component.scss']
})
export class APiTicketingComponent implements OnInit, OnDestroy {
  ticketList: any[] = [];
  ticketListMaster: any[] = [];
  reporterList: any[] = [];
  assignedToList: any[] = [];
  currentResultsHeader: any[] = [
    { key: 'ticket_id', title: "Case Id" },
    { key: 'title', title: 'Title' },
    { key: 'createdAtLong', title: 'Created Date' },
    { key: 'lastUpdatedAtLong', title: 'Update Date' },
    { key: 'statusCd', title: 'Status' },
    { key: '_id', title: 'Action' }
  ];
  options = {
    rowClickEvent: true,
    rowPerPageMenu: [10, 20, 30],
    rowPerPage: 10
  }
  subscriptionAllTickets: Subscription;
  subscriptionSpecificTicket: Subscription;
  pagesize: number = 50;
  pagenumber: number = 0;
  bsModalRef: BsModalRef;
  selectedTicket: any;
  currentUser: User;
  selectedSupportMessage: any;
  filterQuery: string = '';
  noMoreRecordsFlag: boolean = false;
  isLoadingNew: boolean = false;
  isLoadingMessage: boolean = false;
  companyAccountInfo: any;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '600',
    minHeight: '400',
    maxHeight: '600',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Issue Description',
    defaultParagraphSeparator: '',
    uploadWithCredentials: false,                    //Image Upload Can we make this work@Parth
    uploadUrl: '',                                   //Your backend must return imageUrl.   | https://github.com/kolkov/angular-editor/issues/5  
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'strikeThrough',
        'subscript',
        'superscript',
        'indent',
        'outdent',
        // 'insertUnorderedList',
        // 'insertOrderedList',
        'heading',
        'fontName',
        'customClasses',
        'insertImage',
        'insertVideo',
        'toggleEditorMode',
        'link',
        'unlink',
        // 'insertHorizontalRule',
        // 'removeFormat',
        'fontSize',
        'textColor',
        'backgroundColor',
      ],
      []
    ]
  };
  filterList: any = {
    "reporter": [],
    "assignedTo": [],
    "statusCd": [],
    "priority": []
  };
  ticketAssigned: boolean = false;
  ticketId: string = "";
  supportmessage = {
    messages: [],
    title: "",
    priority: "Medium",
    description: ""
  };
  statusArray = [
    { label: 'Open', value: 'OPEN' },
    { label: 'In Progress', value: 'IN PROGRESS' },
    { label: 'Resolved', value: 'RESOLVED' },
    { label: 'Closed', value: 'CLOSED' },
    { label: 'Re-Open', value: 'REOPEN' }
  ];
  priorityArray = [
    { label: 'Low', value: 'Low', img: 'Low.svg' },
    { label: 'Medium', value: 'Medium', img: 'Medium.svg' },
    { label: 'High', value: 'High', img: 'High.svg' },
    { label: 'Critical', value: 'Critical', img: 'Critical.svg' }
  ];
  @ViewChild('tableTickets', { static: false }) tableTickets: ElementRef;
  constructor(
    private modalService: BsModalService,
    private fileService: FileService,
    private ticketService: CommonService,
    private universalUser: UniversalUser,
    private snackBar: MatSnackBar,
    private exportService: ExportService,
    private titleService: Title
  ) {
    this.titleService.setTitle("Support");
  }

  ngOnInit() {
    this.currentUser = this.universalUser.getUser();
    this.getAllTickets();
    this.companyAccountInfo = this.universalUser.getcompanyAccount();
  }

  ngOnDestroy() {
    // this.subscriptionAllTickets.unsubscribe();
  }

  getAllTickets(appendFlag?: boolean) {
    this.subscriptionAllTickets = this.ticketService.fetchTickets(this.pagenumber, this.pagesize).subscribe(
      response => {
        if (appendFlag) {
          this.ticketList.concat(response)
          this.ticketListMaster.concat(response)
        } else {
          this.ticketList = response;
          this.ticketListMaster = response;
        }
        this.reporterList = response.map(item => item.reporter).filter(this.onlyUnique).filter(this.returnTrueValues)
        this.assignedToList = response.map(item => item.assignedTo).filter(this.onlyUnique).filter(this.returnTrueValues);
        this.applyfilter();
      },
      error => {
        console.error(error)
      }
    );
  }
  openTicketById(ticketId) {
    this.selectedSupportMessage = null;
    this.subscriptionSpecificTicket = this.ticketService.fetchTicketById(ticketId).subscribe(
      response => {
        this.selectedTicket = response
      },
      error => { }
    );
  }
  openImage(doc) {
    let fileServiceDoc = {};
    fileServiceDoc['fileName'] = doc.file_name;
    fileServiceDoc['downloadFileUrl'] = doc.file_url;
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: false,
      ignoreBackdropClick: true,
      initialState: {
        doc: fileServiceDoc,
        entityView: true,
        title: 'Document Title'
      }
    };
    this.bsModalRef = this.modalService.show(ImagepdfviewerComponent, config);
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.event.subscribe(res => {
      // for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      //   this.modalService.hide(i);
      // }
      if (res.res == 2133) {
      }
      const div = document.querySelector('body');
      div.classList.remove('modal-open');
    });
  }
  messageError: string = "";
  onDocumentUpload(event, object, key) {
    for (let index = 0; index < event.target.files.length; index++) {
      let uploadedFile = event.target.files[index];
      const file: File = event.target.files[index];
      let fileNameArray = file.name.split(".");
      let fileName = "";
      const fileInputForm = new FormData();
      if (fileNameArray.length > 2) {
        fileName = fileNameArray[0] + "." + fileNameArray[fileNameArray.length - 1]
      } else {
        fileName = file.name;
      }
      fileInputForm.append('file', file, fileName);
      fileInputForm.append("fileName", fileName);
      fileInputForm.append("functionInstanceName", "API");
      fileInputForm.append("entityType", "COMPLAINT");
      fileInputForm.append("entityRef", this.currentUser._id);
      let fileSize = file.size;
      let fileMb = fileSize / 1024 ** 2;
      if (fileMb >= 2) {
        this.messageError = "Please select a file less than 2MB.";

      } else {
        // this.messageError = "Success, your file is " + fileMb.toFixed(1) + "MB.";
        this.uploadFile(fileInputForm, object, key);
      }
    }

  }

  uploadFile(formData: FormData, object, key) {
    this.fileService.upload(formData)
      .subscribe(
        response => {
          if (response && response["url"] && response["fileName"]) {

            let attachment = {
              file_name: response.fileName,
              file_url: response.downloadFileUrl
            }
            if (attachment.file_name && attachment.file_url) {
              this.selectedSupportMessage['attachments'].push(attachment);
            }
          }
        }, (error) => {
          if (error && error.error && error.error.error && error.error.error.message) {
            this.snackBar.open(error.error.error.message, "Dismiss", {
              duration: 10000
            });
          }
        });
    
  }
  addResetMessage() {
    if (this.selectedSupportMessage) {
      this.selectedSupportMessage = null;
    } else {
      this.selectedSupportMessage = {
        message: "",
        attachments: []
      }
    }
  }
  isEscalated:boolean = false;
  replyToTicket(escalate?) {
    this.isLoadingMessage = true;
    this.ticketService.addMessageToTicket(this.selectedTicket._id, this.selectedSupportMessage).subscribe(
      response => { 
        if (escalate) {
          this.sendEscalateEmail()
        }else{
          this.selectedTicket = (response);
          this.isLoadingMessage = false;
          this.addResetMessage();
        }
      },
      error => { }
    );
  }
  getTicketList() {
    return this.selectedTicket.messages
  }
  loadMore(event?) {
    this.pagenumber++;
    if ((this.ticketList.length % 50) > 49) {
      this.getAllTickets();
    } else {
      this.noMoreRecordsFlag = true;
      setTimeout(() => {
        this.noMoreRecordsFlag = false;
      }, 3000);
    }
  }
  checkIfPresent(value, type) {
    if (this.filterList && this.filterList[type]) {
      let pos = this.filterList[type].indexOf(value);
      if (pos > -1) return true;
      return false
    }
  }
  addRemoveFilters(value, type) {
    if (this.filterList && this.filterList[type]) {
      let pos = this.filterList[type].indexOf(value);
      if (pos == -1) {
        this.filterList[type].push(value);
      } else {
        this.filterList[type].splice(pos, 1);
      }
      this.applyfilter()
    }
  }
  applyfilter() {
    let tmp = JSON.parse(JSON.stringify(this.ticketListMaster))
    for (const key in this.filterList) {
      if (Object.prototype.hasOwnProperty.call(this.filterList, key)) {
        const propList = this.filterList[key];
        switch (key) {
          case 'reporter':
            if (propList.length > 0) {
              tmp = tmp.filter(item => {
                if (propList.indexOf(item.reporter) > -1) {
                  return true
                }
                return false;
              })
            }
            this.ticketList = tmp;
            break;
          case 'assignedTo':
            if (propList.length > 0) {
              tmp = tmp.filter(item => {
                if (propList.indexOf(item.assignedTo) > -1) {
                  return true
                }
                return false;
              })
            }
            this.ticketList = tmp;
            break;
          case 'statusCd':
            if (propList.length > 0) {
              tmp = tmp.filter(item => {
                if (propList.indexOf(item.statusCd) > -1) {
                  return true
                }
                return false;
              })
            }
            this.ticketList = tmp;
            break;
          case 'priority':
            if (propList.length > 0) {
              tmp = tmp.filter(item => {
                if (propList.indexOf(item.priority) > -1) {
                  return true
                }
                return false;
              })
            }
            this.ticketList = tmp;
            break;
          default:
            break;
        }
      }
    }
  }
  onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }
  returnTrueValues(element) {
    return element !== undefined && element !== null;
  }

  modalOpen(templateRef: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(templateRef, { class: 'modal-dialog modal-md modal-dialog-centered' });
  }

  modalClose(templateRef: TemplateRef<any>) {
    this.messageError = '';
    this.bsModalRef.hide();
    setTimeout(() => {
      this.ticketAssigned = false;
    }, 2000);

  }
  sendSupportMail() {
    if (this.supportmessage.title == "" && this.supportmessage.description == "") {
      this.messageError = "Please fill in your query";
    } else {
      this.isLoadingNew = true;
      this.messageError = '';
      this.ticketService.createSupportTicket(this.supportmessage).subscribe(
        (response) => {
          this.ticketId = response.ticket_id;
          // this.ticketAssigned = true;
          this.bsModalRef.hide();
          this.isLoadingNew = false;

          this.supportmessage = {
            messages: [],
            priority: "Medium",
            title: "",
            description: ""
          };
          this.getAllTickets();
        },
        (error) => {
          setTimeout(() => {
            this.snackBar.open('Something seems to be the issue.', "Close", {
              duration: 5000
            })
            this.bsModalRef.hide();

          }, 1000);
        },
      );
    }
  }

  onEscalateClick() {
    this.isLoadingNew = true;
    this.messageError = '';
    this.selectedSupportMessage={};
    this.selectedSupportMessage.message ="Escalated to Product Support";
    this.replyToTicket(true)
  }
  sendEscalateEmail(){
    this.selectedTicket['_id'] = null;
    this.selectedTicket['productSupportTicket']=true;
     this.ticketService.createSupportTicket(this.selectedTicket).subscribe(
      (response) => {
        this.ticketId = response.ticket_id;
        // this.ticketAssigned = true;
        this.isLoadingNew = false;
        
        this.supportmessage = {
          messages: [],
          priority: "Medium",
          title: "",
          description: ""
        };
        this.getAllTickets();
        this.addResetMessage()
      },
      (error) => {
        setTimeout(() => {
          this.snackBar.open('Something seems to be the issue.', "Close", {
            duration: 5000
          })
          this.bsModalRef.hide();

        }, 1000);
      },
    );
  }
  onDocumentUploadNew(event, object, key) {
    // console.log(event)
    for (let index = 0; index < event.target.files.length; index++) {
      let uploadedFile = event.target.files[index];
      const fileInputForm = new FormData();
      const file: File = event.target.files[index];
      let fileNameArray = file.name.split(".");
      let fileName = "";
      if (fileNameArray.length > 2) {
        fileName = fileNameArray[0] + "." + fileNameArray[fileNameArray.length - 1]
      } else {
        fileName = file.name;
      }
      fileInputForm.append('file', file, fileName);
      fileInputForm.append("fileName", fileName);
      fileInputForm.append("functionInstanceName", "API");
      fileInputForm.append("entityType", "COMPLAINT");
      fileInputForm.append("entityRef", this.currentUser._id);
      let fileSize = file.size;
      let fileMb = fileSize / 1024 ** 2;
      if (fileMb >= 2) {
        this.messageError = "Please select a file less than 2MB.";

      } else {
        // this.messageError = "Success, your file is " + fileMb.toFixed(1) + "MB.";
        this.uploadFileNew(fileInputForm, object, key);
      }
    }

  }

  uploadFileNew(formData: FormData, object, key) {

    this.fileService.upload(formData)
      .subscribe(
        response => {
          if (response && response["url"] && response["fileName"]) {
            let tmp = {
              message: "Ticket Created",
              attachments: []
            }
            let attachment = {
              file_name: response.fileName,
              file_url: response.downloadFileUrl
            }
            tmp['attachments'].push(attachment);
            object[key].push(tmp)
          }
        }, (error) => {
          if (error && error.error && error.error.error && error.error.error.message) {
            this.snackBar.open(error.error.error.message, "Dismiss", {
              duration: 10000
            });
          }
        }
      );
  }
  exportToExcel() {
    this.exportService.exportTableExcel(this.tableTickets.nativeElement, 'tickets');
  }
  checkIfSupport() {
    if (this.companyAccountInfo && this.companyAccountInfo.supportTeamInternal) {
      let allowedRoles = ["ROLE_ADMIN", "ROLE_COMPANY_ADMIN", "ROLE_COMPANY_DESIGNER"];
      let _this = this;
      var filteredArray = allowedRoles.filter(function (n) {
        return _this.currentUser.authorities.indexOf(n) !== -1;
      });
      if (filteredArray.length > 0) return true;
      return false;
    }
  }
}