import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { User } from '../models/user.model';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UniversalUser } from '../service/shared.service';
import { BusinessDashboardRequest } from '../models/business-dashboard.model';
import {EnvironmentData} from './environments.service';



@Injectable({
    providedIn: 'root'
})
export class BusinessDashboardService {
    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(
        private router: Router,
        private universalUser: UniversalUser,
        private httpClient: HttpClient,
        private environmentData: EnvironmentData
    ) { }

    executeOperation(businessDashboadRequest: BusinessDashboardRequest) {
        const envData = this.environmentData.getEnvData();
        const subject = new Subject<any>();
      
        const url = `${envData.rootUrl + envData.interfaceService + envData.dashboardresult + "/" + businessDashboadRequest.operation}`;
        this.httpClient.post<any>(
          url,
          businessDashboadRequest,
          {
            headers: this.httpHeaders,
            observe: 'response',
            reportProgress: true,
            withCredentials: true
          }
        ).subscribe(
          (response: HttpResponse<any>) => {
            if (response.body) {
              subject.next(response.body);
            }
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
    
            subject.error(err);
          }
        );
    
        return subject.asObservable();
    }

}