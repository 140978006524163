import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as Chartist from 'chartist';
declare var require: any;
import { FetchUserService } from '../service/userhierarchy.service'
import { UniversalUser, NotificationService } from '../service/shared.service'
import { DashboardService } from '../service/dashboard.service'
import { UserHierarchy, User } from '../models/user.model';
import { formatDate } from '@angular/common';
import { NotificationPi } from '../models/shared.model';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {


  p: number = 1;

  user: User;
  users: User[] = [];
  childUserIds: string[] = [];
  groups: string[] = [];
  range: any = {};
  notifications: NotificationPi[] = [];

  //dashboard components
  today: any;
  notificationCount: number = 0;
  dashboardSummary: any;
  taskStatistics: any;
  tasksTimeline: any = null;
  searchAgent: string;
  agentboard: any = null;
  // params
  startTime: any = null;
  endTime: any = null;

  foundUserGroupResult: boolean = false;

  constructor(private fetchUserService: FetchUserService,
    private universalUser: UniversalUser,
    private dashboardService: DashboardService,
    private notificationService: NotificationService,
    private router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle("Agent Dashboard");
    this.user = universalUser.getUser();
    this.dashboardSummary = {};
    this.taskStatistics = {};
    this.agentboard = null;
    this.range = { startDate: moment().subtract(29, 'days'), endDate: moment() };
    this.startTime = this.range.startDate;
    this.endTime = this.range.endDate;
    
  }

  ngOnInit() {
    this.getUserChildren();
    this.setDateTime();
    this.fetchNotifications()
  }

  datesUpdated(range) {
    this.fetchResult(range);
  }

  fetchResult(range, refresh?: boolean) {
    if (range.startDate != null && range.endDate != null) {
      this.dashboardSummary = {};
      this.agentboard = null;
      this.startTime = range.startDate;
      this.endTime = range.endDate;
      let fromDate = range.startDate.format("DD/MM/YYYY");
      let toDate = range.endDate.format("DD/MM/YYYY");
      let body = {}
      // console.log(fromDate);
      body['companyId'] = this.user.companyId;
      body['userId'] = this.user._id;
      body['startDate'] = fromDate;
      body['endDate'] = toDate;
      body['groups'] = this.groups;
      if (this.childUserIds.length > 0) {
        body['type'] = "Team";
        body['childUserIds'] = this.childUserIds;
      }
      body["timeZone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      body['dashboard'] = "flow";
      this.getPendingTasks(body)
      this.getUnassignedTasks(body);
      this.getOverdueTasks(body);
      this.getEscalatedTasks(body);
      // this.getReservedTasks(body);
      this.getCompletedTasks(body);
      this.getCompletedTasksTimeline(body);
      this.getAgentTaskBoard(body);
      if (refresh == true) {
        this.taskStatistics = {};
        this.getCurrentQueue(body);
        this.getWaitingTime(body);
        this.getAvgTATTime(body);
        this.getAvgFirstResponseTime(body);
        this.getTopTasks(body);
      }
      
      
    }
  }

  getPendingTasks(body: any) {
    const request = JSON.parse(JSON.stringify(body));
    request["operation"] = "PENDING_TASKS";
    this.dashboardService.dashboardData(request)
      .subscribe (
        response => {
          if (response && response.pending_states != undefined)
            this.dashboardSummary.pending_states = response.pending_states;
        },
        error => {

        }
      )
  }

  getUnassignedTasks(body: any) {
    const request = JSON.parse(JSON.stringify(body));
    request["operation"] = "UNASSIGNED_TASKS";
    this.dashboardService.dashboardData(request)
      .subscribe (
        response => {
          if (response && response.unassigned_states != undefined)
            this.dashboardSummary.unassigned_states = response.unassigned_states;
        },
        error => {

        }
      )
  }

  getOverdueTasks(body: any) {
    const request = JSON.parse(JSON.stringify(body));
    request["operation"] = "OVERDUE_TASKS";
    this.dashboardService.dashboardData(request)
      .subscribe (
        response => {
          if (response && response.overdue_states != undefined)
            this.dashboardSummary.overdue_states = response.overdue_states;
        },
        error => {

        }
      )
  }

  getEscalatedTasks(body: any) {
    const request = JSON.parse(JSON.stringify(body));
    request["operation"] = "ESCALATED_TASKS";
    this.dashboardService.dashboardData(request)
      .subscribe (
        response => {
          if (response && response.escalated_states != undefined)
            this.dashboardSummary.escalated_states = response.escalated_states;
        },
        error => {

        }
      )
  }

  // getReservedTasks(body: any) {
  //   const request = JSON.parse(JSON.stringify(body));
  //   request["operation"] = "SELF_RESERVED";
  //   this.dashboardService.dashboardData(request)
  //     .subscribe (
  //       response => {
  //         if (response && response.reserved_states != undefined)
  //           this.dashboardSummary.reserved_states = response.reserved_states;
  //       },
  //       error => {

  //       }
  //     )
  // }

  getCompletedTasks(body: any) {
    const request = JSON.parse(JSON.stringify(body));
    request["operation"] = "COMPLETED";
    this.dashboardService.dashboardData(request)
      .subscribe (
        response => {
          if (response && response.completed_states != undefined)
            this.dashboardSummary.completed_states = response.completed_states;
        },
        error => {

        }
      )
  }

  getCurrentQueue(body: any) {
    const request = JSON.parse(JSON.stringify(body));
    request["operation"] = "CURRENT_QUEUE";
    delete request.startDate;
    delete request.endDate;
    this.dashboardService.dashboardData(request)
      .subscribe (
        response => {
          if (response && response.current_queue != undefined)
            this.taskStatistics.current_queue = response.current_queue;
        },
        error => {

        }
      )
  }

  getWaitingTime(body: any) {
    const request = JSON.parse(JSON.stringify(body));
    request["operation"] = "WAITING_TIME";
    delete request.startDate;
    delete request.endDate;
    this.dashboardService.dashboardData(request)
      .subscribe (
        response => {
          if (response && response.avg_waiting_time != undefined)
            this.taskStatistics.avg_waiting_time = response.avg_waiting_time;
        },
        error => {

        }
      )
  }

  getAvgTATTime(body: any) {
    const request = JSON.parse(JSON.stringify(body));
    request["operation"] = "AVG_TAT";
    delete request.startDate;
    delete request.endDate;
    this.dashboardService.dashboardData(request)
      .subscribe (
        response => {
          if (response && response.avg_task_tat != undefined)
            this.taskStatistics.avg_task_tat = response.avg_task_tat;
        },
        error => {

        }
      )
  }

  getAvgFirstResponseTime(body: any) {
    const request = JSON.parse(JSON.stringify(body));
    request["operation"] = "AVG_FIRST_RESPONSE_TIME";
    delete request.startDate;
    delete request.endDate;
    this.dashboardService.dashboardData(request)
      .subscribe (
        response => {
          if (response && response.avg_first_response_time != undefined)
            this.taskStatistics.avg_first_response_time = response.avg_first_response_time;
        },
        error => {

        }
      )
  }

  getTopTasks(body: any) {
    const request = JSON.parse(JSON.stringify(body));
    request["operation"] = "TOP_TASKS";
    delete request.startDate;
    delete request.endDate;
    this.dashboardService.dashboardData(request)
      .subscribe (
        response => {
          if (response && response.length > 0)
            this.taskStatistics.topTasks = response;
        },
        error => {

        }
      )
  }

  getCompletedTasksTimeline(body: any) {
    const request = JSON.parse(JSON.stringify(body));
    request["operation"] = "COMPLETED_TASK_TIMELINE";
    request["completedTaskTimeInterval"] = 24*60*60;
    this.dashboardService.dashboardData(request, true)
      .subscribe (
        response => {
          if (response)
            this.tasksTimeline = response
        },
        error => {

        }
      )
  
  }

  getTaskStatistics(body: any) {
    this.dashboardService.task_statistics(body)
      .subscribe(taskStatistics => {
        // console.log(taskStatistics);
        this.taskStatistics = taskStatistics;
      });
  }

  getAgentTaskBoard(body: any) {
    const request = JSON.parse(JSON.stringify(body));
    if (request.childUserIds && request.childUserIds.length > 0) {
      const childUserIds = JSON.parse(JSON.stringify(request.childUserIds));
      childUserIds.push(request.userId);
      this.agentboard = [];
      while (true) {
        if (!childUserIds || childUserIds.length == 0) {
          break;
        }
        const tempChildUserIds = childUserIds.splice(0, 2);
        if (tempChildUserIds) {
          
          request.childUserIds = tempChildUserIds;
          this.dashboardService.agents_task_board(request)
          .subscribe(agentboard => {
            // console.log(this.agentboard);
            if (!this.agentboard) {
              this.agentboard = [];
            }
            this.agentboard = this.agentboard.concat(agentboard);
            this.agentboard.sort(this.compareCompletedTasks(this));
            
            // this.agentboard = agentboard;
          });
        }
      }
    }
    
  }

  compareCompletedTasks(domRef ) {
    return function(a,b){
      if (domRef && domRef.user && a.userId == domRef.user._id) {
        return 0;
      }
      if ( a.completed_tasks < b.completed_tasks ){
        return 1;
      }
      if ( a.completed_tasks > b.completed_tasks ){
        return -1;
      }
      return 0;
    }
  }

  getDashboardSummary(body: any) {
    this.dashboardService.dashboard_summary(body)
      .subscribe(dashboardSummary => {
        // console.log(dashboardSummary);
        this.dashboardSummary = dashboardSummary;
      });
  }

  getUserChildren(): void {
    this.fetchUserService.fetchChildrensUserObjList()
      .subscribe(
        (userList) => {
          if (userList && userList.length > 0) {
            this.users = userList;
            for (let user of userList) {
              this.groups = this.groups.concat(user.groupAsStringList);
              this.childUserIds.push(user._id);
            }
          }
          else {
            this.groups = this.user.groupAsStringList;
          }
          this.fetchResult(this.range, true);

        },
        (error) => {
          if (error.status == 401) {
            // this.router.navigateByUrl
          }
        });
  }

  getUserGroups(userId: string) {
    this.fetchUserService.getUser(userId)
      .subscribe(user => {
        this.groups = this.groups.concat(user.groupAsStringList);
        this.fetchResult(this.range, true);
      });
  }

  performAction(notification: NotificationPi) {
    this.markNotificationAsRead(notification);
  }

  markNotificationAsRead(notification: NotificationPi) {
    // console.log(notification.createdAt);
    this.notificationService.markNotificationAsRead(notification._id).subscribe(data => {
      if (data["success"]) {
        notification.notificationSeen = true;
        if (notification.entityType == "Task") {
          this.router.navigate(['task', notification.associatedEntityId]);
        }
      }
    })
  }

  setDateTime() {
    let now = new Date();
    this.today = formatDate(now, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
  }

  fetchNotifications() {
    this.notificationService.fetchNotifications(this.user._id)
      .subscribe(notifications => {
        if (notifications != null && notifications.length > 0) {
          this.notifications = notifications;
          this.notificationCount = notifications.length;
        }
      });
  }
  gotoInbox(user, tasktype?) {
    let temp = {
      searchForUser: user
    };
    if (tasktype) {
      temp['taskType'] = tasktype;
    }
    this.router.navigateByUrl('/task', { state: temp });
  }

  getQueryParams(taskType: string) {
    if (this.childUserIds && this.childUserIds.length > 0) {
      taskType = "TEAM_" + taskType;
    }
    const params: any = {};
    params["taskType"] = taskType;
    if (this.startTime && this.endTime) {
      params["startTime"] = this.startTime.format();
      params["endTime"] = this.endTime.format();
    }
    return params;
  }
}
