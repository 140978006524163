import { Component, OnInit, Input, Output, EventEmitter, NgZone, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Customer } from 'src/app/models/customer.model';
import { CommunicationService, DataSharingService, UniversalUser } from 'src/app/service/shared.service';
import { CustomerInteraction } from 'src/app/models/customer.model';
import { OneViewService } from 'src/app/service/oneview.service';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { StateService } from 'src/app/service/state.service';
import { MatSnackBar } from '@angular/material';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { DataModelService } from 'src/app/service/datamodel.service';
import { CommonSearchModel } from 'src/app/models/shared.model';
import { ConversationService } from 'src/app/service/helper.service';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvironmentData } from 'src/app/service/environments.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ImagepdfviewerComponent } from 'src/app/shared/imagepdfviewer/imagepdfviewer.component';
import { SingleMailComponent } from 'src/app/shared/single-mail/single-mail.component';
import { EmailPersister } from 'src/app/models/helper.model';
import { EmailService } from 'src/app/service/helper.service';
import { DialerService } from 'src/app/service/dialer.service';
import * as moment from 'moment';
@Component({
  selector: 'taskapp-one-view',
  templateUrl: './one-view.component.html',
  styleUrls: ['./one-view.component.scss'],
})
export class OneViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currUserId;
  @Input() taskDetails;
  @Output() closeEvent = new EventEmitter();
  @Output() selectedCustomerEmit = new EventEmitter();
  bsModalRef: BsModalRef;
  currentTab: any;
  selectedCustomer: Customer;
  customerId: string;
  interactionsPage: number;
  activitiesPage: number;
  processesPage: number;
  conversationPage: number;
  fetchRecords: number;
  interactionsList: any;
  totalInteractions: number = 0;
  totalActivities: number = 0;
  totalProcesses: number = 0;
  totalConversations: number = 0;
  activityList: any[];
  processList: CustomerInteraction[];
  conversationList: CustomerInteraction[];
  showCustomerDetail: boolean = true;
  customerName: string;
  isEditFlag: boolean = false;
  bsModelDelete: BsModalRef;
  bsModalMerge: BsModalRef;
  showPerAddress: boolean = true;
  isMergeFlag: boolean = false;
  selectedCustomers: string[] = [];
  dataModelList: any[] = [];
  companyId: string = "";
  editCustomerFlag: boolean = false;
  timeZoneList = [
    {
      text: "LOCAL",
      value: "LOCAL"
    },
    {
      text: "UTC",
      value: "UTC"
    }
  ]
  mySlideOptions: OwlOptions = {
    items: 4,
    dots: false,
    nav: true,
    loop: false,
    autoplay: false,
    autoWidth: true,
    margin: 0,
    autoplayHoverPause: true,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],

  };
  myTabs: any[] = [
    { "name": "allForms", "type": "Entity", "label": "Forms" },
    { "name": "documents", "type": "docs", "label": "Documents" },
    { "name": "emails", "type": "emails", "label": "Emails" },
    { "name": "callLogs", "type": "calls", "label": "Call Logs" }
  ]
  genders: string[];

  constructor(
    private oneViewService: OneViewService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private stateService: StateService,
    private snackBar: MatSnackBar,
    private modalService: BsModalService,
    private ngZone: NgZone,
    private sanitizer: DomSanitizer,
    private dataModelService: DataModelService,
    private conversationService: ConversationService,
    private universalUser: UniversalUser,
    private environmentData: EnvironmentData,
    private dataSharingService: DataSharingService,
    private communicationService: CommunicationService,
    private emailService: EmailService,
    public dialerService: DialerService
  ) {
    this.selectedCustomer = new Customer();
    this.interactionsList = [];
    this.activityList = [];
    this.processList = [];
    this.conversationList = [];
    this.genders = ["Male", "Female", "Other"];
    this.currentTab = this.myTabs[0];
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currUserId  && changes.currUserId.previousValue != changes.currUserId.currentValue) {
      if (changes.currUserId.currentValue) {
        this.customerId = this.currUserId
        this.getCustomer();
        this.getDataModelList();
      }
      if(this.taskDetails){
      }
      this.interactionsPage = 1;
      this.activitiesPage = 1;
      this.processesPage = 1;
      this.conversationPage = 1;
      this.fetchRecords = 50;
      this.getInteractionCount();
      this.getMenubarDataModels();
      this.getEmailsForCustomer()
    }
  }
  ngOnInit() {
  }

  customerSelect(customerId) {
    if (customerId) {
      this.currActivityList = [];
      this.activityList = [];
      this.customerId = customerId
      this.getCustomer();
      this.getDataModelList();
      this.interactionsPage = 1;
      this.activitiesPage = 1;
      this.processesPage = 1;
      this.conversationPage = 1;
      this.fetchRecords = 50;
      this.getInteractionCount();
      this.getMenubarDataModels();
      this.selectedCustomerEmit.emit(customerId);
    } else {
      this.customerName = null
    }

  }
  getCustomer() {
    this.oneViewService.getCustomer(this.customerId)
      .subscribe(customer => {
        if (customer) {
          this.selectedCustomer = customer;
          this.customerName = this.getName();
        }
      });
  }
  getName(): string {
    if (this.selectedCustomer.name) {
      return this.selectedCustomer.name
    }
    else if (this.selectedCustomer.firstName && this.selectedCustomer.middleName && this.selectedCustomer.lastName) {
      return this.selectedCustomer.firstName + " " + " " + this.selectedCustomer.lastName;
    }
    else if (this.selectedCustomer.firstName && this.selectedCustomer.middleName) {
      return this.selectedCustomer.firstName + " " + this.selectedCustomer.middleName;
    }
    else if (this.selectedCustomer.firstName && this.selectedCustomer.lastName) {
      return this.selectedCustomer.firstName + " " + this.selectedCustomer.lastName;
    }
    else if (this.selectedCustomer.firstName) {
      return this.selectedCustomer.firstName;
    }
    else if (this.selectedCustomer.lastName) {
      return this.selectedCustomer.lastName;
    }
    else if (this.selectedCustomer.email) {
      if (this.selectedCustomer.email.includes("<")) {
        return this.selectedCustomer.email.split('<')[0]
      }
      else {
        return this.selectedCustomer.email.split('@')[0]
      }

    }
    else {
      return "--";
    }
  }
  filterQuery:string="";
  getDataModelList() {
    const commonsearchModel = new CommonSearchModel();
    commonsearchModel.searchParams = [{ 'statusCd': 'ACTIVE' }];
    commonsearchModel.returnFields = ['label', 'version', 'statusCd', 'name', 'subEntity', 'type'];
    this.dataModelService.getDataModelList(commonsearchModel)
      .pipe()
      .subscribe(list => {
        if (list != null && list.length > 0) {
          for (let item of list) {
            this.dataModelList.push({ display: item.label, value: item.name, id: item._id, name: item.name, statusCd: item.statusCd })
          }

        }
      });
  }
  currentDetailsCard: string = '';
  getInteractionCount() {
    this.oneViewService.getInteractionsCount(this.customerId)
      .subscribe(interactionCount => {
        if (interactionCount) {
          this.totalInteractions = this.interactionsList.length;
          this.totalActivities = interactionCount["activityCount"];
          this.totalConversations = interactionCount["conversationCount"];
          this.totalProcesses = interactionCount["processCount"];
        }
        this.getInteractions(0)
      });
  }

  getInteractions(pagenumber) {
    this.currActivityList = [];
    // console.log("here")
    let escMap = {
      "criteria": {
        "interactionType": "entity",
        "customerId": this.customerId
      }

    }
    this.oneViewService.getInteractions(pagenumber, this.fetchRecords, escMap)
      .subscribe(interactionList => {
        if (interactionList) {
          let interactions = interactionList.sort(function (a, b) {
            return (b['lastUpdatedDate']) - (a['lastUpdatedDate']);
          })
          for (let index = 0; index < interactions.length; index++) {
            this.activityList.push(interactions[index])
          }
          this.currActivityList = this.activityList;
        }
      });
  }
  openCardDetails(cardId) {
    if (this.currentDetailsCard == cardId) {
      this.currentDetailsCard = '';
    } else {
      this.currentDetailsCard = cardId;
    }
  }
  getInteractionsByDataModel(datamodelName) {
    this.currActivityList = this.activityList.filter(item => item.entityName == datamodelName);
  }
  getDocumentsForCustomer() {
    this.currActivityList = [];
    this.oneViewService.getMiniOneViewCustomerDocuments(this.customerId)
      .subscribe(documentList => {
        if (documentList && documentList.length > 0) {
          let documents = documentList.sort(function (a, b) {
            return (b['createdTime']) - (a['createdTime']);
          })

          this.currActivityList = documentList;
        }
      });
  }

  viewDoc(document) {
    let tempURL = document.downloadFileUrl;
    // console.log(document)
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: false,
      ignoreBackdropClick: true,
      initialState: {
        doc: document,
        title: 'Document Title',
        entityView: true
      }
    };
    this.bsModalRef = this.modalService.show(ImagepdfviewerComponent, config);
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.event.subscribe(res => {
      for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
        this.modalService.hide(i);
      }
      // if (res.res ==2133) {
      //   this.onUpdateDocument(res.data);
      // }
      const div = document.querySelector('body');
      div.classList.remove('modal-open');
    });
  }


  getEmailsForCustomer() {
    this.currActivityList = [];
    this.oneViewService.getMiniOneViewCustomerEmails(this.customerId)
      .subscribe(emailList => {
        if (emailList && emailList.length > 0) {
          let documents = emailList.sort(function (a, b) {
            return (b['emailTime']) - (a['emailTime']);
          })

          this.currActivityList = emailList;
        }
      });
  }
  giveBackStart(emailFrom: string) {
    return emailFrom.substring(0, 1).toUpperCase();
  }
  openModalEmailWithComponent(email, mode?) {
    email['tmpDisplay'] = email['htmlText']
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: false,
      ignoreBackdropClick: true,
      initialState: {
        email: email,
        title: 'Case Email',
        type: 'view',
        singleViewer: true
      },
      class: 'fullModalWidth emailModal'
    };
    this.bsModalRef = this.modalService.show(SingleMailComponent, config);
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onHide.subscribe((e) => {
      this.bsModalRef.hide();
    })

  }

  getCallLogsForCustomer() {
    this.currActivityList = [];
    this.oneViewService.getMiniOneViewCustomerCallLogs(this.customerId)
      .subscribe(callLogsList => {

        if (callLogsList && callLogsList.length > 0) {
          let documents = callLogsList.sort(function (a, b) {
            return (b['call_connected_datetime']) - (a['call_connected_datetime']);
          })

          this.currActivityList = callLogsList;
        }
      });
  }
  getDuration(startDate, endDate) {
    if(!endDate || !startDate){
      return 0;
    }else{
    let d1 = (this.pyToJsDate(endDate));
    let d2 = (this.pyToJsDate(startDate));
    return (d1.diff(d2,"seconds"));

    // return Math.floor(d1 - d2 / 60) % 60;
    }
    return 0;
  }

  getTimeForView(date) {
    return this.datePipe.transform(date, 'HH:mm');
  }
  getDateForView(date) {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }
  getKey(interaction) {
    return interaction["key"];
  }
  entityId = "";
  entityName = "";
  entityLabel = "";
  agentId = "";
  interactionCardType = "";
  workbenchChatHistotyURL: any;
  baseURL = "";
  showDetails: string = "";
  goToIneraction(interaction: CustomerInteraction) {
    this.entityId = "";
    this.entityName = "";
    this.entityLabel = "";
    this.agentId = "";
    this.interactionCardType = "";
    this.workbenchChatHistotyURL = "";
    if (interaction.interactionType == "entity") {
      this.companyId = this.universalUser.getUser().companyId;
      this.entityId = interaction.interactionId;
      this.entityName = interaction.interactionName;
      this.entityLabel = interaction.interactionLabel;
      this.interactionCardType = "entity";
      this.baseURL = this.environmentData.getEnvData().rootUrl;
      // this.getAssociatedEntity();
      // if(customElements.get('pi-blocks') == undefined){
      // this.loadScript("assets/element-pi-blocks.js");
      // this.loadPiBlocksCss("assets/element-pi-blocks.css");
      // }
      this.showDetails = "entity";
    }
    else if (interaction.interactionType == "episode") {
      this.interactionCardType = "episode";
      this.conversationService.getEpisode(interaction.interactionId)
        .subscribe(episode => {
          if (episode && episode._id) {
            this.agentId = episode.agentId;
            this.entityId = interaction.interactionId;
            this.workbenchChatHistotyURL = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.environmentData.getEnvData().workbenchBaseURL}${this.agentId}/${this.entityId}`);
          }
        });
      // this.router.navigate(['/conversation'], { queryParams: { entityId: interaction.interactionId, customerId: interaction.customerId } });
    }
  }


  onSaveOrSubmit(entity: any) {
    // if(entity["type"] == 'onsubmit'){
    //   this.snackBar.open(this.selectedDataModel.label.toString() + ' has been submitted successfully', "Close", {
    //     duration: 5000
    //   })
    //   this.router.navigateByUrl("/activities");
    // }
    // else if(entity["type"] == 'onsave'){
    //   this.snackBar.open(this.selectedDataModel.label.toString() + ' has been saved successfully', "Close", {
    //     duration: 5000
    //   })
    // }
  }
  piBlocksDivId: string = "piBlockDivScript";
  piBlocksCSSId: string = "piBlockDivCss";
  piblockApiLoaded: boolean = false;

  loadScript(scriptURL: string) {
    return new Promise((resolve, reject) => {
      if (!document.getElementById(this.piBlocksDivId)) {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = this.piBlocksDivId;
        script.src = scriptURL;
        script.onload = () => {
          this.piblockApiLoaded = true;
          resolve({ script: scriptURL, loaded: true, status: 'Loaded' });
        };
        script.onerror = (error: any) => resolve({ script: scriptURL, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
        this.interactionCardType = 'entity';
      }

    });
  }

  loadPiBlocksCss(linkUrl: string): void {
    const link = document.createElement('link');
    link.id = this.piBlocksCSSId;
    link.rel = 'stylesheet';
    link.href = linkUrl;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  unloadPiBlocksCss(): void {
    let elementCSSLink = document.getElementById(this.piBlocksCSSId);
    if (elementCSSLink) {
      document.head.removeChild(elementCSSLink);
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    let elementJsFileBlock = document.getElementById(this.piBlocksDivId);
    if (elementJsFileBlock) {
      document.head.removeChild(elementJsFileBlock);
    }

    this.unloadPiBlocksCss();
  }
  addDataModelByUser(datamodel) {
    this.interactionCardType = '';
    this.entityName = datamodel.name;
    this.entityId = "";


    this.companyId = this.universalUser.getUser().companyId;
    this.entityName = datamodel.name;
    this.entityLabel = datamodel.display;
    this.baseURL = this.environmentData.getEnvData().rootUrl;
    // this.getAssociatedEntity();
    this.loadScript("assets/element-pi-blocks.js");
    this.loadPiBlocksCss("assets/element-pi-blocks.css");
    // this.router.navigate(['/add-activities'], { queryParams: { customerId: this.customerId } });
  }
  generateLink(selectedCustomer: Customer) {
    if (this.dialerService.isConfigPresent() && (selectedCustomer.mobileNumber != null || selectedCustomer.phoneNumber != null)) {
      return 'dialler';
    } else if (!this.dialerService.isConfigPresent() && (selectedCustomer.mobileNumber != null || selectedCustomer.phoneNumber != null)) {
      return 'tel'
    } else {
      return 'default'
    }
  }
  callFromDialler(selectedCustomer: Customer) {
    let callRequest = {};
    if (selectedCustomer.mobileNumber != null) {
      callRequest = {
        "mobile_number": selectedCustomer.mobileNumber,
        "userId": this.universalUser.getUser()._id
      }
    } else {
      callRequest = {
        "mobile_number": selectedCustomer.phoneNumber,
        "userId": this.universalUser.getUser()._id
      }

    }
    if(this.taskDetails){
      callRequest['machineType'] = this.taskDetails.machineType;
      callRequest['entityId'] = this.taskDetails.entityId;
    }
    this.communicationService.diallerCallCustomer(callRequest).subscribe(
      (response) => { 
        // console.log(response)
       },
      (error) => { console.log(error) }
    );
  }
  currActivityList: any[] = [];
  openSection(tabDetail) {
    this.currentTab = tabDetail;
    switch (this.currentTab.name) {
      case 'allForms':
        this.currActivityList = JSON.parse(JSON.stringify(this.activityList));
        break;
      case 'documents':
        this.getDocumentsForCustomer();
        break;
      case 'emails':
        this.getEmailsForCustomer();
        break;
      case 'callLogs':
        this.getCallLogsForCustomer();
        break;
      default:
        if(this.currentTab && this.currentTab.name){
        this.getInteractionsByDataModel(this.currentTab.name)
      }
        break;
    }

  }
  tabListFilled: boolean = false;
  getMenubarDataModels() {
    if (this.tabListFilled == false) {
      this.dataModelService.dataModelFav().subscribe(
        (list) => {
          if (list && list.length > 0) {
            for (let index = 0; index < list.length; index++) {
              const element = list[index];
              let pos = this.myTabs.map(item => item.name).indexOf(element.name)
              if (pos == -1) {
                let tmp = { "name": element.name, "type": element.type, "label": element.label }
                this.myTabs.splice(1, 0, tmp);
                // this.myTabs.unshift(tmp)
              }
            }
            this.tabListFilled = true;
            this.openSection(this.myTabs[0])
          }else{
            this.tabListFilled = true;
          }
        },
        (error) => {

        }
      );
    }
  }
  shortenName(str) {
    let opStr = "-"
    if (str) {
      if (str.split(" ").length > 1) {
        let strArr = str.split(" ");
        opStr = strArr[0].substring(0, 1) + strArr[1].substring(0, 1)
        opStr = opStr.toUpperCase();
      } else {
        opStr = str.substring(0, 2).toUpperCase();
      }
    }
    return opStr;
  }
  updateCustomer() {
    this.oneViewService.updateCustomer(this.selectedCustomer).subscribe(customer => {
      if (customer) {
        this.selectedCustomer = customer;
        this.editCustomerFlag = false;
      }
    });
  }
  pyToJsDate(dateString) {
    if (typeof dateString == 'object') {
        let tempDate = new Date();
        tempDate.setFullYear(dateString[0])
        tempDate.setMonth(dateString[1] - 1)
        tempDate.setDate(dateString[2])
        tempDate.setHours(dateString[3]+5)
        tempDate.setMinutes(dateString[4]+30)
        tempDate.setSeconds(dateString[5])
        var date = moment(tempDate);
        if (date.isValid()) {
          return moment(tempDate);
        } else {
          return moment(new Date());
        }
    } else {
      var date = moment(new Date(+dateString));
        if (date.isValid()) {
          return moment(new Date(+dateString));
        } else {
          return moment(new Date());
        }
    }
}
toggleEditCustomer(){
  this.editCustomerFlag = !this.editCustomerFlag;
}
formFullName(){
  let tmp = "";
  if (this.selectedCustomer.firstName!=null && this.selectedCustomer.firstName!="") {
    tmp += this.selectedCustomer.firstName;
  }
  if (this.selectedCustomer.middleName!=null && this.selectedCustomer.middleName!="") {
    tmp = tmp + " " + this.selectedCustomer.middleName;
  }
  if (this.selectedCustomer.lastName!=null && this.selectedCustomer.lastName!="") {
    tmp = tmp + " " +  this.selectedCustomer.lastName;
  }
  this.selectedCustomer.name = tmp
}
}
