import { Component, OnInit , Input,Output,EventEmitter} from '@angular/core';
import { UserHierarchy,User} from '../../models/user.model'
import { UniversalUser } from 'src/app/service/shared.service';


@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.css']
})
export class TeamListComponent implements OnInit {

 @Input() userOutput: UserHierarchy[];
 @Input() peersOutput: any[] = [];
 @Input() tags:any;
 @Output() userEvent = new EventEmitter<User>();
 @Output() allocateEvent = new EventEmitter();
 @Output() closeEvent = new EventEmitter();

 tempUser:User
 type:string;
 taskTags:any[]=[];
 tagsArr:string[] = []
 remarks:string;
 emitObject:any = {};
 userType = ["Team"];
 allocationUsers:any[] = [];  
 
  constructor(
    private universalUser: UniversalUser
  ) { }

  ngOnInit() {
    if(this.userOutput.length > 0){
      this.type = "Team";
      this.allocationUsers = this.userOutput;
    }
    else if(this.peersOutput.length > 0){
      this.type = "Peer";
      this.allocationUsers = this.peersOutput;
    }

    if (this.universalUser.getUser() && !this.universalUser.getUser().restrictPeersAllocation) {
      this.userType.push("Peer");
    }
    
  }

  sendUser(user:User) {
    this.userEvent.emit(user)
  }

  selectUserType(type:String){
    if(type == "Team"){
      this.allocationUsers = this.userOutput;
    }
    else if(type == "Peer"){
      this.allocationUsers = this.peersOutput;
    }
  }

  allocateTask(){
    let tag:any;
    for(tag of this.taskTags){
      this.tagsArr.push(tag.display);
    }
    this.emitObject.tags = this.tagsArr;
    this.emitObject.remarks = this.remarks;
    this.allocateEvent.emit(this.tagsArr);
  }

  closeSection(){
    this.closeEvent.emit();
  }

}
