import { BaseModel } from './base.model';

export class UserGroup {
  userGroupCd: string;
  expression: string;
  parentUserGroupCd: string;
  escalationUserGroupCd: string;

  constructor() {
    this.userGroupCd = '';
    this.expression = '';
    this.parentUserGroupCd = '';
    this.escalationUserGroupCd = '';
  }
}

export class User extends BaseModel {
  username: string;
  password: string;
  email: string;
  mobile: string;
  name: string;
  designation:string;
  enabled: boolean;
  authorities: string[];
  groups: UserGroup[];
  groupAsStringList: string[];
  credentialsNonExpired: boolean;
  accountNonLocked: boolean;
  accountNonExpired: boolean;
  autoAssignFlag: boolean;
  sla: SLA;
  alertPermission:{}
  isWhiteLabel: boolean;
  logoUrl: string;
  imageUrl: string;
  restrictPeersAllocation: boolean;
  uspEnable: boolean;
  currentCompanyId:string;
  selectedApplicationId:string;
  companyAccountStage:string;
  changePwdOnLogin: boolean;

  constructor() {
    super();

    this.username = '';
    this.password = '';
    this.email = '';
    this.name = '';
    this.designation = '';
    this.enabled = true;
    this.authorities = [];
    this.groups = [];
    this.groupAsStringList = [];
    this.credentialsNonExpired = true;
    this.accountNonLocked = true;
    this.accountNonExpired = true;
    this.autoAssignFlag = false;
    this.sla = new SLA();
    this.isWhiteLabel=false;
    this.logoUrl='';
    this.imageUrl = null;
    this.restrictPeersAllocation = false;
    this.currentCompanyId = '';
    this.selectedApplicationId = "";
    this.changePwdOnLogin = false;
  }
}
export class UserHierarchy extends BaseModel {

  parentUserId: string;
  userId: string;
  userName: string;
  parentUserName: string;
  reportingUserId: string
  reportingUserName: string;
  companyId: string;
  email:string;

  constructor() {
    super();
    this.parentUserId = '';
    this.userName = '';
    this.parentUserName = '';
    this.reportingUserId = '';
    this.reportingUserName = '';
    this.userId = "";

  }
}

export class UserGraphObject extends BaseModel {

  xml: string;
  userHierarchy: UserHierarchy[];

  constructor() {
    super();
    this.xml = "";
    this.userHierarchy = [];
  }
}


export class SLA extends BaseModel {

  slaUnit: string;
  level: number;
  unit: number;
  escalationLevel: number;


  constructor() {
    super();
    this.slaUnit = '';
    this.level = 0;
    this.unit = 0;
    this.escalationLevel = 0;

  }
}

export class Customer extends BaseModel {
  firstName:string;
  middleName:string;
  lastName:string;
  nickName:string;
  profileUrl:string;
  pictureUrl:string;
  websiteUrl:string;
  email:string;
  emailVerified:boolean = false;
  gender:string;
  birthDate:string;
  timeZone:string;
  locale:string;
  phoneNumber:string;
  mobileNumber:string;
  phoneNumberVerified:boolean = false;
  mobileNUmberVerified:boolean = false;
  permenantAddress:string;
  currentAddress:string;
  permenantAddSameAsCurrent:boolean = false;


  constructor() {
    super();
    this.firstName="";
    this.lastName="";
  }
}

export class UserMapping extends BaseModel {
  userId: string;
  username: string;
  target: string;
  agentStatus: string;
  reason: string;
  additionalParams: KeyValue[];
}

export class KeyValue {
  key: string;
  value: string;
}