import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomDashboard, CustomDashboardComponent } from '../models/customDashboard.model';
import { CustomDashboardService } from '../service/dashboard.service';

@Component({
  selector: 'app-custom-dashboard-list',
  templateUrl: './custom-dashboard-list.component.html',
  styleUrls: ['./custom-dashboard-list.component.css']
})
export class CustomDashboardListComponent implements OnInit {
  componentList: any[] = [];
  customDashboard:CustomDashboard
  private subscription: Subscription;
  constructor(
    private customDashboardService: CustomDashboardService
  ) { }

  ngOnInit() {
    this.getDashboardList();
  }
  getAllComponents() {
    this.customDashboardService.getAllComponents().subscribe(
      (response) => {
        if (response && response.data) {
          this.componentList = response.data
          const customDashboard:CustomDashboard =this.customDashboardService.getSharedDashboard()
          // console.log(response.data)
          
        }
      },
      (error) => { },
    );
  }
  getDashboardList() {
    this.subscription = this.customDashboardService.getAllDashboards()
      .subscribe(dashboardList => {
        if (dashboardList && dashboardList.data) {
          // console.log(dashboardList)
        }
      });
  }
}
