import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';


import { USPSearchRequest, USPSearchResult } from '../models/usp.model';
import {EnvironmentData} from './environments.service';
import { Observable, Subject } from "rxjs";




@Injectable()
export class USPService {
    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json'});
    constructor(private httpClient: HttpClient, private flowEnvironments: EnvironmentData) { }

    getEntities(): Observable<string[]> {
        const envData = this.flowEnvironments.getEnvData();
        const subject = new Subject<string[]>();
        const url = `${envData.rootUrl + envData.interfaceService + envData.uspentitties}`;
        this.httpClient.post<string[]> (
            url,
            {},
            {
                headers: this.httpHeaders,
                observe: 'response',
                reportProgress: true,
                withCredentials: true 
            }
        ).subscribe(
            (response: HttpResponse<string[]>) => {
                subject.next(response.body);
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
        
    }
    
    search(uspSearchRequest: USPSearchRequest, type:string) {
        const envData = this.flowEnvironments.getEnvData();
        const subject = new Subject<any>();
        const url = `${envData.rootUrl + envData.statemachineroot + envData.universalSearch}` + type;
        this.httpClient.post<any>(
            url,
            uspSearchRequest,
            {
                headers: this.httpHeaders,
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                subject.next(response.body);
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
    }

    selfTrainSerchData(highlights, query, entityName) {
        const envData = this.flowEnvironments.getEnvData();
        const subject = new Subject<any>();
        var selfTrainRequest = {
            highlight: highlights,
            searchText : query,
            entityName: entityName
        }
        const url = `${envData.rootUrl + envData.interfaceService + envData.uspselftrain}`;
        this.httpClient.post<any>(
            url,
            selfTrainRequest,
            {
                headers: this.httpHeaders,
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                subject.next(response.body);
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        
    }

}