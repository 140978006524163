import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncatetitle'
})
export class TruncateTitlePipe implements PipeTransform {

  transform(str: any, noOfChars: any=35,  ...args: any[]): any {
    if(!args) {
      return null;
    }
    if(!str) {
      return null;
    }else{
      let opStr = ""
      if (str.length > noOfChars) {
        opStr = str.substring(0, +noOfChars) + "..."
      } else {
        opStr = str
      }
      return opStr;
    }
  }
}