  import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, HostListener, ElementRef } from '@angular/core';
  import { MatSort, MatTableDataSource, MatTable } from '@angular/material';
  import { State } from 'src/app/models/tasks.model';
  import { CommunicationService } from 'src/app/service/shared.service';
  
  @Component({
    selector: 'app-task-listing',
    templateUrl: './task-listing.component.html',
    styleUrls: ['./task-listing.component.scss']
  })
  export class TaskListingComponent implements OnInit, OnChanges {
    @Input() searchQuery: string;
    filterQuery: string
    @Input() taskList: any = [];
    assignedStates: any
    @Input() assignedTask: State;
    assignedTaskDdetails: State;
    @Input() stateClass;
    assignedStateTabclass: any;
    @Input() userName: any;
    @Input() showBulk: boolean;
    @Input() totalTaskCount: number;

    @ViewChild(MatTable, { static: false }) table: MatTable<any>;

    @Output() selectedState = new EventEmitter<State>(false);
    @Output() loadMoreTasks = new EventEmitter<boolean>(false);
    @Output() emitSelectedBulkTasks = new EventEmitter<any>(false);
    recordsMessage: string = "";
    displayedColumns = ['task'];
    selectedBulk: State[] = [];
    constructor(
      private communicationService:CommunicationService
    ) { }
        selectedActiveTask:any;
    @ViewChild('loadMore', { static: true }) loadMoreDiv: ElementRef;
    ngOnInit() {
      // this.assignedStates = new MatTableDataSource(this.taskList);
      this.filterQuery = this.searchQuery;
      this.assignedTaskDdetails = this.assignedTask;

    }
    ngOnChanges(changes: SimpleChanges): void {
      if (changes.taskList && changes.taskList.currentValue) {
        this.assignedStates = new MatTableDataSource(changes.taskList.currentValue);
        for (let index = 0; index < changes.taskList.currentValue.length; index++) {
          const element = changes.taskList.currentValue[index];
          if(element.cssClass.indexOf('active')>-1){
            this.selectedActiveTask = element;
            if(element && element._id){
              setTimeout(() => {
                let el = document.getElementById('div'+element._id);
                if(el) {
                  let topPos =(el.offsetTop);
                  document.getElementById('scrolling_div').scrollTop = topPos;
                }
              }, 1000);
            }
          }
        }
      }
      if (changes.searchQuery) {
        this.filterQuery = changes.searchQuery.currentValue; this.applyFilter(this.filterQuery)
      }
      if (changes.assignedTask) {
        this.assignedTaskDdetails = changes.assignedTask.currentValue;
      }
      if (changes.stateClass) {
        this.assignedStateTabclass = changes.stateClass.currentValue;
      }

      // console.log(changes)
    }

    refreshDatasource() {
      this.assignedStates._updateChangeSubscription();
    }
    checkifInUpdate(task){
      if(this.communicationService && this.communicationService.bulkTaskUpdated && this.communicationService.bulkTaskUpdated.indexOf(task.entityId) > -1){
        return true;
      }
      return false;
    }
    selectTask(state) {
      // console.log(state)
      if (this.showBulk == false) {
        this.selectedState.emit(state)
      }
    }
    selectForBulk(event,state?: State) {
      if (state) {
        let pos = this.selectedBulk.map(item => item._id).indexOf(state._id);
        if (pos == -1) {
          this.selectedBulk.push(state);
        } else {
          this.selectedBulk.splice(pos, 1)
        }
      } else {
        if (event.target.checked == true) {
          this.selectedBulk =this.assignedStates.data;
        } else {
          this.selectedBulk=[];  
        }
        
      }
      
      this.emitSelectedBulkTasks.emit(this.selectedBulk);
    }
    checkifAddedToBulk(state: State) {
      let pos = this.selectedBulk.map(item => item._id).indexOf(state._id);
      if (pos == -1) {
        return false;
      } else {
        return true;
      }
    }
    noRecordsFound() {
      if (this.assignedStates && this.assignedStates.length == 0) {
        this.recordsMessage = "No Records Found";
      }
      return this.recordsMessage;
    }
    hideAfterAt(str) {
      if (str) {
        return str.split("@")[0]
      }
      return str
    }
    applyFilter(filterValue: string) {
      this.assignedStates.filter = filterValue ? filterValue.trim().toLowerCase() : null;
    }
    loadMore() {
      this.loadMoreTasks.emit(true)
    }
    pyToJsDate(daetString) {
      let tempDate = new Date();
      tempDate.setDate(daetString[2])
      tempDate.setFullYear(daetString[0])
      tempDate.setMonth(daetString[1] - 1)
      tempDate.setHours(daetString[3])
      tempDate.setMinutes(daetString[4])
      tempDate.setSeconds(daetString[5])
      tempDate.setMilliseconds(daetString[6])
      return (tempDate)
    }
    compareLongDates(dt1,dt2){
      if(dt1+"".substring(dt1+"".length-2,2)== dt2+"".substring(dt2+"".length-2,2)) return true
      return false
    }

    getTaskLabel(assignedState:State, lengthOfChar, fullTextFlag:boolean){
      let outputStr = ""
      if(assignedState.taskLabel && assignedState.businessKey){
        outputStr =  assignedState.taskLabel;
      }else if(assignedState.taskLabel){
        outputStr = assignedState.taskLabel;
      }
      else if(assignedState.businessKey){
        outputStr =  assignedState.businessKey;
      }else{
        outputStr = assignedState.stateCd
      }
      if(outputStr.length > lengthOfChar && !fullTextFlag){
        return outputStr.substring(0,lengthOfChar) + "...";
      }else{
        return outputStr
      }
    }
  }
