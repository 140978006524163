export class USPSearchRequest {
    searchText: string;
    fromTime:string =null;
    toTime:string =null;
    statusCdList:any[]=[];
    pageNo: number;
    pageSize:number = 5;
    
    constructor(searchText?: string) {
        this.searchText = searchText ? searchText : null;
    }
}

export class USPSearchResult {
    result: USPSearchResultData[];
    scrollId: string;

    constructor(result?: USPSearchResultData[], scrollId?: string) {
        this.result = result ? result : [];
        this.scrollId = scrollId ? scrollId : null;
    }
}

export class USPSearchResultData {
    data: any;
    highlight: any;
    maxScore: number;
    relevant: string;
    entityName: string;

    constructor(data?: any, highlight?: any, maxScore?: number, relevant?: string, entityName?: string) {
        this.data = data ? data : {};
        this.highlight = highlight ? highlight : {};
        this.maxScore = maxScore ? maxScore : 0;
        this.relevant = relevant ? relevant : '';
        this.entityName = entityName ? entityName : null;
    }
    
}