import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-component-loader',
  templateUrl: './component-loader.component.html',
  styleUrls: ['./component-loader.component.css']
})
export class ComponentLoaderComponent implements OnInit {

  @Input() loaderMessage;
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes)
  }

  ngOnInit() {
    // console.log("Message", this.loaderMessage)
  }

}
