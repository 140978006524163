import { v4 as uuid } from 'uuid';
import { Component, OnInit, OnDestroy, Input, SimpleChanges, TemplateRef, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { EnvironmentData } from 'src/app/service/environments.service';
import { Router } from '@angular/router';
import { FileService, NotificationService, UniversalUser } from '../service/shared.service';
import { CommonService } from '../service/common.service';
import { Subscription, Subject } from 'rxjs';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { ImagepdfviewerComponent } from '../shared/imagepdfviewer/imagepdfviewer.component';
import { User } from '../models/user.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatSnackBar } from '@angular/material';
import { ExportService } from '../service/export.service';
import { NotificationPi } from '../models/shared.model';


@Component({
  selector: 'api-notification-page',
  templateUrl: './notification-page.component.html',
  styleUrls: ['./notification-page.component.scss']
})
export class APiNotificationPageComponent implements OnInit, OnDestroy {
  notificationLis: any[] = [];
  notificationLisMaster: any[] = [];
  private notifications = new Subject<any>();
  notificationsForTable = this.notifications.asObservable();
  reporterList: any[] = [];
  assignedToList: any[] = [];
  currentResultsHeader: any[] = [
    { key: 'ticket_id', title: "Case Id" },
    { key: 'title', title: 'Title' },
    { key: 'createdAtLong', title: 'Created Date' },
    { key: 'lastUpdatedAtLong', title: 'Update Date' },
    { key: 'statusCd', title: 'Status' },
    { key: '_id', title: 'Action' }
  ];
  options = {
    rowClickEvent: true,
    rowPerPageMenu: [10, 20, 30],
    rowPerPage: 10
  }
  subscriptionAllTickets: Subscription;
  subscriptionSpecificTicket: Subscription;
  pagesize: number = 50;
  pagenumber: number = 0;
  bsModalRef: BsModalRef;
  selectedTicket: any;
  currentUser: User;
  selectedSupportMessage: any;
  filterQuery: string = '';
  noMoreRecordsFlag: boolean = false;
  isLoadingNew: boolean = false;
  isLoadingMessage: boolean = false;
  companyAccountInfo: any;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '600',
    minHeight: '400',
    maxHeight: '600',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Issue Description',
    defaultParagraphSeparator: '',
    uploadWithCredentials: false,                    //Image Upload Can we make this work@Parth
    uploadUrl: '',                                   //Your backend must return imageUrl.   | https://github.com/kolkov/angular-editor/issues/5  
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'strikeThrough',
        'subscript',
        'superscript',
        'indent',
        'outdent',
        // 'insertUnorderedList',
        // 'insertOrderedList',
        'heading',
        'fontName',
        'customClasses',
        'insertImage',
        'insertVideo',
        'toggleEditorMode',
        'link',
        'unlink',
        // 'insertHorizontalRule',
        // 'removeFormat',
        'fontSize',
        'textColor',
        'backgroundColor',
      ],
      []
    ]
  };
  filterList: any = {
    "reporter": [],
    "assignedTo": [],
    "statusCd": [],
    "priority": []
  };
  ticketAssigned: boolean = false;
  ticketId: string = "";
  supportmessage = {
    messages: [],
    title: "",
    priority: "Medium",
    description: ""
  };
  statusArray = [
    { label: 'Open', value: 'OPEN' },
    { label: 'In Progress', value: 'IN PROGRESS' },
    { label: 'Resolved', value: 'RESOLVED' },
    { label: 'Closed', value: 'CLOSED' },
    { label: 'Re-Open', value: 'REOPEN' }
  ];
  priorityArray = [
    { label: 'Low', value: 'Low', img: 'Low.svg' },
    { label: 'Medium', value: 'Medium', img: 'Medium.svg' },
    { label: 'High', value: 'High', img: 'High.svg' },
    { label: 'Critical', value: 'Critical', img: 'Critical.svg' }
  ];
  @ViewChild('tableTickets', { static: false }) tableTickets: ElementRef;
  constructor(
    private modalService: BsModalService,
    private fileService: FileService,
    private notificationService: NotificationService,
    private universalUser: UniversalUser,
    private snackBar: MatSnackBar,
    private exportService: ExportService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.currentUser = this.universalUser.getUser();
    this.getAllNotifications();
    this.companyAccountInfo = this.universalUser.getcompanyAccount();
  }

  ngOnDestroy() {
    // this.subscriptionAllTickets.unsubscribe();
  }
noMoreCalls:boolean = false;
  getAllNotifications(appendFlag?: boolean) {
    
      this.subscriptionAllTickets = this.notificationService.fetchPaginatedNotifications(this.pagenumber, this.pagesize).subscribe(
        response => {
          
            if (appendFlag) {
              this.notificationLis = this.notificationLis.concat(response)
              this.notificationLisMaster = this.notificationLisMaster.concat(response)
            } else {
              this.notificationLis = response;
              this.notificationLisMaster = response;
            }
            if (response.length!=this.pagesize) {
              this.noMoreCalls = true
            }
          
          
        },
        error => {
          console.error(error)
        }
      );
    
  }
  markNotificationAsRead(notification: NotificationPi) {
    if (notification.associatedEntityId != null) {
      this.snackBar.open('Notification removed and marked as seen', "Close", {
        duration: 5000
      })
    }
    else {
      this.snackBar.open('Could not find any associated Entity with the Notification', "Close", {
        duration: 5000
      })
    }

    this.notificationService.markNotificationAsRead(notification._id).subscribe(data => {
      if (data["success"]) {
        notification.notificationSeen = true;
        
        if (notification.entityType == "Task") {
          this.router.navigate(['/task'], { queryParams: { entityId: notification.associatedEntityId } });
          //this.router.navigate(['task',notification.associatedEntityId]);
        }
      }
    })
  }
  
  loadMore(event?) {
    if (!this.noMoreCalls) {
      this.pagenumber++;
      this.getAllNotifications(true);
    } else {
      this.noMoreRecordsFlag = true;
      setTimeout(() => {
        this.noMoreRecordsFlag = false;
      }, 3000);
    }
  }
  onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }
  returnTrueValues(element) {
    return element !== undefined && element !== null;
  }

  modalOpen(templateRef: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(templateRef, { class: 'modal-dialog modal-md modal-dialog-centered' });
  }

  modalClose(templateRef: TemplateRef<any>) {
    this.bsModalRef.hide();
    setTimeout(() => {
      this.ticketAssigned = false;
    }, 2000);

  }
  exportToExcel() {
    this.exportService.exportTableExcel(this.tableTickets.nativeElement, 'tickets');
  }
}