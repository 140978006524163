import { Injectable } from '@angular/core';
import { Observable, Subject, from } from 'rxjs';
import { User } from '../models/user.model';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UniversalUser } from '../service/shared.service';
import { CommonSearchModel } from '../models/shared.model';
import { DataModel, LinkageModel } from '../models/datamodel.model';
import { Entity } from '../models/datamodel.model';
import { EnvironmentData } from './environments.service';


@Injectable({
  providedIn: 'root'
})
export class DataModelService {
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private router: Router, private httpClient: HttpClient, private environmentData: EnvironmentData) { }

  getDataModelList(commonSearchModel?: CommonSearchModel): Observable<DataModel[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<DataModel[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.datamodelurl}`;

    this.httpClient.post<DataModel[]>(
      url,
      commonSearchModel,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<DataModel[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getDataModelListWiithOtherParams(commonSearchModel?: CommonSearchModel): Observable<DataModel[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<DataModel[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.datamodelurl + 'findAllWithData'}`;

    this.httpClient.post<DataModel[]>(
      url,
      commonSearchModel,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<DataModel[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  saveDataModel(datamodel?: DataModel): Observable<DataModel> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<DataModel>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.datamodelsaveurl}`;

    this.httpClient.post<DataModel>(
      url,
      datamodel,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<DataModel>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  activate(_id: string): Observable<DataModel> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<DataModel>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.datamodelurl + _id}/activate`;

    this.httpClient.get<DataModel>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<DataModel>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );
    return subject.asObservable();
  }


  updateDataModel(datamodel?: DataModel): Observable<DataModel> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<DataModel>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.datamodelsaveurl}`;

    this.httpClient.put<DataModel>(
      url,
      datamodel,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<DataModel>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }




  getDataModel(id?: string): Observable<DataModel> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<DataModel>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.datamodelurl + id}`;
    this.httpClient.get<DataModel>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<DataModel>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getDataModelByName(name?: string): Observable<DataModel> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<DataModel>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.datamodelurl + 'name/'}${name}`;
    this.httpClient.get<DataModel>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<DataModel>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }
  // Quick Access
  dataModelQuickAccess(): Observable<DataModel[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<DataModel[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.datamodelQuickAccess}`;

    this.httpClient.get<DataModel[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<DataModel[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );
    return subject.asObservable();
  }
  // Quick Access
  dataModelOnMenubar(): Observable<DataModel[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<DataModel[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.datamodelQuickMenu}`;

    this.httpClient.get<DataModel[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<DataModel[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );
    return subject.asObservable();
  }


  getDataModelLookupByType(datamodelName: string, type: string, attributeName?: string, parentGroupName?: string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    let url = "";
    if (parentGroupName) {
      url = `${envData.rootUrl + envData.statemachineroot + envData.datamodelurl + 'lookup/' + datamodelName + '/' + attributeName + '/USER/' + parentGroupName}`;
    } else {
      let suffix = ""
      switch (type) {
        case 'GROUP':
          suffix = '/' + attributeName + '/GROUP';
          break;
        case 'USER':
          suffix = '/' + attributeName + '/USER';
          break;
        case 'PRIORITY':
          suffix = '/' + attributeName + '/PRIORITY';
          break;
        case 'STATUS':
          suffix = '/' + attributeName + '/STATUS';
          break;

        default:
          break;
      }
      url = `${envData.rootUrl + envData.statemachineroot + envData.datamodelurl + 'lookup/' + datamodelName + suffix}`;
    }
    this.httpClient.get<DataModel>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }

    )
      .subscribe(
        (response: HttpResponse<DataModel>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getValuesForListingFilter(payload,datamodelName,filterType): Observable<any[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any[]>();

    const url = `${envData.rootUrl + envData.statemachineroot+ "console/entity/"+datamodelName + "/filter/" + filterType}`;

    this.httpClient.post<any[]>(
      url,
      payload,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  // Quick Access
  dataModelFav(): Observable<DataModel[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<DataModel[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + 'console/dataModel/favourites'}`;

    this.httpClient.get<DataModel[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<DataModel[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );
    return subject.asObservable();
  }

  getLinkageModelById(linkageId: string): Observable<LinkageModel> {
    const subject = new Subject<LinkageModel>();
    const envData = this.environmentData.getEnvData();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.datamodelurl + "linkage/" + linkageId}`;

    this.httpClient.get<LinkageModel>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<LinkageModel>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getLinkageModelByParentId(parentId: string): Observable<LinkageModel[]> {
    const subject = new Subject<LinkageModel[]>();
    const envData = this.environmentData.getEnvData();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.datamodelurl + 'linkage/parentid/' + parentId}`;

    this.httpClient.get<LinkageModel[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<LinkageModel[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }
}
