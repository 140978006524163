// loader-interceptor.service.ts
import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  urlsToSkipIntercept = [
    'console/statemachine/timeline/'
  ]
  constructor(private loaderService: LoaderService) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
      this.requests.push(req);

      // console.log("No of requests--->" + this.requests.length);

      if (this.isValidRequestForInterceptor(req.url)) {
        this.loaderService.isLoading.next(true);
      }  
      return Observable.create(observer => {
        const subscription = next.handle(req)
          .subscribe(
            event => {
              if (event instanceof HttpResponse) {
                this.removeRequest(req);
                observer.next(event);
              }
            },
            err => {
              console.log('error', err);
              this.removeRequest(req);
              observer.error(err);
            },
            () => {
              this.removeRequest(req);
              observer.complete();
            });
        // remove request from queue when cancelled
        return () => {
          this.removeRequest(req);
          subscription.unsubscribe();
        };
      });
    
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    
    for (let i = 0; i < this.urlsToSkipIntercept.length; i++) {
      const address = this.urlsToSkipIntercept[i];
      if (requestUrl.search(address)==-1) {
        return false;
      }
    }
    return true;
  }
}
