import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, HostListener, ElementRef } from '@angular/core';
import { MatSort, MatTableDataSource, MatTable } from '@angular/material';
import { State, DataPoint, GraphObject } from 'src/app/models/tasks.model';
import { IMyDpOptions } from 'mydatepicker';
import { DataModel } from 'src/app/models/datamodel.model';
import { CommonSearchModel } from 'src/app/models/shared.model';
import { EntityService } from 'src/app/service/entity.service';
import { commonKeys, DependencyExpressionActions } from '../../models/constants';
import { Observable, Subject, Subscription } from 'rxjs';
import { DataModelService } from 'src/app/service/datamodel.service';

import * as moment from 'moment';
import { AngularEditorConfig } from '@kolkov/angular-editor';


@Component({
  selector: 'datapoints-view',
  templateUrl: './datapoints.component.html',
  styleUrls: ['./datapoints.component.scss']
})
export class DatapointsComponent implements OnInit, OnChanges {

  @Input() selectedState: State;
  @Input() graphObject: GraphObject;
  @Input() disabled: boolean;
  @Input() responseError: any;
  @Input() responseErrorFields: any[];
  @Input() parentWidth: number = 100;
  @Output() emitState = new EventEmitter<State>();


  dataPoints: DataPoint[] = [];
  actionMap: any = {};
  fieldKeyMap: any = {};
  arrayTableHeaders: any = {};
  currentState: State = new State();
  public mytime: Date = new Date();
  currentYear: any = this.mytime.getUTCFullYear();
  currentPrevDate: any = this.mytime.getUTCDate() + 1;
  currentFutDate: any = this.mytime.getUTCDate() - 1;
  currentMonth: any = this.mytime.getUTCMonth() + 1;
  arrayforTime = {};
  arrayforDate = {};
  lookupEntityList: any[];
  selectedDataModelFieldMap: Map<string, DataPoint> = new Map();
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '600',
      minHeight: '400',
      maxHeight: '600',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      uploadWithCredentials:false,                    //Image Upload Can we make this work@Parth
      uploadUrl:'',                                   //Your backend must return imageUrl.   | https://github.com/kolkov/angular-editor/issues/5  
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        // 'undo',
        // 'redo',
        // 'strikeThrough',
        // 'subscript',
        // 'superscript',
        // 'indent',
        // 'outdent',
        // 'insertUnorderedList',
        // 'insertOrderedList',
        // 'heading',
        // 'fontName',
        'customClasses',
        'insertImage',
        'insertVideo',
        // 'toggleEditorMode',
        // 'link',
        // 'unlink',
        // 'insertHorizontalRule',
        // 'removeFormat',
        // 'fontSize',
        // 'textColor',
        // 'backgroundColor',
      ],
      []
    ]
  };
  public myFutureDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd-mm-yyyy'
  };
  public ngxLocale={ "format": "YYYY-MM-DDTHH:mm:ss.SSSZ", "displayFormat": "DD-MM-YYYY", "separator": " To ", "cancelLabel": "Cancel", "applyLabel": "Okay" }
  constructor(private entityService: EntityService,    
    private dataModelService: DataModelService) {
    this.lookupEntityList = [];
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes.selectedState != undefined) {
      this.currentState = changes.selectedState.currentValue;
      this.extractParams();
    }
    if (changes.graphObject != undefined) {
      this.graphObject = changes.graphObject.currentValue;
      this.extractParams();
    }
    if (changes.responseError != undefined) {
      this.responseError = changes.responseError.currentValue;
    }
    if (changes.responseErrorFields) {
      // console.log(changes.responseErrorFields.currentValue)
    }
    // this.extractParams();
    // console.log(changes.selectedState.currentValue)
  }

  ngOnInit() {
    this.currentState = this.selectedState;
    this.extractParams();
  }

  setValue(dataPoint?: any, value?: any) {
    this.currentState.parameters[dataPoint.dataPointName] = value;
    this.emitState.emit(this.currentState);
  }


  isDataPointDisabled(dataPoint: DataPoint) {
    if (!this.disabled) {
      if (this.graphObject != null) {
        for (let state of this.graphObject.states) {

          if (state.stateCd == this.currentState.stateCd && state.dataPointAccessList) {
            for (let dataAccess of state.dataPointAccessList) {
              if (dataAccess.dataPointName != null && dataPoint.dataPointName != null && dataAccess.dataPointName == dataPoint.dataPointName) {
                // console.log(dataPoint.dataPointName,"----", dataAccess.writeAccess)
                return !dataAccess.writeAccess;
              }
            }
          }
        }
      }
      return false;
    }
    else {
      return true;
    }

  }

  returnIfErrorField(dataPointName){
    if(this.responseErrorFields && this.responseErrorFields.indexOf(dataPointName)>-1){return true;}
    return false;
  }

  toBeDisplayOrNot(dataPoint: DataPoint) {
    if (this.graphObject != null && this.graphObject.dataPointConfigurationList != null && this.graphObject.dataPointConfigurationList.length > 0) {
      if (this.graphObject.states) {
        for (let state of this.graphObject.states) {

          if (state.stateCd == this.currentState.stateCd && state.dataPointAccessList) {
            for (let dataAccess of state.dataPointAccessList) {
              if (dataAccess.dataPointName != null && dataPoint.dataPointName != null && dataAccess.dataPointName == dataPoint.dataPointName) {
                return dataAccess.hide;
              }
            }
          }
        }
      }
    }
    return false;
  }

  extractParams() {
    this.dataPoints = [];
    if (this.currentState) {
      if (!this.actionMap) {
        this.actionMap = {};
      }
      if (!this.fieldKeyMap) {
        this.fieldKeyMap = {};
      }
      if (!this.dataPoints) {
        this.dataPoints = [];
      }

      // if (this.selectedState.parameters && this.selectedState.parameters["mailTrail"]) {
      //   const emailTrail = JSON.parse(JSON.stringify(this.selectedState.parameters["mailTrail"]));

      //   if (emailTrail != null && emailTrail.length > 0) {
      //     this.sourceEmailTrailList = emailTrail;
      //   }
      // }

      if (this.currentState.parameters && this.graphObject && this.graphObject.dataPointConfigurationList) {
        for (const dataPoint of this.graphObject.dataPointConfigurationList) {
          this.selectedDataModelFieldMap.set(dataPoint.dataPointName, dataPoint)
        }
        this.graphObject.dataPointConfigurationList.sort((a, b) => { return   a.sequence-b.sequence });
        for (const dataPoint of this.graphObject.dataPointConfigurationList) {
          const paramValue: any = this.currentState.parameters[dataPoint.dataPointName];

          if (dataPoint.dataType === 'STRING') {
            dataPoint.value = paramValue ? paramValue : '';
          }else if (dataPoint.dataType === 'TEXT') {
            dataPoint.value = paramValue ? paramValue : '';
          } 
          else if (dataPoint.dataType === 'BOOLEAN') {
            dataPoint.value = (paramValue !== null && (typeof paramValue === 'boolean' || paramValue instanceof Boolean)) ? paramValue : false;
          } else if (dataPoint.dataType === 'NUMBER') {
            dataPoint.value = (paramValue && (typeof paramValue === 'number' || paramValue instanceof Number)) ? paramValue : 0;
          } else if (dataPoint.dataType === 'SINGLE_SELECT') {
            if (paramValue && !(paramValue instanceof Array) && !dataPoint.inputSource.includes(paramValue)) {
              dataPoint.inputSource.push(paramValue);
            }

            dataPoint.value = paramValue;
          } else if (dataPoint.dataType === 'MULTI_SELECT') {
            const uniqueValues: string[] = [];

            if (paramValue && paramValue instanceof Array && paramValue.length > 0) {
              for (const value of paramValue) {
                if (!dataPoint.inputSource.includes(value)) {
                  uniqueValues.push(value);
                }

              }

              dataPoint.inputSource = dataPoint.inputSource.concat(uniqueValues);
            }

            dataPoint.value = paramValue;
          } else if (dataPoint.dataType === 'ARRAY') {
            dataPoint.value = (paramValue && paramValue instanceof Array) ? paramValue : [];
          } else if (dataPoint.dataType === 'ANY') {
            dataPoint.value = paramValue;
          } else if (dataPoint.dataType === 'DATE' || dataPoint.dataType === 'DATE_TIME') {
            let date;
            if (paramValue && paramValue['year']) {
              if(paramValue['day'] < 10) {
                let tmpDt = "0" + paramValue['day'];
                paramValue['day'] = +(tmpDt).substr(tmpDt.length - 2);
              }
              if (paramValue['hour'] < 10) {
                let tmpDt = "0" + paramValue['hour'];
                paramValue['hour'] = +(tmpDt).substr(tmpDt.length - 2);
              }
              if (paramValue['min'] < 10 ) {
                let tmpDt = "0" + paramValue['min'];
                paramValue['min'] = +(tmpDt).substr(tmpDt.length - 2);
              }
              if (paramValue['month'] < 10 ) {
                let tmpDt = "0" + paramValue['month'];
                paramValue['month'] = +(tmpDt).substr(tmpDt.length - 2);
              }

              let formattedDate = paramValue['year'] + '-' + paramValue['month'] +  '-' + paramValue['day'] +  'T' + paramValue['hour'] + ":" + paramValue['min'] + ":00.000Z";
              // 2021-09-15T13:31:58.347Z
              date = new Date(formattedDate);
            } else if (paramValue instanceof Object && paramValue['jsdate']) {
              date = new Date(paramValue['jsdate']);
            } else {
              if ( paramValue) {
                if(typeof paramValue == 'number'){
                  date = new Date(paramValue);
                }else{
                  date = new Date();
                }
              } else {
                date = new Date();
              }
            }
            if(typeof paramValue == 'number'){
              dataPoint.value = this.getDateToDisplay(dataPoint, date);
              this.currentState.parameters[dataPoint.dataPointName] = dataPoint.value;
            }
          } else if(dataPoint.dataType == 'ENTITY_LOOKUP') {
            this.getLookupEntitiesList(dataPoint);
          }else if(dataPoint.dataType == 'ENTITY_LOOKUP_REFERENCE') {
              if(paramValue!=null && paramValue!=undefined && paramValue._lookup_reference_id!=null && paramValue._lookup_reference_id!=undefined && paramValue.value!=undefined){
                dataPoint["actualValue"] = paramValue.value._lookup_reference_id;
              }
              else{
                dataPoint["actualValue"] = paramValue;
              }
              this.getRefLookupEntitiesList(dataPoint);
          } else {
            dataPoint.dataType = 'STRING';
            dataPoint.value = '';
          }

          /** ---------------------- IMPORTANT ----------------------
           * This is done on purpose, please don't remove.
           * Required for UI -> Inline form -> Label & Value
           * Object at even indices are used to create label div
           * Object at odd indices are used to create input field
           * while updating info on server only odd indices objects are selected
           **/
          if (!this.toBeDisplayOrNot(dataPoint)) {
            this.dataPoints.push(dataPoint);
          }

          if (dataPoint.dataType == 'ARRAY' && dataPoint.value.length > 0 && !this.isString(dataPoint.value[0])) {
            if (!this.toBeDisplayOrNot(dataPoint)) {
              this.dataPoints.push(dataPoint);
            }
          }
          else {
            if (!this.toBeDisplayOrNot(dataPoint)) {
              this.dataPoints.push(dataPoint);
            }
          }

          this.fieldKeyMap[dataPoint.dataPointName] = dataPoint.dataPointLabel;
        }
      }
    }
  }
  getDateToDisplay(datapoint: DataPoint, date: Date) {
    if (date+"" !='Invalid Date') {
      this.arrayforTime[datapoint.dataPointName] = date;
      // This is for my date picker
      // let value = {
      //   date: {
      //     year: date.getFullYear(),
      //     month: date.getMonth() + 1,
      //     day: date.getDate()
      //   }
      // };
      //This is fr ngx daterangepicker
      let value={
        startDate: moment(date), endDate: moment(date)
      }
      this.arrayforDate[datapoint.dataPointName] = value;

      return value;
    }
    
  }

  isString(value) {
    return typeof value === 'string';
  }

  getValueForArraydatatype(data, dataPointName) {
    let values = []
    for (let d of data) {
      let headerValue = []
      for (let key of this.arrayTableHeaders[dataPointName]) {
        headerValue.push(d[key]);
      }
      values.push(headerValue);
    }
    return values;
  }

  getUniqueDataPoints() {
    return Array.from(new Set(this.dataPoints));
  }
  returnReadableValue(obj) {
    if (typeof (obj) == 'object') {
      // console.log(obj)
      let tmp = {};
      if (obj != null && obj != undefined) {
        tmp = (obj.fullFileUrl.replace("http://api.automatapi.com", "https://flow.automatapi.com/flow/interfaces"))
      }
      return [true, tmp];
    } else {
      return [false, obj];
    }
  }
  getKeysForArrayDataType(data, dataPointName) {
    this.arrayTableHeaders[dataPointName] = [];
    for (let key in data[0]) {
      this.arrayTableHeaders[dataPointName].push(key);
    }
    return this.arrayTableHeaders[dataPointName];

  }
  getKeysForHashmap(hashmap) { 
    let arrKeys = [];
    for (let key in hashmap) {
      arrKeys.push({key:key,value:hashmap[key]})
    }
    return arrKeys;
  }

  addRemoveFromArray(array,index?){
    let blankRecord = array[0];
    for (let key in blankRecord) {
      blankRecord[key] =null
    }
    if(index){
      array.splice(index,1)
    }else{
      array.push(blankRecord)
    }
  }
  getType(varia){
    return typeof varia
  }
  getDatePickerOptions() {
    return this.myFutureDatePickerOptions;
  }

  datesUpdated(singleDatePicker,event,datapoint) {
    if(singleDatePicker == true){
      let originalDate = datapoint.value;
      if(datapoint && datapoint.value ){
        if(originalDate.startDate){
          // isObject
          let hour = +(moment(originalDate.startDate).format("hh"));
          let minute = +(moment(originalDate.startDate).format("mm"));
          let seconds = +(moment(originalDate.startDate).format("ss"));
          if (event.startDate) {
            let tmp = moment(event.startDate).utc().toDate();
            moment(tmp).utc().toDate().setHours(hour)
            moment(tmp).utc().toDate().setMinutes(minute)
            moment(tmp).utc().toDate().setSeconds(seconds)
            datapoint.value = tmp.getTime();
          } else {
            datapoint.value = moment(originalDate.startDate).utc().toDate().getTime();
          }
        }else{
          // is Number in milliseconds
          var d2 = new Date(0);
          d2.setUTCMilliseconds(originalDate)
          let hour = +(moment(d2).format("hh"));
          let minute = +(moment(d2).format("mm"));
          let seconds = +(moment(d2).format("ss"));
          let tmp = moment(event.startDate).utc().toDate();
          
          moment(tmp).utc().toDate().setHours(hour)
          moment(tmp).utc().toDate().setMinutes(minute)
          moment(tmp).utc().toDate().setSeconds(seconds)
          datapoint.value = tmp.getTime();
        }
      }else{
        var d2 = new Date();
        datapoint.value = d2.getTime();
      }
      // if(this.currentState.parameters[datapoint.dataPointName]){
        this.currentState.parameters[datapoint.dataPointName] = datapoint.value;
      // }
      this.emitState.emit(this.currentState);
    }
  }

  

  onTimeChanged(event, dataPoint: DataPoint) {
    if (this.currentState.parameters[dataPoint.dataPointName] && this.currentState.parameters[dataPoint.dataPointName]['date']) {
      let temp = this.currentState.parameters[dataPoint.dataPointName]['date'];
      let currentTimeRef = new Date(event)
      temp['hour'] = currentTimeRef.getHours();
      temp['min'] = currentTimeRef.getMinutes();
      this.currentState.parameters[dataPoint.dataPointName] = temp;
      // this.currentState
      
    }else if (this.currentState.parameters[dataPoint.dataPointName] && this.currentState.parameters[dataPoint.dataPointName]['startDate']) {
      let temp = moment(this.currentState.parameters[dataPoint.dataPointName]['startDate']).utc().toDate();
      let currentTimeRef = new Date(event)
      let hour = currentTimeRef.getHours();
      let min = currentTimeRef.getMinutes();
      temp.setHours(hour);
      temp.setMinutes(min);
      this.currentState.parameters[dataPoint.dataPointName] = temp.getTime();
    }else{
      let temp = new Date(0);
      temp.setUTCMilliseconds(this.currentState.parameters[dataPoint.dataPointName])
      let currentTimeRef = new Date(event)
      let hour = currentTimeRef.getHours();
      let min = currentTimeRef.getMinutes();
      temp.setHours(hour);
      temp.setMinutes(min);
      this.currentState.parameters[dataPoint.dataPointName] = temp.getTime()
    }
    this.emitState.emit(this.currentState);
  }

  getLookupEntitiesList(field: DataPoint) {
    const commonsearchModel = new CommonSearchModel();
    commonsearchModel.searchParams = [{}];
    commonsearchModel.returnFields = [];

    if (!field.parentDataPointName || field.parentDataPointName.trim().length == 0) {
      if (field.lookupType) {
        commonsearchModel.searchParams = [{
          lookupType: field.lookupType
        }];
      }
      this.entityService.getLookupEntities(field.referenceModel, commonsearchModel)
        .subscribe(lookupList => {
          for (let lookupEntity of lookupList) {
            this.lookupEntityList.push(lookupEntity);
          }
          field.lookupEntityList = lookupList;
        });
    } else {
      const parentField = this.selectedDataModelFieldMap.get(field.parentDataPointName);

      if (parentField) {
        let tempSearchParam = {};
        tempSearchParam["parentValueCode"] = this.currentState.parameters[parentField.dataPointName];
        if (field.lookupType) {
          tempSearchParam["lookupType"] = field.lookupType;
        }
        commonsearchModel.searchParams = [tempSearchParam];

        this.entityService.getLookupEntities(field.referenceModel, commonsearchModel)
          .subscribe(lookupList => {
            for (let lookupEntity of lookupList) {
              this.lookupEntityList.push(lookupEntity);
            }
            field.lookupEntityList = lookupList;
          });
      }
    }
  }
 
  getRefLookupEntitiesList(datapoint: DataPoint) {
    const commonsearchModel = new CommonSearchModel();
    commonsearchModel.searchParams = [{}];
    commonsearchModel.returnFields = [commonKeys.entityLookUpRefLabel, commonKeys.entityLookUpRefId];
    // console.log("----------------- datapoints ---------------------------");
    this.entityService.getRefLookupEntities(datapoint.referenceModel, commonsearchModel)
      .subscribe(lookupList => {
        let lookupRefEntityList = [];
        for (let lookupEntity of lookupList) {
          if (lookupEntity[commonKeys.entityLookUpRefId] != null && lookupEntity[commonKeys.entityLookUpRefId] != undefined) {
            let map = {};
            map["id"] = lookupEntity[commonKeys.entityLookUpRefId];
            map["name"] = lookupEntity[commonKeys.entityLookUpRefLabel];
            lookupRefEntityList.push(map);
          }
        }
        datapoint.lookupEntityList = lookupRefEntityList;
      });
  }

  compareObjects(o1: any, o2: any) {
    if (o2 != undefined) {
      if (o1.id == o2)
        return true;
      else return false
    }
  }

  onLookupSelectRef(item, datapoint){
      this.fetchDataModel(datapoint.referenceModel).subscribe(model => {
        this.fetchRefrenceEntity(datapoint.referenceModel, item.source.value.id).subscribe(entity => {
          //datapoint["actualValue"] = entity;
          this.currentState.parameters[datapoint.dataPointName] = entity;
          datapoint["actualValue"] = entity._lookup_reference_id;
          this.emitState.emit(this.currentState);
        });
      });
  }

  fetchDataModel(modelName): Observable<DataModel> {
    const subject = new Subject<DataModel>();
    const commonsearchModel = new CommonSearchModel();
    commonsearchModel.searchParams = [{ 'statusCd': 'ACTIVE' }];
    commonsearchModel.returnFields = ['_id', 'name'];
    this.dataModelService.getDataModelList(commonsearchModel)
      .subscribe(list => {
        if (list.length > 0) {
          let pos = list.map(item => item.name).indexOf(modelName);
          if (list[pos]) {
            this.dataModelService.getDataModel(list[pos]._id)
              .subscribe(
                datamodel => {
                  if (datamodel) {
                    subject.next(datamodel);
                  }
                }
              );
          }
        }
      });
    return subject.asObservable();
  }

  fetchRefrenceEntity(modelName: any, id: any): Observable<any> {
    const subject = new Subject<any>();
    this.entityService.getEntityLookupRef(modelName, id)
      .subscribe(entity => {
        if (entity) {
          subject.next(entity);
        }
      });
    return subject.asObservable();
  }

  onLookupSelect(changedField: DataPoint) {
    let childField: DataPoint = null;

    for (let field of this.graphObject.dataPointConfigurationList) {
      if (field.parentDataPointName && field.parentDataPointName == changedField.dataPointName) {
        childField = field;
        childField.value = "";
        childField.lookupEntityList = [];
        this.onLookupSelect(field);
        break;
      }
    }

    if (changedField && this.currentState.parameters[changedField.dataPointName] && childField) {
      const commonsearchModel = new CommonSearchModel();
      let tempSearchParam = {};
      tempSearchParam["parentValueCode"] = this.currentState.parameters[changedField.dataPointName];
      if (childField.lookupType) {
        tempSearchParam["lookupType"] = childField.lookupType;
      }
      commonsearchModel.searchParams = [tempSearchParam];
      commonsearchModel.returnFields = [];
      this.entityService.getLookupEntities(childField.referenceModel, commonsearchModel)
        .subscribe(lookupList => {
          for (let lookupEntity of lookupList) {
            this.lookupEntityList.push(lookupEntity);
          }
          childField.lookupEntityList = lookupList;
        });
    }
  }
  @ViewChild('editor',{static:false}) editor: any;
  onPaste(event: ClipboardEvent,dataPoint){
    event.preventDefault();
    let contentToPaste = "";
    if (event.clipboardData.getData('text/html')) {
      contentToPaste = event.clipboardData.getData('text/html');
    } else {
      contentToPaste = event.clipboardData.getData('text');
    }
    if(!dataPoint.value)
    dataPoint.value="";
    this.editor.focus();
    if(contentToPaste == ""){
      
    }else{
      event.preventDefault();
      let strPlace = "{{" +  new Date().getTime() + "_ReplaceString}}";
      this.editor.editorService.insertHtml(strPlace);
      let valuePositionSplitArray = dataPoint.value.split(strPlace);
      if(valuePositionSplitArray[1] == undefined) valuePositionSplitArray.push("");
      let newValue = valuePositionSplitArray[0] + contentToPaste + valuePositionSplitArray[1];
      newValue.replace("undefined","");
      dataPoint.value = newValue
      this.setValue(dataPoint, newValue)
    }
  }
  checkifLink(string){
    return string != null && string != undefined && string.toString().indexOf("http") == 0;
  }
}
