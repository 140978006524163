import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { User } from '../models/user.model';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UniversalUser } from './shared.service';
import { UserPreferences } from '../models/userPreferences.model';
import {EnvironmentData} from './environments.service';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService {
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private router: Router,
    private universalUser: UniversalUser,
    private httpClient: HttpClient,
    private environmentData: EnvironmentData
  ) { }

  getUserPref(): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    const url = envData.rootUrl + envData.statemachineroot + envData.allUserPref;
    this.httpClient.get<any>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response && response.body) {
            subject.next(response.body)
          } else {
            subject.next([]);
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );
    return subject.asObservable();
  }
  setUserPref(screen: string, component: string, identifier: string, columns: any[], id?: string,autoSuggestEmails?:any[]): Observable<User> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    const url = envData.rootUrl + envData.statemachineroot + envData.setAllUserPref;
    if (screen && component && identifier && columns) {
      const body = {};
      body['screen'] = screen;
      body['component'] = component;
      body['identifier'] = identifier;
      body["createdUserId"] = this.universalUser.getUser().email;
      if (id && id != null) {
        body['_id'] = id;
      }
      body['preferences'] = {
        "columns": columns,
        "autoSuggestEmails":autoSuggestEmails
      };
      this.httpClient.post<any>(
        url,
        body,
        {
          headers: this.httpHeaders,
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      )
        .subscribe(
          (response: HttpResponse<any>) => {
            if (response && response.body) {
              subject.next(response.body)
            } else {
              subject.error('Failed to save Preferences, please try again later');
            }
          },
          (err: HttpErrorResponse) => {
            subject.error(err);
          }
        );
    } else {
      subject.error('User object is null or empty');
    }

    return subject.asObservable();

  }

  getUserPrefByScreenAndComponent(screen: string, component: string): Observable<UserPreferences[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<UserPreferences[]>();
    const url = envData.rootUrl + envData.statemachineroot + envData.setAllUserPref + screen + "/" + component;
    this.httpClient.get<UserPreferences[]>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<UserPreferences[]>) => {
          if (response && response.body) {
            subject.next(response.body)
          } else {
            subject.error('Failed to save Preferences, please try again later');
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );
    return subject.asObservable();
  }

  getUserPrefByScreenAndComponentAndIdentifier(screen: string, component: string,identifier:string): Observable<UserPreferences[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<UserPreferences[]>();
    const url = envData.rootUrl + envData.statemachineroot + envData.setAllUserPref + screen + "/" + component + "/" + identifier;
    this.httpClient.get<UserPreferences[]>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<UserPreferences[]>) => {
          if (response && response.body) {
            subject.next(response.body)
          } else {
            subject.error('Failed to save Preferences, please try again later');
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );
    return subject.asObservable();
  }
  
  saveUserPref(userPreferences: UserPreferences): Observable<UserPreferences> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<UserPreferences>();
    const url = envData.rootUrl + envData.statemachineroot + envData.setAllUserPref;
    this.httpClient.post<UserPreferences>(
      url,
      userPreferences,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<UserPreferences>) => {
          if (response && response.body) {
            subject.next(response.body)
          } else {
            subject.error('Failed to save Preferences, please try again later');
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );
    return subject.asObservable();
  }
  registerUserMappingForDialler(userMapping: any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    if (userMapping) {
      const url = `${envData.rootUrl + envData.statemachineroot}` + "console/user/mappings";

      this.httpClient.post<any>(
        url,
        userMapping,
        {
          headers: this.httpHeaders,
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      )
        .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
        );
    } else {
      subject.error('User object is null or empty');
    }

    return subject.asObservable();
  }


}