import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Application } from '../models/application.model';
import { EnvironmentData } from './environments.service';
@Injectable()
export class ApplicationService {

  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  selectedApplication: Application;
  allApplications: Application[];

  constructor(
    private httpClient: HttpClient,
    private flowEnvironments: EnvironmentData
  ) { }

  clearAll() {
    this.selectedApplication = null;
    this.allApplications = null;
    localStorage.removeItem("theme");
  }

  private fetchSubcribedApplications(): Observable<Application[]> {
    const subject = new Subject<Application[]>();

    const envData = this.flowEnvironments.getEnvData();
    const url = `${envData.rootUrl}${envData.statemachineroot}${envData.application}/subscribedApplications`;

    this.httpClient.get<Application[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<Application[]>) => {
        if (response.body) {
          const subscribedApplications = response.body;

          if (subscribedApplications && subscribedApplications.length > 0) {
            const selectedApplication = subscribedApplications[0];

            this.setSelectedApplication(selectedApplication);
            this.setAllApplication(subscribedApplications);

            const theme = {
              "whiteLabel": {
                "allowCustom": true,
                "companyName": selectedApplication.applicationDisplayName,
                "logoUrl": selectedApplication.applicationUiProperties.logoUrl,
                "uiProperties": {
                  "theme": selectedApplication.applicationUiProperties.applicationTheme,
                  "fontFamily": selectedApplication.applicationUiProperties.applicationFont,
                  "logoUrl": selectedApplication.applicationUiProperties.logoUrl
                }
              }
            };

            localStorage.setItem("theme", JSON.stringify(theme));
          } else {
            this.allApplications = null;
            this.selectedApplication = null;
          }

          subject.next(subscribedApplications);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        this.setSelectedApplication(null);
        this.setAllApplication(null);
        subject.error(err);
      }
    );

    return subject.asObservable();

  }


  saveApplication(application: Application, setApplicationAfterSaving?: boolean) {
    const subject = new Subject<Application>();

    const envData = this.flowEnvironments.getEnvData();
    const url = `${envData.rootUrl}${envData.statemachineroot}${envData.application}`;

    this.httpClient.put<Application>(
      url,
      application,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe((response: HttpResponse<Application>) => {
      if (setApplicationAfterSaving) {
        this.setSelectedApplication(response.body);
      }

      subject.next(response.body);
    },
      (err: HttpErrorResponse) => {
        subject.error(err);
      })
    return subject.asObservable();
  }

  setSelectedApplication(selectedApplication: Application) {
    this.selectedApplication = selectedApplication;
  }

  getSelectedApplication() {
    const subject: Subject<Application> = new Subject<Application>();

    if (this.selectedApplication) {
      setTimeout(() => {
        subject.next(this.selectedApplication);
      }, 10);
    } else {
      this.fetchSubcribedApplications().subscribe(
        allApplications => {
          subject.next(this.selectedApplication);
        },
        error => {
          subject.next(this.selectedApplication);
        }
      );
    }

    return subject.asObservable();
  }

  setAllApplication(application: Application[]) {
    this.allApplications = application;
  }

  getAllApplication(): Observable<Application[]> {
    const subject: Subject<Application[]> = new Subject<Application[]>();

    if (this.allApplications) {
      setTimeout(() => {
        subject.next(this.allApplications);
      }, 10);
    } else {
      this.fetchSubcribedApplications().subscribe(
        allApplications => {
          subject.next(this.allApplications);
        },
        error => {
          subject.next(this.allApplications);
        }
      );
    }

    return subject.asObservable();
  }
}
