import { Component, EventEmitter, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FileService } from 'src/app/service/shared.service';
import { Document } from '../../models/tasks.model';

@Component({
  selector: 'app-imagepdfviewer',
  templateUrl: './imagepdfviewer.component.html',
  styleUrls: ['./imagepdfviewer.component.css']
})
export class ImagepdfviewerComponent implements OnInit {
  title: string;
  closeBtnName: string;
  doc: any;
  dataFormat:any;
  entityView:boolean = false;
  ocrFile: boolean = false;
  tempUrl:any;
  downloadFileObjectUrl: any;
  DOCUMENT_STATUS = ["PENDING", "APPROVED", "REJECTED"];
  DOCUMENT_REJECTED_MESSAGE = "Mandatory: Description is mandatory for rejected documents";
  errorMessage:string = null;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    private fileService: FileService,
    private sanitizer: DomSanitizer,
    private modalService: BsModalService,
    private bsModalRef:BsModalRef
  ) { }

  ngOnInit() {
    this.checkForValidation();
    if(this.doc && this.tempUrl){
      let fileName = "";
      if (this.doc.fileName && this.doc.fileName.indexOf(".")>-1) {
        fileName =this.doc.fileName;
      } else {
        fileName = this.doc.userFileName ? this.doc.userFileName : this.doc.fileName;
      }
      this.dataFormat = (fileName.split('.')[fileName.split('.').length-1]);

      this.downloadFileObjectUrl = this.tempUrl
    }else{
      if (this.doc && this.doc.downloadFileUrl) {
        if (this.doc.downloadFileUrl.startsWith('http')) {
          this.downloadFileObjectUrl = this.doc.downloadFileUrl
        } else {
          this.fileService.download(this.doc.downloadFileUrl, true).subscribe(
            objectUrl => {
              if (objectUrl) {
                var type = (objectUrl.split(';')[0]).replace("data:","");
              if(type=='video/mp4'){
                this.dataFormat = type;
                fetch(objectUrl)
                .then(res => res.blob())
                .then(blob => {
                  var file = new Blob([blob], {type: type});
                  this.downloadFileObjectUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
                })
              }else if(type=='audio/mpeg'){
                this.dataFormat = type;
                fetch(objectUrl)
                .then(res => res.blob())
                .then(blob => {
                  var file = new Blob([blob], {type: type});
                  this.downloadFileObjectUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
                })
              }
              else if(type=='audio/x-wav'){
                this.dataFormat = type;
                fetch(objectUrl)
                .then(res => res.blob())
                .then(blob => {
                  var file = new Blob([blob], {type: type});
                  this.downloadFileObjectUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
                })
              }
              else if(type=='text/html'){
                this.dataFormat = type;
                fetch(objectUrl)
                .then(res => res.blob())
                .then(blob => {
                  this.downloadFileObjectUrl = blob['text']()
                })
              }
              else if (type == 'application/octet-stream' || type == 'application/pdf') {
                type = "application/pdf";
                this.dataFormat = type;
                fetch(objectUrl)
                .then(res => res.blob())
                .then(blob => {
                  var file = new Blob([blob], {type: type});
                  this.downloadFileObjectUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
                })
              }else{
                this.dataFormat = type;
                fetch(objectUrl)
                .then(res => res.blob())
                .then(blob => {
                  let FileNameParts = [];
                  let file;
                  if (this.doc && this.doc.userFileName) {
                    FileNameParts = this.doc.userFileName.split(".");
                    if (FileNameParts.length>1) {
                      file = new File([blob], this.doc.userFileName.replace(/[^a-zA-Z0-9.]/g, ""),{ type: type })
                    } else{
                      file = new File([blob], "temp.jpeg",{ type: type })
                    }
                  } else {
                    FileNameParts = this.doc.fileName.split(".");
                    if (FileNameParts.length>1) {
                      file = new File([blob], this.doc.fileName.replace(/[^a-zA-Z0-9.]/g, ""),{ type: type })
                    } else{
                      file = new File([blob], "temp.jpeg",{ type: type })
                    }
                  }
                  this.downloadFileObjectUrl = (file);
                })
              }
                // this.downloadFileObjectUrl = objectUrl;
              }
            }
          );
        }
      }
    }
  }
  updateDocument(){
    if(this.checkForValidation()){
      this.bsModalRef.hide();
      this.event.emit({ data: this.doc , res:2133 });
    }
  }
  hideModal(){
    this.bsModalRef.hide();
    this.event.emit({ data: this.doc , res:20010 });
  }
  onStatusChange(status:string){
    if(this.doc.status == "REJECTED"){
      this.errorMessage = this.DOCUMENT_REJECTED_MESSAGE;
    }
    else{
      this.errorMessage = null;
    }
  }

  checkForValidation(){
    if(this.doc && this.doc.status == "REJECTED" && (this.doc.description == null || this.doc.description == undefined || this.doc.description.length == 0)){
      this.errorMessage = this.DOCUMENT_REJECTED_MESSAGE;
      return false;
    }
    return true;
  }

  getTitleForDataPoint(dataPoint: any) {
    var title = "";
    if (dataPoint.type) {
      title = "Type " + dataPoint.type + "\n"
    }
    if (dataPoint.valueConfidence) {
      title = title + "Confidence " + Math.round(dataPoint.valueConfidence *100)/100;
    }
    return title;
  }
  downloadDocument() {
    let doc = this.doc;
    if (doc && doc.downloadFileUrl) {
      if (doc.downloadFileUrl.startsWith('http')) {
        window.open(doc.downloadFileUrl, '_blank');
      } else {
        this.fileService.download(doc.downloadFileUrl);
      }
    }
  }
}
