import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CustomDashboardComponent, Filter } from 'src/app/models/customDashboard.model';
import { CustomDashboardService } from 'src/app/service/dashboard.service';
import { UniversalUser } from 'src/app/service/shared.service';

@Component({
  selector: 'app-speedometer-chart-dashboard',
  templateUrl: './speedometer-chart-dashboard.component.html',
  styleUrls: ['./speedometer-chart-dashboard.component.css']
})
export class SpeedometerChartDashboardComponent implements OnInit, OnChanges {
  @Input() startTime: string;
  @Input() endTime: string;
  @Input() selectedComponent: CustomDashboardComponent;
  @Input() selectedDashboardId: string;
  @Input() colourList: string[] = [];
  @Input() componentFilters: Filter[] = [];
  currentResults: any = [];
  chartDiv: string;
  height
  constructor(
    public cd: ChangeDetectorRef,
    private customDashboardService: CustomDashboardService,
    private universalUser: UniversalUser
  ) { }

  ngOnInit(
    // this.currentResults = {
    //   score: 89.7,
    //   min:0,
    //   max:100,
    //   targettoAchieve:60
    // };
  ) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedComponent && changes.selectedComponent.currentValue) {
      this.chartDiv = changes.selectedComponent.currentValue._id;
    }
    if (changes && changes.endTime && changes.endTime.currentValue != undefined) {
      this.endTime = changes.endTime.currentValue;
    }
    if (changes && changes.startTime && changes.startTime.currentValue != undefined) {
      this.startTime = changes.startTime.currentValue;
    }
    if (changes && changes.componentFilters && changes.componentFilters.currentValue != undefined) {
      this.componentFilters = changes.componentFilters.currentValue;
    }
    this.getResults(this.startTime, this.endTime, this.componentFilters)
  }
  getResults(startTime, endTime, filters) {
    this.customDashboardService.getComponentResult(this.universalUser.getUser().companyId, this.selectedComponent, startTime, endTime, filters, this.selectedDashboardId).subscribe(
      (response) => {
        if (response && response.length > 0) {
          for (let index = 0; index < response.length; index++) {
            const element = response[index];
            let temp = {
              score: element['value'],
              min: 0,
              max: element['totalCount'],
              category: element['category'],
            }
            if (this.selectedComponent.uiProperties && this.selectedComponent.uiProperties["showInPercentage"]) {
              temp.score = this.getPercentage(temp.score, temp.max);
              temp.max = 100;
            }
            this.currentResults.push(temp)
          }
          this.height = 400 / this.currentResults.length + "px"
          // this.currentResults = {
          //   score:response[0]['value'],
          //   min:0,
          //   max:response[0]['totalCount'],
          //   category:response[0]['category'],
          // }
        }
        // this.cd.detectChanges();

      },
      (error) => {
        console.error(error)

      }
    );
  }

  getPercentage(count: number, totalCount: number) {
    if (totalCount == 0 || count == 0 )
      return 0;
    return (Math.round((count/totalCount) * 100) / 100) * 100;
  }
  getLabel(selectedComponent){
    return selectedComponent.label
  }
}
