import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  notNeeded = ['companyId', 'createdAt', 'createdBy', 'dueDate', 'updatedBy', 'machineType', 'statusCd', 'updatedAt', '_entityName', '_id', 'datamodelId', '_lookup_reference_id', '_lookup_reference_label', 'flowId', 'stageCd', 'updateByMachineType', 'customerId', 'lastKnownPage', 'pageProgress', 'pageFinished', '_carouselLastKnownIndex', '_carouselProgress', '_carouselFinished', '_lastKnownVideoDuration', '_videoFinished','Actions','ViewDelete','_parentDataModelName','_piExtractResponseIds','_linkageId'];
  constructor() { }

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  public exportJSONExcel(jsonData: any[], fileName: string): void {

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  public exportTableExcel(tableData: any, fileName: string): void {
    

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(tableData);
    Object.keys(ws).forEach(key => {
      let pos = this.notNeeded.indexOf(ws[key]['v']);
      if(pos>-1){
        delete ws[key];
      }
    });
    
    
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName); 
  }
  public exportAllExcel(fileName: string, jsonObj:any ): void {
    for (let index = 0; index < jsonObj.length; index++) {
      for (const key in jsonObj[index]) {
        if (Object.prototype.hasOwnProperty.call(jsonObj[index], key)) {
          const element = jsonObj[index][key];
          if(typeof(element) == 'object' && element && element.length>0){
            jsonObj[index][key] = element.join(",");
          }
        }
      }
    }

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonObj);
    Object.keys(ws).forEach(key => {
      let pos = this.notNeeded.indexOf(ws[key]['v']);
      if(pos>-1){
        delete ws[key];
      }
      // if (ws[key]['v'] == "Actions" || ws[key]['v'] == "ViewDelete") {
      //   delete ws[key];
      // }
    });
    
    
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName); 
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }
}
