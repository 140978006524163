import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { User } from '../models/user.model';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UniversalUser } from './shared.service';
import { CustomDashboard } from '../models/customDashboard.model';
import {EnvironmentData} from './environments.service';



@Injectable({
  providedIn: 'root'
})
export class MultiTenantService {
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private router: Router,
    private universalUser: UniversalUser,
    private httpClient: HttpClient,
    private environmentData: EnvironmentData
  ) { }

  getTenants(): Observable<any[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any[]>();
    const url = envData.rootUrl + envData.statemachineroot + envData.userTenant;
    this.httpClient.get<any[]>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
    .subscribe(
      (response: HttpResponse<any[]>) => {
        if (response && response.body) {
          subject.next(response.body)
        } else {
          subject.error('No tenants');
        }
      },
      (err: HttpErrorResponse) => {
        subject.error(err);
      }
    );
    return subject.asObservable();
  }
  setTenant(userId): Observable<any[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any[]>();
    const url = envData.rootUrl + envData.statemachineroot + envData.setTenant+userId;
    this.httpClient.put<any>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
      (response) => {
          subject.next(response);
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
      );
    return subject.asObservable();
  }
}
