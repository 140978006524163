import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, Routes } from '@angular/router';
import * as FileSaver from 'file-saver';
import { Observable,Subject } from 'rxjs';
import {  ConnectorInfo, ConnectorConfig } from '../models/connector.model';
import { CampaignConfig } from '../models/tasks.model';
import {EnvironmentData} from './environments.service';
// import { RoleRouteMap, ApiConfig, ConnectorInfo, ConnectorConfig, Language, TaskConfigAttribute, EnvVarible, AccessControlDefinition, CampaignConfig } from '../models/setup.model';
// import { DataModel } from '../models/datamodel.model';
// import { Account } from '../models/account.model';
// import { CommonSearchModel } from '../models/flow.model';
// import { AlertService, UniversalUser } from './shared.service';

@Injectable()
export class ConnectorConfigService {
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private router: Router, private httpClient: HttpClient , private environmentData: EnvironmentData) { }
  getConnecterInfo(): Observable<ConnectorInfo[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<ConnectorInfo[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.connectorinfo}`;

    this.httpClient.get<ConnectorInfo[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<ConnectorInfo[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }



  getAllCons(): Observable<ConnectorConfig[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<ConnectorConfig[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.getallconconfig}`;
    this.httpClient.get<ConnectorConfig[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<ConnectorConfig[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }
  getAllEmailCampaigns(): Observable<CampaignConfig[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<CampaignConfig[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.getAllCampaign}`;

    this.httpClient.get<CampaignConfig[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<CampaignConfig[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }
  sendAdhocEmail(payload): Observable<any[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any[]>();

    const url = `${envData.rootUrl + envData.interfaceService + envData.sendEmail}`;
    this.httpClient.post<any>(
      url,
      payload,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }
}
