import { Directive, ElementRef,  Renderer, AfterContentChecked, HostListener } from '@angular/core';

@Directive({
  selector: '[ddScrollEnd]'
})
export class ScrollEndDirective implements AfterContentChecked {
  innerDivHeight:number;
  containerHeight:number;

  constructor(private el:ElementRef, private renderer: Renderer) { }
  ngAfterContentChecked(): void {
    // console.log(this.el)
    // if(this.el.nativeElement.offsetHeight > 0){
    //   this.containerHeight = this.el.nativeElement.offsetHeight;
    //   this.innerDivHeight = this.el.nativeElement.childNodes[0]['offsetHeight']
    // }
  }
  @HostListener('scroll')
  scrollChange(){
    // console.log(this.el)
  }
  // ngOnChanges(changes: SimpleChanges): void {
  //   console.log(changes)
  //   console.log(this.el.nativeElement.offsetHeight)
  // }
  // ngAfterViewInit(): void {
  //   // throw new Error("Method not implemented.");
  //   console.log(this.el.nativeElement)
  //   this.innerDiv = this.el.nativeElement.childNodes[0];
  //   console.log(this.innerDiv['offsetHeight'])
  // }

}
