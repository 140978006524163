import { v4 as uuid } from 'uuid';
import { Component, OnInit, OnDestroy, NgZone, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { REACTIVE_FORM_DIRECTIVES } from '@angular/forms'
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataSharingService, UniversalUser, CommunicationService } from '../../service/shared.service';
import { DataModelObject, FileService, EntiySharingService } from '../../service/shared.service';
//import { DataModelService } from '../../../../services/setup.service';
import { EntityService } from '../../service/entity.service';
import { DataModel, Field, ValidatorInstance, ExtractorInstance, Entity } from '../../models/datamodel.model';
import { CommonSearchModel } from '../../models/shared.model';
import { DataModelService } from '../../service/datamodel.service'
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ImagepdfviewerComponent } from '../../shared/imagepdfviewer/imagepdfviewer.component';





import { commonKeys } from '../../models/constants';
import { IMyDpOptions } from 'mydatepicker';
import { first, map } from 'rxjs/operators';
import { AccessControlRequest } from 'src/app/models/helper.model';
import { AccessControlService } from 'src/app/service/helper.service';
import { Location } from '@angular/common';
import { OneViewService } from 'src/app/service/oneview.service';
import { NotesForEntityComponent } from 'src/app/shared/notes-for-entity/notes-for-entity.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-add-activities',
  templateUrl: './add-activities.component.html',
  styleUrls: ['./add-activities.component.css'],
})
export class AddActivitiesComponent implements OnInit, OnChanges, OnDestroy {

  dataModelList: DataModel[];
  boolean: any[];
  filterQuery: string;
  selectedDataModel: any;
  selectedDataModelId: string;
  showSubmit: boolean;
  form: FormGroup;
  viewEntity: any = null;
  placeHolderText: String;
  private subscription: Subscription;
  showFields: boolean = false;
  showFieldsMap: any = {};
  submitted: boolean = false;
  showSubmitButton: boolean = false;
  lookupEntityList: any[];
  showFullPage: boolean = false;
  DataModelfields: any = {};
  formValid: boolean = false;
  showSave: boolean = true;
  bsModalRef: BsModalRef;
  dependencyExpressions: any;
  lookupRefEntityList: any[];
  entityMap: any = {};
  parentField: Field = new Field();
  manualStateEntityAction: boolean = false;
  listCallInProcess: boolean = false;
  datamodelName: string;
  navigationSubscription: Subscription;
  showLoaderOverlay: boolean = false
  //load from oneview
  entityId: string;
  entityName: string;
  entityLabel: string;
  customerId: string;
  navigatedEvent: boolean = false;
  onSamePage: boolean = true;
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd-mm-yyyy'
  };
  currentPanel: string = '';
  baseDocumentId: string = uuid();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private communicationService: CommunicationService,
    private dataModelService: DataModelService,
    private entityService: EntityService,
    private fileService: FileService,
    private snackBar: MatSnackBar,
    private modalService: BsModalService,
    private entiySharingService: EntiySharingService,
    private cd: ChangeDetectorRef,
    private dataSharingService: DataSharingService,
    private universalUser: UniversalUser,
    private accessControlService: AccessControlService,
    private _location: Location,
    private oneViewService: OneViewService,
    private titleService: Title
  ) {
    this.titleService.setTitle("Forms - Classic Mode")
    this.dataModelList = [];
    this.boolean = [true, false];
    this.filterQuery = '';
    this.showSubmit = false;
    this.navigatedEvent = false;
    this.showSubmitButton = true;
    this.selectedDataModel = new DataModel();
    this.placeHolderText = commonKeys.entityDefaultPlaceHolder;
    this.lookupEntityList = [];
    this.lookupRefEntityList = [];
    this.dependencyExpressions = {};
    this.entityMap = {};

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.navigatedEvent = true;
        this.intFunctions();
      }
    });

  }
  ngOnInit() {
    if (!this.navigatedEvent) {
      this.intFunctions();
    }
  }

  ngOnDestroy() {
    this.onSamePage = false;
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
  intFunctions() {
    this.form = new FormGroup({});
    this.showFields = false;
    this.route.params.subscribe(params => {
      if (params["entityId"]) {
        this.entityId = params["entityId"];
        this.entityName = params["entityName"];
        this.titleService.setTitle("Forms - " + this.entityName + "- Classic Mode");
        let datamodel = this.communicationService.getDataModel();
        if (datamodel) {
          this.entityLabel = datamodel['display'];
        }      
      }
    })
    this.route.queryParams.subscribe(queryparams => {
      if (queryparams["entityId"]) {
        this.communicationService.setAssocEntityDetails(null);
        this.entityId = queryparams["entityId"];
        this.entityName = queryparams["entityName"];
        this.entityLabel = queryparams["entityLabel"];
        this.customerId = queryparams["customerId"];

      }
    })
    if (this.entityId && this.entityName && this.entityId!='new') {
      this.getAssociatedEntity();
    }
    else {
      this.viewEntity = this.communicationService.getEntity();
      this.datamodelName = this.communicationService.getDataModelName();
      this.selectedDataModelId = this.communicationService.getSelectedDataModelId();
      this.executeforEntity();
    }

  }
  getAssociatedEntity() {
    this.entityService.getEntity(this.entityName, this.entityId).subscribe(entity => {
      if (entity) {
        this.viewEntity = entity;

        if (this.viewEntity.customerId) {
          this.togglePanel("customer");
        }

        if (this.viewEntity && this.viewEntity._documentId) {
          this.baseDocumentId = this.viewEntity._documentId;
        }

        this.selectedDataModelId = entity.datamodelId;
        // this.createEntityModal(this.selectedDataModelId, true);
        this.executeforEntity();
      }
    });
  }
  executeforEntity() {
    if(this.entityId=='new'){
      this.viewEntity = null;
    }
    if (this.viewEntity != null && this.selectedDataModelId != null) {
      let entityName = null;
      if (!this.viewEntity._entityName) {
        entityName = this.datamodelName;
      }
      else {
        entityName = this.viewEntity._entityName;
      }
      
      this.setEntity(entityName, this.viewEntity._id, this.viewEntity["stateInstanceId"]);
      if (this.viewEntity['_piExtractResponseIds'] && this.viewEntity['_piExtractResponseIds'].length > 0) {
        this.entityService.getPiExtractMetaData(this.viewEntity['_piExtractResponseIds']).subscribe(
          (response) => {
            if (response.length > 0) {
              // console.log(response[0]['extractedData'].map(item=>item.dataPoints));
              this.dataSharingService.piextractMeta[this.viewEntity._id] = response
              this.dataSharingService.piExtractResponse = (response);
            }
          },
          (error) => { console.log(error) }
        );
      }
      // if (this.dataSharingService && this.dataSharingService.piextractMeta && this.dataSharingService.piextractMeta.length > 0 && this.dataSharingService.piextractMeta.map(item => item.id).indexOf(this.viewEntity._id) > -1) {
      //   let piextractId = (this.dataSharingService.piextractMeta[this.dataSharingService.piextractMeta.map(item => item.id).indexOf(this.viewEntity._id)])
      //   this.entityService.getPiExtractMetaData(piextractId['value']).subscribe(
      //     (response) => {
      //       if (response.length > 0) {
      //         console.log(response)
      //         this.dataSharingService.piExtractResponse = (response)
      //       }
      //     },
      //     (error) => { }
      //   );
      // }

    }
    else if (this.viewEntity == null && this.selectedDataModelId != null) {
      this.showFullPage = true;
      this.createEntityModal(this.selectedDataModelId);
    }
    else {
      this.router.navigateByUrl('/activities');
      this.getDataModelList();
    }


  }
  setEntity(entityName: string, id: string, stateInstanceId: string = null) {
    if (this.communicationService.isDuplicateEntity()) {
      this.entityService.duplicateEntity(entityName, id)
        .subscribe(
          entity => {
            this.viewEntity = entity;
            this.viewEntity.statusCd = "DRAFT";
            this.showFullPage = true;
            if (this.viewEntity.statusCd == "ACTIVE") {
              this.showSubmitButton = false;
            }
            else {
              this.showSubmitButton = true;
            }
            this.createEntityModal(this.selectedDataModelId);
          },
          error => {

          }
        )
    }
    else {
      this.entityService.getEntity(entityName, id).subscribe(entity => {
        if (entity) {
          this.viewEntity = entity;
          if (this.viewEntity && this.viewEntity._documentId) {
            this.baseDocumentId = this.viewEntity._documentId;
          }
          if (stateInstanceId != null) {
            this.viewEntity["stateInstanceId"] = stateInstanceId;
          }
          this.showFullPage = true;
          if (this.viewEntity.statusCd == "ACTIVE") {
            this.showSubmitButton = false;
          }
          else {
            this.showSubmitButton = true;
          }
          this.createEntityModal(this.selectedDataModelId);
        }
      });
    }

  }

  createEntityModal(dataModelId?: string, isAssociatedEntity?: boolean) {
    this.showFieldsMap = {};
    if (dataModelId) {
      this.subscription = this.dataModelService.getDataModel(dataModelId)
        .subscribe(
          datamodel => {
            if (datamodel) {
              this.selectedDataModel = datamodel;
              this.setAccessOfUserForDataModel(this.selectedDataModel);
              // if(this.entityId=='new'){
              //   this.viewEntity = {};
              // }
              // this.datamodelName = datamodel.name;
              this.datamodelName = datamodel.label;
              this.showFields = true;
               // This is the V2 Version of the datamodel old api is deprecated
               
              if (this.viewEntity && this.viewEntity['_id']) {
                this.entityService.getPiExtractMetaDataV2(this.viewEntity['_id'], datamodel.name).subscribe(
                  (response) => {
                    if (response.length > 0) {
                      // console.log(response[0]);
                    //   this.dataSharingService.piextractMeta[this.viewEntity._id] = response
                      this.dataSharingService.piExtractResponse = (response[0]['dataPoints']);
                      this.dataSharingService.piExtractFile = response[0]['extractedDataFromFile'];
                    }
                  },
                  (error) => { console.log(error) }
                );
              }
              if (isAssociatedEntity && isAssociatedEntity == true) {
                this.showSave = true;
                this.showSubmit = true;
                this.executeforEntity()
              }
            }
          }
        );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(val => {
      for (let key of Object.keys(val)) {
        this.DataModelfields[key] = val[key];
      }
    });
  }


  conditionForField(field: Field) {
    if (!field.hide) {
      if (field.dependencyExpression) {
        const exp = field.dependencyExpression.replace(/datamodel/g, commonKeys.dependencyExpressionReplacer);
        const result = this.evaluateExpression(exp);
        if (result) {
          this.showFieldsMap[field.name + "*" + field.sortOrder] = true;
        }
        else {
          this.showFieldsMap[field.name + "*" + field.sortOrder] = false;

        }
        return result;
      }
      else {
        return true;
      }

    }
    else {
      return false;
    }


  }

  checkForRefrenceField(field) {
    if (field.name == commonKeys.entityLookUpRefId) {
      return true;
    }
    else {
      return false;
    }
  }


  renderLookupEntityFields(selectedDataModel: DataModel, lookupEntityList: any[], referenceModel: String) {
    for (let field of selectedDataModel.fields) {
      if (field.name == referenceModel) {
        field.lookupEntityList = lookupEntityList;
        this.selectedDataModel.fields = selectedDataModel.fields;
        this.lookupEntityList = [];
        break;
      }

    }
  }


  getDataModelList() {
    const commonsearchModel = new CommonSearchModel();
    commonsearchModel.searchParams = [{ 'statusCd': 'ACTIVE' }];
    commonsearchModel.returnFields = ['label', 'version', 'statusCd', 'subEntity'];
    this.subscription = this.dataModelService.getDataModelList(commonsearchModel)
      .subscribe(list => {
        if (list.length > 0) {
          for (let dataModel of list) {
            if (!dataModel.subEntity) {
              this.dataModelList.push(dataModel);
            }
          }
          this.createEntityModal(this.dataModelList[0]._id)
        }
      });
  }

  getLookupEntitiesList(referenceModel: string) {
    const commonsearchModel = new CommonSearchModel();
    commonsearchModel.searchParams = [{}];
    commonsearchModel.returnFields = [];
    this.subscription = this.entityService.getLookupEntities(referenceModel, commonsearchModel)
      .subscribe(lookupList => {
        for (let lookupEntity of lookupList) {
          this.lookupEntityList.push(lookupEntity);
        }
        this.renderLookupEntityFields(this.selectedDataModel, lookupList, referenceModel);
      });
  }

  getRefLookupEntitiesList(referenceModel: string) {
    const commonsearchModel = new CommonSearchModel();
    commonsearchModel.searchParams = [{}];
    commonsearchModel.returnFields = [commonKeys.entityLookUpRefLabel, commonKeys.entityLookUpRefId];
    this.subscription = this.entityService.getRefLookupEntities(referenceModel, commonsearchModel)
      .subscribe(lookupList => {
        for (let lookupEntity of lookupList) {
          if (lookupEntity[commonKeys.entityLookUpRefId] != null && lookupEntity[commonKeys.entityLookUpRefId] != undefined) {
            let map = {};
            map["id"] = lookupEntity[commonKeys.entityLookUpRefId];
            map["name"] = lookupEntity[commonKeys.entityLookUpRefLabel];
            this.lookupRefEntityList.push(map);
          }
        }
        this.renderLookupEntityFields(this.selectedDataModel, this.lookupRefEntityList, referenceModel);
      });
  }

  isValid(field: Field) {
    let required = false;
    for (const validator of field.validators) {
      if (validator.name === 'Required') {
        required = true;
      }
    }
    if (field.mandatory || required) {
      if (field.value != null && field.value != "" && field.value != undefined) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  goBack() {
    if (this.customerId) {
      this.router.navigate(['/customer-details'], { queryParams: { customerId: this.customerId } });
    }
    else {
      this._location.back();
    }
  }

  toFormGroup(fields: Field[]) {
    const group: any = {};

    fields.forEach(field => {
      let required = false;
      required = field.mandatory;
      for (const validator of field.validators) {
        if (validator.name === 'Required') {
          required = true;
        }
      }
      group[field.name] = required ? new FormControl(field.value || '', Validators.required) : new FormControl(field.value || '');
    });
    return new FormGroup(group);
  }

  checkFormisValid() {
    let valid = [];
    for (let field of this.selectedDataModel.fields) {
      if (field.mandatory) {
        if (field.value != null && field.value != "" && field.value != undefined) {
          valid.push("true");
        }
        else {
          if (field.dependencyExpression) {
            if (!this.showFieldsMap[field.name + "*" + field.sortOrder]) {
              valid.push("true");
            }
            else {
              valid.push("false");
            }
          }
          else {
            valid.push("false");
          }
        }
      }
      else {
        valid.push("true");
      }
    }
    if (valid.includes("false")) {
      return false;
    }
    else {
      return true;
    }
  }

  onBooleanChange(event, field?: Field) {

  }

  onDateChanged(event) {
    if (event) {
      // this.selectedAgent.uiComponent.startTime = event["jsdate"];
    }
  }


  getSelectedEntity(event, field) {

  }



  saveEntity(emittedMap?: any) {
    let type = emittedMap["type"];
    this.selectedDataModel = emittedMap["model"];
    const entity = new Entity();

    entity.fields = this.selectedDataModel.fields;
    entity.label = this.selectedDataModel.label;
    entity.name = this.selectedDataModel.name;
    entity.version = this.selectedDataModel.version;
    entity.process = this.selectedDataModel.process;
    let map = {};
    this.entityMap = this.entiySharingService.getEntityMap(entity.fields);
    if (type === "save") {
      this.entityMap["statusCd"] = "DRAFT";
      let entityForError = null;
      if (this.viewEntity != null) {
        this.entityMap["_id"] = this.viewEntity._id;
        this.entityMap["createdAt"] = this.viewEntity.createdAt;
        this.entityMap["createdBy"] = this.viewEntity.createdBy;
        this.entityMap["_entityName"] = this.viewEntity._entityName;
        entityForError = this.viewEntity;
      }
      else {
        entityForError = this.entityMap;
      }
      this.entityMap._documentId = this.baseDocumentId;
      this.route.queryParams.subscribe(params => {
        this.customerId = params["customerId"];
      });
      if (this.customerId) { 
        this.entityMap['customerId'] = this.customerId; 
      } else if (this.selectedCustomerId) { 
        this.entityMap['customerId'] = this.selectedCustomerId; 
      }
      this.viewEntity = this.entityMap;
      this.subscription = this.entityService.saveEntity(entity, this.entityMap)
        .subscribe(
          savedEntity => {
            if (savedEntity) {
              this.viewEntity = savedEntity;
              this.snackBar.open(this.selectedDataModel.label.toString() + ' has been saved successfully', "Undo", {
                duration: 5000
              })
            }
          },
          error => {
            if (error.error.message) {
              this.snackBar.open(error.error.message, "Undo", {
                duration: 5000
              })
              this.viewEntity = entityForError;
            }
            else {

              this.snackBar.open('Something went wrong please try again in sometime', "Undo", {
                duration: 5000
              })
            }
          }
        );
    }
    else if (type === "submit") {
      this.entityMap["statusCd"] = "ACTIVE";
      this.showLoaderOverlay = true;
      if (this.viewEntity != null) {
        this.entityMap["_id"] = this.viewEntity._id;
        this.entityMap["createdAt"] = this.viewEntity.createdAt;
        this.entityMap["createdBy"] = this.viewEntity.createdBy;
        this.entityMap["_entityName"] = this.viewEntity._entityName;
      }
      this.route.queryParams.subscribe(params => {
        this.customerId = params["customerId"];
      });
      if (this.customerId) { this.entityMap['customerId'] = this.customerId; } else if (this.selectedCustomerId) { this.entityMap['customerId'] = this.selectedCustomerId; }
      this.viewEntity = this.entityMap;
      this.subscription = this.entityService.submitEntity(entity, this.entityMap)
        .subscribe(
          entity => {
            if (entity) {
              this.showLoaderOverlay = false;
              this.goBack();
              this.snackBar.open(this.selectedDataModel.label.toString() + ' has been Submitted successfully ', "Undo", {
                duration: 5000
              })
            }
          },
          error => {

            if (error.error.message) {
              this.showLoaderOverlay = true;
              this.snackBar.open(error.error.message, "Undo", {
                duration: 5000
              })
            }
            else {
              this.showLoaderOverlay = true;
              this.snackBar.open('Something went wrong please try again in sometime', "Undo", {
                duration: 5000
              })
            }
          }  // if(this.onSamePage){
          //   this.goBack();
          // }
        );
    }

    else if (type === "update") {
      this.entityMap["statusCd"] = "ACTIVE";
      if (this.viewEntity != null) {
        this.entityMap["_id"] = this.viewEntity._id;
        this.entityMap["createdAt"] = this.viewEntity.createdAt;
        this.entityMap["createdBy"] = this.viewEntity.createdBy;
        this.entityMap["_entityName"] = this.viewEntity._entityName;
      }

      this.route.queryParams.subscribe(params => {
        this.customerId = params["customerId"];
      });
      if (this.customerId) { this.entityMap['customerId'] = this.customerId; } else if (this.selectedCustomerId) { this.entityMap['customerId'] = this.selectedCustomerId; }
      this.subscription = this.entityService.updateEntity(entity, this.entityMap)
        .subscribe(
          entity => {
            if (entity) {
              this.router.navigateByUrl('/task');
              this.snackBar.open(entity._entityName.toString() + ' has been created successfully and the associated flow has been triggerd', "Undo", {
                duration: 5000
              })
            }
          },
          error => {
            this.snackBar.open('Something went wrong please try again in sometime', "Undo", {
              duration: 5000
            })
          }
        );
    }
  }
  selectedCustomerId: string = '';
  updateCustomerForDatamodel(customerId) {
    this.selectedDataModel.customerId = customerId;
    this.selectedCustomerId = customerId;
    let payload = {
      "customerId": customerId,
      "interactionType": "entity",
      "interactionMode": "Manual",
      "interactionId": this.entityId,
      "interactionName": this.selectedDataModel.name
    }
    this.oneViewService.linkCustomer(payload).subscribe(
      response=>{
        this.snackBar.open('Customer Linked Successfully', "Close", {
          duration: 2000
        })
      },
      error=>{
        console.error(error)
      }
    );
  }
  //delete
  createMap(field: Field, parentField?: Field, listCall?: boolean) {
    if (field.type == commonKeys.entityLookupReference && field.value) {
      field.value = field["actualValue"];
    }
    if ((field.type == "DATE" || field.type == "DATETIME") && field.value) {
      field.value = this.getIsoDate(field.value);
    }
    if (field.type == "MODEL" && !field.list) {
      if (field.value) {
        for (let f of field.value.fields) {
          let map = {}
          if (field["map"] != undefined) {
            map = field["map"];
          }
          if (f.type == "MODEL" && !f.list) {
            this.createMap(f, field);
          }
          else if (f.type == "MODEL" && f.list) {
            this.createMap(f, field);
          }
          else {
            map[f.name] = this.getFieldValue(f);
          }
          field["map"] = map;
        }
        if (parentField != null || parentField != undefined) {
          if (parentField["map"] == undefined) {
            parentField["map"] = {}
          }
          parentField["map"][field.name] = field["map"];
          return;
        }
        else {
          if (!this.listCallInProcess) {
            this.entityMap[field.name] = field["map"];
            return;
          }
          else {
            return field["map"];
          }
        }
      }
    }
    if (field.type == "MODEL" && field.list) {
      let mapArr = [];
      if (field.value && field.value.length > 0) {
        this.listCallInProcess = true;
        for (let value of field.value) {
          let map = {};
          for (let f of value.fields) {
            map[f.name] = this.createMap(f, field);
          }
          mapArr.push(field["map"]);
          field["map"] = {};

        }
        if (parentField != null || parentField != undefined) {
          parentField["map"][field.name] = mapArr;
        }
        else {
          this.listCallInProcess = false;
          this.entityMap[field.name] = mapArr;
        }
        return;
      }
    }
    else {
      if (!this.listCallInProcess) {
        if (this.getFieldValue(field) != undefined) {
          this.entityMap[field.name] = this.getFieldValue(field);
        }
      }
      else {
        let map = parentField["map"];
        if (map == undefined) {
          map = {}
          parentField["map"] = map;
        }
        if (map[field.name] == undefined || map[field.name].length == 0) {
          if (this.getFieldValue(field) != undefined) {
            map[field.name] = this.getFieldValue(field);
          }
          parentField["map"] = map;
        }
      }
    }
  }

  //delete
  getFieldValue(field: Field) {

    if (field.validationExpression) {
      let f = this.validateRegex(field);
      if (f) {
        if (f.value) {
          return f.value;
        }
      }
      else {
        return false;
      }
    }
    else {
      if (field.value) {
        return field.value;
      }
    }
  }

  onDocumentUpload(event, field: Field) {
    for (let index = 0; index < event.target.files.length; index++) {
      let uploadedFile = event.target.files[index];
      this.form.controls[field.name].setValue(uploadedFile ? uploadedFile.name : '');

      const fileInputForm = new FormData();
      const file: File = event.target.files[index];
      fileInputForm.append('file', file, file.name);
      var uploadFileName = uuid();
      fileInputForm.append("fileName", file.name);
      fileInputForm.append("functionInstanceName", "Activity");
      fileInputForm.append("entityType", "ACTIVITY_" + this.selectedDataModel.name);
      fileInputForm.append("entityRef", this.selectedDataModel._id);
      if (field.publicFile) {
        fileInputForm.append("publicRead", field.publicFile.toString());
      }
      this.uploadFile(fileInputForm, field);
    }

  }

  uploadFile(formData: FormData, field: Field) {
    this.fileService.upload(formData)
      .subscribe(
        response => {
          if (response && response["url"] && response["fileName"]) {
            if (field.list == true) {
              if (field.value == undefined) {
                field.value = [];
              }
              let doc: any = {};
              doc.url = response["url"];
              doc.fileName = response["fileName"];
              doc.downloadFileUrl = response["downloadFileUrl"];
              doc.fullDataUrl = response["fullDataUrl"];
              doc.fullFileUrl = response["fullFileUrl"];
              if (response["publicURL"]) {
                doc.publicURL = response["publicURL"]
              }
              field.value.push(doc);
            } else {
              let doc: any = {};
              doc.url = response["url"];
              doc.fileName = response["fileName"];
              doc.downloadFileUrl = response["downloadFileUrl"];
              doc.fullDataUrl = response["fullDataUrl"];
              doc.fullFileUrl = response["fullFileUrl"];
              if (response["publicURL"]) {
                doc.publicURL = response["publicURL"]
              }
              field.value = doc;
            }
          }
        }, (error) => {
          if (error && error.error && error.error.error && error.error.error.message) {
            this.snackBar.open(error.error.error.message, "Dismiss", {
              duration: 10000
            });
          }
        });
  }




  getRefrenceEntity(field: Field) {
    let payload = {}
    payload[commonKeys.entityLookUpRefId] = field.value;
    this.subscription = this.entityService.getReferenceEntity(field.referenceModel, payload)
      .subscribe(
        entity => {
          if (entity) {
            return entity;
          }
        },
        error => {
          this.snackBar.open('Something went wrong please try again in sometime', "Undo", {
            duration: 5000
          })
        }
      );
    return null;
  }

  //delete
  getIsoDate(date: any) {
    let dt = new Date();
    dt.setFullYear(date.date['year']);
    dt.setMonth(+date.date['month'] - 1);
    dt.setDate(date.date['day']);

    if (date.date['hour'] && date.date['min']) {
      dt.setHours(date.date['hour']);
      dt.setMinutes(date.date['min']);
    }

    return dt.toISOString();
  }

  checkPassword(type: string) {
    if (type == "PASSWORD") {
      return true;
    }
    return false;
  }

  gateDateToDisplay(date: Date) {
    let value = {
      date: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      }
    };
    return value;
  }


  //delete
  validateRegex(field: Field) {
    if (field.validationExpression && field.validationExpression.length > 0) {
      const matchValues = field.value.match(field.validationExpression);
      if (!matchValues || matchValues.length == 0) {
        var errorMsg = "Invalid input for field :: " + field.name;
        if (field.errorMessage) {
          errorMsg = field.errorMessage;
        }
        this.snackBar.open(errorMsg, "Dismiss", {
          duration: 5000
        })
        return false;
      }
      else {
        var regexExp = new RegExp(field.validationExpression, "g");
        if (regexExp) {
          var matches = regexExp.exec(field.value);
          if (matches && matches.length > 0 && field.value && field.value.length > 0 && matches[0] != null && matches[0].length > 0) {
            field.value = matches[0];
            return field;
          }
        }
      }
    }
  }

  //dependency eval
  evaluateExpression(expression: string) {

    if (expression && expression.trim().length > 0) {
      const result = eval(expression);
      return result;
    }
  }
  viewFile(fieldValue) {
    this.fileService.download(fieldValue.downloadFileUrl, true).subscribe(
      objectUrl => {
        if (objectUrl) {
          var type = (objectUrl.split(';')[0]).replace("data:", "");
          fetch(objectUrl)
            .then(res => res.blob())
            .then(blob => {
              // const file = new File([blob], this.doc.fileName,{ type: type })
              // return (file);
            })
          // this.downloadFileObjectUrl = objectUrl;
        }
      }
    );
  }
  openModalWithComponent(field: Field, index?: number) {
    if (index != undefined) {
      const initialState = {
        doc: this.viewFile(field.value[index]),
        title: 'Document Title'
      };
      this.bsModalRef = this.modalService.show(ImagepdfviewerComponent, { initialState });
      this.bsModalRef.content.closeBtnName = 'Close';
    } else {
      const initialState = {
        doc: this.viewFile(field.value),
        title: 'Document Title'
      };
      this.bsModalRef = this.modalService.show(ImagepdfviewerComponent, { initialState });
      this.bsModalRef.content.closeBtnName = 'Close';
    }

  }

  onRemoveDocument(field: Field, ind?: number) {
    if (ind != undefined) {
      let index = this.selectedDataModel.fields.indexOf(field)
      if (index != -1) {
        this.selectedDataModel.fields[index].value.splice(ind, 1);
      }
    } else {
      let index = this.selectedDataModel.fields.indexOf(field)
      if (index != -1) {
        this.selectedDataModel.fields[index].value = undefined;
      }
      // this.form = this.toFormGroup(this.selectedDataModel.fields);
    }
  }

  getFileName(field: Field) {
    return field.value.fileName;
  }

  //Autocomplete

  selectEvent(item, field) {
    // do something with selected item
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }
  editAssociatedEntity() {
    // localStorage.setItem("testToDelete",JSON.stringify(this.communicationService.getAssocEntityDetails()));

    let taskDetails = this.communicationService.getAssocEntityDetails();
    let entityId = null;
    let entityName = null;
    let stateInstanceId = taskDetails._id;
    if (taskDetails.parameters['_associatedEntityId'] && taskDetails.parameters['_associatedEntityName']) {
      entityId = taskDetails.parameters['_associatedEntityId'];
      entityName = taskDetails.parameters['_associatedEntityName'];

    }
    //for backward compatibility
    else if (taskDetails.source != undefined && taskDetails.source != null && taskDetails.source.length > 0) {
      let pos = taskDetails.source.map(item => item.referenceType).indexOf('ENTITY');
      if (pos != null && pos != undefined && pos >= 0) {
        entityId = taskDetails.source[pos]['referenceId'];
        entityName = taskDetails.source[pos]['referenceEntityName'];
      }
    }


    if (entityId != null && entityName != null) {
      this.entityService.getEntity(entityName, entityId).subscribe(entity => {
        if (entity) {

          this.viewEntity = entity;
          if (this.viewEntity && this.viewEntity._documentId) {
            this.baseDocumentId = this.viewEntity._documentId;
          }
          this.viewEntity["stateInstanceId"] = stateInstanceId;
          this.selectedDataModelId = entity.datamodelId;
          // this.createEntityModal(this.selectedDataModelId, true);
          this.executeforEntity();
        }
      });

    }



  }

  setAccessOfUserForDataModel(dataModel: DataModel) {
    let accessControlReq = new AccessControlRequest();
    accessControlReq.componentType = "ENTITY";
    accessControlReq.componentName = dataModel.name;
    accessControlReq.userId = this.universalUser.getUser()._id;
    this.accessControlService.getUserAccess(accessControlReq)
      .subscribe(
        accessControl => {
          if (!accessControl || accessControl.operationAccessList == null || accessControl.operationAccessList.length == 0) {
            dataModel.allowCreate = true;
            dataModel.allowUpdate = true;
            dataModel.allowDelete = true;
          }
          else {
            if (accessControl.operationAccessList) {
              for (let operation of accessControl.operationAccessList) {
                if (operation.operationName == "Create" && operation.enabled) {
                  dataModel.allowCreate = true;
                }
                if (operation.operationName == "Update" && operation.enabled) {
                  dataModel.allowUpdate = true;
                }
                if (operation.operationName == "Delete" && operation.enabled) {
                  dataModel.allowDelete = true;
                }
                if (operation.operationName == "View" && operation.enabled) {
                  dataModel.allowView = true;
                }
              }
            }
          }

        });
  }

  togglePanel(panelName) {
    if (this.currentPanel == panelName) {
      this.currentPanel = '';
    } else {
      this.currentPanel = panelName;
    }
  }
  openModalForNotes() {
    const initialState = {
      entityId: this.entityId,
      datamodelId: this.selectedDataModelId,
      datamodelName: this.datamodelName
    };
    this.bsModalRef = this.modalService.show(NotesForEntityComponent, { initialState });
    this.bsModalRef.setClass('modal-lg-full')
    this.bsModalRef.content.closeBtnName = 'Close';
  }
  ocrSelectedField: any;
  ocrAllField: any;
  ocrtableFields: any;
  openOcrPanel(fieldsToShowOcr) {
    this.ocrtableFields = fieldsToShowOcr.tableFields;
    this.ocrSelectedField = fieldsToShowOcr.field;
    this.ocrAllField = fieldsToShowOcr.allFields;
    this.currentPanel = 'OCRField'
  }
  updateSelectedValue(event){
    if(event.selectedField){
      let pos = this.selectedDataModel.fields.map(item=>item.name).indexOf(event.selectedField.name);
      this.selectedDataModel.fields[pos].value = event.newValue;
      this.viewEntity[event.selectedField.name] = event.newValue;
      //This is to refresh the dynamic fields on dom
      this.showFields = !this.showFields;
      this.currentPanel ='';
      setTimeout(() => {
        this.showFields = !this.showFields;
      }, 200);
    }
  }
}
