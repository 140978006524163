import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomDashboard, CustomDashboardComponent, CustomDashboardComponentRef, Filter } from '../models/customDashboard.model';
import { CustomDashboardService } from '../service/dashboard.service';
import * as moment from 'moment';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { PreferenceService } from '../service/user.service';
import { UserPreferences } from '../models/userPreferences.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown'
import { CommonSearchModel } from '../models/shared.model';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { RouteService } from '../service/shared.service';
import { FileService } from 'src/app/service/shared.service';
import { MatSnackBar } from '@angular/material';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-custom-dashboard',
  templateUrl: './custom-dashboard.component.html',
  styleUrls: ['./custom-dashboard.component.css']
})
export class CustomDshbrdComponent implements OnInit {
  componentList: any[] = [];
  customDashboard: CustomDashboard;
  customDashboardUserPref: UserPreferences;
  allDashboards: any[] = [];
  items = [{"name": "PDF" ,"value": "pdf" }, {"name" : "Excel" , "value" : "excel"}];
  selectedOption : string;
  startTime: string;
  endTime: string;
  showList: boolean = false;
  filterList: any[] = [];
  showLoaderOverlay : boolean = false
  dropdownSettings: IDropdownSettings = {};
  private subscription: Subscription;
  private filterSubscription: Subscription;
  applyFilter: Filter = new Filter();
  componentFilters: Filter[] = [];
  selectedItems = [];
  colourList = []; // to be used for colour
  range: any = null;
  @ViewChild("reportCustom", { static: false }) reportCustom: ElementRef;

  filterSourceList = [];

  constructor(
    private customDashboardService: CustomDashboardService,
    private userPrefService: PreferenceService,
    private renderer: Renderer2,
    private fileService : FileService,
    private sanitizer: DomSanitizer,
    private routeService: RouteService,
    private snackBar: MatSnackBar,
    private titleService: Title,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.titleService.setTitle("Reports");

  }
  ngOnInit() {
    this.getDashboardList();
    //this.getAllComponents();
    this.getFilterList();
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 10,
      allowSearchFilter: true
    }
    let todayDate = new Date();
    this.range = { startDate: moment().subtract(29, 'days'), endDate: moment() };
    this.endTime = moment(todayDate).toISOString();
    this.startTime = moment(todayDate).subtract(29, 'days').toISOString();
    // this.startTime = "2020-06-30T12:00:00+05:30";
    // this.endTime = "2021-03-31T11:59:59+05:30";
    this.customDashboardUserPref = null;
    this.customDashboard = null;
  }
  getAllComponents() {
    this.customDashboardService.getAllComponents().subscribe(
      (response) => {
        if (response && response.data) {
          this.componentList = response.data;
          this.getDashboardList();
        }
      },
      (error) => { },
    );
  }
  getDashboardList() {
    const commonSearch: CommonSearchModel = new CommonSearchModel();
    commonSearch.returnFields = null;
    this.subscription = this.customDashboardService.fetchAllDashboards(commonSearch)
      .subscribe(dashboardList => {
        if (dashboardList && dashboardList.length > 0) {
          // this.customDashboard = dashboardList[0];
          this.allDashboards = dashboardList;
          if (this.allDashboards && this.allDashboards.length > 0)
            this.setCustomDashboardByUserPref();
        }
      });
  }
  getFilterList() {
    this.filterSubscription = this.customDashboardService.getAllFilters()
      .subscribe(filterList => {
        if (filterList && filterList.length > 0) {
          const tempFilterMap: any = {};
          for (let index = 0; index < filterList.length; index++) {
            const element = filterList[index];
            if (tempFilterMap[element.key])
              continue;
            tempFilterMap[element.key] = element.value;
            let display = capitalize(element.value.replace("payload.", ""));
            let temp = {
              item_id: element.key,
              item_text: display
            }
            this.filterList.push(temp)
          }
          // console.log(this.filterList)

        }
      });
  }
  datesUpdated(event) {
    if (moment(event.startDate).format('YYYY-MM-DDThh:mm:ssZ') != 'Invalid date') {
      this.startTime = moment(event.startDate).toISOString();
      this.endTime = moment(event.endDate).toISOString();
    }
  }
  getComponentType(component: CustomDashboardComponentRef) {
    let curr: CustomDashboardComponent = this.componentList[this.componentList.map(item => item._id).indexOf(component.refId)];
    if (curr != undefined) {
      return curr;
    }
    else {
      return new CustomDashboardComponent()
    }
  }
  optionSelected(){
    
    if (this.selectedOption && this.selectedOption == "pdf" ){
      this.downloadAsPDF()
    }
    if (this.selectedOption && this.selectedOption == "excel"){
      this.downloadAsExcel()
    }
  }
  startExport: boolean = false;

  downloadAsExcel() {
    this.showLoaderOverlay = true
    let requestFilter = {}
    requestFilter["startTime"] = new Date(this.startTime).getTime()
    requestFilter["endTime"] = new Date(this.endTime).getTime()
    if (this.componentFilters && this.componentFilters.length > 0){
      for (let i = 0; i < this.componentFilters.length ; i++){
        let key = this.componentFilters[i].attribute.split('payload.').pop().split('.keyword')[0]; // returns 'two'

        let value = this.componentFilters[i].value
        requestFilter[key] = value
      }      
    }
    this.customDashboardService.getExcelFile(requestFilter,this.customDashboard._id)
      .subscribe(
        response => {
          if (!response.fileUrl) {
            this.showLoaderOverlay = false
            this.snackBar.open("There was an error to export report", "Dismiss", {
              duration: 10000
            });
            return;
          }
          this.fileService.download(response.fileUrl);
          this.showLoaderOverlay = false
        },
        error => {
          this.showLoaderOverlay = false
          
        }
      )
  }

  public downloadAsPDF() {
    // this.startExport = true;
    this.renderer.addClass(this.reportCustom.nativeElement, "fullLengthPage")
    const businessDashboardParent = document.getElementById("reportCustom");
    var data = businessDashboardParent;
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 208;
      var imgHeight = canvas.height;
      var fullImgHeight = canvas.height * imgWidth / canvas.width;
      var pageHeight = 295;
      var numberOfPages = Math.round(fullImgHeight / pageHeight);
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 0;
      for (let index = 0; index < numberOfPages; index++) {
        position = index * pageHeight;
        pdf.addImage(contentDataURL, 'PNG', 0, position, 208, 295)
      }

      pdf.save(this.customDashboard.name + '_' + this.startTime + '_' + this.endTime + ".pdf"); // Generated PDF   
      this.startExport = false;
      // this.renderer.removeClass(this.reportCustom.nativeElement,"fullLengthPage")
    });

  }
  showAlDashboards() { }

  setCustomDashboardByUserPref() {
    this.userPrefService.getUserPrefByScreenAndComponent("custom-dashboard", "VIEW")
      .subscribe(
        response => {
          if (response && response.length > 0) {
            this.customDashboardUserPref = response[0];
            if (this.customDashboardUserPref && this.customDashboardUserPref.preferences && this.customDashboardUserPref.preferences.customDashboardId) {
              for (let dashboard of this.allDashboards) {
                if (dashboard._id == this.customDashboardUserPref.preferences.customDashboardId) {
                  this.setDashboard(dashboard);


                  if (dashboard.customThemeColors == undefined) {
                    this.colourList = [];
                  } else {
                    this.colourList = dashboard.customThemeColors;
                  }
                  if (this.customDashboard.panels) {
                    this.customDashboard.panels.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1);
                    for (let index = 0; index < this.customDashboard.panels.length; index++) {
                      const element = this.customDashboard.panels[index];
                      for (let compInd = 0; compInd < element.componentIds.length; compInd++) {
                        const el = element.componentIds[compInd];
                        el.compDetail = this.getComponentType(el);

                      }
                      if (element.uiProperties.panelCSS) {
                        const css = element.uiProperties.panelCSS;
                        const head = document.getElementsByTagName('head')[0];
                        const style = document.createElement('style');
                        style.type = 'text/css';
                        style.id = 'panel';
                        style.appendChild(document.createTextNode(css));
                        head.appendChild(style);
                      }
                    }
                  }
                  
                  // console.log(this.componentList)
                }
              }
            }
          }
          if (this.customDashboard == null) {
            this.setDashboard(this.allDashboards[0]);
            if (this.allDashboards[0].customThemeColors == undefined) {
              this.colourList = [];
            } else {
              this.colourList = this.allDashboards[0].customThemeColors;

            }
          }
        },
        error => {

        }
      )
  }
  
 setDashboard(dashboard: CustomDashboard) {
    
    this.filterList = [];
    this.componentFilters = [];
    this.customDashboard = dashboard;
    this.selectedOption = null;
  
    if (!this.componentFilters || this.componentFilters.length == 0) {
      this.componentFilters = this.customDashboard.filters;
    }
  
    for (let index = 0; index < this.customDashboard.panels.length; index++) {
      let panel = this.customDashboard.panels[index];
      if (panel.uiProperties['startHtml']) {
        this.getReturnParsedHtml(panel.uiProperties['startHtml'], index);
      }
    }
  
    this.getFilterList();
    this.setComponentList();
    
    // Set the title dynamically based on the custom dashboard name
    this.titleService.setTitle("Reports :" + dashboard.name);


  
    // Update the URL without triggering navigation
    const customDashboardId = dashboard._id;
    this.updateUrlWithDashboardId(customDashboardId);
  }
  
  private updateUrlWithDashboardId(dashboardId: string) {
    // Use Location service to replace the current URL
    this.location.replaceState(`/custom-dashboard/${dashboardId}`);
  }

  setComponentList() {
    const commonSearch: CommonSearchModel = new CommonSearchModel();
    commonSearch.returnFields = null;
    this.customDashboardService.getComponentListForDashboardId(this.customDashboard._id)
      .subscribe(
        response => {
          this.componentList = response;
        },
        error => {

        }
      )
  }

  saveCustomDashboardPref(customDashboard: CustomDashboard) {
    this.customDashboard = null;
    this.panelParsedHTML = [];
    if (this.customDashboard != customDashboard) {
      this.componentFilters = []
    }


    if (this.customDashboardUserPref == null) {
      this.customDashboardUserPref = new UserPreferences();
    }
    this.customDashboardUserPref.screen = "custom-dashboard";
    this.customDashboardUserPref.component = "VIEW";
    this.showList = false;
    this.customDashboardUserPref.preferences = { customDashboardId: customDashboard._id };
    this.userPrefService.saveUserPref(this.customDashboardUserPref)
      .subscribe(
        response => {
          this.customDashboard = customDashboard;
          this.customDashboardUserPref = response;
          this.setDashboard(customDashboard);
        },
        error => {

        }
      )
  }
  onItemSelect(event) {
    this.applyFilter.attribute = event.item_id
    this.filterSourceList = [];
    this.customDashboardService.getDistinctValuesByField(this.applyFilter.attribute)
      .subscribe(
        response => {
          this.filterSourceList = response;
        },
        error => {

        }
      )
  }
  filterResults() {
    if (this.applyFilter.value) {
      this.filterSourceList = [];
      this.applyFilter.operation = "equal to"
      this.applyFilter.display = true;
      this.componentFilters.push(this.applyFilter);
      this.applyFilter = new Filter();
      this.selectedItems = [];
      this.customDashboard = null;
      this.setCustomDashboardByUserPref();
    }
  }
  removeFilter(filter: Filter) {
    const index = this.componentFilters.indexOf(filter);
    if (index != -1) {
      this.componentFilters.splice(index, 1);
      this.customDashboard = null;
      this.setCustomDashboardByUserPref()
    }

  }

  getFilterFieldSourceList() {

    if (this.customDashboard) {
      if (!this.customDashboard.filterFields) {
        return this.filterList;
      }
      const filterList = []
      for (let field of this.customDashboard.filterFields) {
        if (!field)
          continue
        for (let item of this.filterList) {
          if (item.item_id && field == item.item_id) {
            filterList.push(item);
          }
        }
      }
      return filterList;
    }
    return [];

  }

  getComponentLabel(refComponent: CustomDashboardComponentRef) {
    if (!refComponent) {
      return null;
    }
    if (!refComponent.showTitle) {
      return null;
    }
    if (refComponent.componentTitle) {
      return refComponent.componentTitle;
    }
    if (this.componentList) {
      for (let component of this.componentList) {
        if (component._id == refComponent.refId) {
          if (component.displayLabel) {
            return component.displayLabel;
          }
          return component.label;
        }
      }
    }
    return null;
  }
  humanRedableAttribute(str: string) {
    if (str) {
      let strSplit = str.split(".");
      return capitalize(strSplit[1])
    }
  }

  showComponentFilters() {
    const filterList: Filter[] = [];
    if (this.componentFilters) {
      for (let filter of this.componentFilters) {
        if (filter.display) {
          filterList.push(filter);
        }
      }
    }
    return filterList;
  }
  panelParsedHTML = [];
  getReturnParsedHtml(html, panelId) {
    let outputHtml = ""
    if (this.panelParsedHTML.map(it=>it.id).indexOf(+panelId) == -1) {
      var re = /{{{(.*?)}}}/;
      let splitString = html.split(re);
      for (let index = 0; index < splitString.length; index++) {
        const element = splitString[index];
        if (element.indexOf('filter::') > -1) {
          let filterName = (element.replace('filter::', ''))
          let filterPos = (this.customDashboard.filters.map(item => item.attribute).indexOf('payload.' + filterName + '.keyword'));
          outputHtml += this.customDashboard.filters[filterPos].value;
          // console.log(outputHtml)
          this.panelParsedHTML.push({"id":panelId,"panelHtml":(outputHtml)});
        } else if (element.indexOf('dashboard::') > -1) {
          let dashBoardParamName = (element.replace('dashboard::', ''));
          outputHtml += this.customDashboard[dashBoardParamName];
          this.panelParsedHTML.push({"id":panelId,"panelHtml":(outputHtml)});

        } else if (element.indexOf('route::') > -1) {
          let dashBoardParamName = (element.replace('route::', ''));
          let routedetails = dashBoardParamName.split("=");
          if(this.panelParsedHTML.map(it=>it.id).indexOf(+panelId) == -1){
            this.panelParsedHTML.push({"id":panelId,"panelHtml":""});
            let pos1 = this.panelParsedHTML.length-1;
            let payload = {};
            payload['payload'] = routedetails[1];
            payload['dashboardDetails'] = {
              "filters":this.customDashboard.filters,
              "_id":this.customDashboard._id,
              "name":this.customDashboard.name,
            };
            this.routeService.executeRoute(routedetails[0].trim(), payload).subscribe(
              response => {
                for (let index = 0; index < response.payload.output.length; index++) {
                  const opHtml = response.payload.output[index];
                  outputHtml += opHtml
                }
                this.panelParsedHTML[pos1]['panelHtml'] = outputHtml;
              }
            );
          }
        } else {
          outputHtml += element;
          this.panelParsedHTML.push({"id":panelId,"panelHtml":(outputHtml)});
        }
      }

    }
    return true;
  }
  getpanelParsedHTML(panelId){
    let pos = this.panelParsedHTML.map(it=>it.id).indexOf(panelId);
    // console.log(this.panelParsedHTML)
    if(pos>-1)
    return this.panelParsedHTML[pos]['panelHtml']
  }

  getDashboardDisplayName(dashboard: any) {
    if (dashboard.displayName) {
      return dashboard.displayName;
    }
    return dashboard.name;
  }
}
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  let tempStr = s.charAt(0).toUpperCase() + s.slice(1)
  return tempStr.split(/(?=[A-Z])/).join(" ")
}