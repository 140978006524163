// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const baseURL = "https://api.automatapi.com";
//const baseURL="http://localhost:8080";

export const environment = {
  production: true,
  firebase:{
    apiKey: "AIzaSyDgt_cOngpvXKu9Q7IsByEubGGNTr-QcGA",
    authDomain: "automatapi-8176f.firebaseapp.com",
    projectId: "automatapi-8176f",
    storageBucket: "automatapi-8176f.appspot.com",
    messagingSenderId: "913504410273",
    appId: "1:913504410273:web:96760a98e08b186aba2ee0",
    measurementId: "G-P0GR51B2DY"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
