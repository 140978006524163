import { v4 as uuid } from 'uuid';
import { Component, OnInit, OnDestroy,Input, SimpleChanges, OnChanges } from '@angular/core';
import { EnvironmentData } from 'src/app/service/environments.service';
import { Router } from '@angular/router';
import { CommunicationService } from '../service/shared.service';
import { Application } from '../models/application.model';
import { UniversalUser } from '../service/shared.service';
import { ApplicationService } from '../service/application.service';

@Component({
  selector: 'piForm',
  templateUrl: './piForm.component.html',
  styleUrls: ['./piForm.component.css']
})
export class PiFormComponent implements OnInit, OnChanges, OnDestroy {
 
  @Input() viewEntity:any;
  @Input() dataModelName: string;
  companyId:string;
  selectedApplication:Application;
  piBlocksDivId: string;

  piblockApiLoaded:boolean = false;

  userInfo: any;

  constructor(
    private router: Router,
    private environmentData: EnvironmentData,
    private communicationService: CommunicationService,
    private applicationService: ApplicationService,
    private universalUser: UniversalUser
  ) {
    this.piBlocksDivId = "piForm_" + this.generateObjectId();
  }

  ngOnInit() {
    this.piblockApiLoaded = false;
    this.userInfo = this.universalUser.getUser();
    this.companyId = this.universalUser.getUser().companyId;

    this.applicationService.getSelectedApplication().subscribe(
      (selectedApplication: Application) => {
        if (selectedApplication) {
          this.selectedApplication = selectedApplication;

          if (selectedApplication && selectedApplication.applicationWizardDetails
              && selectedApplication.applicationWizardDetails.wizardDatamodelCode) {
            this.dataModelName = selectedApplication.applicationWizardDetails.wizardDatamodelCode;

            if (selectedApplication.applicationWizardDetails.wizardEntityId) {
              let entity = {};
              entity["_id"] = selectedApplication.applicationWizardDetails.wizardEntityId;
              this.viewEntity = entity;
            }
          }
        } else {
          if (this.universalUser.displayBusinessDashboard()) {
            this.router.navigateByUrl("/businessoutlook");
          } else {
            this.router.navigateByUrl("/dashboard");
          }
        }
      },
      error => {
        this.selectedApplication = null;
        this.dataModelName = null;
        this.viewEntity = null;

        if (this.universalUser.displayBusinessDashboard()) {
          this.router.navigateByUrl("/businessoutlook");
        } else {
          this.router.navigateByUrl("/dashboard");
        }
      }
    );

    let checker = document.createElement("pi-blocks").constructor !== HTMLElement;
    if (!checker) {
      this.loadScript("assets/element-pi-blocks.js");
    } else {
      this.piblockApiLoaded = true;
    }
  }

  ngOnDestroy() {
    this.piblockApiLoaded = false;

    let elementJsFileBlock = document.getElementById(this.piBlocksDivId);
    if (elementJsFileBlock) {
      document.head.removeChild(elementJsFileBlock);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
      
  }

  loadScript(scriptURL: string) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = this.piBlocksDivId;
      script.src = scriptURL;
      script.onload = () => {
        this.piblockApiLoaded = true;
        resolve({ script: scriptURL, loaded: true, status: 'Loaded' });
      };
      script.onerror = (error: any) => resolve({ script: scriptURL, loaded: false, status: 'Loaded' });
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }

  onSaveOrSubmit(entity: any)
  {
    // Wizard Completed
    if (entity && entity['detail'] && entity['detail']['pageFinished']) {
      this.selectedApplication.applicationWizardDetails.wizardCompleted = true;
      // this.selectedApplication.applicationWizardDetails.wizardCompletePercentage = 100;

      this.applicationService.saveApplication(this.selectedApplication).subscribe(
        (savedApplication: Application) => {
          this.selectedApplication = savedApplication;
          this.router.navigateByUrl("/custom-dashboard");
        },
        error => {
          this.router.navigateByUrl("/custom-dashboard");
        }
      );
    } else {
      this.router.navigateByUrl("/custom-dashboard");
    }
  }
  
  generateObjectId() {
    let timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function() {
      return (Math.random() * 16 | 0).toString(16);
    }).toLowerCase();
  }
}