import { AfterViewChecked, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { DataSharingService, FileService } from 'src/app/service/shared.service';
import { Document } from '../../models/tasks.model';
import { EntityService } from 'src/app/service/entity.service';

@Component({
  selector: 'app-ocr-fieldTrack-llm',
  templateUrl: './ocr-fieldTrack-llm.component.html',
  styleUrls: ['./ocr-fieldTrack-llm.component.css']
})
export class ocrFieldTrackLLMComponent implements OnInit, AfterViewChecked, OnChanges {
  title: string;
  closeBtnName: string;
  field: any;
  tableFields: any;
  allFields: any;
  fielField: any;
  entityView: boolean = false;
  ocrFile: boolean = false;
  downloadFileObjectUrl: any;
  DOCUMENT_STATUS = ["PENDING", "APPROVED", "REJECTED"];
  DOCUMENT_REJECTED_MESSAGE = "Mandatory: Description is mandatory for rejected documents";
  errorMessage: string = null;
  public event: EventEmitter<any> = new EventEmitter();
  currentPage = 0;
  zipFileArray: any[] = [];
  imgHeight: number = 0;
  imgWidth: number = 0;
  imgScale: number = 1;
  imgRotate:number =0;
  imagePadding: number = 0;
  adjustFieldFlag:boolean;
  metaField:any;
  biggerView:boolean = false;
  @ViewChild('loadedImage', { static: false }) loadedImage: ElementRef;
  bsModalRef: BsModalRef;
  placeHolderDivName:string = "";

  @Input() isonTaskScreen: boolean = false;
  @Input() passedInSelectedField;
  @Input() passedInAllField;
  @Input() passedInTableFields;

  @Output() emittedSelectedFieldValue: EventEmitter<any> = new EventEmitter();

  constructor(
    private fileService: FileService,
    private sanitizer: DomSanitizer,
    private dataSharingService: DataSharingService,
    private modalService: BsModalService,
    private entityService: EntityService,
    private _bsModalRef: BsModalRef
  ) { 
    this.adjustFieldFlag = false;
    this.placeHolderDivName = "placeHolderCont" + new Date().getTime();
  }
  ngOnChanges(changes: SimpleChanges): void {
    let changed = false;
    if (this.isonTaskScreen == true && changes.passedInAllField && changes.passedInAllField.currentValue) {
      this.allFields = changes.passedInAllField.currentValue;
      changed = true;
    }
    if (changes.passedInSelectedField && changes.passedInSelectedField.currentValue && changes.passedInSelectedField.previousValue && changes.passedInSelectedField.currentValue.name != changes.passedInSelectedField.previousValue.name) {
      this.field = changes.passedInSelectedField.currentValue;
      changed = true;
    }
    if (changes.passedInTableFields && changes.passedInTableFields.currentValue != undefined) {
      this.tableFields = changes.passedInTableFields.currentValue;
      changed = true;
    }
    this.zoomReset()
    if (changed == true && this.field) {
      this.initFunction();
    }
  }
  ngAfterViewChecked(): void {
    if (this.loadedImage && this.loadedImage.nativeElement.height > 0) {
      this.imgHeight = this.loadedImage.nativeElement.height;
      this.imgWidth = this.loadedImage.nativeElement.width;
    }
  }

  @HostListener('document:mouseover', ['$event'])
  mouseover(event) {
    if (event.target.id.indexOf('idDiv') > -1) {
      this.showDatapoint(event.target.id.split('_-_')[1]);
    }
    // if (event.target.id.indexOf('idDiv') > -1) {
    //   this.showDatapoint(event.target.id);
    // }
  }

  ngOnInit() {
    if (this.isonTaskScreen == true) {
      this.field = this.passedInSelectedField;
      this.allFields = this.passedInAllField;
    }

    this.initFunction();
  }
  initFunction() {
    if(this.field.piExtractPageNo == 0){
      this.currentPage = 1;
    }else{
      this.currentPage = this.field.piExtractPageNo;
    }
    this.downloadOcrFile(this.dataSharingService.piExtractFile);
  }
  downloadOcrFile(downloadObj: any) {
    this.zipFileArray = [];
    this.fileService.download(downloadObj.downloadFileUrl, true).subscribe(
      objectUrl => {
        if (objectUrl) {
          var type = (objectUrl.split(';')[0]).replace("data:", "");
          fetch(objectUrl)
            .then(res => res.blob())
            .then(blob => {
              let fileName = "";
              if (downloadObj.fileName.indexOf(".") > -1) {
                fileName = downloadObj.fileName;
              } else {
                fileName = downloadObj.userFileName;
              }
              const file = new File([blob], fileName, { type: type })
              if (type && type.split("/")[0] == 'image') {
                let tmpArray = [];
                let tmpFile = URL.createObjectURL(blob);
                this.zipFileArray.push({
                  "fname": fileName,
                  "file": this.sanitizer.bypassSecurityTrustUrl(tmpFile),
                  "raw":blob
                })
              } else {
                const fileInputForm = new FormData();
                fileInputForm.append('file', file, fileName);
                fileInputForm.append("fileName", fileName);
                this.fileService.filePDFtoImage(fileInputForm).subscribe(
                  (response) => {
                    if (response.type.indexOf('image') > -1) {
                      let tmpArray = [];
                      let tmpFile = URL.createObjectURL(response);
                      this.zipFileArray.push({
                        "fname": fileName,
                        "file": this.sanitizer.bypassSecurityTrustUrl(tmpFile),
                        "raw":response
                      })
                    } else {
                      const jsZip = require('jszip');
                      let responseZip = this.blobToFile(response, "fileName.zip");
                      jsZip.loadAsync(responseZip).then((zip) => {
                        let tmpArray = [];
                        Object.keys(zip.files).forEach((fname) => {
                          zip.files[fname].async('blob')
                            .then((fileData) => {
                              let tmpFile = URL.createObjectURL(fileData);
                              tmpArray['fname'] = fname;
                              this.zipFileArray.push({
                                "fname": fname,
                                "file": this.sanitizer.bypassSecurityTrustUrl(tmpFile),
                                "raw":fileData
                              })
                            })
                            .then((filedata) => {
                              this.zipFileArray.sort(function (a, b) {
                                if (a.fname < b.fname) { return -1; }
                                if (a.fname > b.fname) { return 1; }
                                return 0;
                              })

                            });
                        });
                      });


                    }
                  },
                  (error) => {

                  }
                );
              }
              this.activatePage(this.field.piExtractPageNo);
            })
          // this.downloadFileObjectUrl = objectUrl;
        }
      }
    );
  }
  updateDocument() {
    if (this.checkForValidation()) {
      this.event.emit({ data: this.field, res: 2133 });
      this._bsModalRef.hide();
    }
  }
  hideModal() {
    this.event.emit({ data: this.field, res: 20010 });
    this._bsModalRef.hide();
  }
  onStatusChange(status: string) {
    if (this.field.status == "REJECTED") {
      this.errorMessage = this.DOCUMENT_REJECTED_MESSAGE;
    }
    else {
      this.errorMessage = null;
    }
  }

  checkForValidation() {
    if (this.field.status == "REJECTED" && (this.field.description == null || this.field.description == undefined || this.field.description.length == 0)) {
      this.errorMessage = this.DOCUMENT_REJECTED_MESSAGE;
      return false;
    }
    return true;
  }

  getTitleForDataPoint(dataPoint: any) {
    var title = "";
    if (dataPoint.type) {
      title = "Type " + dataPoint.type + "\n"
    }
    if (dataPoint.valueConfidence) {
      title = title + "Confidence " + Math.round(dataPoint.valueConfidence * 100) / 100;
    }
    return title;
  }
  public blobToFile = (theBlob: Blob, fileName: string): File => {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  }
  populateMarkers(pageNo) {
    let placeholders = document.getElementById(this.placeHolderDivName);
    if (placeholders) {
      placeholders.innerHTML = "";
      if (this.tableFields && this.tableFields.length > 0) {
        let filepos = this.dataSharingService.piExtractResponse.map(item => item.businessObjectCode).indexOf(this.field.piExtractCode);


        for (let pgIndexAttribs = 0; pgIndexAttribs < this.dataSharingService.piExtractResponse[filepos]['extractedData'].length; pgIndexAttribs++) {
          const pgWiseAttribs = this.dataSharingService.piExtractResponse[filepos]['extractedData'][pgIndexAttribs];
          let tmppos = 0;
          for (let dpIndex = 0; dpIndex < pgWiseAttribs.dataPoints.length; dpIndex++) {
            let dp = pgWiseAttribs.dataPoints[dpIndex]
            if (dp.key == this.field.piExtractKey && dp.pageNo == this.field.piExtractPageNo) {
              tmppos = dpIndex;
              if (pgWiseAttribs.dataPoints[tmppos] && pgWiseAttribs.dataPoints[tmppos].value && pgWiseAttribs.dataPoints[tmppos].value.length > 0) {

                for (let k = 0; k < pgWiseAttribs.dataPoints[tmppos].value.length; k++) {
                  let cells = pgWiseAttribs.dataPoints[tmppos].value[k];

                  for (let cellIndex = 0; cellIndex < cells.length; cellIndex++) {

                    let tableSelectedFieldsList = this.tableFields.map(item => item.value)
                    if (tableSelectedFieldsList.map(itm => itm._id).indexOf(cells[cellIndex]._id) > -1) {
                      let singleBox = cells[cellIndex];
                      if (singleBox) {
                        let divid = "idDiv_-_" + pgIndexAttribs + "_" + k + this.field.name + this.placeHolderDivName;
                        let confidenceclass = "";
                        if (singleBox.valueConfidence > 90) {
                          confidenceclass = "bordered-box-indicator-green";
                        } else if (singleBox.valueConfidence > 70 && singleBox.valueConfidence < 89) {
                          confidenceclass = "bordered-box-indicator-amber";
                        } else {
                          confidenceclass = "bordered-box-indicator-red";
                        }
                        if (singleBox.valueRoundingBox) {
                          placeholders.innerHTML += "<div class='" + confidenceclass + "' style='top:" + singleBox.valueRoundingBox.y1 + "%; left:" + singleBox.valueRoundingBox.x1 + "%; width:" + (singleBox.valueRoundingBox.x2 - singleBox.valueRoundingBox.x1) + "%; height:" + (singleBox.valueRoundingBox.y2 - singleBox.valueRoundingBox.y1) + "%; min-height:2px' id='" + divid + "' >&nbsp;</div>";
                        }
                      }
                    }
                  }
                }
              }
            }
          }


        }




      } else {
        for (let index = 0; index < this.allFields.length; index++) {
          if (this.allFields[index].ocrAttr == true && this.dataSharingService.piExtractResponse.length > 0) {
            let extractedFieldPos = this.dataSharingService.piExtractResponse.map(item => item.key).indexOf(this.field._id + "." + this.field.name);
            if(extractedFieldPos > -1){
              let extractedField = this.dataSharingService.piExtractResponse[extractedFieldPos];
              let pgWiseAttribs = this.dataSharingService.piExtractResponse.filter(itm=> itm.pageNo == pageNo);
              for (let index = 0; index < pgWiseAttribs.length; index++) {
                const tmpAttrib = pgWiseAttribs[index];
                let origKeyPos = (this.allFields.map(function(its){
                  return its._id + "." + its.name
                }).indexOf(tmpAttrib.key));
                let divid = "idDiv_-_" + tmpAttrib.key + this.placeHolderDivName;
                let confidenceclass = "";
                if (tmpAttrib.valueConfidence > 90) {
                  confidenceclass = "bordered-box-indicator-green";
                } else if (tmpAttrib.valueConfidence > 70 && tmpAttrib.valueConfidence < 89) {
                  confidenceclass = "bordered-box-indicator-amber";
                } else {
                  confidenceclass = "bordered-box-indicator-red";
                }
                placeholders.innerHTML += "<div class='" + confidenceclass + "' style='top:" + tmpAttrib.valueRoundingBox.y1 + "%; left:" + tmpAttrib.valueRoundingBox.x1 + "%; width:" + (tmpAttrib.valueRoundingBox.x2 - tmpAttrib.valueRoundingBox.x1) + "%; height:" + (tmpAttrib.valueRoundingBox.y2 - tmpAttrib.valueRoundingBox.y1) + "%; min-height:20px' id='" + divid + "' >&nbsp;</div>"
              }
            }

          }
        }
        this.showOnImage(this.field)

      }
      
    }
  }
  highlightDatapoint(field){
    let placeholders = document.getElementById(this.placeHolderDivName);
    if (placeholders) {
      if (this.tableFields && this.tableFields.length > 0){
        // if the isGrid functionality is set to true
        if (this.dataSharingService.piExtractResponse.length > 0) {
          // let extractedFieldPos = this.dataSharingService.piExtractResponse.map(item => item.key).indexOf(this.field._id + "." + this.field.name);
          console.log(this.field,this.dataSharingService.piExtractResponse)
        }
      }else{
        // For individual Data points
        if (this.dataSharingService.piExtractResponse.length > 0) {
          let extractedFieldPos = this.dataSharingService.piExtractResponse.map(item => item.key).indexOf(this.field._id + "." + this.field.name);
          console.log(extractedFieldPos)
        }

      }
    }
  }
  highLightedDiv = ""
  showDatapoint(dataPointId) {
    if(dataPointId)
    this.highLightedDiv = "valueDiv" + dataPointId;
  }
  showOnImage(dataPOint) {
    if (dataPOint) {
      let divid = "idDiv_-_" + dataPOint._id + "." + dataPOint.name + this.placeHolderDivName;
      let selected = document.getElementById(divid);
      this.highLightedDiv = "valueDiv" + dataPOint._id + "." + dataPOint.name;
      if (selected)
        selected.classList.add("redborder");
    }
  }
  resetOnImage(dataPOint) {
    if (dataPOint) {
      let divid = "idDiv_-_" + dataPOint._id + "." + dataPOint.name + this.placeHolderDivName;
      let selected = document.getElementById(divid);
      if (selected)
        selected.classList.remove("redborder");
    }
  }
  activatePage(pageNo) {
    if (pageNo) {
      this.currentPage = pageNo;
      // this.populateMarkers(pageNo);
      this.highlightDatapoint(this.field);
      this.showDatapoint(this.field._id + "." + this.field.name)
    }
  }
  getImgHeight() {
    return this.imgHeight;
  }
  getImgWidth() {
    return this.imgWidth;
  }
  checkFielType(field) {
    return typeof field.value;
  }
  checkItem(item) {
    return true
  }
  zoomIn() {
    // if (this.imgScale > 0) {
    this.imgScale += .5;
    // if(this.imgScale<2){
    this.imagePadding += 20;
    // }
    // }
  }
  zoomOut() {
    // if (this.imgScale < 6) {
    this.imgScale -= .5;
    if (this.imagePadding >= 0) {
      this.imagePadding -= 20;
    }
    // }
  }
  imageRotate() {
    // if (this.imgScale < 6) {
    this.imgRotate += 90;
    // }
  }
  zoomReset() {
    this.imgScale = 1;
    this.imagePadding = 0;
    this.imgRotate = 0;
  }
  downloadDocument() {
    let doc = this.fielField.value;
    if (doc && doc.downloadFileUrl) {
      if (doc.downloadFileUrl.startsWith('http')) {
        window.open(doc.downloadFileUrl, '_blank');
      } else {
        this.fileService.download(doc.downloadFileUrl);
      }
    }
  }
  openBigger(){
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: false,
      ignoreBackdropClick: true,
      class: 'modal-lg width80percent',
      initialState: {
        field: this.passedInSelectedField,
        allFields: this.passedInAllField,
        title: 'Document Title',
        biggerView: true,
        entityView: true
      }
    }
      this.bsModalRef = this.modalService.show(ocrFieldTrackLLMComponent, config);
      this.bsModalRef.content.closeBtnName = 'Close';
      this.bsModalRef.content.event.subscribe(res => {
        const div = document.querySelector('body');
        div.classList.remove('modal-open');
        this.biggerView = false;
        for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
          this.modalService.hide(i)
        }
      });
  }

  adjustField(){
    this.adjustFieldFlag = !this.adjustFieldFlag;
    if(this.adjustFieldFlag){
      setTimeout(() => {
        // var canvas = document.getElementById('fieldAdjustContainer' + this.placeHolderDivName) as HTMLCanvasElement;
        var container = document.getElementById('fieldAdjustContainerMain') as HTMLElement;
        var canvas = document.getElementById('fieldAdjustContainer' + this.placeHolderDivName) as HTMLCanvasElement;

        // if (canvas && canvas.getContext) {
        //   let context = canvas.getContext('2d');
        //   var imageObj = new Image();
        //   imageObj.src = (this.zipFileArray[this.currentPage - 1]['justFile']);
        //   imageObj.onload = function () {
        //     console.log()
        //     var imgWidth = imageObj.naturalWidth;
        //     var screenWidth  = container.offsetWidth;
        //     var scaleX = 1;
        //     if (imgWidth > screenWidth)
        //       scaleX = screenWidth/imgWidth;

        //     var imgHeight = imageObj.naturalHeight;
        //     var screenHeight = canvas.height;
        //     var scaleY = 1;
        //     if (imgHeight > screenHeight)
        //         scaleY = screenHeight/imgHeight;
        //     if(scaleY != 1){
        //       // console.log(imgWidth,screenWidth, scaleX)
        //       // console.log(imgHeight * scaleX,screenHeight, scaleY)
        //         imgHeight = imgHeight * scaleX
        //     }
            
        //     // var scale = scaleY;
        //     // if(scaleX < scaleY)
        //     //     scale = scaleX;
            
        //     // if(scale < 1){
        //     //     imgHeight = imgHeight*scale;
        //     //     imgWidth = imgWidth*scale;          
        //     // }
        //     canvas.height = imgHeight;
        //     canvas.width = container.offsetWidth;
        //     // //draw background image
        //     context.drawImage(imageObj, 0, 0, 100, 100);
        //     context.drawImage(imageObj, 0, 0, screenWidth, imgHeight);
        //     // // //draw a box over the top
        //     // // context.fillStyle = "rgba(200, 0, 0, 0.5)";
        //     // // context.fillRect(0, 0, 500, 500);

        //   };
        // }
      }, 1500);
    }else{
      this.activatePage(this.field.piExtractPageNo);
    }
  }
  last_mousex = 0;
  last_mousey = 0;
  mousex=0;
  mousey=0;
  mousedown:boolean = false;
  boundingBox:any;
  displayMarkerFlag:boolean = false;
  canvasMouseDown(e){
    var canvas = document.getElementById('fieldAdjustContainer' + this.placeHolderDivName) as HTMLCanvasElement;
    if (canvas && canvas.getContext) {
      this.mousex = e.offsetX;
      this.mousey = e.offsetY;
      this.mousedown = true;
    }
    this.displayMarkerFlag = false;
  }
  canvasMouseMove(e){
    var canvas = document.getElementById('fieldAdjustContainer' + this.placeHolderDivName) as HTMLCanvasElement;
    canvas.height = this.getImgHeight();
    canvas.width = this.getImgWidth();
    // if (canvas && canvas.getContext) {
    //   let context = canvas.getContext('2d');
    //   this.mousex = +(e.clientX);
    //   this.mousey = +(e.clientY);
    //   context.beginPath();
    //   context.strokeStyle = 'black';
    //   context.lineWidth = 3;
    //   context.stroke();
    //   if(this.mousedown == true) {
    //     var width = this.mousex-this.last_mousex;
    //     var height = this.mousey-this.last_mousey;
    //     context.rect(this.last_mousex,this.last_mousey,width,height);
    //   }
    // }
  }
  canvasMouseUp(e){
    this.mousedown = false;
    let _this=this;
    // Get the bounding area coordiantes
    var canvas = document.getElementById('fieldAdjustContainer' + this.placeHolderDivName) as HTMLCanvasElement;
    canvas.height = this.getImgHeight();
    canvas.width = this.getImgWidth();
    let context = canvas.getContext('2d');
    if (canvas && canvas.getContext) {
      this.last_mousex = e.offsetX;
      this.last_mousey = e.offsetY;
      var height = this.mousey-this.last_mousey;
      if(height <0){height = height * -1};
      var width = this.mousex-this.last_mousex;
      if(width <0){width = width * -1};
    }

    //Create Image for cropping
    var imgCanvasTmp = document.getElementById("CA") as HTMLCanvasElement;
    imgCanvasTmp.height = this.getImgHeight();
    imgCanvasTmp.width = this.getImgWidth(); 
    let imgContexttmp = imgCanvasTmp.getContext('2d');
    if (imgCanvasTmp && imgCanvasTmp.getContext) {
      var img = new Image();
      let imgheight = this.getImgHeight();
      let imgwidth = this.getImgWidth();
      img.onload = function() {
        imgContexttmp.drawImage(img,
          _this.mousex, _this.mousey,   // Start at 10 pixels from the left and the top of the image (crop),
          width, height,   // "Get" a `80 * 30` (w * h) area from the source image (crop),
          _this.last_mousex, _this.last_mousey,     // Place the result at 0, 0 in the canvas,
          width, height); // With as width / height: 160 * 60 (scale)
      }
      var reader = new FileReader();
      reader.readAsDataURL(_this.zipFileArray[_this.currentPage - 1]['raw']); 
      reader.onloadend = function() {
        var base64data = reader.result + "";                
        let pLoad = {
          base64:base64data.split(";")[1].split(",")[1],
        };
        if(_this.mousex < _this.last_mousex){
          pLoad['boundingBox'] = {
            x1:_this.mousex*100/canvas.width,
            y1:_this.mousey*100/canvas.height,
            x2:_this.last_mousex*100/canvas.width,
            y2:(_this.last_mousey*100/canvas.height),
          }
        }else{
          pLoad['boundingBox'] = {
            x1:_this.last_mousex*100/canvas.width,
            y1:_this.last_mousey*100/canvas.height,
            x2:_this.mousex*100/canvas.width,
            y2:_this.mousey*100/canvas.height,
          }
        }
        _this.displayMarkerFlag=true;
        _this.entityService.getPiExtractByBoundingBox(pLoad)
        .subscribe(
          result=>{
            if(result && result['response']){
              let paylod = {
                selectedField:_this.passedInSelectedField,
                newValue: result['result']
              }
              _this.adjustFieldFlag = !_this.adjustFieldFlag;
              _this.emittedSelectedFieldValue.emit(paylod);
            }
          },
          error=>{}
        );
      }
      
    }
  }
  getMarkerBoxCss(displayFlag){
    return "border: 1px inset #000; position: absolute; top: "+ this.mousey +"%; left: "+ this.mousex +"%; width: "+ (this.mousex - this.last_mousex) +"%; height: "+ (this.mousey - this.last_mousey) +"%;"
  }
}
