import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortentext'
})
export class ShortenNamePipe implements PipeTransform {

  transform(str: any, noOfChars: any=2,  ...args: any[]): any {
    if(!args) {
      return null;
    }
    if(!str) {
      return null;
    }else{
      let opStr = ""
      if (str.split(" ").length > 1) {
        let strArr = str.split(" ");
        for (let index = 0; index < 2 && index < +noOfChars; index++) {
          let el = strArr[index];
          opStr += el.substring(0, 1)
        }
        opStr = opStr.toUpperCase();
      } else {
        opStr = str.substring(0, +noOfChars).toUpperCase();
      }
      return opStr;
    }

   
    
  }

}

/**
 * 
 * shortenName(str, charlength) {
    
  }
 */