import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Customer } from '../models/customer.model';
import { CustomerInteraction } from 'src/app/models/customer.model';
import { EnvironmentData } from './environments.service';


@Injectable({
  providedIn: 'root'
})
export class OneViewService {
  constructor(
    private httpClient: HttpClient,
    private environmentData: EnvironmentData
  ) { }

  getCustomerList(pageNumber: any, fetchRecords: any, esc: any): Observable<Customer[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Customer[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.customerurl + '/findAll/'}${pageNumber},${fetchRecords}`;

    this.httpClient.post<Customer[]>(
      url,
      esc,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<Customer[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getCustomerCount() {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.customerurl + '/count/'}`;

    this.httpClient.get<any>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  searchCustomer(escMap: any) {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Customer[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.customerurl + '/search/'}0,10`;
    this.httpClient.post<Customer[]>(
      url,
      escMap,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<Customer[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();

  }

  searchCustomerByText(text: string) {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.customerurl + '/searchText/'}${text}`;

    this.httpClient.get<any>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getInteractions(pageNo, pageSize, escMap) {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<CustomerInteraction[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.customerinteractionurl + '/search/'}${pageNo},${pageSize}`;
    this.httpClient.post<CustomerInteraction[]>(
      url,
      escMap,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<CustomerInteraction[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getInteractionsMiniOneView(pageNo, pageSize, dataModelName, customerId) {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + 'console/customerInteraction/interactionsForEntity/' + dataModelName + '/' + customerId + "/"}${pageNo},${pageSize}`;
    this.httpClient.get<any[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getInteractionsDateWise(pageNo, pageSize, customerId) {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.customerinteractionurl + '/interactions/'}${pageNo},${pageSize},${customerId}`;
    this.httpClient.get<any[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getInteractionsCount(customerId) {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.customerinteractionurl + '/interactionsCount/'}${customerId}`;
    this.httpClient.get<any[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getMiniOneViewCustomerDocuments(customerId) {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any[]>();
    const url = `${envData.rootUrl + envData.statemachineroot + 'console/customer/customerDocument/'}${customerId}`;
    this.httpClient.get<any[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );
    return subject.asObservable();
  }
  getMiniOneViewCustomerEmails(customerId) {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any[]>();
    const url = `${envData.rootUrl + envData.statemachineroot + 'console/customer/customerEmailData/'}${customerId}`;
    this.httpClient.get<any[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );
    return subject.asObservable();
  }
  getMiniOneViewCustomerCallLogs(customerId) {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any[]>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.dialerCallLog }/customer/${customerId}`;
    this.httpClient.get<any[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );
    return subject.asObservable();
  }
  saveCustomer(customer?: Customer): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Customer>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.customerurl}`;
    this.httpClient.post<Customer>(
      url,
      customer,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }
  
  linkCustomer(payload): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    const url = `${envData.rootUrl + envData.statemachineroot + 'console/customerInteraction/newInteraction'}`;
    this.httpClient.post<any>(
      url,
      payload,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  updateCustomer(customer?: Customer): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Customer>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.customerurl}`;
    this.httpClient.put<Customer>(
      url,
      customer,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  deleteCustomer(customerId: string) {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Customer>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.customerurl + "/"}${customerId}`;
    this.httpClient.delete<Customer>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }


  deleteMultipleCustomer(customerIds: string[]) {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Customer>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.customerurl + "/deleteMany"}`;
    this.httpClient.post<Customer>(
      url,
      customerIds,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }


  mergeCustomers(customerId: string, customerIds: string[]) {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Customer>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.customerurl + "/merge/"}${customerId}`;
    this.httpClient.post<Customer>(
      url,
      customerIds,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }


  submitCustomer(Customer?: Customer, map?: any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.customerurl + Customer.name + '/submit'}`;

    this.httpClient.post<Customer>(
      url,
      map,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }


  getCustomer(id?: string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.customerurl + "/" + id}`;

    this.httpClient.get<any>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getCustomerLookupRef(referenceModel: string, id: any) {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.customerurl + envData.entitrefurl + referenceModel + "/" + id}`;

    this.httpClient.get<any>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getLookupEntities(referenceModel: string, map?: any): Observable<Customer[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Customer[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.customerurl + envData.lookupurl + referenceModel}`;

    this.httpClient.post<any>(
      url,
      map,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getRefLookupEntities(referenceModel: string, map?: any): Observable<Customer[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Customer[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.customerurl + envData.reflookupurl + referenceModel}`;

    this.httpClient.post<any>(
      url,
      map,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );

    return subject.asObservable();
  }


}