import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import * as FileSaver from 'file-saver';
import { Observable, Subject } from 'rxjs';
import { Application } from '../models/application.model';
import { commonKeys } from '../models/constants';
import { Customer } from '../models/customer.model';
import { DataModel, Entity, Field } from '../models/datamodel.model';
import { CommonSearchModel, NotificationPi } from '../models/shared.model';
import { ConnectorConfig, GraphObject, SearchableDataPointResponse, State, Task, TaskFilter, TaskTags } from '../models/tasks.model';
import { User } from '../models/user.model';
import { EnvironmentData } from './environments.service';
import { UserProfileComponent } from '../user-profile/user-profile.component';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  taskScreenZIndex = 3;
  sharedObject: any;
  roadBlockSHown:boolean = false;
  piextractMeta:any[]=[];
  piExtractResponse:any[]=[];
  piExtractFile:any;

  setSharedObject(sharedObject: any) {
    this.sharedObject = sharedObject;
  }

  getSharedObject() {
    let tempObject = null;
    if (this.sharedObject) {
      tempObject = JSON.parse(JSON.stringify(this.sharedObject));
      this.sharedObject = null;
    }
    return tempObject;
  }
}


@Injectable({
  providedIn: 'root'
})
export class UniversalUser {
  private readonly X_AUTH_TOKEN_KEY: string = 'token';
  private xAuthToken: string;
  private user: User;
  private userChangedSubject: Subject<boolean> = new Subject<boolean>();
  private userDirty: boolean = false;

  isUserDirty() {
    return this.userDirty;
  }

  resetUserDirty() {
    this.userDirty = false;
  }

  getUserChangedObservable() {
    return this.userChangedSubject.asObservable();
  }

  setXAuthToken(xAuthToken: string): void {
    if (xAuthToken && xAuthToken.trim().length > 0) {
      this.xAuthToken = xAuthToken;
      localStorage.setItem(this.X_AUTH_TOKEN_KEY, this.xAuthToken);
    }
  }

  getXAuthToken(): string {
    if (this.xAuthToken && this.xAuthToken.trim().length > 0) {
      return this.xAuthToken;
    } else if (localStorage.getItem(this.X_AUTH_TOKEN_KEY) && localStorage.getItem(this.X_AUTH_TOKEN_KEY).trim().length > 0) {
      this.xAuthToken = localStorage.getItem(this.X_AUTH_TOKEN_KEY);
      return this.xAuthToken;
    }

    return null;
  }

  setUser(user: User, shouldRedirect?: boolean) {
    window.localStorage.setItem(commonKeys.uninversalUser, JSON.stringify(user));
    this.user = user;

    this.userDirty = true;
    this.userChangedSubject.next(this.userDirty);

    if (shouldRedirect) {
      window.localStorage.setItem(commonKeys.sessionAvailable, new Date().getTime().toString());
    }
  }

  setAgentId(agentId?: string) {
    window.localStorage.setItem(commonKeys.companyAgentId, agentId);
  }

  getUser() {
    if (!this.user) {
      const local_user = JSON.parse(window.localStorage.getItem(commonKeys.uninversalUser));

      if (local_user && local_user) {
        this.setUser(local_user);
      }
    }

    return this.user;
  }

  getAgentId() {
    return window.localStorage.getItem(commonKeys.companyAgentId);
  }

  removeUser() {
    this.xAuthToken = null;
    localStorage.removeItem(this.X_AUTH_TOKEN_KEY);

    this.user = null;
    window.localStorage.removeItem(commonKeys.uninversalUser);
    window.localStorage.setItem(commonKeys.sessionExpired, new Date().getTime().toString());
  }

  displayBusinessDashboard() {
    if (this.user != null && this.user.authorities != null && this.user.authorities.length > 0) {
      const allowedDashboardRoles = ["BUSINESS_OWNER", "PROCESS_OWNER" ];
      return this.user.authorities.some(r=> allowedDashboardRoles.includes(r));
    }
    return false;
  }
  displayPiForms() {
    if (this.user && this.user.authorities && this.user.authorities.length > 0) {
      const allowedPiFormsRoles = ["BUSINESS_OWNER", "ROLE_COMPANY_ADMIN"];
      return this.user.authorities.some(r => allowedPiFormsRoles.includes(r));
    }
    return false;
  }
  setUserPref(userPref?: any) {
    window.localStorage.removeItem(commonKeys.userPreferences);
    window.localStorage.setItem(commonKeys.userPreferences, JSON.stringify(userPref));
  }

  getUserPref() {
    return JSON.parse(window.localStorage.getItem(commonKeys.userPreferences));
  }

  setcompanyAccount(accountInfo?: any) {
    window.localStorage.removeItem('accountInfo');
    window.localStorage.setItem('accountInfo', JSON.stringify(accountInfo));
  }

  getcompanyAccount() {
    return JSON.parse(window.localStorage.getItem('accountInfo'));
  }

  setDefaultAutoSuggestEmails(emails?:string[]){
    window.localStorage.removeItem(commonKeys.defaultAutoSuggestEmails);
    window.localStorage.setItem(commonKeys.defaultAutoSuggestEmails, JSON.stringify(emails));
  }

  getDefaultAutoSuggestEmails(){
    return JSON.parse(window.localStorage.getItem(commonKeys.defaultAutoSuggestEmails));
  }
  setCompanyDetails(companyDetails?: any) {
    window.localStorage.removeItem(commonKeys.companyDetails);
    window.localStorage.setItem(commonKeys.companyDetails, JSON.stringify(companyDetails));
  }

  getCompanyDetails() {
    return JSON.parse(window.localStorage.getItem(commonKeys.companyDetails));
  }
  removeCompany() {
    window.localStorage.removeItem(commonKeys.companyDetails);
  }
}

@Injectable({
  providedIn: 'root'
})
export class WhiteLabel {
  
  private logoUrl: any;
  private isWhiteLabel:boolean ;
  private slideImages:any[] = [];

  setLogoUrl(logoUrl: any): void {
    if (logoUrl && logoUrl instanceof Blob) {
      this.logoUrl = URL.createObjectURL(logoUrl);
      localStorage.setItem("logoUrl", this.logoUrl);
    }
  }

  getLogoUrl(): any {
    if (this.logoUrl) {
      return this.logoUrl;
    } else if (localStorage.getItem("logoUrl") && localStorage.getItem("logoUrl").length > 0) {
      this.logoUrl = localStorage.getItem("logoUrl");
      return this.logoUrl;
    }
    return "https://www.automatapi.com/assets/images/logo.svg";
  }
  
  setSLideImages(slideImages: any[]): void {
    if (slideImages && slideImages.length > 0) {
      this.slideImages = slideImages;
      localStorage.setItem("slideImages", JSON.stringify(this.slideImages));
    }
  }

  getSLideImages(): any[] {
    if (this.slideImages) {
      return this.slideImages;
    } else if (localStorage.getItem("slideImages") && JSON.parse(localStorage.getItem("slideImages")).length > 0) {
      this.slideImages = JSON.parse(localStorage.getItem("logoUrl"));
      return this.slideImages;
    }
  }


  setWhiteLabel(isWhiteLabel?: boolean): void{
    this.isWhiteLabel = isWhiteLabel;
    localStorage.setItem("isWhiteLabel", JSON.stringify(this.isWhiteLabel));
    // if(isWhiteLabel){
      
    // }
    
  }

  getWhiteLabel():boolean{
    if(this.isWhiteLabel){
      return this.isWhiteLabel;
    }else if (localStorage.getItem(JSON.stringify(this.isWhiteLabel))){
      let isWhiteLabelStr = localStorage.getItem(JSON.stringify(this.isWhiteLabel));
      if(isWhiteLabelStr == "true"){
        this.isWhiteLabel = true;
      }
      else{
        this.isWhiteLabel = false;
      }
      return this.isWhiteLabel;
    }
    return null;
  }
  setTheme(client){
    localStorage.setItem("theme", JSON.stringify(client));
  }
  getTheme(){
    if(localStorage.getItem("theme")){
      let client = JSON.parse(localStorage.getItem("theme"));
      if(client["whiteLabel"]['uiProperties'] && client["whiteLabel"]['uiProperties']['theme']){
        let cssFile = "./assets/theme/" + client["whiteLabel"]['uiProperties']['theme'] + '.css';
        this.loadcssfile(cssFile);
      }
      if(client["whiteLabel"]['uiProperties'] && client["whiteLabel"]['uiProperties']['fontFamily']){
        this.addFont(client["whiteLabel"]['uiProperties']['fontFamily']);
      }
      if(client["whiteLabel"]['uiProperties'] && client["whiteLabel"]['uiProperties']['logoUrl']){
        return client["whiteLabel"]['uiProperties']['logoUrl'];
      }else{
        return "https://www.automatapi.com/assets/images/logo.svg";
      }
    }
  }
  loadcssfile(filename) {
    var fileref = document.createElement("link")
    fileref.setAttribute("rel", "stylesheet")
    fileref.setAttribute("type", "text/css")
    fileref.setAttribute("href", filename);
    fileref.setAttribute("id", "loadedCssFile");
    document.getElementsByTagName("head")[0].appendChild(fileref)
  }
  addCss:boolean = false;
  addFont(fontName){
    var fileref = document.createElement("link")
    fileref.setAttribute("rel", "stylesheet")
    fileref.setAttribute("href", "https://fonts.googleapis.com/css2?family=" + fontName + ":wght@400;700&display=swap")
    document.getElementsByTagName("head")[0].appendChild(fileref);
    this.addCss = true;
    let text = `body{font-family:'` + fontName.replace("+", " ") +`',sans-serif !important}`;
    var styleref = document.createElement("style");
    let divName = ("id" + (new Date().getTime()));
    styleref.setAttribute("id", divName);
    document.getElementsByTagName("head")[0].appendChild(styleref);
    document.getElementById(divName).innerHTML = (text);
  }
}
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private universalUser: UniversalUser
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.universalUser.getUser()) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class DataModelObject {
  private dataModel: DataModel;

  setDataModel(dataModel: DataModel, shouldRedirect?: boolean) {
    window.localStorage.setItem(commonKeys.dataModel, JSON.stringify(dataModel));
    this.dataModel = dataModel;

    //   if (shouldRedirect) {
    //     window.localStorage.setItem(commonKeys.sessionAvailable, new Date().getTime().toString());  
    //   }
  }


  getDataModel() {
    if (!this.dataModel) {
      this.dataModel = JSON.parse(window.localStorage.getItem(commonKeys.dataModel));
    }

    return this.dataModel;
  }


  removeDataModel() {
    this.dataModel = null;
    window.localStorage.removeItem(commonKeys.dataModel);
    //window.localStorage.setItem(commonKeys.dataModel, this.dataModel);
  }
}


@Injectable({
  providedIn: 'root'
})
export class AntiAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private universalUser: UniversalUser
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.universalUser.getUser()) {
      // logged in so return false and navigate to home page
      this.router.navigate(['/dashboard']);
      return false;
    }

    // not logged in so redirect to login page
    return true;
  }
}
@Injectable({
  providedIn: 'root'
})
export class DeactivateGuard implements CanDeactivate<UserProfileComponent> {
  user = JSON.parse(window.localStorage.getItem(commonKeys.uninversalUser));
  canDeactivate() {
    if(this.user != null && this.user.hasOwnProperty('changePwdOnLogin') && this.user['changePwdOnLogin']){
      return true;
    }else{
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private httpHeaders = new HttpHeaders({'Content-Type': 'application/json' });

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private environmentData: EnvironmentData
  ) { }
  upload(formData: FormData): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    const url = `${envData.rootUrl + envData.interfaceService + envData.fileUploadUrl}`;
    if (formData) {
      this.httpClient.post<any>(
        url,
        formData,
        {
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      ).subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );
    } else {
      subject.error('File is null or empty');
    }

    return subject.asObservable();
  }

  decodeURL(encodedString) {
    if (encodedString && typeof encodedString == "string") {
      var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
      var translate = {
        "nbsp": " ",
        "amp": "&",
        "quot": "\"",
        "lt": "<",
        "gt": ">"
      };
      return encodedString.replace(translate_re, function (match, entity) {
        return translate[entity];
      }).replace(/&#(\d+);/gi, function (match, numStr) {
        var num = parseInt(numStr, 10);
        return String.fromCharCode(num);
      });
    }
    return encodedString;
  }

  download(filePath: string, getAsFileUrl?: boolean): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    filePath = this.decodeURL(filePath.replace(/&lt;/g, "<").replace(/&gt;/g, ">"));
    const url = `${envData.rootUrl + envData.interfaceService}${filePath}`;

    this.httpClient.get<any>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        responseType: "blob" as "json"
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          if (getAsFileUrl) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
              subject.next(reader.result);
            }, false);

            reader.readAsDataURL(response.body);
          } else {
            let urlParts = filePath.split("/");
            let fileName = urlParts && urlParts.length > 0 && urlParts[urlParts.length - 1] ? urlParts[urlParts.length - 1] : 'temp';
            FileSaver.saveAs(response.body, fileName);
          }
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error
      }
    );

    return subject.asObservable();
  }

  delete(fileUrl: string,viemoId?:string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    
    const subject = new Subject<any>();
    
    if (fileUrl.startsWith("/api/downloadData")) {
      fileUrl = fileUrl.replace("/api/downloadData", "");
    }
    let url = null;

    if(viemoId && viemoId.trim().length && viemoId!=undefined && viemoId!=null){
      url = `${envData.rootUrl + envData.interfaceService}${envData.deleteDataFile}${fileUrl}"/"${viemoId}`;
    }
    else{
      url = `${envData.rootUrl + envData.interfaceService}${envData.deleteDataFile}${fileUrl}`;
    }
    
    this.httpClient.delete<any>(
      url,
      {
        observe: 'response',
        reportProgress: true,
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error
      }
    );

    return subject.asObservable();
  }

  view(filePath: string, getAsFileUrl?: boolean): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    const url = `${envData.rootUrl + envData.interfaceService}${filePath}`;

    this.httpClient.get<any>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        responseType: "blob" as "json"
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body)
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error
      }
    );

    return subject.asObservable();
  }
  filePDFtoImage(formData: any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    const url = `${envData.rootUrl +  envData.pdfToImage}`;
    if (formData) {
      this.httpClient.post<any>(
        url,
        formData,
        {
          withCredentials: true,
          observe: 'response',
          reportProgress: true,
          responseType: "blob" as "json"
        }
      ).subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );
    } else {
      subject.error('File is null or empty');
    }

    return subject.asObservable();
  }
  getAWSCreds(requestBody: any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    //requestBody.clearCache = true;
    const subject = new Subject<any>();
    const url = `${envData.rootUrl + envData.interfaceService}` + "/api/getcredsforfileop";
      this.httpClient.post<any>(
        url,
        requestBody,
        {
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      ).subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );
    

    return subject.asObservable();
  }

  getVimeoVideoLink(requestBody: any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    //requestBody.clearCache = true;
    const subject = new Subject<any>();
    const url = `${envData.rootUrl + envData.interfaceService}` + "/api/getvideourl";
      this.httpClient.post<any>(
        url,
        requestBody,
        {
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      ).subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );
    

    return subject.asObservable();
  }
}


@Injectable({
  providedIn: 'root'
})
export class TagsService {
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private router: Router, private httpClient: HttpClient, private environmentData: EnvironmentData) { }
  getTags(process: String): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.fetchTagsService}${process}`;
    this.httpClient.get<TaskTags>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<TaskTags>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  getAllTags(): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    const url = `${envData.rootUrl}` + "/flow/console/tags/";
    this.httpClient.get<TaskTags[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<TaskTags[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  saveTaskTags(tags: any[], process: string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.saveTagService}${process}`;
    this.httpClient.post<any>(
      url,
      tags,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }




}

@Injectable({
  providedIn: 'root'
})
export class GraphService {
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private httpClient: HttpClient, private environmentData: EnvironmentData) { }

  fetch(commonSearchModel?: CommonSearchModel): Observable<GraphObject[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<GraphObject[]>();

    let url;
    url = `${envData.rootUrl + envData.statemachineroot + envData.graphbystatusurl}`;

    this.httpClient.post<GraphObject[]>(
      url,
      commonSearchModel,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<GraphObject[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();

  }

  fetchSearchableDataPointsByMachineTypes(machineTypes: string[]): Observable<SearchableDataPointResponse[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<SearchableDataPointResponse[]>();

    let url;
    url = `${envData.rootUrl + envData.statemachineroot}` + "console/graph/searchabledatapoints";

    this.httpClient.post<SearchableDataPointResponse[]>(
      url,
      machineTypes,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<SearchableDataPointResponse[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }
  searchConnectors(commonsearchModel: CommonSearchModel): Observable<ConnectorConfig[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<ConnectorConfig[]>();

    const url = `${envData.rootUrl + envData.statemachineroot}` + "console/connectors/config/search";

    this.httpClient.post<ConnectorConfig[]>(
      url,
      commonsearchModel,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<ConnectorConfig[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }


}



@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  stages: any = {};
  readOnly: boolean;
  entity: Entity;
  duplicateEntity: boolean;
  dataModelId: string;
  datamodel:DataModel;
  selectedTask: State;
  datamodelName:string;
  customer:Customer;
  // Case Listing Separate
  listingSelectedTask;
  currentTaskList:any[]=[];
  bulkTaskUpdated:any[]=[];
  formFilter:any;
  entitySearchCriteria: any;

  // tasks
  assignedActiveTasks: Task[];
  assignedClosedTasks: Task[];
  groupActiveTasks: Task[];
  teamActiveTasks: Task[];
  teamClosedTasks: Task[];
  peersActiveTasks: Task[];

  // total count
  assignedActiveTasksCount: number = 0;
  assignedClosedTasksCount: number = 0;
  groupActiveTasksCount: number = 0;
  teamActiveTasksCount: number = 0;
  teamClosedTasksCount: number = 0;
  peersActiveTasksCount: number = 0;

  // filters
  assignedActiveTaskFilter: TaskFilter;
  assignedClosedTaskFilter: TaskFilter;
  groupActiveTaskFilter: TaskFilter;
  teamActiveTaskFilter: TaskFilter;
  teamClosedTaskFilter: TaskFilter;
  peersActiveTaskFilter: TaskFilter;
  allTaskFilter: TaskFilter;
  

  currentTaskFilter: TaskFilter;
  currentFolder:string;
  
  //Application
  selectedApplication: Application;
  allApplications: Application[];

  constructor(private httpClient: HttpClient, private environmentData: EnvironmentData) { }

  clearEverything() {
    this.stages = null;
    this.readOnly = null;
    this.entity = null;
    this.dataModelId = null;
    this.selectedTask = null;
    this.datamodelName = null;
    this.customer = null;

    this.listingSelectedTask = null;
    this.currentTaskList = null;
    this.bulkTaskUpdated = null;
    this.formFilter = null;
    this.entitySearchCriteria = null;

    this.assignedActiveTasks = null;
    this.assignedClosedTasks = null;
    this.groupActiveTasks = null;
    this.teamActiveTasks = null;
    this.teamClosedTasks = null;
    this.peersActiveTasks = null;

    this.assignedActiveTasksCount = null;
    this.assignedClosedTasksCount = null;
    this.groupActiveTasksCount = null;
    this.teamActiveTasksCount = null;
    this.teamClosedTasksCount = null;
    this.peersActiveTasksCount = null;

    this.assignedActiveTaskFilter = null;
    this.assignedClosedTaskFilter = null;
    this.groupActiveTaskFilter = null;
    this.teamActiveTaskFilter = null;
    this.teamClosedTaskFilter = null;
    this.peersActiveTaskFilter = null;
    this.allTaskFilter = null;

    this.currentTaskFilter = null;
    this.currentFolder = null;

    this.selectedApplication = null;
    this.allApplications = null;
  }

  sendStages(stages: any) {
    this.stages = stages;
  }

  getStages() {
    return this.stages;
  }

  setEntity(entity: Entity) {
    this.entity = entity;
  }

  setDuplicateEntity(duplicateEntity: boolean) {
    this.duplicateEntity = duplicateEntity;
  }

  isDuplicateEntity() {
    return this.duplicateEntity;
  }

  setCustomer(customer:Customer){
    this.customer = customer;
  }

  getCustomer(){
    return this.customer;
  }

  setDataModelName(datamodelName:string){
    this.datamodelName = datamodelName;
  }

  getDataModelName(){
    return this.datamodelName;
  }

  setDataModel(datamodel:DataModel){
    this.datamodel = datamodel;
  }

  getDataModel(){
    return this.datamodel;
  }

  getEntity() {
    return this.entity;
  }

  setAssocEntityDetails(task: State) {
    this.selectedTask = task;
  }

  getAssocEntityDetails() {
    return this.selectedTask;
  }

  setSelectedDataModelId(dataModelId: string) {
    this.dataModelId = dataModelId;
  }

  getSelectedDataModelId() {
    return this.dataModelId;
  }
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
  getUserMappingForDiallerById(userId: string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.dialerMapping}` + '/byUserId/' + userId;

    this.httpClient.get<any[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
      (response: HttpResponse<any>) => {
        if (response && response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
      );

    return subject.asObservable();
  }
  diallerStatCurrent(userId,target): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    let payload = {
      "parameterList":["action","reason"]
    }
    const url = `${envData.rootUrl + envData.statemachineroot + envData.diallerStat}`+userId+"," +target;

    this.httpClient.post<any>(
      url,
      payload,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }
  diallerStatChange(userMapping): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    
    const url = `${envData.rootUrl + envData.statemachineroot + envData.dialerMapping}`;
    
    this.httpClient.post<any>(
      url,
      userMapping,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  diallerCallCustomer(callRequest): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.diallerCallCustomer}`;

    if (envData.mockDialer) {
      callRequest['mock'] = true;
    }

    this.httpClient.post<ConnectorConfig[]>(
      url,
      callRequest,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<ConnectorConfig[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }
  diallerPoll(payload:any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.diallerPoller}`;
    this.httpClient.post<any>(
      url,
      payload,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }
}


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private httpClient: HttpClient , private environmentData: EnvironmentData) { }

  fetchNotifications(userId: String): Observable<NotificationPi[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<NotificationPi[]>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.notificationservice + 'user/'}${userId}`;
    this.httpClient.get<NotificationPi[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<NotificationPi[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }
  fetchPaginatedNotifications(pagenumber?,pagesize?): Observable<NotificationPi[]> {
    if(!pagenumber)
      pagenumber = 0;
    if(!pagesize)
      pagesize = 20;
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<NotificationPi[]>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.notificationservice + 'byPage/'}${pagenumber},${pagesize}`;
    this.httpClient.get<NotificationPi[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<NotificationPi[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }
  fetchNotificationsCount(): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.notificationservice + 'count'}`;
    this.httpClient.get<any>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }
  fetchNotificatioMode(): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<NotificationPi[]>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.notificationservice + 'mode'}`;
    this.httpClient.get<any>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  markNotificationAsRead(id:string){
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.notificationservice + 'markasseen/'}${id}`;
    this.httpClient.get<NotificationPi>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );
    return subject.asObservable();
  }

 

}

@Injectable({
  providedIn: 'root'
})
export class EntiySharingService {
  listCallInProcess:boolean = false;
  entityMap:any = {};
  validationErrorMsgs: string;
   constructor(private snackBar: MatSnackBar){
   }

  getEntityMap(fields?:Field[]){
    this.entityMap = {};
    for(let field of fields){
      this.createMap(field);
    }
    return this.entityMap;
  }

  createMap(field:Field,parentField?:Field,listCall?:boolean){
    if(field.type == commonKeys.entityLookupReference && field.value){
      field.value = field["actualValue"];
    }
    if( ( field.type == "DATE" || field.type == "DATETIME" ) && field.value){
      field.value = this.getIsoDate(field.value);
    }
    if(field.type == "MODEL" && !field.list){
      if(field.value){
        for(let f of field.value.fields ){
          let map = {}
          if(field["map"]!=undefined) {
            map = field["map"];
          }
          if(f.type == "MODEL" && !f.list){
            this.createMap(f,field);
          }
          else if(f.type == "MODEL" && f.list){
            //this.createMap(f,field);
            let mapArr = [];
            if(f.value && f.value.length > 0){
              this.listCallInProcess = true;
              for(let value of f.value){
                let map = {};
                for(let f1 of value.fields ){
                  if(field!=undefined){
                    map[f.name] = this.createMap(f1,f);
                  }
                }
              mapArr.push(f["map"]);
              f["map"] = {};
              }
              if(parentField!=null || parentField!=undefined){
                parentField["map"][f.name] = mapArr;
              }
              else{
                this.listCallInProcess = false;
                field["map"][f.name] = mapArr;
                this.entityMap[field.name] = field["map"];
              }       
              return;
            }
          }
          else if( ( f.type == "DATE" || f.type == "DATETIME" ) && f.value)
          {
            f.value = this.getIsoDate(f.value);
            map[f.name] = f.value;
          }
          else{
            map[f.name] = this.getFieldValue(f);
          }
          field["map"] = map;
        }
        if(parentField!=null || parentField!=undefined){
          if(parentField["map"]==undefined){
            parentField["map"] = {}
          }
          parentField["map"][field.name] = field["map"];
          return;
        }
        else{
          if(!this.listCallInProcess){
           
            this.entityMap[field.name] = field["map"];
            return;
          }
          else{
            return field["map"];
          }
        }
      }
    }
    if(field.type == "MODEL" && field.list){
      let mapArr = [];
      if(field.value && field.value.length > 0){
        this.listCallInProcess = true;
        for(let value of field.value){
          let map = {};
          for(let f of value.fields ){
            if(field!=undefined){
              map[f.name] = this.createMap(f,field);
            }
             //parent field is jobs
          }
         mapArr.push(field["map"]);
         field["map"] = {};

        }
        if(parentField!=null || parentField!=undefined){
          parentField["map"][field.name] = mapArr;
        }
        else{
          this.listCallInProcess = false;
          this.entityMap[field.name] = mapArr;
        }       
        return;
      }
    }
    else{
      if(!this.listCallInProcess){
        if(this.getFieldValue(field) !=undefined){
          this.entityMap[field.name] = this.getFieldValue(field);
        }
      }
      else{
        let map = parentField["map"];
        if(map == undefined){
          map = {}
          parentField["map"] = map;
        }
        if(map[field.name]==undefined || map[field.name].length == 0){
          if(this.getFieldValue(field) !=undefined){
            map[field.name] = this.getFieldValue(field);
          }
          parentField["map"] = map;
        }
      }
    }
  }


  //Copy of create map please dont delete parth is working on this
  
  // createMap(field:Field,parentField?:Field,listCall?:boolean){
  //   if(field.type == commonKeys.entityLookupReference && field.value){
  //     field.value = field["actualValue"];
  //   }
  //   if( ( field.type == "DATE" || field.type == "DATETIME" ) && field.value){
  //     field.value = this.getIsoDate(field.value);
  //   }
  //   if(field.type == "MODEL" && !field.list){
  //     if(field.value){
  //       for(let f of field.value.fields ){
  //         let map = {}
  //         if(field["map"]!=undefined){
  //           map = field["map"];
  //         }
  //         if(f.type == "MODEL" && !f.list){
  //           this.createMap(f,field);
  //         }
  //         else if(f.type == "MODEL" && f.list){
  //           this.createMap(f,field);
  //         }
  //         else{
  //           map[f.name] = this.getFieldValue(f);
  //         }
  //         field["map"] = map;
  //       }
  //       if(parentField!=null || parentField!=undefined){
  //         if(parentField["map"]==undefined){
  //           parentField["map"] = {}
  //         }
  //         parentField["map"][field.name] = field["map"];
  //         return;
  //       }
  //       else{
  //         if(!this.listCallInProcess){
  //           this.entityMap[field.name] = field["map"];
  //           return;
  //         }
  //         else{
  //           return field["map"];
  //         }
  //       }
  //     }
  //   }
  //   if(field.type == "MODEL" && field.list){
  //     let mapArr = [];
  //     if(field.value && field.value.length > 0){
  //       this.listCallInProcess = true;
  //       for(let value of field.value){
  //         let map = {};
  //         for(let f of value.fields ){
  //           map[f.name] = this.createMap(f,field); //parent field is jobs
  //         }
  //        mapArr.push(field["map"]);
  //        field["map"] = {};

  //       }
  //       if(parentField!=null || parentField!=undefined){
  //         parentField["map"][field.name] = mapArr;
  //       }
  //       else{
  //         this.listCallInProcess = false;
  //         this.entityMap[field.name] = mapArr;
  //       }       
  //       return;
  //     }
  //   }
  //   else{
  //     if(!this.listCallInProcess){
  //       if(this.getFieldValue(field) !=undefined){
  //         this.entityMap[field.name] = this.getFieldValue(field);
  //       }
  //     }
  //     else{
  //       let map = parentField["map"];
  //       if(map == undefined){
  //         map = {}
  //         parentField["map"] = map;
  //       }
  //       if(map[field.name]==undefined || map[field.name].length == 0){
  //         if(this.getFieldValue(field) !=undefined){
  //           map[field.name] = this.getFieldValue(field);
  //         }
  //         parentField["map"] = map;
  //       }
  //     }
  //   }
  // }

 
  getFieldValue(field:Field){
      if(field.value!=null && field.value!=undefined){
        return field.value;
      }
  }

  checkValidation(selectedDataModel:DataModel){
    let validationArr = [];
    this.validationErrorMsgs = "";
    for(let field of selectedDataModel.fields){
      if(field!=undefined && field.validationExpression!=null && field.validationExpression!=undefined){
        let validationResult = this.validateRegex(field);
        if(validationResult == false){
          validationArr.push(validationResult);
        }
      }
      if (field.loader || field.uploadProgressBar || field.downloadProgressBar) {
        validationArr.push(false);
      }
    }
    return validationArr;
  }

  validateRegex(field: Field) {
    if (field!=undefined  && field.validationExpression!=undefined && field.validationExpression && field.validationExpression.length > 0 && field.type!="FILE") {
      if(field.value!=null && field.value!=undefined){
        const matchValues = field.value.match(field.validationExpression);
        if (!matchValues || matchValues.length == 0) {
          var errorMsg = "Invalid input for field :: " + field.name;
          if (field.errorMessage) {
            errorMsg = field.errorMessage;
            this.validationErrorMsgs = this.validationErrorMsgs.concat(errorMsg)+' ,';
          }
          var allValidationErrorMsgs = this.validationErrorMsgs.substring(0,this.validationErrorMsgs.length-1)
          this.snackBar.open(allValidationErrorMsgs, "Dismiss", {
            duration: 5000
          })
          return false;
        }
        else {
          var regexExp = new RegExp(field.validationExpression, "g");
          if (regexExp) {
            var matches = regexExp.exec(field.value);
            if (matches && matches.length > 0 && field.value && field.value.length > 0 && matches[0] != null && matches[0].length > 0) {
              field.value = matches[0];
              return field;
            }
          }
        }
      }
      else{
        if(field.mandatory){
          var errorMsg = "Invalid input for field :: " + field.name;
          if (field.errorMessage) {
            errorMsg = field.errorMessage;
          }
          this.snackBar.open(errorMsg, "Dismiss", {
            duration: 5000
          })
          return false;
        }
      }
    }
  }

  getIsoDate(date: any) {
    if (typeof date == "string") {
      return date;
    }
    
    if (typeof date == "object" && date.year) {
      let dateValue = new Date();  
      
      dateValue.setFullYear(date['year']);
      dateValue.setMonth(date['monthValue']);
      dateValue.setDate(date['dayOfMonth']);
      
      if (date['hour'] && date['minute']) {
        dateValue.setHours(date['hour']);
        dateValue.setMinutes(date['minute']);
      }
      
      return dateValue.toISOString();
    }

    let dt = new Date();
    dt.setFullYear(date.date['year']);
    dt.setMonth(+date.date['month'] - 1);
    dt.setDate(date.date['day']);
    
    if (date.date['hour'] && date.date['min']) {
      dt.setHours(date.date['hour']);
      dt.setMinutes(date.date['min']);
    }
    
    return dt.toISOString();
  }

}
@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  
  constructor(
    private matSnackBar: MatSnackBar
  ) { }

  openSnackBar(message, type?: 'success' | 'error', action?: string, duration?:number) {
    if (!type) {
      type = 'success';
    }

    if (action == null || action == undefined) {
      action = 'Close';
    }

    if (!duration) {
      duration = 5000;
    }

    let config: MatSnackBarConfig = new MatSnackBarConfig();
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.duration = duration;

    if (type === 'error') {
      config.panelClass = ['errorMsg'];
    } else {
      config.panelClass = ['success'];
    }

    this.matSnackBar.open(message, action, config);
  }
}



@Injectable({
  providedIn: 'root'
})
export class RouteService {
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private httpClient: HttpClient,
    private environmentData: EnvironmentData
  ) { }

   executeRoute(routeCode: string, requestBody: any): Observable<any> {
     
    const envData = this.environmentData.getEnvData();
    const subject: Subject<any> = new Subject<any>();

    const url: string = `${envData.rootUrl}${envData.executeRoute}${routeCode}`;
    this.httpClient.post<any>(
      url,
      requestBody,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
}
  
}
