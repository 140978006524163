import { AfterViewChecked, Component, ElementRef, EventEmitter, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
  import { DataSharingService, FileService } from 'src/app/service/shared.service';
import { Document } from '../../models/tasks.model';

@Component({
  selector: 'app-ocr-comp',
  templateUrl: './ocrComp.component.html',
  styleUrls: ['./ocrComp.component.css']
})
export class ocrComponent implements OnInit, AfterViewChecked {
  title: string;
  closeBtnName: string;
  doc: any;
  allFields:any;
  tmpallFields:any;
  entityView:boolean = false;
  ocrFile: boolean = false;
  downloadFileObjectUrl: any;
  DOCUMENT_STATUS = ["PENDING", "APPROVED", "REJECTED"];
  DOCUMENT_REJECTED_MESSAGE = "Mandatory: Description is mandatory for rejected documents";
  errorMessage:string = null;
  public event: EventEmitter<any> = new EventEmitter();
  currentPage= 0;
  zipFileArray:any[]=[];
  imgHeight:number = 0;
  @ViewChild('loadedImage', {static: false}) loadedImage: ElementRef;

  constructor(
    private fileService: FileService,
    private sanitizer:DomSanitizer,
    private dataSharingService: DataSharingService,
    private _bsModalRef: BsModalRef
  ) { }
  ngAfterViewChecked(): void {
    if(this.loadedImage && this.loadedImage.nativeElement.height>0){
      this.imgHeight = this.loadedImage.nativeElement.height;
    }
  }

  @HostListener('document:mouseover', ['$event'])
  mouseover(event) {
    if(event.target.id.indexOf('idDiv') > -1){
      this.showDatapoint(event.target.id.split('_-_')[1]);
    }
      // if(event.target.matches('.editor-div')) {
      //     alert('hover to editor div')
      // }
  }

  ngOnInit() {
    this.checkForValidation();
    if (this.doc) {
      if (this.ocrFile) {
        if (this.doc) {
          this.downloadOcrFile(this.doc);
          // console.log(this.allFields)
        }
      }
    }
    
  }
  downloadOcrFile(downloadObj: any) {
    this.fileService.download(downloadObj.downloadFileUrl, true).subscribe(
      objectUrl => {
        if (objectUrl) {
          var type = (objectUrl.split(';')[0]).replace("data:", "");
          fetch(objectUrl)
            .then(res => res.blob())
            .then(blob => {
              let fileName = "";
              fileName = downloadObj.userFileName;
              const file = new File([blob], fileName, { type: type })
              if (type && type.split("/")[0] == 'image') {
                let tmpArray = [];
                let tmpFile = URL.createObjectURL(blob);
                this.zipFileArray.push({
                  "fname": fileName,
                  "file": this.sanitizer.bypassSecurityTrustUrl(tmpFile)
                })
              } else {
                const fileInputForm = new FormData();
                fileInputForm.append('file', file, fileName);
                fileInputForm.append("fileName", fileName);
                this.fileService.filePDFtoImage(fileInputForm).subscribe(
                  (response) => {
                    if(response.type.indexOf('image')>-1){
                      let tmpArray = [];
                      let tmpFile = URL.createObjectURL(response);
                      this.zipFileArray.push({
                        "fname": fileName,
                        "file": this.sanitizer.bypassSecurityTrustUrl(tmpFile)
                      })
                    }else{
                      const jsZip = require('jszip');
                      let responseZip = this.blobToFile(response, "fileName.zip");
                      jsZip.loadAsync(responseZip).then((zip) => {
                        let tmpArray = [];
                        Object.keys(zip.files).forEach((fname) => {
                          zip.files[fname].async('blob')
                            .then((fileData) => {
                              let tmpFile = URL.createObjectURL(fileData);
                              tmpArray['fname'] = fname;
                              this.zipFileArray.push({
                                "fname": fname,
                                "file": this.sanitizer.bypassSecurityTrustUrl(tmpFile)
                              })
                            })
                            .then((filedata) => {
                              this.zipFileArray.sort(function (a, b) {
                                if (a.fname < b.fname) { return -1; }
                                if (a.fname > b.fname) { return 1; }
                                return 0;
                              })
  
                            });
                        });
                      });
                    
                    
                    }
                  },
                  (error) => {
                    console.log(error)
                  }
                );
              }
              this.activatePage(1);
            })
        }
      }
    );
  }

  updateDocument(){
    if(this.checkForValidation()){
      this.event.emit({ data: this.doc , res:2133 });
      this._bsModalRef.hide();
    }
  }
  removeDocument(){
      this.event.emit({ data: this.doc , res:430353 });
      this._bsModalRef.hide();
  }
  hideModal(){
    this.event.emit({ data: this.doc , res:20010 });
    this._bsModalRef.hide();
  }
  checkFielType(field){
    return typeof field.value;
  }
  onStatusChange(status:string){
    if(this.doc.status == "REJECTED"){
      this.errorMessage = this.DOCUMENT_REJECTED_MESSAGE;
    }
    else{
      this.errorMessage = null;
    }
  }

  checkForValidation(){
    if(this.doc.status == "REJECTED" && (this.doc.description == null || this.doc.description == undefined || this.doc.description.length == 0)){
      this.errorMessage = this.DOCUMENT_REJECTED_MESSAGE;
      return false;
    }
    return true;
  }

  getTitleForDataPoint(dataPoint: any) {
    var title = "";
    if (dataPoint.type) {
      title = "Type " + dataPoint.type + "\n"
    }
    if (dataPoint.valueConfidence) {
      title = title + "Confidence " + Math.round(dataPoint.valueConfidence *100)/100;
    }
    return title;
  }
  public blobToFile = (theBlob: Blob, fileName:string): File => {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  } 
  populateMarkers(pageNo) {
    this.tmpallFields = this.allFields.filter(item => (item.piExtractForFileFieldName+"") == (this.doc.name+""))
    let placeholders = document.getElementById("placeholderContainer");
    if (placeholders) {
      placeholders.innerHTML = "";
      for (let index = 0; index < this.tmpallFields.length; index++) {
        if (this.tmpallFields[index].ocrAttr == true && this.dataSharingService.piExtractResponse.length>0) {
          let filepos = this.dataSharingService.piExtractResponse.map(item=>item.businessObjectCode).indexOf(this.doc.piExtractCode); 
          for (let pgIndexAttribs = 0; pgIndexAttribs < this.dataSharingService.piExtractResponse[filepos]['extractedData'].length; pgIndexAttribs++) {
            const pgWiseAttribs = this.dataSharingService.piExtractResponse[filepos]['extractedData'][pgIndexAttribs];
            let pos = pgWiseAttribs.dataPoints.map(item => item.key).indexOf(this.tmpallFields[index].piExtractKey);
            if (pos > -1) {
              let tmpAttrib = pgWiseAttribs.dataPoints[pos];
              let refKey = this.tmpallFields[index]['piExtractKey'];
              const el = (tmpAttrib.valueRoundingBox);
              if (el) {

                if(this.tmpallFields[index].piExtractPageNo == this.currentPage){
                  let divid = "idDiv_-_" + this.tmpallFields[index]['piExtractKey'];
                  let confidenceclass="";
                  if(pgWiseAttribs.dataPoints[pos].valueConfidence>90){
                    confidenceclass="bordered-box-indicator-green";
                  }else if(pgWiseAttribs.dataPoints[pos].valueConfidence > 70 && pgWiseAttribs.dataPoints[pos].valueConfidence < 89){
                    confidenceclass="bordered-box-indicator-amber";
                  }else{
                    confidenceclass="bordered-box-indicator-red";
                  }
                  placeholders.innerHTML += "<div class='"+ confidenceclass +"' style='top:" + el.y1  + "%; left:" + el.x1  + "%; width:" + (el.x2-el.x1)  + "%; height:" + (el.y2-el.y1)  + "%; min-height:20px' id='"+ divid +"' >&nbsp;</div>";
                }
              }
            }
          }
        }
      }
      // this.showOnImage(this.field.piExtractKey)
    }
  }
  highLightedDiv=""
  showDatapoint(dataPoint){
    this.highLightedDiv = "valueDiv" + dataPoint;
  }
  showOnImage (dataPOint){
    let divid = "idDiv_-_" + dataPOint.replace("valueDiv","")
    let selected = document.getElementById(divid);
    if(selected)
    selected.classList.add("redborder");
    // console.log(selected.classList)
  }
  resetOnImage(dataPOint){
    let divid = "idDiv_-_" + dataPOint.replace("valueDiv","")
    let selected = document.getElementById(divid);
    if(selected)
    selected.classList.remove("redborder");
  }
  activatePage(pageNo) {
    this.currentPage = pageNo;
    this.populateMarkers(pageNo);
    // this.showDatapoint(this.field.piExtractKey)
  }
  getImgHeight(){
    return this.imgHeight +"px";
  }
  downloadDocument() {
    let doc = this.doc;
    if (doc && doc.downloadFileUrl) {
      if (doc.downloadFileUrl.startsWith('http')) {
        window.open(doc.downloadFileUrl, '_blank');
      } else {
        this.fileService.download(doc.downloadFileUrl);
      }
    }
  }
}
