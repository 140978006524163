import { BaseModel } from './base.model';
import { SafeHtml } from '@angular/platform-browser';
import { Document } from './tasks.model';
import { Phrase } from './conversation.model';

export class EmailPersister extends BaseModel {
  to: string;
  from: string;
  subject: string;
  cc: string;
  bcc: string;
  htmlText: any;
  parsedBody: string;
  entityId: string;
  fileUrls: any;
  emailType: string;
  dataModel: any;
  sendDate: any;
  attachments: any[];
  emailTime: any = {};
  dataServicePayload: any[] = [];
  sentByUserName:string;
  referenceEntityId:string;

  constructor(to?: string, from?: string, subject?: string, cc?: string,bcc?: string, parsedBody?: string, entityId?: string, attachments?: any[]) {
    super();

    this.to = to && to != null ? to : null;
    this.from = from && from != null ? from : null;
    this.cc = cc && cc != null ? cc : null;
    this.bcc = bcc && bcc != null ? bcc : null;
    this.parsedBody = parsedBody && parsedBody != null ? parsedBody : null;
    this.entityId = entityId && entityId != null ? entityId : null;
    this.attachments = attachments && attachments.length > 0 ? attachments : [];
  }

}

export class CRUDOperationInput {
  operation: string;
  collection: string;
  payload: any;
  fields: any;
  page: number;
  pageSize: number;
  sort:any;
}

export class Notes extends BaseModel {
  flowInstanceId: string;
  stateInstanceId: string;
  entityId: string;
  stateCd: string;
  noteTitle: string;
  noteContent: string;
  doc: Document;
  audioUrl: string;
  noteType: string;
  createdTime: Date;
  updatedTime: Date;
  notesPriority: string;

  constructor(flowInstanceId?: string, stateInstanceId?: string, entityId?: string, stateCd?: string, noteTitle?: string, noteContent?: string, doc?: Document, audioUrl?: string, noteType?: string,
    createdTime?: null, updatedTime?: null) {
    super();
    this.flowInstanceId = flowInstanceId ? flowInstanceId : null;
    this.stateInstanceId = stateInstanceId ? stateInstanceId : null;
    this.entityId = entityId ? entityId : null;
    this.stateCd = stateCd ? stateCd : null;
    this.noteTitle = noteTitle ? noteTitle : null;
    this.doc = doc ? doc : null;
    this.audioUrl = audioUrl ? audioUrl : null;
    this.noteType = noteType ? noteType : null;
    this.createdTime = new Date();
    this.notesPriority = '';
    this.noteContent = noteContent ? noteContent : "";
  }
}

export class ChatMessage {
  _id: string;
  agentId: string;
  messageText: string;
  displayText: string;
  episodeStatus: string;
  episodeId: string;
  conversationId: string;
  from: string;
  options: Option[];
  settings: Settings;
  language: string;
  newLanguage: string;
  to: string;
  messageTime: Date;
  contextMessageId: string;
  messageType: string;
  disableUserInput: boolean;
  safeHtml: SafeHtml;
  companyId: string;
  isLoaderGif: boolean;
  isBookmarked: boolean;
  downloadDocuments: CardData[];
  uploadedDocuments: [];
  contextExpression: string;
  autoDisengaged: boolean;
  oldGoalStepAnswer: string;
  formResult: any;
  raiseEventIdOnResponse: string = null;
  contextData: any;
  parentEpisodeId: string = null;
  isAudioMessage: boolean = false;
  audioUrl: any;
  audioBase64: any;

  phrases: Phrase[];
  onlyEntityFound: string[];
  onlyIntentFound: string[];
  debugData: any;




  constructor(_id?: string, from?: string, to?: string, messageTime?: Date, messageType?: string, messageText?: string, options?: Option[], displayText?: string,
    language?: string, newLanguage?: string, episodeStatus?: string, agentId?: string, episodeId?: string, companyId?: string, contextMessageId?: string,
    disableUserInput?: boolean, isLoaderGif?: boolean, settings?: Settings, downloadDocuments?: CardData[], uploadedDocuments?: any, conversationId?: string,
    contextExpression?: string, autoDisengaged?: boolean, oldGoalStepAnswer?: string, formResult?: any, raiseEventIdOnResponse?: string, contextData?: any,
    parentEpisodeId?: string
  ) {
    this._id = _id && _id != null ? _id : null;
    this.from = from && from != null ? from : null;
    this.to = to && to != null ? to : null;
    this.messageTime = messageTime && messageTime != null ? messageTime : null;
    this.messageType = messageType && messageType != null ? messageType : null;
    this.messageText = messageText && messageText != null ? messageText : '';
    this.safeHtml = null;
    this.options = options && options != null ? options : [];
    this.displayText = displayText && displayText != null ? displayText : '';
    this.language = language && language != null ? language : null;
    this.newLanguage = newLanguage && newLanguage != null ? newLanguage : null;
    this.episodeStatus = episodeStatus && episodeStatus != null ? episodeStatus : null;
    this.agentId = agentId && agentId != null ? agentId : null;
    this.episodeId = episodeId && episodeId != null ? episodeId : null;
    this.companyId = companyId && companyId != null ? companyId : null;
    this.contextMessageId = contextMessageId && contextMessageId != null ? contextMessageId : null;
    this.disableUserInput = disableUserInput && disableUserInput != null ? disableUserInput : false;
    this.isLoaderGif = isLoaderGif && isLoaderGif != null ? isLoaderGif : false;
    this.settings = settings && settings != null ? settings : null;
    this.downloadDocuments = downloadDocuments && downloadDocuments != null ? downloadDocuments : [];
    this.uploadedDocuments = uploadedDocuments && uploadedDocuments != null ? uploadedDocuments : [];
    this.conversationId = conversationId && conversationId != null ? conversationId : null;
    this.contextExpression = contextExpression && contextExpression != null ? contextExpression : null;
    this.autoDisengaged = autoDisengaged && autoDisengaged != null ? autoDisengaged : false;
    this.oldGoalStepAnswer = oldGoalStepAnswer && oldGoalStepAnswer != null ? oldGoalStepAnswer : null;
    this.formResult = formResult ? formResult : {};
    this.raiseEventIdOnResponse = raiseEventIdOnResponse ? raiseEventIdOnResponse : null;
    this.contextData = contextData ? contextData : {};
    this.parentEpisodeId = parentEpisodeId ? parentEpisodeId : null;
  }
}

export class CardData {
  templateName: string;
  title: string;
  subTitle: string;
  avatarImageUrl: string;
  imageUrl: string;
  content: string;
  actionable: Option[];
  document: {};
  cardImagestyle: string;
  cardImageSmall: boolean;

  constructor() {
    this.templateName = '';
    this.title = '';
    this.subTitle = '';
    this.avatarImageUrl = '';
    this.imageUrl = '';
    this.content = '';
    this.actionable = [];
    this.document = {};
    this.cardImagestyle = null;
    this.cardImageSmall = false;
  }
}

export class Option {
  type: string;
  url: string;
  data: Data[];
  cardData: CardData[];
  progressBar: boolean;
  disabled?: boolean;
  formModel: FormModel;

  constructor() {
    this.type = '';
    this.url = '';
    this.data = [];
    this.cardData = [];
    this.progressBar = false;
    this.disabled = false;
    this.formModel = new FormModel();
  }
}

export class FormModel {
  modelName: string;
  header: string;
  formCSS: string;
  formJS: string;
  formStartHtml: string;
  formEndHtml: string;
  responseOptions: ModelResponseOption[]

  constructor(modelName?: string, header?: string, formCSS?: string, formJS?: string, formStartHtml?: string, formEndHtml?: string, responseOptions?: ModelResponseOption[]) {
    this.modelName = modelName != null && modelName.trim().length > 0 ? modelName : null;
    this.header = header ? header : null;
    this.formCSS = formCSS ? formCSS : null;
    this.formJS = formJS ? formJS : null;
    this.formStartHtml = formStartHtml ? formStartHtml : null;
    this.formEndHtml = formEndHtml ? formEndHtml : null;
    this.responseOptions = responseOptions != null && responseOptions.length > 0 ? responseOptions : [];
  }

}

export class ModelResponseOption {
  rawHtml: string;  // Applicable only to 'HTML' type of ResponseOption.
  cssClass: string;
  option: string;
  sequence: number;
  label: string = null;
  afterSubmitLabel: string = null;
  key: string = null;
  isMandatory: boolean;
  alignVertically: boolean;
  api: string;
  responseData: ModelResponseData[];
  prePopulatedValue: any;
  dependencyExpression: string;
  disable: boolean

  constructor(rawHtml?: string, cssClass?: string, option?: string, responseData?: ModelResponseData[],
    label?: string, key?: string, isMandatory?: boolean,
    api?: string, afterSubmitLabel?: string, alignVertically?: boolean,
    prePopulatedValue?: any, dependencyExpression?: string, disable?: boolean) {
    this.rawHtml = rawHtml ? rawHtml : null;
    this.cssClass = cssClass ? cssClass : null;
    this.option = option ? option : null;
    this.responseData = responseData ? responseData : [];
    this.label = label ? label : null;
    this.key = key ? key : null;
    this.isMandatory = isMandatory ? isMandatory : false;
    this.api = api ? api : null;
    this.afterSubmitLabel = afterSubmitLabel ? afterSubmitLabel : null;
    this.alignVertically = alignVertically ? alignVertically : false;
    this.prePopulatedValue = prePopulatedValue ? prePopulatedValue : null;
    this.dependencyExpression = dependencyExpression ? dependencyExpression : null;
    this.disable = disable ? disable : false;
  }
}

export class ModelResponseData {
  value: any;
  label: string;
  dataType: string;
  settings: Settings;

  constructor(label?: string, value?: string, dataType?: string, settings?: Settings) {
    this.label = label ? label : null;
    this.value = value ? value : null;
    this.dataType = dataType ? dataType : null;
    this.settings = settings ? settings : new Settings();

  }

}

export class Settings {
  mask: string;
  secured: boolean;
  validationRegex: string;
  placeholder: string;
  isBookmarkable: boolean;
  errorMessage: string;
  enableDatePicker: boolean;
  disabled: boolean;

  constructor() {
    this.mask = '';
    this.secured = false;
    this.validationRegex = '';
    this.placeholder = '';
    this.isBookmarkable = false;
    this.errorMessage = '';
    this.enableDatePicker = false;
    this.disabled = false;
  }
}


export class Data {
  label: string;
  value: any;
  url: string;
  agentId: string;
  language: string;
  disabled: boolean;
  fileSize: string;
  fileType: string;
  fileReference: string;
  labelSafeHtml: SafeHtml;


  constructor() {
    this.label = '';
    this.value = null;
    this.url = null;
    this.agentId = null;
    this.language = null;
    this.disabled = false;
    this.fileSize = '';
    this.fileType = '';
    this.fileReference = '';
    this.labelSafeHtml = '';
  }
}

export class EpisodeGoal {
  _id: string;
  companyId: string;
  statusCd: string;
  episodeId: string;
  expression: string;
  goalName: string;
  domainGoalSteps: EpisodeGoalStep[];
  model: any;
  api: string;
  apiResponseKey: string;
  apiResponseValue: any;
  flowResponseValue: any;
  responseExpression: string;
  responseChangeExpression: string;
  validationCheck: any;
  flowFlag: boolean;
  htmlFlag: boolean;
  responseChange: boolean;
  responseDependent: boolean;
  valueExpression: string;
  tagExpression: string;
  startTriggerMessageId: string;
  endTriggerMessageId: string;
  startTriggerTime: Date;
  endTriggerTime: Date;
  isClosureGoal: boolean;
}


export class Episode {
  _id: string;
  companyId: string;
  statusCd: string;
  source: string;
  agentId: string;
  domainId: string;
  campaignCd: string;
  userId: string;
  conversationId: string;
  langDetected: string[];
  langSelected: string;
  lastMessageId: string;
  mode: string;
  intents: string[];
  entities: string[];
  sentiments: string[];
  goals: EpisodeGoal[];
  stages: string[];
  lastStage: string;
  transactionValue: number;
  startTime: Date;
  endTime: Date;
  modifiedTime: Date;
  currentGoal: EpisodeGoal;
  currentGoalStep: EpisodeGoalStep;
  episodeContext: EpisodeContext;
  flowTriggered: boolean;
  bargedInAgentId: string;
  episodeClosureAgentId: string;
  businessKey: string;
  businessKeyValue: string;
  businessKeyLabel: string;
  newEpisode: boolean; // Temp field only used at UI side, not present at backend
}

export class EpisodeGoalStep {
  statusCd: string;
  goalExpression: string;
  sequence: number;
  goalResponse: any;
  lang: any;
  api: string;
  apiResponseKey: string;
  apiResponseValue: any;
  key: string;
  value: any;
  authRequired: boolean;
  mandatory: boolean;
  ignoreIntent: boolean;
  goalValidationTypes: string[];
  dependencyExpression: string;
  dependencyResult: any;
  responseExpression: string;
  actionHtml: string;
  isUniquePerGoal: boolean;
  startTriggerMessageId: string;
  endTriggerMessageId: string;
  startTriggerTime: Date;
  endTriggerTime: Date;
  hybridResponseExpression: string;
  pickFromNoun: boolean;
  skipped: boolean;
}

export class EpisodeContext {
  model: any;
  goalStack: EpisodeGoal[];
  lastUserMessageId: string;
  lastUserMessageTime: Date;
  lastAutoMessageId: string;
  lastAutoMessageTime: Date;
  userMessageCount: number;
  autoMessageCount: number;
  missedExpressionCount: number;
}

//Access Control

export class AccessControlRequest {

  userId: string;
  componentName: string;
  componentType: string;

}


export class AccessControlResponse {
  
  componentName: string;
  componentType: string;
  operationAccessList: AccessControlOperaion[];

}

export class AccessControlOperaion {
  enabled: boolean;
  operationName: string;
}