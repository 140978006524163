import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EmailPersister } from 'src/app/models/helper.model';
import { Subscription } from 'rxjs';
import { EmailService } from 'src/app/service/helper.service';
import { State } from 'src/app/models/tasks.model';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AdhocEmailComponent } from 'src/app/shared/adhocemail/adhocemail.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { FileService } from 'src/app/service/shared.service';
@Component({
  selector: 'app-single-mail-comp',
  templateUrl: './single-mail.component.html',
  styleUrls: ['./single-mail.component.scss'],
  encapsulation:ViewEncapsulation.ShadowDom
})
export class SingleMailComponent implements OnInit , OnChanges{

  @Input() email: any;
  @Input() campaignInfo: any;
  @Input() type: string="view";
  @Input() singleViewer: boolean=false;
 
  @Output() onPaste = new EventEmitter();
  @Output() onHide = new EventEmitter();

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '600',
    minHeight: '400',
    maxHeight: '600',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    uploadWithCredentials: false,                    //Image Upload Can we make this work@Parth
    uploadUrl: '',                                   //Your backend must return imageUrl.   | https://github.com/kolkov/angular-editor/issues/5  
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        // 'undo',
        // 'redo',
        'strikeThrough',
        // 'subscript',
        // 'superscript',
        // 'indent',
        // 'outdent',
        // 'insertUnorderedList',
        // 'insertOrderedList',
        // 'heading',
        // 'fontName',
        'customClasses',
        'insertImage',
        'insertVideo',
        // 'toggleEditorMode',
        'link',
        'unlink',
        // 'insertHorizontalRule',
        // 'removeFormat',
        // 'fontSize',
        // 'textColor',
        // 'backgroundColor',
      ],
      []
    ]
  };

  constructor(
    private modalService: BsModalService,
    private emailService: EmailService,
    private sanitizer: DomSanitizer,
    private snackbar: MatSnackBar,
    private fileService: FileService,

  ) {
  }

  ngOnInit() {
    if(this.type=='mail'){
      var div = document.createElement('div');
      div.innerHTML = this.campaignInfo.templateHTML;
      var elements = div.getElementsByTagName('style');
      while (elements[0])
      elements[0].parentNode.removeChild(elements[0])
      this.campaignInfo.templateHTML = div.innerHTML;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes.email && changes.email.currentValue != undefined){
      this.email = changes.email.currentValue
    }
  }
  onPasteEvent(event){
    this.onPaste.emit(event);
  }
  onHideEvent(event){
    this.onHide.emit(event)
  }
}