import { Component, OnInit, OnDestroy, Input, SimpleChanges, OnChanges } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';


@Component({
    selector: 'api-date-difference',
    templateUrl: './datedifference.component.html'
  })
  export class DateDifferenceComponent implements OnInit, OnChanges {

    @Input()
    date1: number;

    @Input()
    date2: number;

    @Input()
    hideMinutes: boolean;

    days: number;
    hours: number;
    minutes: number;
    isValid: boolean = false;

    ngOnChanges(changes: SimpleChanges) {
        this.ngOnInit();
    }
    

    ngOnInit() {
        if (!this.date1 &&  !this.date2) {
            console.error("Invalid dates to calculate date difference")
            this.invalidate();
        } else {
            this.isValid = true;
            this.calculateRemainingTime(this.date1 || new Date().getTime(), this.date2 || new Date().getTime());
        }
    }

    calculateRemainingTime(date1: number, date2: number) {
        const remainingMillis = date1 - date2;


        // get total seconds between the times
        var delta = Math.abs(remainingMillis) / 1000;

        // calculate (and subtract) whole days
        this.days = Math.floor(delta / 86400);
        delta -= this.days * 86400;

        // calculate (and subtract) whole hours
        this.hours = Math.floor(delta / 3600) % 24;
        delta -= this.hours * 3600;

        // calculate (and subtract) whole minutes
        this.minutes = Math.floor(delta / 60) % 60;
    }

    invalidate() {
        this.isValid = false;
        this.reset();
    }

    reset() {
        this.days = this.hours = this.minutes = 0;
    }
  }
  