import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CustomDashboardComponent, Filter } from 'src/app/models/customDashboard.model';
import { CustomDashboardService } from 'src/app/service/dashboard.service';
import { UniversalUser } from 'src/app/service/shared.service';

@Component({
  selector: 'app-bar-chart-dashboard',
  templateUrl: './bar-chart-dashboard.component.html',
  styleUrls: ['./bar-chart-dashboard.component.css']
})
export class BarChartDashboardComponent implements OnChanges {
  @Input() startTime: string;
  @Input() endTime: string;
  @Input() selectedComponent: CustomDashboardComponent;
  @Input() componentFilters: Filter[] = [];
  @Input() colourList: string[] = [];
  @Input() selectedDashboardId: string;
  currentResults: any = [];
  filteredResults: any = [];
  columnList: any = [];
  currDiv="";

  chartDiv: string;
  constructor(
    // public cd: ChangeDetectorRef,
    private customDashboardService: CustomDashboardService,
    private universalUser: UniversalUser
  ) { }
  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedComponent && changes.selectedComponent.currentValue) {
      this.chartDiv = changes.selectedComponent.currentValue._id;
    }
    if (changes && changes.endTime && changes.endTime.currentValue != undefined) {
      this.endTime = changes.endTime.currentValue;
    }
    if (changes && changes.startTime && changes.startTime.currentValue != undefined) {
      this.startTime = changes.startTime.currentValue;
    }
    if (changes && changes.componentFilters && changes.componentFilters.currentValue != undefined) {
      this.componentFilters = changes.componentFilters.currentValue;
    }
    this.getResults(this.startTime, this.endTime, this.componentFilters)
    // console.log(this.universalUser.getUser().companyId, this.selectedComponent, changes.startTime.currentValue, changes.endTime.currentValue,tmpFilter)


  }
  getResults(startTime, endTime, filters) {
    let aggregationList = [];
    this.customDashboardService.getComponentResult(this.universalUser.getUser().companyId, this.selectedComponent, startTime, endTime, filters, this.selectedDashboardId).subscribe(
      (response) => {
        if (response && response.length > 0) {
          this.currentResults = [];
          if (this.selectedComponent.uiProperties && this.selectedComponent.uiProperties.comboGraph == true) {

            for (let index = 0; index < response.length; index++) {
              let element = response[index];
              for (const key in element) {
                if (Object.prototype.hasOwnProperty.call(element, key)) {
                  let keyOfObj = element[key];
                  let tmpCat = '';
                  if (this.isValidDate(element.category) == true) {
                    let date = new Date(element.category)
                    tmpCat = ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
                      ("00" + date.getDate()).slice(-2) + " " +
                      ("00" + date.getHours()).slice(-2) + ":" +
                      ("00" + date.getMinutes()).slice(-2);
                  } else {
                    tmpCat = element.category
                  }
                  let temp = {};
                  temp['category'] = tmpCat;
                  let tmp = JSON.parse(JSON.stringify(element));
            
                  if (tmp['subCategory'] && tmp['subCategory'].length > 0 ) {
                    let subCategoryList = JSON.parse(JSON.stringify(tmp['subCategory']));  
                    
                    delete tmp['subCategory'];
                    delete tmp['description'];
                    delete tmp['category'];
                    delete tmp['value'];
                    let otherkeys  = Object.keys(tmp);
                    
                    for (let subInd = 0; subInd < subCategoryList.length; subInd++) {
                      const subElement = subCategoryList[subInd];
                      temp[subElement.category] = subElement.value;
                      for (let i = 0; i < otherkeys.length; i++) {
                        let keys = otherkeys[i];
                        temp[keys] = subElement[keys]
                      }
                    }
                    this.currentResults.push(temp)
                  } else {
                    element['count'] = element['value']
                    delete tmp['subCategory'];
                    delete tmp['description'];
                    delete tmp['category'];
                    for (const key in tmp) {
                      if (Object.prototype.hasOwnProperty.call(tmp, key)) {
                        const el = tmp[key];
                        let tmpKey = (key + "").replace(" ", "");
                        temp[tmpKey] = el
                      }
                    }
                    delete temp['value'];
                    this.currentResults.push(temp)
                  }
                  
                }
              }
            }
          } else {
            for (let index = 0; index < response.length; index++) {
              const element = response[index];

              let tmpCat = '';
              if (this.isValidDate(element.category) == true) {
                let date = new Date(element.category)
                tmpCat = ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
                  ("00" + date.getDate()).slice(-2) + " " +
                  ("00" + date.getHours()).slice(-2) + ":" +
                  ("00" + date.getMinutes()).slice(-2);
              } else {
                tmpCat = element.category
              }

              let temp = {
                category: tmpCat
              }
              let tmp = JSON.parse(JSON.stringify(element))
              delete tmp['subCategory'];
              delete tmp['description'];
              for (const key in tmp) {
                if (Object.prototype.hasOwnProperty.call(tmp, key)) {
                  const el = tmp[key];
                  temp[key] = el
                  if (key != 'category') {
                    aggregationList.push(key)
                  }
                }
              }

              if (element.subCategory.length > 0) {
                for (let subInd = 0; subInd < element.subCategory.length; subInd++) {
                  const subElement = element.subCategory[subInd];
                  for (let aggInd = 0; aggInd < aggregationList.length; aggInd++) {
                    const aggElement = aggregationList[aggInd];
                    temp[subElement.category] = subElement[aggElement]
                    delete temp[aggElement];
                  }
                }
              }
              this.currentResults.push(temp)
            }
            for (let index = 0; index < this.currentResults.length; index++) {
              const element = this.currentResults[index];
              if (this.otherKeysPresent(element) == true)
                delete element["value"]
            }
          }
        }
        // This is for filtering from dropdown
        for (let index = 0; index < this.currentResults.length; index++) {
          const element = this.currentResults[index];
          let tmp = {
            name:element.category,
            showKey:true
          }
          this.columnList.push(tmp)
        }
        
        // this is for finding mean and then changing format
        let total = 0;
        for (let index = 0; index < this.currentResults.length; index++) {
          const rows = this.currentResults[index];
          for (const key in rows) {
            if (Object.prototype.hasOwnProperty.call(rows, key)) {
              const element = rows[key];
              if(key!= 'category'){
                total += element;
              }
            }
          }
        }
        let numOfZeros = "1"
        let multiplier  = (numOfZeros.padEnd(((Math.round(total/this.currentResults.length)+"").length-1) ,"0"));
        let tmpArr = [];
        if ((multiplier+"").length>3) {
          for (let index = 0; index < this.currentResults.length; index++) {
            let rows = this.currentResults[index];
            let tmpRec ={}
            for (const key in rows) {
              if (Object.prototype.hasOwnProperty.call(rows, key)) {
                let el = rows[key];
                let key1 = key + this.nFormatter(multiplier,1)['words']
                if(key!= 'category'){
                  el = +el/(+multiplier);
                  tmpRec[key1]=Math.round(el * 10) / 10
                }else{
                  tmpRec[key]=el
                }
              }
            }
            tmpArr.push(tmpRec)
          }
          this.currentResults = JSON.parse(JSON.stringify(tmpArr))
          this.filteredResults = JSON.parse(JSON.stringify(tmpArr))
        } else {
          this.filteredResults =this.currentResults
        }
        
      },
      (error) => {
        console.error(error)

      }
    );
  }
  otherKeysPresent(element) {
    let keyList = []
    for (const key in element) {
      if (Object.prototype.hasOwnProperty.call(element, key)) {
        keyList.push(key)
      }
    }
    keyList.splice(keyList.indexOf("category"), 1)
    keyList.splice(keyList.indexOf("value"), 1)
    if (keyList.length > 0) {
      return true
    } else {
      return false
    }

  }
  convertToCamelCase(string) {
    return (" " + string).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
  }
  isValidDate(d) {
    var timestamp = Date.parse(d);

    if (isNaN(timestamp) == false) {
      return true
    } else {
      return false
    }
  }
  filterList(column, i){
    column.showKey = column.showKey == true ? false:true
    this.filteredResults = JSON.parse(JSON.stringify(this.currentResults));
    for (let index = 0; index < this.columnList.length; index++) {
      const element = this.columnList[index];
      if (element.showKey == false) {
        let pos = (this.filteredResults.map(item=>item.category).indexOf(element.name))
        if(pos>-1){
          this.filteredResults.splice(pos,1)
        }
      }
    }
  }
  toggleDropDown(divId){
    this.currDiv = this.currDiv == divId ? "":divId
  }
  nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "", words:"" },
      { value: 1e3, symbol: "K", words:"(in Thousands)" },
      { value: 1e6, symbol: "M)", words: "(in Millions)" },
      { value: 1e9, symbol: "G", words:"(in G)" },
      { value: 1e12, symbol: "T", words:"(in T)" },
      { value: 1e15, symbol: "P", words:"(in P)" },
      { value: 1e18, symbol: "E", words:"(in E)" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? {"numberValue":(num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol, "words": item.words} :  {"numberValue":"0", "words": ""};
  }
  getLabel(selectedComponent){
    return selectedComponent.label
  }
}
