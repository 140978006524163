import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { User } from 'src/app/models/user.model';
import { Application } from '../models/application.model';
import { ApplicationService } from '../service/application.service';
import { AuthService } from '../service/auth.service';
import { CommonService } from '../service/common.service';
import { CommunicationService, DataSharingService, FileService, UniversalUser } from '../service/shared.service';
import { FetchUserService } from '../service/userhierarchy.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { PreferenceService } from '../service/user.service';
import { DialerService } from '../service/dialer.service';

@Component({
  selector: 'app-user-mapping',
  templateUrl: './user-mapping.component.html',
  styleUrls: ['./user-mapping.component.scss']
})
export class UserMappingComponent implements OnInit {
  currentUser: User;
  profileImageObject: any = null;
  profilePhotoLoad: boolean = true;
  editMode: boolean = false;
  userTeam: any;
  passwordRegx = /^(?=.*\d)(?=.*[!@#$%^?`<>()-_+<.>()&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  displayRoles: string[] = [];
  credits: any;
  allApplications: Application[];
  resetPasswordMode: boolean;
  oldPassword: string;
  newPassword: string;
  showLoaderOverlay: boolean;
  diallerUserMapping: any;

  constructor(
    private universalUser: UniversalUser,
    private commonService: CommonService,
    private fetchUsers: FetchUserService,
    private router: Router,
    private userService: PreferenceService,
    private dataSharingService: DataSharingService,
    private dialerService: DialerService
  ) {
    if (!dialerService.isConfigPresent()) {
      this.router.navigateByUrl("/profile")
    }
  }

  ngOnInit() {
    this.currentUser = this.universalUser.getUser();
    this.fetchMasterRoles();
    this.currentUser.password = '';
    if (!this.currentUser.alertPermission) {
      this.currentUser.alertPermission = {
        "emailPermission": false,
        "mobilePermission": false,
        "appPermission": false,
        "desktopPermission": false
      };
    }


    this.fetchUsers.fetchChildUsers(this.currentUser._id).subscribe(
      (response) => {
        this.userTeam = response;
        let tmp = JSON.parse(JSON.stringify(this.currentUser))
        tmp['userId'] = tmp['_id']
        tmp['userName'] = tmp['username']
        this.userTeam.push(tmp)
      },
      (error) => { }
    )
  }

  fetchMasterRoles() {
    if (this.currentUser == null || !this.currentUser.authorities || this.currentUser.authorities.length == 0)
      return;
    this.fetchUsers.getMasterRoles()
      .subscribe(
        response => {
          if (response) {
            for (let role of this.currentUser.authorities) {
              if (response[role])
                this.displayRoles.push(response[role]);
              else
                this.displayRoles.push(role);
            }
          }

        },
        error => {

        }
      )
  }
  addRemoveUserMapping(index?) {
    if (index == undefined) {
      this.diallerUserMapping.additionalParams.push({ key: "", value: "" });
    } else {
      this.diallerUserMapping.additionalParams.splice(index, 1);
    }
  }
  saveUserMapping() {
    this.userService.registerUserMappingForDialler(this.diallerUserMapping).subscribe(
      (response) => {
        // console.log(response)
        this.diallerUserMapping={};
        this.loadTab('mappingList')
      },
      (error) => { console.log(error) }
    );
  }
  currentTab: string = 'mappingList';
  loadTab(tabName) {
    this.currentTab = tabName;
  }
  backtoList(){
    this.loadTab('mappingList')
    this.diallerUserMapping={}
  }
  addDialerMapping(user) {
    this.loadTab("dialerDetails")
    this.diallerUserMapping = {
      "userId": user.email,
      "username": user.username,
      "target": "Rightside",
      "additionalParams": []
    }
  }
}
