import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UserMapping } from '../models/user.model';
import { DialerCallLog } from '../models/dialer.model';

import { UniversalUser } from './shared.service';
import { Observable, Subject } from 'rxjs';
import { EnvironmentData } from './environments.service';
import { ConnectorConfig } from '../models/connector.model';
import { CommonSearchModel } from '../models/shared.model';
import { MessagingService } from './messaging.service';

@Injectable({
    providedIn: 'root'
})
export class DialerService {
    private readonly _DIALER_CATEGORY: string = "DIALER";

    private userMapping: UserMapping;
    private latestCallLog: DialerCallLog;
    private dialerConfig: ConnectorConfig;

    private dialerNotificationSubject: Subject<any> = new Subject<any>();
    private onCallConnectedSubject: Subject<any> = new Subject<any>();
    private onCallHangUpSubject: Subject<any> = new Subject<any>();
    private onCallWrapUpSubject: Subject<any> = new Subject<any>();

    constructor(
        private httpClient: HttpClient,
        private universalUser: UniversalUser,
        private environmentData: EnvironmentData,
        private messagingService: MessagingService
    ) {
        this.universalUser.getUserChangedObservable().subscribe(
            userUpdated => {
                this.checkEnvAndInitialize();
            }
        );

        if (this.universalUser.isUserDirty()) {
            this.checkEnvAndInitialize();
        }

        this.subscribeToFirebaseMessages();
    }

    checkEnvAndInitialize() {
        if (this.environmentData.getEnvData()) {
            this.init();
        }
        else if (this.environmentData.getEnvDataObservable()) {
            this.environmentData.getEnvDataObservable().subscribe(
                envData => {
                    this.init();
                }
            );
        }
    }

    init() {
        this.getDialerConnectorConfig().subscribe(
            (dialerConfig: ConnectorConfig) => {
                this.fetchUserMapping().subscribe(
                    (userMapping: UserMapping) => {
                        // if (!userMapping.agentStatus || userMapping.agentStatus == "logout") {
                        this.changeAgentStatus("login", null);
                        // }
                    }
                );
                this.fetchLatestCallLogForLoggedInUser();
            }
        );

        this.universalUser.resetUserDirty();
    }

    getDialerConnectorConfig(): Observable<ConnectorConfig> {
        const commonsearchModel: CommonSearchModel = new CommonSearchModel();
        commonsearchModel.searchParams = [{ "connectorInfoRef": "dialer" }];
        commonsearchModel.returnFields = ['configMap'];

        const envData = this.environmentData.getEnvData();
        const subject = new Subject<ConnectorConfig>();

        const url = `${envData.rootUrl + envData.statemachineroot}` + "console/connectors/config/search";

        this.httpClient.post<ConnectorConfig[]>(
            url,
            commonsearchModel,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        )
            .subscribe(
                (response: HttpResponse<ConnectorConfig[]>) => {
                    if (response.body) {
                        const conConfigList = response.body;

                        if (conConfigList && conConfigList.length > 0 && conConfigList[0] && conConfigList[0].configMap) {
                            this.dialerConfig = conConfigList[0];
                            subject.next(this.dialerConfig);
                        }

                    }
                },
                (err: HttpErrorResponse) => {
                    // All errors are handled in ErrorInterceptor, no further handling required
                    // Unless any specific action is to be taken on some error

                    subject.error(err);
                }
            );

        return subject.asObservable();
    }

    isConfigPresent(): boolean {
        return this.dialerConfig != null && this.dialerConfig != undefined;
    }

    /**
     * Get wrap up time from dialer config
     * @returns Wrap Up time in seconds
     */
    getCallWrapTime(): number {
        if (this.dialerConfig && this.dialerConfig.configMap && this.dialerConfig.configMap.wrapTime) {
            return this.dialerConfig.configMap.wrapTime;
        }

        return 5;
    }

    fetchUserMapping() {
        const envData = this.environmentData.getEnvData();
        const subject = new Subject<UserMapping>();


        const url = `${envData.rootUrl + envData.statemachineroot + envData.dialerMapping}/byUserId/${this.universalUser.getUser()._id}`;

        this.httpClient.get<UserMapping>(
            url,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        )
            .subscribe(
                (response: HttpResponse<UserMapping>) => {
                    if (response.body) {
                        this.userMapping = response.body;
                        subject.next(response.body);
                    }
                },
                (err: HttpErrorResponse) => {
                    // All errors are handled in ErrorInterceptor, no further handling required
                    // Unless any specific action is to be taken on some error

                    subject.error(err);
                }
            );

        return subject.asObservable();
    }

    changeAgentStatus(action?: string, reason?: string) {
        const envData = this.environmentData.getEnvData();
        const subject = new Subject<any>();

        const url = `${envData.rootUrl + envData.statemachineroot + envData.diallerStatChange}`;

        if (!action) {
            const currentStatus = this.getDialerAgentStatus();

            if (currentStatus == "Paused") {
                action = "unpause";
            } else if (currentStatus == "Unpaused") {
                action = "pause";
            }
        }

        if (!action) {
            console.error("'action' can't be null or empty while changing call status");
            return;
        }

        const body = {
            "action": action,
            "userId": this.universalUser.getUser()._id
        }

        if (action == 'pause') {
            body["reason"] = reason;
        }

        if (envData.mockDialer) {
            body['mock'] = true;
        }

        this.httpClient.post<any>(
            url,
            body,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        )
            .subscribe(
                (response: HttpResponse<any>) => {
                    if (response.body) {
                        this.fetchUserMapping();
                        subject.next(response.body);
                    }
                },
                (err: HttpErrorResponse) => {
                    // All errors are handled in ErrorInterceptor, no further handling required
                    // Unless any specific action is to be taken on some error

                    subject.error(err);
                }
            );

        return subject.asObservable();
    }

    getDialerAgentStatus() {
        if (this.userMapping && this.userMapping.agentStatus) {
            if (this.userMapping.agentStatus == "pause") {
                return "Paused";
            } else if (this.userMapping.agentStatus == "unpause") {
                return "Unpaused";
            } else if (this.userMapping.agentStatus == "login") {
                return "Logged In";
            } else if (this.userMapping.agentStatus == "logout") {
                return "Logged Out";
            }
        }

        return "Unpaused";
    }

    fetchLatestCallLogForLoggedInUser() {
        const envData = this.environmentData.getEnvData();
        const subject = new Subject<DialerCallLog>();

        const url = `${envData.rootUrl + envData.statemachineroot + envData.dialerCallLog}/getLatestCallLogByUserId/${this.universalUser.getUser()._id}`;

        this.httpClient.get<DialerCallLog>(
            url,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        )
            .subscribe(
                (response: HttpResponse<DialerCallLog>) => {
                    if (response.body) {
                        this.latestCallLog = response.body;
                        subject.next(response.body);
                    }
                },
                (err: HttpErrorResponse) => {
                    // All errors are handled in ErrorInterceptor, no further handling required
                    // Unless any specific action is to be taken on some error

                    subject.error(err);
                }
            );
        return subject.asObservable();
    }

    getStatusForLatestCallLog() {
        if (this.latestCallLog && this.latestCallLog.callStatus) {
            return this.latestCallLog.callStatus;
        }

        return null;
    }

    getTimestampForTimerFromOngoingCallLog() {
        if (this.latestCallLog && this.latestCallLog.callStatus) {
            if (this.latestCallLog.callStatus == 'Connected') {
                return this.latestCallLog.callConnectedDateTime;
            } else if (this.latestCallLog.callStatus == 'Hangup') {
                return this.latestCallLog.callHangupDateTime;
            }
        }

        return null;
    }

    getEntityIdAndMachineTypeFromLatestCallLog() {
        if (this.latestCallLog && this.latestCallLog.processEntityId && this.latestCallLog.processMachineType) {
            return {
                "entityId": this.latestCallLog.processEntityId,
                "machineType": this.latestCallLog.processMachineType
            }
        }

        return null;
    }

    shouldDisableCallButton(): boolean {
        return this.latestCallLog && this.latestCallLog.callStatus && this.latestCallLog.callStatus != 'Wrapped';
    }

    wrapUpCall() {
        const envData = this.environmentData.getEnvData();

        const url = `${envData.rootUrl + envData.statemachineroot + envData.diallerCallWrap}`;

        const body = {
            "uniqueNo": this.latestCallLog.uniqueNo,
            "mock": envData.mockDialer
        };

        this.httpClient.post<any>(
            url,
            body,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        )
            .subscribe(
                (response: HttpResponse<any>) => {
                    if (response.body) {
                        this.fetchLatestCallLogForLoggedInUser().subscribe(
                            refreshedCallLog => {
                                this.onCallWrapUpSubject.next(response.body);
                            }, error => {
                                this.onCallWrapUpSubject.next(response.body);
                            }
                        );
                    }
                },
                (err: HttpErrorResponse) => {
                    // All errors are handled in ErrorInterceptor, no further handling required
                    // Unless any specific action is to be taken on some error

                    this.onCallWrapUpSubject.error(err);
                }
            );

        return this.onCallWrapUpSubject.asObservable();
    }

    private subscribeToFirebaseMessages() {
        if (this.messagingService.getFirebaseMessageSubscription()) {
            this.messagingService.getFirebaseMessageSubscription().subscribe(
                message => {
                    if (this.isConfigPresent() && message && message['data'] && message['data']['category'] && message['data']['category'] == this._DIALER_CATEGORY) {
                        this.processDialerMessage(message);
                    }
                }
            );
        }
    }

    private processDialerMessage(message: any) {
        const dialerNotification = this.createDialerNotifiation(message);
        if (dialerNotification && Object.keys(dialerNotification).length > 0) {
            this.dialerNotificationSubject.next(dialerNotification);
        }

        this.fetchLatestCallLogForLoggedInUser().subscribe(
            refreshedCallLog => {
                let callUid = null;
                // Call UID is available against 'field1' key
                if (message['data']['field1']) {
                    callUid = message['data']['field1'];
                }

                // Call Event is available against 'field2' key - CALL_CONNECTED or CALL_HANG_UP
                if (message['data']['field2']) {
                    if (message['data']['field2'] == "CALL_CONNECTED") {
                        this.onCallConnectedSubject.next(callUid);
                    } else if (message['data']['field2'] == "CALL_HANG_UP") {
                        this.onCallHangUpSubject.next(callUid);
                    }
                }
            }
        );
    }

    private createDialerNotifiation(message: any) {
        const notification = {};

        if (message && message['data']) {
            notification['title'] = message['data']['title'] ? message['data']['title'] : 'Ongoing Call';   // title - Call Connected or Call Hung Up
            notification['description'] = message['data']['description'] ? message['data']['description'] : 'Unknown Caller';   // description - Swami Naik or Unknown Caller
            notification['displayMessage'] = message['data']['displayMessage'] ? message['data']['displayMessage'] : null;  // displayMessage - Inbound call from (phone number) Outbound call to (phone number)

            if (message['data']['entityId'] && message['data']['machineType']) {
                notification['entityId'] = message['data']['entityId'];
                notification['machineType'] = message['data']['machineType'];
            }
        }

        return notification;
    }

    getDialerNotificationSubscription(): Observable<any> {
        return this.dialerNotificationSubject.asObservable();
    }

    getCallConnectedSubscription(): Observable<any> {
        return this.onCallConnectedSubject.asObservable();
    }

    getCallHangUpSubscription(): Observable<any> {
        return this.onCallHangUpSubject.asObservable();
    }

    getCallWrapUpSubscription(): Observable<any> {
        return this.onCallWrapUpSubject.asObservable();
    }
}