import { BaseModel } from './base.model';

export class UserPreferences extends BaseModel {
    screen: string;
    component: string;
    identifier: string;
    preferences: any;

    constructor() {
        super();
        this.screen = null;
        this.component = null;
        this.identifier = null;
        this.preferences = null;
    }
}