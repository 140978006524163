
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Agent } from '../models/agent.model';
import { CRUDOperationInput } from '../models/crudOperationInput.model';
import {EnvironmentData} from './environments.service';

@Injectable()
export class AgentService {
    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private httpClient: HttpClient, private environmentData: EnvironmentData){}
  
    getAgentById(_id: String) : Observable<Agent> {
        const envData = this.environmentData.getEnvData();
        const subject = new Subject<Agent>();
        const crudUrl = `${envData.rootUrl + envData.interfaceService + envData.crudFunction}`;
        const crudInput = new CRUDOperationInput();
        crudInput.payload = new Map<any, any>();
        crudInput.collection = 'agent';
        crudInput.operation = "READ";
        crudInput.payload = {"_id" : _id};
        //crudInput["companyContext"] = {"companyId":"6efe654013b041e79c5935e2228f34b2"}
        this.httpClient.post<Map<string, Agent>>(
          crudUrl, 
          crudInput,
          {
            headers: this.httpHeaders,
            observe: 'response',
            reportProgress: true,
            withCredentials: true
          }
        ).subscribe(
          (response: HttpResponse<Map<string, Agent>>) => {
            subject.next(response.body['data']);
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
    
            subject.error(err);
          }
        );
    
        return subject.asObservable();
      }
}