import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { UniversalUser } from './shared.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router:Router,
    private universalUser: UniversalUser
  ) { }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const updatedHeaders = {};
    for (const key of req.headers.keys()) {
      if (key && req.headers.get(key)) {
        updatedHeaders[key] = req.headers.get(key);
      }
    }

    if (this.universalUser.getXAuthToken()) {
      updatedHeaders['X-Auth-Token'] = this.universalUser.getXAuthToken();
    }

    req = req.clone({
      setHeaders: updatedHeaders
    });

    return next.handle(req).pipe(
      tap(event => {

      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            if (err && err.error && err.error.message) {
              console.error(err.error.message);
            }
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            if (err) {
              let statusCd = 0;
              let message = '';

              if (err.status) {
                statusCd = err.status;
              }
              if (err.error && err.error.message) {
                message = err.error.message;
              }
              if (statusCd !== 401) {
                console.error(message);
              } else if(statusCd == 401) {
                this.authService.initiateLogoutSequence(this.router.url);
              }
            }
          }
        }
      })
    );
  }
}