import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { User, UserHierarchy, UserGraphObject } from '../models/user.model';
import { State } from '../models/tasks.model';
import {EnvironmentData} from './environments.service';




@Injectable()
export class FetchUserService {
  constructor(private httpClient: HttpClient, private environmentData: EnvironmentData) { }
  fetchUsers(): Observable<UserHierarchy[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<UserHierarchy[]>();


    var url = `${envData.rootUrl + envData.statemachineroot + envData.userlisturl}`;

    this.httpClient.get<UserHierarchy[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<UserHierarchy[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  fetchUserGroups(): Observable<any[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any[]>();


    var url = `${envData.rootUrl + envData.statemachineroot + envData.usergroupurl}`;

    this.httpClient.get<any[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  fetchChildUsers(userId): Observable<UserHierarchy[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<UserHierarchy[]>();

    var url = `${envData.rootUrl + envData.statemachineroot + envData.userlisturl + userId}/childUsers`;

    this.httpClient.get<UserHierarchy[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<UserHierarchy[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  getPeers(): Observable<any[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any[]>();

    var url = `${envData.rootUrl + envData.statemachineroot + envData.getpeers}`;

    this.httpClient.get<any[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  fetchChildrensUserObjList(): Observable<User[]> {
    const subject = new Subject<User[]>();
    const envData = this.environmentData.getEnvData();

    var url = `${envData.rootUrl + envData.statemachineroot + envData.fetchchildrens}`;

    this.httpClient.get<User[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<User[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  getUserHierarchy(id): Observable<UserHierarchy> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<UserHierarchy>();

    var url = `${envData.rootUrl + envData.statemachineroot + envData.getuserhierarchy + id}/userHierarchy`;

    this.httpClient.get<UserHierarchy>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<UserHierarchy>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  getUserChildren(): Observable<UserHierarchy[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<UserHierarchy[]>();

    var url = `${envData.rootUrl + envData.statemachineroot + envData.userchildren}`;

    this.httpClient.get<UserHierarchy[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<UserHierarchy[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  getUser(userId: string): Observable<User> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<User>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.getuser}${userId}`;
    this.httpClient.get<User>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<User>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );


    return subject.asObservable();
  }

  getMasterRoles(): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();


    var url = `${envData.rootUrl + envData.statemachineroot + envData.masterroles}`;
    this.httpClient.get<any>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );
    return subject.asObservable();
  }


}





@Injectable()
export class AllocateTaskToUser {
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private httpClient: HttpClient, private environmentData: EnvironmentData) { }

  allocateTask(userId, stateId, type): Observable<State> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<State>();
    var url = `${envData.rootUrl + envData.statemachineroot + envData.alocateuserurl}/${stateId}/${userId}/${type}`;

    this.httpClient.get<any>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<State>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );
    return subject.asObservable();
  }
  allocateBulkTask(payload): Observable<string[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<string[]>();


    var url = `${envData.rootUrl + envData.statemachineroot + envData.alocatebulkuserurl}`;
    this.httpClient.post<any>(
      url,
      payload,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<string[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );
    return subject.asObservable();
  }
  bulkTaskOperations(payload): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();


    var url = `${envData.rootUrl + envData.statemachineroot + envData.statesaveurl + 'taskBulkOperation'}`;
    this.httpClient.post<any>(
      url,
      payload,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );
    return subject.asObservable();
  }
}