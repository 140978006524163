import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'templatesearch'
})
export class TemplatesearchPipe implements PipeTransform {

  transform(value: any, searchName: any,  ...args: any[]): any {
    if(!args) {
      return null;
    }
    if(!value) {
      return null;
    }
    if(searchName)
    {
      return value.filter(function(item){
        return JSON.stringify(item.displayName).toLowerCase().includes(searchName);
    });

    }
    else {
      return value;
    }
    
  }

}
