import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {
  transform(s: any): any {
    if (typeof s !== 'string') return s
    let tempStr = s.charAt(0).toUpperCase() + s.slice(1)
    return tempStr.split(/(?=[A-Z])/).join(" ")

  }
}

@Pipe({
  name: 'searchfilter'
})
export class SearchFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
  
    return items.filter(item => {
      return Object.keys(item).some(key => {
        return String(item[key]).toLowerCase().includes(searchText.toLowerCase());
      });
    });
   }
}
