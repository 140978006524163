import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Notes } from 'src/app/models/helper.model';
import { State } from 'src/app/models/tasks.model';
import { TaskService } from 'src/app/service/task.service';
@Component({
  selector: 'app-notes-for-entity',
  templateUrl: './notes-for-entity.component.html',
  styleUrls: ['./notes-for-entity.component.scss']
})
export class NotesForEntityComponent implements OnInit, OnDestroy {
  entityId:string;
  datamodelId:string;
  datamodelName:string;

  showLoadMoreNotes: boolean = false;
  notesPageNumber: number = 0;
  notesPageSize: number = 10;
  notesPageDir: 'ASC' | 'DESC' = 'ASC';

  sourceNotesList: Notes[] = [];
  currentNote: Notes;
  private subscriptionFetchNotes: Subscription;
  private subscriptionSaveNotes: Subscription;
  selectedState: State;
  selectedNote: Notes;
  bsModalRef: BsModalRef;
  currentNoteViewId:string ='';
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '150',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    uploadWithCredentials: false,                    //Image Upload Can we make this work@Parth
    uploadUrl: '',                                   //Your backend must return imageUrl.   | https://github.com/kolkov/angular-editor/issues/5  
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'strikeThrough',
        'subscript',
        'superscript',
        'indent',
        'outdent',
        // 'insertUnorderedList',
        // 'insertOrderedList',
        'heading',
        'fontName',
        'customClasses',
        'insertImage',
        'insertVideo',
        'toggleEditorMode',
        'link',
        'unlink',
        'insertHorizontalRule',
        'removeFormat',
        'fontSize',
        'textColor',
        'backgroundColor',
      ],
      []
    ]
  };


  constructor(
    private modalService: BsModalService,
    private snackBar: MatSnackBar,
    private taskService: TaskService
  ) {
    this.currentNote = new Notes();
  }

  ngOnInit() {
    // this.selectedState = this.currentSelectedTaskDetails;
    this.fetchNotes();
    console.log(this.entityId,this.datamodelId,this.datamodelName)
  }

  ngOnDestroy() {
    if (this.subscriptionFetchNotes && !this.subscriptionFetchNotes.closed) {
      this.subscriptionFetchNotes.unsubscribe();
    }
    if (this.subscriptionSaveNotes && !this.subscriptionSaveNotes.closed) {
      this.subscriptionSaveNotes.unsubscribe();
    }
  }


  
  getTimeStamp(localDateTimeArray: number[]) {
    const timeStamp = new Date();
    
    if (localDateTimeArray && localDateTimeArray.length == 7) {
      timeStamp.setUTCFullYear(localDateTimeArray[0]);
      timeStamp.setUTCMonth(localDateTimeArray[1] - 1); // January starts from 0
      timeStamp.setUTCDate(localDateTimeArray[2]);
      timeStamp.setUTCHours(localDateTimeArray[3]);
      timeStamp.setUTCMinutes(localDateTimeArray[4]);
      timeStamp.setUTCSeconds(localDateTimeArray[5]);
      timeStamp.setUTCMilliseconds(~~(localDateTimeArray[6] / 1000000)); // Convert millis to supported format of JS
    }

    return timeStamp;
  }

  openSnackBar(message, type?: 'success' | 'error', action?: string, duration?:number) {
    if (!type) {
      type = 'success';
    }

    if (action == null || action == undefined) {
      action = 'Close';
    }

    if (!duration) {
      duration = 5000;
    }

    let config: MatSnackBarConfig = new MatSnackBarConfig();
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.duration = duration;

    if (type === 'error') {
      config.panelClass = ['errorMsg'];
    } else {
      config.panelClass = ['success'];
    }

    this.snackBar.open(message, action, config);
  }

  fetchNotes() {
    this.subscriptionFetchNotes = this.taskService.paginateNotesByEntityId(this.entityId, this.notesPageNumber, this.notesPageSize, this.notesPageDir).subscribe(
      (notes: Notes[]) => {
        if (!this.sourceNotesList) {
          this.sourceNotesList = [];
        }

        if (notes) {
          this.sourceNotesList = this.sourceNotesList.concat(notes);
          
          if (notes.length == this.notesPageSize) {
            this.showLoadMoreNotes = true;
            this.notesPageNumber++;
          } else {
            this.showLoadMoreNotes = false;
          }
        } else {
          this.showLoadMoreNotes = false;
        }
      }
    );
  }

  saveNote() {
    if (!this.currentNote || !this.entityId) {
      return;
    }

    if (!this.currentNote.noteTitle || this.currentNote.noteTitle.trim().length == 0) {
      this.openSnackBar('Title is mandatory', 'error');
      return;
    } else if (!this.currentNote.noteContent || this.currentNote.noteContent.trim().length == 0) {
      this.openSnackBar('Content is mandatory', 'error');
      return;
    }

    this.currentNote.entityId = this.entityId;

    this.subscriptionSaveNotes = this.taskService.saveNote(this.currentNote).subscribe(
      (savedNote: Notes) => {
        if (!this.sourceNotesList) {
          this.sourceNotesList = [];
        }

        if (savedNote) {
          this.sourceNotesList.push(savedNote);
        }

        this.currentNote = new Notes();
      }
    );
  }
}
