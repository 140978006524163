import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { EmailPersister, CRUDOperationInput, ChatMessage, Episode, AccessControlRequest,AccessControlResponse } from '../models/helper.model';
import { UniversalUser } from './shared.service';
import {EnvironmentData} from './environments.service';

import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { ChatFilter } from '../models/tasks.model';
import { Agent, Domain } from '../models/conversation.model';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private httpClient: HttpClient,private environmentData: EnvironmentData ) { }

  getFlowInstanceById(flowInstanceId?: string){
    const envData = this.environmentData.getEnvData();
    const subject = new Subject();
    const crudInput = new CRUDOperationInput();
    crudInput.collection = 'flowInstance';
    
    crudInput.payload = {
      _id: flowInstanceId
      }
    crudInput.fields = ["_id","subprocessFlag","parentProcessId","entityId"]
    crudInput.operation = "READ";
    crudInput.sort = {"_id":-1};

    const url = `${envData.rootUrl + envData.interfaceService + envData.smCrudFunction}`;
    this.httpClient.post(
      url,
      crudInput,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<object>) => {
        if (response.body) {
          subject.next(response.body["data"]);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  getEmailTrail(entityId?: string,referenceEntityId?:string,pageNo?:number,pageSize?: number,sort?: any): Observable<EmailPersister[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<EmailPersister[]>();
    const crudInput = new CRUDOperationInput();
    // domainLookup(payload?: any,pageNo?: number,pageSize?: number, fields?: String[], sort?: any):
    crudInput.collection = 'email';
    if(referenceEntityId == entityId){
      crudInput.payload = {
        entityId: entityId
      }
    }else{
      crudInput.payload = {"$or" : [
        {
          entityId: entityId
        },
        {
          referenceEntityId: referenceEntityId
        }
      ]
      }
    }
    crudInput.fields = ["_id","entityId","emailTime","subject","from","to","sentByUserName","emailType","referenceEntityId","cc","bcc","dataServicePayload"]
    crudInput.operation = "READ_ALL";
    crudInput.pageSize = pageSize;
    crudInput.page = pageNo;
    crudInput.sort = sort;

    const url = `${envData.rootUrl + envData.interfaceService + envData.smCrudFunction}`;
    this.httpClient.post<EmailPersister[]>(
      url,
      crudInput,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<EmailPersister[]>) => {
        if (response.body) {
          subject.next(response.body["data"]);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  getEmailHtmlText(_id:string){
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<EmailPersister>();
    const crudInput = new CRUDOperationInput();
    crudInput.collection = 'email';
    crudInput.payload = { "_id": _id }
    crudInput.fields = ["htmlText","dataServicePayload"]
    crudInput.operation = "READ";
    const url = `${envData.rootUrl + envData.interfaceService + envData.smCrudFunction}`;

    this.httpClient.post<EmailPersister>(
      url,
      crudInput,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<EmailPersister>) => {
        if (response.body) {
          subject.next(response.body["data"]);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  saveEmailTrail(emailTrail: EmailPersister) : Observable<EmailPersister> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<EmailPersister>();
    const crudInput = new CRUDOperationInput();
    crudInput.collection = 'email';
    crudInput.payload = emailTrail
    crudInput.operation = "CREATE";

    const url = `${envData.rootUrl + envData.interfaceService + envData.smCrudFunction}`;

    this.httpClient.post<EmailPersister>(
      url,
      crudInput,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
      (response: HttpResponse<EmailPersister>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
      );

    return subject.asObservable();
  }
}


@Injectable({
  providedIn: 'root'
})
export class ConversationService {
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private httpClient: HttpClient,
    private universalUser: UniversalUser,
    private environmentData: EnvironmentData
  ) { }

  getChat(episodeId: string): Observable<ChatMessage[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<ChatMessage[]>();
    const crudUrl = `${envData.rootUrl + envData.interfaceService + envData.crudFunction}`;
    const crudInput = new CRUDOperationInput();
    crudInput.payload = {
      'episodeId': episodeId
    };
    crudInput.collection = 'message';
    crudInput.operation = "READ_ALL";
    this.httpClient.post<ChatMessage[]>(
      crudUrl,
      crudInput,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<ChatMessage[]>) => {
        if (response.body) {
          let messageList: ChatMessage[] = response.body['data'];

          if (messageList && messageList.length > 1) {
            messageList = messageList.sort((msg1, msg2) => {
              const date1 = new Date(msg1.messageTime);
              const date2 = new Date(msg2.messageTime);

              if (date1 > date2) {
                return 1;
              } else if (date1 < date2) {
                return -1;
              } else {
                return 0;
              }
            });
          }

          subject.next(messageList);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }


  getEpisode(episodeId: string): Observable<Episode> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Episode>();
    const crudUrl = `${envData.rootUrl + envData.interfaceService + envData.crudFunction}`;
    const crudInput = new CRUDOperationInput();
    crudInput.payload = {
      '_id': episodeId
    };
    crudInput.collection = 'episode';
    crudInput.operation = "READ";

    this.httpClient.post<Episode>(
      crudUrl,
      crudInput,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<Episode>) => {
        if (response.body) {
          subject.next(response.body['data']);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  searchConversations(chatFilter: ChatFilter): Observable<Episode[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Episode[]>();
    const searchConversationUrl = `${envData.rootUrl + envData.statemachineroot + envData.searchConversation}`;
    
    this.httpClient.post<Episode[]>(
      searchConversationUrl,
      chatFilter,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<Episode[]>) => {
        subject.next(response.body);
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  countConversations(chatFilter: ChatFilter): Observable<number> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<number>();
    const searchConversationUrl = `${envData.rootUrl + envData.statemachineroot + envData.countConversation}`;
    
    this.httpClient.post<number>(
      searchConversationUrl,
      chatFilter,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<number>) => {
        subject.next(response.body['count']);
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  getEpisodesForBargeIn(missedExpressionTheshold: number): Observable<Episode[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Episode[]>();

    const crudUrl = `${envData.rootUrl + envData.interfaceService + envData.crudFunction}`;
    const crudInput = new CRUDOperationInput();

    crudInput.payload = {
      '$and': [
        { 'statusCd': { '$exists': true } },
        { 'modifiedTime': { '$exists': true } },
        { 'episodeContext': { '$exists': true } },
        { 'episodeContext.missedExpressionCount': { '$exists': true } },
        { 'statusCd': 'ACTIVE' },
        { 'episodeContext.missedExpressionCount': { '$gte': missedExpressionTheshold } },
        {
          '$or': [
            {
              '$or': [
                { 'bargedInAgentId': { '$exists': false } },
                { 'bargedInAgentId': null },
                { 'bargedInAgentId': '' }
              ]
            },
            {
              '$and': [
                { 'bargedInAgentId': { '$exists': true } },
                { 'bargedInAgentId': this.universalUser.getUser()._id }
              ]
            }
          ]
        },
      ]
    };

    crudInput.fields = {
      'episodeContext.missedExpressionCount': 1,
      'modifiedTime': 1,
      'bargedInAgentId': 1,
      'agentId':1,
      'source': 1,
      'businessKey': 1,
      'businessKeyValue': 1,
      'businessKeyLabel': 1,
      'languageSelected': 1
    };
    crudInput.collection = 'episode';
    crudInput.operation = "READ_ALL";

    this.httpClient.post<Episode[]>(
      crudUrl,
      crudInput,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          let episodeList: Episode[] = response.body['data'];

          if (episodeList && episodeList.length > 1) {
            episodeList = episodeList.sort((ep1, ep2) => {
              const date1 = new Date(ep1.modifiedTime);
              const date2 = new Date(ep2.modifiedTime);

              if (date1 < date2) {
                return 1;
              } else if (date1 > date2) {
                return -1;
              } else {
                return 0;
              }
            });
          }

          subject.next(episodeList);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  saveEpisode(episode: Episode): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const crudInput = new CRUDOperationInput();
    crudInput.payload = episode;
    crudInput.collection = 'episode';
    crudInput.operation = "UPDATE";
    const crudUrl = `${envData.rootUrl + envData.interfaceService + envData.crudFunction}`;
    this.httpClient.post<any>(
      crudUrl, 
      crudInput,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error
        subject.error(err);
      }
    );
    return subject.asObservable();
  }

  sendAgentMessage(messagePayload: any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.interfaceService + envData.sendAgentMessage}`;
    this.httpClient.post<any>(
      url, 
      messagePayload,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error
        subject.error(err);
      }
    );
    return subject.asObservable();
  }

  getAllAgents(): Observable<Agent[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Agent[]>();
    const crudUrl = `${envData.rootUrl + envData.interfaceService + envData.crudFunction}`;

    const crudInput = new CRUDOperationInput();
    crudInput.payload = {};
    crudInput.collection = 'agent';
    crudInput.operation = "READ_ALL";
    crudInput.fields = {
      _id: 1,
      name: 1,
      desc: 1,
      domainId: 1,
      companyId: 1,
      uiComponent:1
    };

    this.httpClient.post<Agent[]>(
      crudUrl,
      crudInput,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<Agent[]>) => {
        if (response.body) {
          subject.next(response.body['data']);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );
    return subject.asObservable();
  }

  getWhatsAppTemplate(domainId:string){
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Domain>();
    const crudUrl = `${envData.rootUrl + envData.interfaceService + envData.crudFunction}`;
    const crudInput = new CRUDOperationInput();
    crudInput.payload = {
      '_id': domainId
    };
    crudInput.fields = ["whatsAppTemplates"]
    crudInput.collection = 'domain';
    crudInput.operation = "READ";

    this.httpClient.post<Domain>(
      crudUrl,
      crudInput,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<Domain>) => {
        if (response.body) {
          subject.next(response.body['data']);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  engageConversation(episodeId: string, isEngaging: boolean): Observable<Episode> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Episode>();
    const url = `${envData.rootUrl}${envData.statemachineroot}${envData.engageConversation}/${episodeId}/${isEngaging}`;

    this.httpClient.get<Episode>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<Episode>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  closeConversation(episodeId: string): Observable<Episode> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Episode>();
    const url = `${envData.rootUrl}${envData.statemachineroot}${envData.closeConversation}/${episodeId}`;

    this.httpClient.get<Episode>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<Episode>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }
}

@Injectable({
  providedIn: 'root'
})
export class AccessControlService {
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private httpClient: HttpClient,
    private universalUser: UniversalUser,
    private environmentData: EnvironmentData
  ) { }

  getUserAccess(accessControlReq: AccessControlRequest): Observable<AccessControlResponse> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<AccessControlResponse>();
    const crudUrl = `${envData.rootUrl + envData.statemachineroot + envData.accesscontrol + envData.fetchaccess + accessControlReq.componentType + "/" + accessControlReq.componentName}`;
  

    this.httpClient.post<AccessControlResponse>(
      crudUrl,
      accessControlReq,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<AccessControlResponse>) => {
          subject.next(response.body);
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  getUserAccessByComponentType(componentType: string): Observable<AccessControlResponse[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<AccessControlResponse[]>();
    const crudUrl = `${envData.rootUrl + envData.statemachineroot + envData.accesscontrol + envData.fetchaccess + componentType}`;
  

    this.httpClient.post<AccessControlResponse[]>(
      crudUrl,
      {},
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<AccessControlResponse[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }
}

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  constructor(private _scrollToService: ScrollToService) { }

  public triggerScrollTo(targetId: string) {

    const config: ScrollToConfigOptions = {
      target: targetId
    };

    this._scrollToService.scrollTo(config);
  }
}