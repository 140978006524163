import { AfterViewInit, Component } from '@angular/core';
import { EnvironmentData } from './service/environments.service';
import { MessagingService } from './service/messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {

  title = 'piflow';
  envLoaded: boolean = false;
  message;
  constructor(
    private environmentData: EnvironmentData,
    private messagingService: MessagingService
  ) { }

  ngAfterViewInit(): void {
    this.environmentData.fetchEnvData().subscribe(
      envData => {
        if (envData) {
          this.envLoaded = true;
          if(envData.GaTag){
            AppComponent.loadGoogleAnalytics(envData.GaTag)
          }
        }
      });
    // this.notifyMe();

   
    
  }
  notifyMe() {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          // const notification = new Notification("Hi there!");
          // …
        }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them anymore.
  }
  
  static loadGoogleAnalytics(trackingID: string): void {

    let gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ trackingID }`);

    let gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${ trackingID }\');`;

    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
  }
}