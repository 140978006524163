import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { User } from '../models/user.model';
import { CommunicationService, UniversalUser } from '../service/shared.service';
import { ApplicationService } from './application.service';
import { EnvironmentData } from './environments.service';
import { DialerService } from './dialer.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  private logoutSubject = new Subject<any>();

  private readonly DISALLOWED_RETURN_URLS = [
    '/login',
    '/pi-forms'
  ];

  constructor(
    private router: Router,
    private universalUser: UniversalUser,
    private communicationService: CommunicationService,
    private applicationService: ApplicationService,
    private httpClient: HttpClient,
    private environmentData: EnvironmentData,
    private dialerService: DialerService
  ) { }

  getOnLogoutObservable(): Observable<any> {
    return this.logoutSubject.asObservable();
  }

  initiateLogoutSequence(rediredUrl?: string): Observable<any> {
    if(this.dialerService.isConfigPresent()) {
      const subject = new Subject<any>();

      this.dialerService.changeAgentStatus("logout", null).subscribe(
        response => {
          this.logout(rediredUrl).subscribe(
            logoutResponse => {
              subject.next(logoutResponse);
            },
            logoutError => {
              subject.error(logoutError);
            }
          );
        },
        error => {
          this.logout(rediredUrl).subscribe(
            logoutResponse => {
              subject.next(logoutResponse);
            },
            logoutError => {
              subject.error(logoutError);
            }
          );
        }
      );

      return subject.asObservable();
    }
    else {
      return this.logout(rediredUrl);
    }
  }

  /**
   * Please call initiateLogoutSequence() for logging out
   */
  private logout(rediredUrl?: string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.logout}`;

    if (rediredUrl && this.DISALLOWED_RETURN_URLS.includes(rediredUrl)) {
      rediredUrl = null;
    }

    this.httpClient.get<any>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        response => {
          // remove user from local storage to log user out
          //ithe logout
          this.logoutSubject.next();
          this.universalUser.removeUser();
          this.universalUser.removeCompany();
          this.communicationService.clearEverything();
          this.applicationService.clearAll();
          localStorage.clear();
          if(document.getElementById('loadedCssFile')){
            document.getElementById('loadedCssFile')['disabled'] = true;
          }
          if (rediredUrl && rediredUrl.trim().length > 0) {
            this.router.navigate(['/login'], { queryParams: { returnUrl: rediredUrl } });
          } else {
            this.router.navigate(['/login']);
          }
        },
        error => {

        }
      )



    return this.logoutSubject.asObservable();
  }


  authenticate(user: User): Observable<User> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    const url = envData.rootUrl + envData.statemachineroot + "auth/";
    if (user) {
      const body = {};
      body['username'] = user.username;
      body['password'] = user.password;

      this.httpClient.post<User>(
        url,
        body,
        {
          headers: this.httpHeaders,
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      )
        .subscribe(
          (response: HttpResponse<User>) => {
            if (response && response.headers) {
              // Since headers are lazy-loaded, calling keys() loads them
              response.headers.keys();

              if (response.headers.get('x-auth-token') && response.headers.get('x-auth-token').trim().length > 0) {
                this.universalUser.setXAuthToken(response.headers.get('x-auth-token'));

                this.redirect()
                  .subscribe(
                    user => {
                      subject.next(user);
                    },
                    error => {
                      subject.error(error);
                    }
                  );
              } else {
                subject.error('Failed to authenticate, please try again later');
              }
            } else {
              subject.error('Failed to authenticate, please try again later');
            }
          },
          (err: HttpErrorResponse) => {
            subject.error(err);
          }
        );
    } else {
      subject.error('User object is null or empty');
    }

    return subject.asObservable();

  }

  forgotPassword(user: User): Observable<User> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<User>();

    if (user) {
      const url = `${envData.rootUrl + envData.statemachineroot}` + "forgotPassword";

      const body = {};
      body['username'] = user.username;


      this.httpClient.post<any>(
        url,
        body,
        {
          headers: this.httpHeaders,
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      )
        .subscribe(
          (response: HttpResponse<any>) => {
            if (response.body) {
              subject.next(response.body);
            }
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
            if (err.error instanceof Error) {
              // A client-side or network error occurred. Handle it accordingly.
              subject.error(err);
            } else {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              if (err) {
                let statusCd = 0;

                if (err.status) {
                  statusCd = err.status;
                }

                if (statusCd === 0) {
                  this.redirect()
                    .subscribe(
                      user => {
                        subject.next(user);
                      },
                      error => {
                        subject.error(error);
                      }
                    );
                } else {
                  subject.error(err);
                }
              }
            }
          }
        );
    } else {
      subject.error('User object is null or empty');
    }

    return subject.asObservable();
  }

  redirect(): Observable<User> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<User>();

    const url = `${envData.rootUrl + envData.statemachineroot}`;

    this.httpClient.get<User>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<User>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }
  //Company
  getCompanyDetails(comapnyId): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    return subject.asObservable();
  }
  getCompanyAccount(companyIdentifier: string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    let url;
    url = `${envData.rootUrl + envData.fetchaccountbyidurl+  companyIdentifier}`;
    this.httpClient.get<any>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );
    return subject.asObservable();
  }

  //Oauth
  getClients(companyIdentifier: string, whiteLabelHostName: string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    let url;
    let headers = null;
    if (envData.localOauthClient) {
      url = `${envData.rootUrl + envData.oauthClientUrlLocal + companyIdentifier + "/" + whiteLabelHostName}`;
      headers = new HttpHeaders({ 'x-consumer-custom-id': "5d0b3ab5360b4b0800504931" });
    }
    else {
      url = `${envData.rootUrl + envData.oauthClientUrl + companyIdentifier + "/" + whiteLabelHostName}`;
    }
    this.httpClient.get<any>(
      url,
      {
        headers: headers,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );
    return subject.asObservable();
  }

  authenticateOauth(token: string): Observable<User> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<User>();

    if (token) {
      const url = `${envData.rootUrl + envData.oauthurl}`;

      const body = {};
      body["token"] = token;

      this.httpClient.post<User>(
        url,
        body,
        {
          headers: this.httpHeaders,
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      )
        .subscribe(
          (response: HttpResponse<User>) => {
            if (response.body) {
              subject.next(response.body);
            }
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error

            subject.error(err);
          }
        );
      return subject.asObservable();
    }
  }

  updateUser(user: User): Observable<User> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<User>();

    if (user) {
      const url = `${envData.rootUrl + envData.statemachineroot + envData.updateuser}`;

      this.httpClient.put<User>(
        url,
        user,
        {
          headers: this.httpHeaders,
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      )
        .subscribe(
          (response: HttpResponse<User>) => {
            if (response.body) {
              subject.next(response.body);
            }
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error

            subject.error(err);
          }
        );
    } else {
      subject.error('User object is null or empty');
    }

    return subject.asObservable();
  }

  updateUserProfile(user: User): Observable<User> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<User>();

    if (user) {
      const url = `${envData.rootUrl + envData.statemachineroot}` + "console/user/updateprofile";

      this.httpClient.put<User>(
        url,
        user,
        {
          headers: this.httpHeaders,
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      )
        .subscribe(
          (response: HttpResponse<User>) => {
            subject.next(response.body);
            
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error

            subject.error(err);
          }
        );
    } else {
      subject.error('User object is null or empty');
    }

    return subject.asObservable();
  }

  resetPassword(oldPassword: string, newPassword: string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    const body = { oldPassword: oldPassword, newPassword: newPassword };
    const url = `${envData.rootUrl + envData.statemachineroot}` + "console/user/resetpassword";
    
    this.httpClient.put<any>(
      url,
      body,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
      );
    return subject.asObservable();
  }

  getLogoByDomain(companyDomain: string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    let url;
    let headers = null;
    url = `${envData.rootUrl +  "/flow/ui/" + companyDomain + "/logo"}`;
    this.httpClient.get<any>(
      url,
      {
        headers: headers,
        observe: 'response',
        reportProgress: true,
        withCredentials: true,
        responseType: "blob" as "json"
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );
    return subject.asObservable();
  }
  getSlideByDomain(companyDomain: string, slideNumber:any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    let url;
    let headers = null;
    url = `${envData.rootUrl +  "/flow/ui/" + companyDomain + "/slide/"+slideNumber}`;
    this.httpClient.get<any>(
      url,
      {
        headers: headers,
        observe: 'response',
        reportProgress: true,
        withCredentials: true,
        responseType: "blob" as "json"
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );
    return subject.asObservable();
  }
  getThemeByDomain(companyDomain: string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    let url;
    let headers = null;
    url = `${envData.rootUrl +  "/flow/ui/" + companyDomain + "/theme"}`;
    this.httpClient.get<any>(
      url,
      {
        headers: headers,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );
    return subject.asObservable();
  }
}