import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { TaskActivity } from '../models/task-activity.model';
import { State, TaskFilter, Task } from '../models/tasks.model';
import { UniversalUser } from '../service/shared.service';
import { Notes } from '../models/helper.model';
import { EnvironmentData } from './environments.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(
      private router: Router,
      private universalUser: UniversalUser,
      private httpClient: HttpClient,
      private environmentData: EnvironmentData
    ) { }


    fetchPersonalTasks(status: string, userId:string, pageNumber: any, fetchRecords: any,processFilter:any,tagFilter:any,groupFilter:any): Observable<State[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    const url = `${envData.rootUrl + envData.interfaceService + envData.personaltasks }`;
    let payload = {}
    payload["status"] = status
    payload["userId"] = userId    
    payload["pageNumber"] = pageNumber
    payload["fetchRecords"] = fetchRecords
    payload["processFilter"] = processFilter
    payload["tagFilter"] = tagFilter
    payload["groupFilter"] = groupFilter
    this.httpClient.post<State>(
      url,
      payload,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        subject.error(err);
      }
    )
    return subject.asObservable();
  }


  getStatesByStatusAndFolder(status: string, folder: string, pageNumber: any, fetchRecords: any): Observable<State[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<State[]>();

    if (!status) {
      status = 'ACTIVE';
    }

    if (!folder) {
      folder = 'Public';
    }


    const url = `${envData.rootUrl + envData.statemachineroot + envData.statebystatusandfolderurl}${pageNumber},${fetchRecords},${status},${folder}`;
    this.httpClient.get<State[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<State[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  getStatesByStatusAndFolderAndFilter(status: string, folder: string, pageNumber: any, fetchRecords: any,taskFilter:TaskFilter): Observable<State[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<State[]>();

    if (!status) {
      status = 'ACTIVE';
    }

    if (!folder) {
      folder = 'Public';
    }


    const url = `${envData.rootUrl + envData.statemachineroot + envData.statebystatusfolderandfilterurl}${pageNumber},${fetchRecords},${status},${folder}`;
    this.httpClient.post<State[]>(
      url,
      taskFilter,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<State[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  getStatesBySubStatusAndFolder(substatus: string, status: string, pageNumber: any, fetchRecords: any, type: string): Observable<State[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<State[]>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.statebysubstatusandfolderurl}${pageNumber},${fetchRecords},${substatus},${status},${type}`;


    this.httpClient.get<State[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<State[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }


  getStatesBySubStatusAndFolderFilter(substatus: string, status: string, pageNumber: any, fetchRecords: any, type: string,taskFilter:TaskFilter): Observable<State[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<State[]>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.statebysubstatusfolderandfilterurl}${pageNumber},${fetchRecords},${substatus},${status},${type}`;


    this.httpClient.post<State[]>(
      url,
      taskFilter,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<State[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }


  getStateById(stateId: string) {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<State>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.statesaveurl}${stateId}`;


    this.httpClient.get<State>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<State>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }



  getTaskByTaskFilter(taskFilter: TaskFilter): Observable<Task[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Task[]>();
    const url = `${envData.rootUrl + envData.statemachineroot}` + "console/task/search" ;

    this.httpClient.post<Task[]>(
      url,
      taskFilter,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<Task[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  getTaskByAutoRefresh(taskFilter: TaskFilter): Observable<Task[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Task[]>();
    const url = `${envData.rootUrl + envData.statemachineroot}` + "console/task/autorefreshsearch" ;

    this.httpClient.post<Task[]>(
      url,
      taskFilter,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<Task[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  getCountByTaskFilter(taskFilter: TaskFilter): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    const url = `${envData.rootUrl + envData.statemachineroot}` + "console/task/search/count" ;

    this.httpClient.post<any>(
      url,
      taskFilter,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body.count);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  getTaskByRefTypeAndRefId(refType: string, refId: string): Observable<Task> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Task>();
    const url = `${envData.rootUrl + envData.statemachineroot}` + "console/task/byref/" + refType + "/" + refId ;

    this.httpClient.get<Task>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<Task>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  getTaskByEntityIdAndMachineType(entityId: string, machineType: string): Observable<Task> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Task>();
    const url = `${envData.rootUrl + envData.statemachineroot}` + "console/task/byentityid/" + entityId + "/" + machineType ;

    this.httpClient.get<Task>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<Task>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  getTaskFilterByRefTypeAndRefId(refType: string, refId: string): Observable<TaskFilter> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<TaskFilter>();
    const url = `${envData.rootUrl + envData.statemachineroot}` + "console/task/taskfilterbyref/" + refType + "/" + refId ;

    this.httpClient.get<TaskFilter>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<TaskFilter>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  getTaskFilterByEntityIdAndMachineType(entityId: string, machineType: string): Observable<TaskFilter> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<TaskFilter>();
    const url = `${envData.rootUrl + envData.statemachineroot}` + "console/task/taskfilterbyentityid/" + entityId + "/" + machineType ;

    this.httpClient.get<TaskFilter>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<TaskFilter>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  fetchNotesByEntityId(entityId: string) {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Notes[]>();
    const url = `${envData.rootUrl + envData.statemachineroot}${envData.fetchNotesByEntityId}${entityId}`;

    this.httpClient.get<Notes[]>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<Notes[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  paginateNotesByEntityId(entityId: string, pageNo?: number, pageSize?: number, sortDir?: 'ASC' | 'DESC') {
    const envData = this.environmentData.getEnvData();
    if (!pageNo) {
      pageNo = 0;
    }

    if (!pageSize) {
      pageSize = 10;
    }

    if (!sortDir) {
      sortDir = 'ASC';
    }

    const subject = new Subject<Notes[]>();
    const url = `${envData.rootUrl + envData.statemachineroot}${envData.paginateNotesByEntityId}${entityId},${pageNo},${pageSize},${sortDir}`;

    this.httpClient.get<Notes[]>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<Notes[]>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  saveNote(note: Notes) {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Notes>();
    const url = `${envData.rootUrl + envData.statemachineroot}${envData.saveNotes}`;

    this.httpClient.post<Notes>(
      url,
      note,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<Notes>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  getBy_id(_id: string): Observable<Task> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Task>();
    const url = `${envData.rootUrl + envData.statemachineroot}` + "console/task/" + _id;

    this.httpClient.get<Task>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<Task>) => {
        subject.next(response.body);
        
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  getTaskByStateid(stateId: string): Observable<Task> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Task>();
    const url = `${envData.rootUrl + envData.statemachineroot}` + "console/task/bystate" + stateId;

    this.httpClient.get<Task>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<Task>) => {
        subject.next(response.body);
        
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }
}