import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';

import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';
import * as Chartist from 'chartist';
import * as moment from 'moment';
import { ChartEvent, ChartType } from 'ng-chartist';
import { GraphService, UniversalUser } from '../service/shared.service';
import { GraphObject } from '../models/tasks.model';
import { CommonSearchModel } from '../models/shared.model';
import { BusinessDashboardService } from '../service/business-dashboard.service';
import { BusinessDashboardRequest, BusinessDashboardResult } from '../models/business-dashboard.model';
import { GraphOptions } from '../shared/graphOptions';
import { Router } from '@angular/router';
import { LoaderService } from '../service/loader.service';
import { Title } from '@angular/platform-browser';



export interface Chart {
  type: ChartType;
  data: Chartist.IChartistData;
  options?: any;
  responsiveOptions?: any;
  events?: ChartEvent;
}

@Component({
  selector: 'app-business-dashboard',
  templateUrl: './business-dashboard.component.html',
  styleUrls: ['./business-dashboard.component.scss']
})
export class BusinessDashboardComponent implements OnInit {

  @ViewChild('piFlowDashboard', { static: false }) piFlowDashboard: ElementRef;
  @ViewChild('businessDashboardParent', {static: false}) businessDashboardParent: ElementRef;

  showLoaderOverlay:any[]=[true,true,true, true, true, true, true, true, true, true, true, true, true, true,true, true, true, true, true];

  businessDashboardRequest: BusinessDashboardRequest;
  businessDashboardResultForMachineType: BusinessDashboardResult;
  businessDashboardResultForGroups: BusinessDashboardResult[];
  groupNameList: string[];
  graphObjectList: GraphObject[];
  selectedGroup: string;

  totalProcessCount: number;
  processCountPercentageChange: number;
  predictedTotalProcessCount: number;

  totalSuccessProcessCount: number;
  successProcessCountPercentageChange: number;
  predictedSuccessProcessCount: number;

  totalFailedProcessCount: number;
  failedProcessCountPercentageChange: number;
  predictedFailedProcessCount: number;

  processTotalRevenue: number;

  avgHandlingTime: string;
  avgHandlingTimePercentageChange: number;
  predicatedAvgHandlingTimeChange: string;

  noOfAgents: number;
  noOfAgentsPercentageChange: number;
  predictedNoOfAgents: number;

  totalProcessCost: number;
  errorMsg: string;

  comboTimeSeries:any; // Grouped timeseries
  processTimeSeries:any; // Process timeseries

  graphOptions: GraphOptions;

  constructor(
    private graphService: GraphService,
    private businessDashboardService: BusinessDashboardService,
    private universalUser: UniversalUser,
    private router: Router,
    private loaderService: LoaderService,
    private titleService: Title
  ) {
    this.titleService.setTitle("Business Dashboard");
    this.graphObjectList = [];
    this.errorMsg = null;
    this.totalProcessCount = 0;
    this.processCountPercentageChange = 0;
    this.businessDashboardRequest = new BusinessDashboardRequest();
    this.businessDashboardRequest.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.businessDashboardResultForMachineType = new BusinessDashboardResult();
    this.businessDashboardResultForGroups = [];
    this.groupNameList = [];
    this.selectedGroup = null;
  }

  ngOnInit() {
    if (!this.universalUser.displayBusinessDashboard()) {
      this.router.navigate(['dashboard']);
      return;
    }
    this.businessDashboardRequest.startTime = moment().subtract(6, 'days').startOf("day").format("YYYY-MM-DD HH:mm:ss");
    this.businessDashboardRequest.endTime = moment().format("YYYY-MM-DD HH:mm:ss");
    this.fetchGroupAndMachineTypes();
  }

  datesUpdated(range) {
    if (range.startDate != null && range.endDate != null) {
      this.businessDashboardRequest.startTime = range.startDate.startOf("day").format("YYYY-MM-DD HH:mm:ss");
      this.businessDashboardRequest.endTime = range.endDate.format("YYYY-MM-DD HH:mm:ss");

    }
  }

  applyFilter(notfetchGroupResult?: boolean) {
    this.getTotalProcessCount();
    // this.getProcessCountPercentageChange();
    // this.predictTotalProcessCount();
    this.getSuccessProcessCount();
    // this.getSuccessProcessCountPercentageChange();
    // this.predictSuccessProcessCount();
    this.getFailedProcessCount();
    // this.getFailedProcessCountPercentageChange();
    // this.predictFailedProcessCount();
    this.getProcessTotalRevenue();
    this.getAvgHandlingTime();
    // this.getAvgHandlingTimePercentageChange();
    // this.predictAvgHandlingTime();
    this.getNoOfAgents();
    // this.getNoOfAgentsPercentageChange();
    // this.predictNoOfAgents();
    this.getTotalProcessCost();
    this.getProcessTimeSearies();
    this.getResourceUtilization();
    this.getResourceUtilizationPercentageChange();
    // this.predictResourceUtilization();
    // if (!notfetchGroupResult)
    //   this.getGroupResult();
  }

  fetchGroupAndMachineTypes() {
    const businessDashboardRequest = JSON.parse(JSON.stringify(this.businessDashboardRequest))
    businessDashboardRequest.operation = "getMachines"
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            // ---------------------------------------------------
            if (response.result) {
              this.graphObjectList = response.result;
            }
            this.setGroupNames();
            // this.getGroupResult();
            this.businessDashboardRequest.groupName = "All Business Processes";
            this.getGraphObjectsForGroup(this.businessDashboardRequest.groupName);
            this.applyFilter();
            // this.setFirstMachinetype();
          }
        },
        error => {

        }
      )
  }

  setFirstMachinetype() {
    for (let graphObject of this.graphObjectList) {
      if (graphObject.groupName == this.selectedGroup) {
        this.businessDashboardRequest.machineTypes.push(graphObject.machineType);
        this.applyFilter(true);
        break;
      }
    }
  }

  setGroupNames() {
    this.groupNameList = [];
    for (let graphObject of this.graphObjectList) {
      const index = this.groupNameList.indexOf(graphObject.groupName);
      if (index == -1) {
        this.groupNameList.push(graphObject.groupName);
      }
    }
  }

  getMachinetypesForGroup(groupName: string) {
    const machineTypes = [];
    for (let graphObject of this.graphObjectList) {
      if (graphObject.groupName == groupName) {
        machineTypes.push(graphObject.machineType);
      }
    }
    return machineTypes;
  }

  getGraphObjectsForGroup(groupName: string) {
    const graphObjects: GraphObject[] = [];
    for (let garphObject of this.graphObjectList) {
      if (garphObject.groupName == groupName) {
        graphObjects.push(garphObject);
      }
    }
//return graphObjects;
  }

  getMachineLabelForMachinetype(machineType: string) {
    for (let graphObject of this.graphObjectList) {
      if (graphObject.machineType == machineType) {
        return graphObject.machineLabel;
      }
    }
    return null;
  }

  onGraoupSelect(groupName) {
    this.selectedGroup = groupName;
    this.setFirstMachinetype();
    this.getGroupResult();
    this.applyFilter(true);
  }

  getGroupResult() {
    this.businessDashboardResultForGroups = [];
    for (let groupName of this.groupNameList) {
      const businessDahsboardResult: BusinessDashboardResult = new BusinessDashboardResult();
      businessDahsboardResult.groupName = groupName;
      const machinetypes = this.getMachinetypesForGroup(groupName)
      this.getTotalProcessCount(machinetypes, businessDahsboardResult);
      this.getAvgHandlingTime(machinetypes, businessDahsboardResult);
      this.getProcessCountPercentageChange(machinetypes, businessDahsboardResult);
      this.getProcessTimeSearies(machinetypes, businessDahsboardResult);
      this.getGroupTimeSeries(machinetypes, businessDahsboardResult);
      this.businessDashboardResultForGroups.push(businessDahsboardResult);
    }
  }

  getNewBusinessRequest(operation: string, machineTypes?: string[]) {
    const businessDashboardRequest: BusinessDashboardRequest = JSON.parse(JSON.stringify(this.businessDashboardRequest))
    businessDashboardRequest.operation = operation;
    if (this.businessDashboardRequest.machineTypes == null || this.businessDashboardRequest.machineTypes.length == 0) {
      businessDashboardRequest.machineTypes = this.getMachinetypesForGroup(this.businessDashboardRequest.groupName);
      businessDashboardRequest.machineType = null;
    }
    return businessDashboardRequest;
  }

  getTotalProcessCount(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    console.log("request::::",this.businessDashboardRequest);
    const businessDashboardRequest: BusinessDashboardRequest = this.getNewBusinessRequest("getProcessTotalCount", machineTypes);
    businessDashboardRequest.operation = "getProcessTotalCount"
    if (machineTypes) {
      businessDashboardRequest.machineTypes = machineTypes;
      businessDashboardRequest.machineType = null;
    }
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {

          if (!this.checkForError(response)) {
              this.showLoaderOverlay[1] = false;
              this.businessDashboardResultForMachineType.totalProcessCount = response.result.totalCount;
            
          }
        },
        error => {

        }
      )
  }

  getProcessCountPercentageChange(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest: BusinessDashboardRequest = this.getNewBusinessRequest("getProcessCountPercentageChange", machineTypes);
    businessDashboardRequest.operation = "getProcessCountPercentageChange"
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[1] = false;
            if (businessDahsbooardResult) {
              businessDahsbooardResult.processCountPercentageChange = response.result.percentageChange;
            }
            else {
              this.businessDashboardResultForMachineType.processCountPercentageChange = response.result.percentageChange;
            }
          }
        },
        error => {

        }
      )
  }

  predictTotalProcessCount(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest: BusinessDashboardRequest = this.getNewBusinessRequest("predictTotalProcessCount", machineTypes);
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[2] = false;
            if (businessDahsbooardResult) {
              businessDahsbooardResult.predictedTotalProcessCount = response.result.predictValue;
            }
            else {
              this.businessDashboardResultForMachineType.predictedTotalProcessCount = response.result.predictValue;
            }
          }
        },
        error => {

        }
      )
  }

  getSuccessProcessCount(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest = this.getNewBusinessRequest("getSuccessProcessCount", machineTypes);
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[3] = false;
            if (businessDahsbooardResult) {
              businessDahsbooardResult.totalSuccessProcessCount = response.result.totalCount;
            }
            else {
              this.businessDashboardResultForMachineType.totalSuccessProcessCount = response.result.totalCount;
            }
          }
        },
        error => {

        }
      )
  }

  getSuccessProcessCountPercentageChange(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest: BusinessDashboardRequest = this.getNewBusinessRequest("getSuccessProcessCountPercentageChange", machineTypes);
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[4] = false;
            if (businessDahsbooardResult) {
              businessDahsbooardResult.successProcessCountPercentageChange = response.result.percentageChange;
            }
            else {
              this.businessDashboardResultForMachineType.successProcessCountPercentageChange = response.result.percentageChange;
            }

          }
        },
        error => {

        }
      )
  }

  predictSuccessProcessCount(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest: BusinessDashboardRequest = this.getNewBusinessRequest("predictSuccessProcessCount", machineTypes);
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[5] = false;
            if (businessDahsbooardResult) {
              businessDahsbooardResult.predictedSuccessProcessCount = response.result.predictValue;
            }
            else {
              this.businessDashboardResultForMachineType.predictedSuccessProcessCount = response.result.predictValue;
            }


          }
        },
        error => {

        }
      )
  }


  getFailedProcessCount(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest = this.getNewBusinessRequest("getFailedProcessCount", machineTypes);
    businessDashboardRequest.operation = "getFailedProcessCount"
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[6] = false;
            if (businessDahsbooardResult) {
              businessDahsbooardResult.totalFailedProcessCount = response.result.totalCount;
            }
            else {
              this.businessDashboardResultForMachineType.totalFailedProcessCount = response.result.totalCount;
            }

          }
        },
        error => {

        }
      )
  }

  getFailedProcessCountPercentageChange(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest = this.getNewBusinessRequest("getFailedProcessCountPercentageChange", machineTypes);
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[7] = false;
            if (businessDahsbooardResult) {
              businessDahsbooardResult.failedProcessCountPercentageChange = response.result.percentageChange;
            }
            else {
              this.businessDashboardResultForMachineType.failedProcessCountPercentageChange = response.result.percentageChange;
            }


          }
        },
        error => {

        }
      )
  }

  predictFailedProcessCount(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest = this.getNewBusinessRequest("predictFailedProcessCount", machineTypes);
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[8] = false;
            if (businessDahsbooardResult) {
              businessDahsbooardResult.predictedFailedProcessCount = response.result.predictValue;
            }
            else {
              this.businessDashboardResultForMachineType.predictedFailedProcessCount = response.result.predictValue;
            }
          }
        },
        error => {

        }
      )
  }

  getProcessTotalRevenue(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest = this.getNewBusinessRequest("getProcessTotalRevenue", machineTypes);
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[9] = false;
            if (businessDahsbooardResult) {
              businessDahsbooardResult.processTotalRevenue = response.result.totalRevenue;
            }
            else {
              this.businessDashboardResultForMachineType.processTotalRevenue = response.result.totalRevenue;
            }
          }
        },
        error => {

        }
      )
  }

  getAvgHandlingTime(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest = this.getNewBusinessRequest("getProcessAvgTime", machineTypes);
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[10] = false;
            if (businessDahsbooardResult) {
              businessDahsbooardResult.avgHandlingTime = response.result.avgTime;
            }
            else {
              this.businessDashboardResultForMachineType.avgHandlingTime = response.result.avgTime;
            }

          }
        },
        error => {

        }
      )
  }


  getAvgHandlingTimePercentageChange(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest = this.getNewBusinessRequest("getProcessAvgTimeChange", machineTypes);
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[11] = false;
            if (businessDahsbooardResult) {
              businessDahsbooardResult.avgHandlingTimePercentageChange = response.result.percentageChange;
            }
            else {
              this.businessDashboardResultForMachineType.avgHandlingTimePercentageChange = response.result.percentageChange;
            }
          }
        },
        error => {

        }
      )
  }

  predictAvgHandlingTime(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest = this.getNewBusinessRequest("predictAvgTime", machineTypes);
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[12] = false;
            if (businessDahsbooardResult) {
              businessDahsbooardResult.predicatedAvgHandlingTimeChange = response.result.predictValue;
            }
            else {
              this.businessDashboardResultForMachineType.predicatedAvgHandlingTimeChange = response.result.predictValue;
            }

          }
        },
        error => {

        }
      )
  }

  getNoOfAgents(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest = this.getNewBusinessRequest("getNumberOfAgentsWork", machineTypes);
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[13] = false;
            if (businessDahsbooardResult) {
              businessDahsbooardResult.noOfAgents = response.result.totalCount;
            }
            else {
              this.businessDashboardResultForMachineType.noOfAgents = response.result.totalCount;
            }

          }
        },
        error => {

        }
      )
  }

  getNoOfAgentsPercentageChange(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest = this.getNewBusinessRequest("getNumberOfAgentsWorkPercentageChange", machineTypes)
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[14] = false;
            if (businessDahsbooardResult) {
              businessDahsbooardResult.noOfAgentsPercentageChange = response.result.percentageChange;
            }
            else {
              this.businessDashboardResultForMachineType.noOfAgentsPercentageChange = response.result.percentageChange;
            }

          }
        },
        error => {

        }
      )
  }

  predictNoOfAgents(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest = this.getNewBusinessRequest("predictNumberOfAgentsWork", machineTypes);
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[15] = false;
            if (businessDahsbooardResult) {
              businessDahsbooardResult.predictedNoOfAgents = response.result.predictValue;
            }
            else {
              this.businessDashboardResultForMachineType.predictedNoOfAgents = response.result.predictValue;
            }
          }
        },
        error => {

        }
      )
  }

  getTotalProcessCost(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest = this.getNewBusinessRequest("getProcessTotalCost", machineTypes);
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[2] = false;
            if (businessDahsbooardResult) {
              businessDahsbooardResult.totalProcessCost = response.result.totalCost;
            }
            else {
              this.businessDashboardResultForMachineType.totalProcessCost = response.result.totalCost;
            }

          }
        },
        error => {

        }
      )
  }

  getGroupTimeSeries(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest = this.getNewBusinessRequest("getGroupTimeSeries", machineTypes);
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          // ---------------Group timeseries------------------------------------------------------------------------------
          if (!this.checkForError(response)) {
          let res = response;
          res['result']['machineTypes'] = ["group"];
          this.comboTimeSeries = res;
          }
        },
        error => {

        }
      )
  }

  getProcessTimeSearies(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest = this.getNewBusinessRequest("getProcessTimeSearies", machineTypes);
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[0] = false;
            // ---------Process timeseries------------------------------------------------------------------------------------
            let tmp= [];
            for (let i = 0; i < response.result.machineTypes.length; i++) {
              tmp[i]=[]
              tmp[i]['name'] = response.result.machineTypes[i];
              tmp[i]['data'] = []; 
              for (let j = 0; j < response.result.labels.length; j++) {
                let innerTmp = {}
                innerTmp['date'] = response.result.labels[j];
                innerTmp[response.result.machineTypes[i]] = response.result.series[i][j];
                tmp[i]['data'].push(innerTmp)
              }
            }
            this.processTimeSeries = tmp;
          }
        },
        error => {

        }
      )
  }

  getResourceUtilization(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest = this.getNewBusinessRequest("getResourceUtilization", machineTypes);
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[16] = false;
            if (businessDahsbooardResult) {
              businessDahsbooardResult.resourceUtilizationPercentage = response.result.resourceUtilizationPercentage;
              businessDahsbooardResult.resourceUtilizationPercentageApplicable = response.result.applicable;
            }
            else {
              this.businessDashboardResultForMachineType.resourceUtilizationPercentage = response.result.resourceUtilizationPercentage;
              this.businessDashboardResultForMachineType.resourceUtilizationPercentageApplicable = response.result.applicable;
            }

          }
        },
        error => {

        }
      )
  }

  getResourceUtilizationPercentageChange(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest = this.getNewBusinessRequest("getResourceUtilizationPercentageChange", machineTypes);
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[17] = false;
            if (businessDahsbooardResult) {
              businessDahsbooardResult.resourceUtilizationPercentageChange = response.result.percentageChange;
              businessDahsbooardResult.resourceUtilizationPercentageApplicable = response.result.applicable;
            }
            else {
              this.businessDashboardResultForMachineType.resourceUtilizationPercentageChange = response.result.percentageChange;
              this.businessDashboardResultForMachineType.resourceUtilizationPercentageApplicable = response.result.applicable;
            }

          }
        },
        error => {

        }
      )
  }

  predictResourceUtilization(machineTypes?: string[], businessDahsbooardResult?: BusinessDashboardResult) {
    const businessDashboardRequest = this.getNewBusinessRequest("predictResourceUtilization", machineTypes);
    this.businessDashboardService.executeOperation(businessDashboardRequest)
      .subscribe(
        response => {
          if (!this.checkForError(response)) {
            this.showLoaderOverlay[18] = false;
            if (businessDahsbooardResult) {
              businessDahsbooardResult.predictedResourceUtilizationPercentage = response.result.predictValue;
              businessDahsbooardResult.resourceUtilizationPercentageApplicable = response.result.applicable;
            }
            else {
              this.businessDashboardResultForMachineType.predictedResourceUtilizationPercentage = response.result.predictValue;
              this.businessDashboardResultForMachineType.resourceUtilizationPercentageApplicable = response.result.applicable;
            }

          }
        },
        error => {

        }
      )
  }

  checkForError(response: any) {
    if (!response.success && response.error && response.error.message) {
      this.errorMsg = response.error.message;
      return true;
    }
    if (!response.success || !response.result) {
      this.errorMsg = "Something went wrong";
      return true;
    }
    return false;
  }

  public downloadAsPDF() {
    this.loaderService.isLoading.next(true);
    const businessDashboardParent = document.getElementById("businessDashboardParent");
    var data = businessDashboardParent;
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      var imgWidth = 208;   
      var pageHeight = 295;    
      var imgHeight = canvas.height * imgWidth / canvas.width;  
      var heightLeft = imgHeight;  
      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      this.loaderService.isLoading.next(false);
      pdf.save('Dashboard.pdf'); // Generated PDF   

    });

  }

}
