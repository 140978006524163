
import { BaseModel } from './base.model';

export class CommonSearchModel {
    searchParams: any;
    returnFields: any;
    sort:any;
    constructor() {
      this.searchParams = [];
      this.returnFields = [];
      this.sort=null;
    }
  
  }

  export class NotificationPi {
    _id:any;
    source: any;
    information: any;
    displayMessage:string;
    completeMessage:string;
    type:string; //informational or actionable
    notificationToUserId:string;
    notificationFromUserId:string;
    notificationSeen:boolean;
    associatedEntityId:string;
    entityType:string;
    createdAt:any;
    title:string;
    entityName:string;
    notificationFromUserName:string;
    notificationToUserName:string;
    actionTaken:string;
    businessKey:string;
    createdAtLong:number;

  }


 

  