export class CustomDashboard {
    _id: string;
    name: string;
    panels: Panel[];
    filterFields: string[];
    filters: Filter[];

    constructor() {
        this._id = null;
        this.name = null;
        this.panels = [];
        this.filterFields = [];
        this.filters = [];
    }
}

export class Panel {    
    name: string;
    componentIds: CustomDashboardComponentRef[];
    uiProperties: UIProperties;
    sequence: number;

    constructor() {
        this.name = null;
        this.componentIds = [];
        this.uiProperties = new UIProperties();
    }
}

export class UIProperties {
    colSize: string;
    extraClass:string;
    panelCSS:string;
    showTitle:boolean = false;
    YAxisLabel:string ='';
    XAxisLabel:string ='';
    graphLegendPosition:string;
    graphShowGridLines:string;
    graphShowTooltip:string;
    comboGraph:boolean = false;
    comboGraphAggName:string =''
}

export class CustomDashboardComponentRef {
    refId: string;
    sequence: number;
    componentTitle:string ="";
    colSize:string ="";
    showTitle:boolean = false;
    isSelectedForEdit:boolean = false;
    compDetail: CustomDashboardComponent
    constructor() {
        this.refId = null;
        this.sequence = null;
    }
}

export class CustomDashboardComponent {
    _id: string;
    companyId: string;
    type: string; // PIE_CHART, LINE_GRAPH, HORIZONTAL_BAR_GRAPH, VERTICAL_BAR_GRAPH, METRIC, TABLE
    label: string; // Pie chart...
    sourceType: string; // Process, API, Entity, Episode, Route, Automatons, State
    sourceIdentifier: string; // process name...
    aggregations: Aggregation[]=[];
    elements: Aggregation[]=[];
    uiProperties: UIProperties;
    filters: Filter[]=[];
    defaultDate:any;
    initialData:any;
    componentTitle:string;

    constructor() {
        this.type = null;
        this.label = null;
        this.sourceType = null;
        this.sourceIdentifier = null;
        // this.aggregations.push(new Aggregation());
        this.uiProperties = null;
        this.filters=[];
    }
}

export class Aggregation {
    attribute: string;
    attributeDataType: string; // Date, Number, String
    aggregationOperation: string; // avg, sum, count, min, max
    filters: Filter[];
    attributeLabel:string;
    displayFormat: string;
    constructor() {
        this.attribute = null;
        this.attributeDataType = null;
        this.aggregationOperation = null;
        this.filters = [];
        this.attributeLabel ="";
        this.displayFormat = null;
    }
}

export class Filter {
    logicalOperator: string; // AND OR
    attribute: string;
    operation: string; // =, !=, <, >, <=, >=, in, not_in
    value: any; // list in case of in and not_in
    display: boolean;

    constructor() {
        this.logicalOperator = null;
        this.attribute = null;
        this.operation = null;
        this.value = null;
        this.display = false;
    }
}
export class OtherProperties {
    customLabel:string;
    showMetricChange:string;

    constructor() {
        this.customLabel = null;
        this.showMetricChange = null;
    }
}