import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgxDatatableComponent } from '@tusharghoshbd/ngx-datatable';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { CustomDashboardComponent, Filter } from 'src/app/models/customDashboard.model';
import { CustomDashboardService } from 'src/app/service/dashboard.service';
import { UniversalUser } from 'src/app/service/shared.service';
import { isNgTemplate } from '@angular/compiler';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css'],
})
export class DataTableComponent implements OnChanges {
  @Input() startTime: string;
  @Input() endTime: string;
  @Input() selectedComponent: CustomDashboardComponent;
  @Input() selectedDashboardId: string;
  @ViewChild(NgxDatatableComponent, { static: false }) table: NgxDatatableComponent;
  currentResults: any = [];
  currentResultsHeader: any[] = [];
  randomId = '';  
  @Input() colourList:string[] =[];
  @Input() componentFilters: Filter[] = [];
  constructor(
    private customDashboardService: CustomDashboardService,
    private universalUser: UniversalUser
  ) {
    this.randomId = 'tab' + Math.floor(Math.random() * new Date().getTime())
  }
  sortDir=[];
  //https://stackblitz.com/edit/ngx-datatable-angular --> Reference
  options = {
    rowClickEvent: true,
    rowPerPageMenu: [5, 10, 20, 30],
    rowPerPage: 5
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.endTime && changes.endTime.currentValue != undefined) {
      this.endTime = changes.endTime.currentValue;
    }
    if (changes && changes.startTime && changes.startTime.currentValue != undefined) {
      this.startTime = changes.startTime.currentValue;
    }
    if (changes && changes.componentFilters && changes.componentFilters.currentValue != undefined) {
      this.componentFilters = changes.componentFilters.currentValue;
    }
    this.getResults(this.startTime, this.endTime, this.componentFilters)


  }

  formatHeader(str: string) {
    if (typeof str !== 'string') return '';
    if (str.split(".").length > 2) {
      let outPut = str.split(".")[str.split(".").length - 2]
      let tempStr = outPut.charAt(0).toUpperCase() + outPut.slice(1)
      return {string: tempStr.split(/(?=[A-Z])/).join(" ").replace(".keyword", ""), isAtStart:true};
    } else {
      let tempStr = str.charAt(0).toUpperCase() + str.slice(1)
      return {string: tempStr.split(/(?=[A-Z])/).join(" ").replace(".keyword", ""), isAtStart:false};
    }
  }
  truncateNumber(val) {
    if (typeof val == 'number') {
      if (val % 1 !== 0) {
        return val.toFixed(2)
      } else {
        return val;
      }
    } else {
      return val;
    }
  }

  getResults(startTime, endTime, filters) {
    this.customDashboardService.getComponentResult(this.universalUser.getUser().companyId, this.selectedComponent, startTime, endTime, filters, this.selectedDashboardId).subscribe(
      (response) => {
        this.currentResults = [];
        // this.currentResults = response[0];
        if (response.length > 0) {
          for (const key in response[0]) {
            let pos = (this.currentResultsHeader.map(itm => itm.key).indexOf(key))
            // console.log(this.currentResultsHeader.hasOwnProperty(key))
            if (pos == -1) {
              const element = response[0][key];
              if(this.formatHeader(key)['isAtStart'] == true) {
                this.currentResultsHeader.unshift({
                  "key": key,
                  "title": this.formatHeader(key)['string'],
                  "sorting": true
                })
              }else{
                this.currentResultsHeader.push({
                  "key": key,
                  "title": this.formatHeader(key)['string'],
                  "sorting": true
                })
              }
            }
          }
          let tmpResults = []
          for (let tableIndex = 0; tableIndex < response.length; tableIndex++) {
            const tableEl = response[tableIndex];
            let temp = {}
            for (let recInd = 0; recInd < this.currentResultsHeader.length; recInd++) {
              const element = this.currentResultsHeader[recInd]['key'];
              // console.log(element)
              temp[element] = this.truncateNumber(tableEl[element]);
            }
            tmpResults.push(temp)
          }
          this.currentResults = tmpResults;
          // console.log(JSON.stringify(this.currentResultsHeader))
          // console.log(JSON.stringify(this.currentResults))
        }
      },
      (error) => {
        console.error(error)

      }
    );
  }
}
