export const commonKeys = {
  uninversalUser: 'universalUser',
  sessionExpired: 'sessionExpired',
  sessionAvailable: 'sessionAvailable',
  companyAgentId:'companyAgentId',
  returnUrl: 'returnUrl',
  dataModel: 'dataModel',
  entityDefaultPlaceHolder:'Please enter value',
  entityLookup:'LOOKUP',
  entityLookupReference:'LOOKUP_REFERENCE',
  typeLookupRefrence:"Entity_Lookup_Reference",
  dependencyExpressionReplacer:'this.DataModelfields',
  entityLookUpRefId:'_lookup_reference_id',
  entityLookUpRefLabel:'_lookup_reference_label',
  userPreferences:'_user_preferences',
  defaultAutoSuggestEmails:'_default_auto_suggest_emails',
  companyDetails:'companyDetails'
};

export enum FieldTypes {
  TEXT, FLOAT, INT, DATE, BOOLEAN, MODEL,DOCUMENT
} 

export enum DependencyExpressionActions {
  ENABLE = 'ENABLE',
  SHOW = 'SHOW',
  MANDATORY = 'MANDATORY',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  VALUE = 'VALUE'
}