import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CustomDashboardComponent, Filter } from 'src/app/models/customDashboard.model';
import { CustomDashboardService } from 'src/app/service/dashboard.service';
import { UniversalUser } from 'src/app/service/shared.service';

@Component({
  selector: 'app-metric-stat',
  templateUrl: './metric-stat.component.html',
  styleUrls: ['./metric-stat.component.css']
})
export class MetricStatComponent implements OnChanges {
  @Input() startTime: string;
  @Input() endTime: string;
  @Input() colourList: string[];
  @Input() selectedComponent: CustomDashboardComponent;
  @Input() selectedDashboardId: string;
  @Input() componentFilters: Filter[] = [];

  currentResults: any = [];
  constructor(
    private customDashboardService: CustomDashboardService,
    private universalUser: UniversalUser
  ) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.endTime && changes.endTime.currentValue != undefined) {
      this.endTime = changes.endTime.currentValue;
    }
    if (changes && changes.startTime && changes.startTime.currentValue != undefined) {
      this.startTime = changes.startTime.currentValue;
    }
    if (changes && changes.componentFilters && changes.componentFilters.currentValue != undefined) {
      this.componentFilters = changes.componentFilters.currentValue;
    }
    this.getResults(this.startTime, this.endTime, this.componentFilters)


  }
  truncateNumber(val) {
    if (typeof val == 'number') {
      if (val % 1 !== 0) {
        return val.toFixed(2)
      } else {
        return val;
      }
    } else {
      return val;
    }
  }
  replaceExtras(val) {
    let tmp = val.replace(/./g, "");
    tmp = tmp.replace("payload", "");
    tmp = tmp.replace("keyword", "");
    tmp = tmp.replace("parameters", "");
    return capitalize(tmp);
  }
  lenghtRes = [];
  getResults(startTime, endTime, filters) {
    this.customDashboardService.getComponentResult(this.universalUser.getUser().companyId, this.selectedComponent, startTime, endTime, filters, this.selectedDashboardId).subscribe(
      (response) => {
        this.currentResults=[];
        for (let index = 0; index < response.length; index++) {
          const outEl = response[index];
          this.currentResults.push(outEl);
          this.lenghtRes=[];
          let length=0;
          for (const key in outEl) {
            if (Object.prototype.hasOwnProperty.call(outEl, key)) {
              const element = outEl[key];
              if(element!= undefined && element != null && element!= 0){
                length++;
              }
            }
          }
          this.lenghtRes[index] = length
        }

        // console.log(Object.keys(this.currentResults).length)
        
        // console.log(this.lenghtRes)
      },
      (error) => {
        console.error(error)

      }
    );
  }
  getLabel(selectedComponent){
    if(selectedComponent.displayLabel != ''){
      return selectedComponent.displayLabel;
    }else{
      selectedComponent.label;
    }
  }
}
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  let tempStr = s.charAt(0).toUpperCase() + s.slice(1)
  return tempStr.split(/(?=[A-Z])/).join(" ")
}