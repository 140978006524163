export class BaseModel {
  _id: string;
  statusCd: string;
  companyId: string;
  subStatus:string;
  createdAt:any;
  // companyName: string;

  constructor() {
    this._id = null;
    this.statusCd = null;
    this.subStatus = null;
    this.companyId = null;
    this.createdAt = null;
    // this.companyName = "";
  }
}
