import { AfterViewInit, Component, Input, OnChanges, Inject, NgZone, PLATFORM_ID, ViewChild, ElementRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4plugins_sunburst from '@amcharts/amcharts4/plugins/sunburst';
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import * as am4plugins_wordCloud from '@amcharts/amcharts4/plugins/wordCloud';
import { keys } from '@amcharts/amcharts4/.internal/core/utils/Object';

@Component({
  selector: 'app-amchart-graph',
  templateUrl: './amchart-graph.component.html',
  styleUrls: ['./amchart-graph.component.scss'],

})
export class AmchartGraphsComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input() graphType;
  @Input() chartdiv;
  @Input() timeseriesData;
  @Input() height;
  @Input() theme;
  @Input() colourList = [];
  @Input() displayAsLine;
  @ViewChild('graphWrapper', { static: false }) graphWrapper: ElementRef;

  constructor(
    @Inject(PLATFORM_ID) private platformId, private zone: NgZone
  ) { }
  // Amcharts
  // private chart: am4charts.XYChart;
  private chart: any;
  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }
  ngAfterViewInit() {
    if (this.graphWrapper && this.graphWrapper.nativeElement && this.graphWrapper.nativeElement.id != '') {
      if (this.timeseriesData != undefined && this.graphWrapper && this.graphWrapper.nativeElement.id != "") {
        this.graphWrapper.nativeElement.style.height = this.height;
        let _this = this;
        am4core.ready(function () {
          _this.renderGraphsByType(_this.timeseriesData);
        });
      }
    }
  }


  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes.chartdiv) {
      this.chartdiv = changes.chartdiv.currentValue;
      if (this.graphWrapper && this.graphWrapper.nativeElement && this.graphWrapper.nativeElement.id != '') {
        if (changes.timeseriesData && changes.timeseriesData.currentValue != undefined && this.graphWrapper && this.graphWrapper.nativeElement.id != "") {
          this.graphWrapper.nativeElement.style.height = this.height;
          this.renderGraphsByType(changes.timeseriesData.currentValue);
        }
        if(changes.timeseriesData && changes.timeseriesData.currentValue != changes.timeseriesData.previousValue){
          this.renderGraphsByType(changes.timeseriesData.currentValue);
        }
      }
    }
    if (changes.timeseriesData && changes.timeseriesData.firstChange == false) {
      this.renderGraphsByType(changes.timeseriesData.currentValue);
    }
    
  }
  ngOnDestroy() {
    // Clean up chart when the component is removed
    // console.log("here----------------------------------------------------------------------------")
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
  renderGraphsByType(timeSeries) {
    if (this.colourList === undefined || this.colourList.length == 0) {
      this.colourList = ["#049be4", "#ecac02", "#d4dd68", "#b667de", "#dc6779", "#dc9d67", "#1BA68D", "#E7DA4F", "#E77624", "#DF3520", "#64297B", "#232555"];
    }
    this.browserOnly(() => {
      // Themes begin
      if (this.theme) {
        switch (this.theme) {
          case "peachpurple":
            am4core.useTheme(this.am4themes_peachpurple);
            break;
          default:
            am4core.useTheme(this.am4themes_piflowTheme);
            break;
        }

      } else {
        am4core.useTheme(this.am4themes_piflowTheme);
      }
      am4core.useTheme(am4themes_animated);
      if (this.graphType == "LineGraph") {

        let chart = am4core.create(this.chartdiv, am4charts.XYChart);
        
        chart.colors.list = [];
        if (this.colourList) {
          for (let index = 0; index < this.colourList.length; index++) {
            const element = this.colourList[index];
            chart.colors.list.push(am4core.color(element))
          }
        }
        chart.paddingRight = 20;

        chart.data = this.generateLineChartData(timeSeries.result);
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        var graphLabels: any[] = [];
        if (timeSeries.result.machineTypes) {
          graphLabels = timeSeries.result.machineTypes;
        }
        else {
          graphLabels = timeSeries.result.graphLabels;
        }
        if (graphLabels) {
          for (let j = 0; j < graphLabels.length; j++) {
            const element = graphLabels[j];
            // console.log(chart, element, element, false)
            this.createAxisAndSeries(chart, element, element, false);
          }
        }

        chart.cursor = new am4charts.XYCursor();
        // Add legend
        chart.legend = new am4charts.Legend();
        chart.legend.position = "top";

        this.chart = chart;
      }
      if (this.graphType == "SimpleArea") {
        // Create chart instance
        var chart = am4core.create(this.chartdiv, am4charts.XYChart);
        chart.colors.list = [];
        if (this.colourList) {
          for (let index = 0; index < this.colourList.length; index++) {
            const element = this.colourList[index];
            chart.colors.list.push(am4core.color(element))
          }
        }
        chart.data = this.generateLineChartData(timeSeries.result);
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;


        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.minWidth = 0;
        valueAxis.renderer.width = 0;
        valueAxis.renderer.fontSize = 0;


        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.dateX = "date";
        series.dataFields.valueY = "group";
        series.strokeWidth = 0;
        series.fillOpacity = 1;
        series.opacity = 0;
        series.fill = am4core.color("#007bff");
        series.tensionX = 0.8;
        series.tooltipText = "{valueY.value}";
        this.chart = chart;

      }
      if (this.graphType == "pieChartDonut") {

        // Create chart instance
        let chart = am4core.create(this.chartdiv, am4charts.PieChart);
        chart.data = timeSeries.result;
        chart.innerRadius = am4core.percent(40);
        chart.colors.list = [];
        if (this.colourList) {
          if (this.colourList) {
            for (let index = 0; index < this.colourList.length; index++) {
              const element = this.colourList[index];
              chart.colors.list.push(am4core.color(element))
            }
          }
        }
        // Add and configure Series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "taskAssignedTo";
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;
      }
      if (this.graphType == "sunburst") {
        // Create chart instance
        let chart = am4core.create(this.chartdiv, am4plugins_sunburst.Sunburst);
        chart.padding(0, 0, 0, 0);
        chart.radius = am4core.percent(98);
        chart.colors.list = [];
        if (this.colourList) {
          for (let index = 0; index < this.colourList.length; index++) {
            const element = this.colourList[index];
            chart.colors.list.push(am4core.color(element))
          }
        }
        chart.data = this.timeseriesData;
        chart.colors.step = 1;
        chart.fontSize = 11;
        chart.innerRadius = am4core.percent(20);
        // define data fields
        chart.dataFields.value = "value";
        chart.dataFields.name = "category";
        chart.dataFields.children = "subCategory";

        let level0SeriesTemplate = new am4plugins_sunburst.SunburstSeries();
        chart.seriesTemplates.setKey("0", level0SeriesTemplate);
        // this makes labels to be hidden if they don't fit
        level0SeriesTemplate.labels.template.truncate = true;
        level0SeriesTemplate.labels.template.hideOversized = true;
        level0SeriesTemplate.showOnInit = false;
        level0SeriesTemplate.usePercentHack = false;

        level0SeriesTemplate.radius = am4core.percent(100);
        level0SeriesTemplate.innerRadius = am4core.percent(0);

        let selectedState = level0SeriesTemplate.states.create("selected");
        selectedState.properties.opacity = 0.7;
        level0SeriesTemplate.defaultState.properties.radius = am4core.percent(100);
        let currentlySelected;

        level0SeriesTemplate.slices.template.events.on("over", function (event) {
          if (event.target.dataItem['sunburstDataItem'].children) {
            event.target.cursorOverStyle = am4core.MouseCursorStyle.pointer;
          }
        })

        let zoomOutButton = chart.seriesContainer.createChild(am4core.ZoomOutButton);
        zoomOutButton.visible = false;
        zoomOutButton.horizontalCenter = "middle";
        zoomOutButton.verticalCenter = "middle";
        zoomOutButton.events.on("hit", function () {
          this.drillUp(currentlySelected, zoomOutButton, currentlySelected)
        }, this)
        level0SeriesTemplate.labels.template.adapter.add("rotation", function (rotation, target) {
          target.maxWidth = target.dataItem.slice.radius - target.dataItem.slice.innerRadius - 10;
          target.maxHeight = Math.abs(target.dataItem.slice.arc * (target.dataItem.slice.innerRadius + target.dataItem.slice.radius) / 2 * am4core.math.RADIANS);
          return rotation;
        })
        let level1SeriesTemplate = level0SeriesTemplate.clone();
        level1SeriesTemplate.hidden = true;
        level1SeriesTemplate.innerRadius = am4core.percent(10);
        chart.seriesTemplates.setKey("1", level1SeriesTemplate)
        level1SeriesTemplate.fillOpacity = 0.75;

        let level2SeriesTemplate = level0SeriesTemplate.clone();
        level2SeriesTemplate.hidden = true;
        level2SeriesTemplate.innerRadius = am4core.percent(20);
        chart.seriesTemplates.setKey("2", level2SeriesTemplate)

        level0SeriesTemplate.slices.template.events.on("hit", function (event) {
          zoomOutButton.show();
          let hitSlice = event.target;

          if (hitSlice.dataItem['sunburstDataItem'].children) {

            let series = event.target.dataItem.component;

            if (!series.dummyData) {
              series.tooltip.disabled = true;
              hitSlice.dataItem['label'].radius = (hitSlice.radius - hitSlice.pixelInnerRadius) - 7;
              hitSlice.dataItem['label'].bent = true;
              hitSlice.dataItem['label'].rotation = 0;

              currentlySelected = hitSlice;
              series.dummyData = true;
              series.setState("selected");
              hitSlice.dataItem['sunburstDataItem'].series.show();
              series['slices'].each(function (slice) {
                if (slice != event.target) {
                  slice.dataItem.hide();
                }
              })
            }
            else {
              this.drillUp(hitSlice, zoomOutButton, currentlySelected);
            }
          }
        }, this)


        // Legend
        chart.legend = new am4charts.Legend();
        chart.legend.maxHeight = 150;
        chart.legend.scrollable = true;
        let markerTemplate = chart.legend.markers.template;
        markerTemplate.width = 20;
        markerTemplate.height = 20;
      }
      if (this.graphType == "stackedColumnchart") {
        if (this.displayAsLine) {
          // Create chart instance
          let chart = am4core.create(this.graphWrapper.nativeElement, am4charts.XYChart);
          // chart.exporting.menu = new am4core.ExportMenu();
          let allCat = [];
          for (let keysIndex = 0; keysIndex < this.timeseriesData.length; keysIndex++) {
            let eachNode = this.timeseriesData[keysIndex];
            let keys = Object.keys(eachNode)
            keys.splice(keys.indexOf('category'), 1);
            for (let kInd = 0; kInd < keys.length; kInd++) {
              let eachKey = keys[kInd];
              if (allCat.indexOf(eachKey) == -1) {
                allCat.push(eachKey);
              }
            }
          }
          let data = this.timeseriesData;
          let refNode = JSON.parse(JSON.stringify(data[0]))
          /* Create axes */
          var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
          categoryAxis.dataFields.category = "category";
          categoryAxis.renderer.minGridDistance = 30;
          /* Create value axis */
          var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
          for (let yAxesIndex = 0; yAxesIndex < allCat.length; yAxesIndex++) {
            let k = allCat[yAxesIndex];
            if (k == this.displayAsLine) {
              var lineSeries = chart.series.push(new am4charts.LineSeries());
              lineSeries.name = k;
              lineSeries.dataFields.valueY = k;
              lineSeries.dataFields.categoryX = "category";
              lineSeries.strokeWidth = 3;
              lineSeries.tooltip.label.textAlign = "middle";
              var bullet = lineSeries.bullets.push(new am4charts.Bullet());
              var circle = bullet.createChild(am4core.Circle);
              circle.radius = 4;
              circle.fill = am4core.color("#fff");
              circle.strokeWidth = 3;
            } else {
              this.createSeries(chart, k, k,"stackedColumnchart");

            }
          }
          chart.data = data;
          // Legend
          chart.legend = new am4charts.Legend();
          chart.validateData();
        } else {
          // Create chart instance
          let chart = am4core.create(this.graphWrapper.nativeElement, am4charts.XYChart);
          // chart.exporting.menu = new am4core.ExportMenu();
          chart.data = this.timeseriesData;
          chart.colors.list = [];
          if (this.colourList) {
            for (let index = 0; index < this.colourList.length; index++) {
              const element = this.colourList[index];
              chart.colors.list.push(am4core.color(element))
            }
          }
          let categoryAxis
          if(this.timeseriesData && this.timeseriesData[0] && this.timeseriesData[0]['category'] && this.isValidDate(this.timeseriesData[0]['category'])){
            categoryAxis = chart.xAxes.push(new am4charts.DateAxis());
            categoryAxis.renderer.minGridDistance = 10;
          }else{
            // Create axes
            categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "category";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 10;
          }
          
          if(categoryAxis){
            categoryAxis.events.on("sizechanged", function (ev) {
              let axis = ev.target;
              var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
              axis.renderer.labels.template.fontSize = "10px";
              axis.renderer.labels.template.wrap = true;
              axis.renderer.labels.template.maxWidth = cellWidth+20;
              // console.log(cellWidth)stand 
              // if (cellWidth < axis.renderer.labels.template.minWidth) {
              //   axis.renderer.labels.template.rotation = -45;
              //   axis.renderer.labels.template.horizontalCenter = "right";
              //   axis.renderer.labels.template.verticalCenter = "middle";
              // }
              // else {
              //   axis.renderer.labels.template.rotation = 0;
              //   axis.renderer.labels.template.horizontalCenter = "middle";
              //   axis.renderer.labels.template.verticalCenter = "top";
              // }
            });
            var label = categoryAxis.renderer.labels.template;
            label.truncate = false;
            // label.maxWidth = 80;
            label.tooltipText = "{category}";
          }
        


          let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
          valueAxis.renderer.inside = true;
          valueAxis.renderer.labels.template.disabled = true;
          // valueAxis.min = 0;

          if (timeSeries.length > 0) {
            let tmp = JSON.parse(JSON.stringify(this.timeseriesData))
            delete tmp[0]['category'];
            // delete tmp[0]['value'];
            for (const key in tmp[0]) {
              if (Object.prototype.hasOwnProperty.call(tmp[0], key)) {
                const element = tmp[0][key];
                this.createSeries(chart, key, key,"stackedColumnchart");
              }
            }
          }
          // Legend
          chart.legend = new am4charts.Legend();
          chart.validateData();
        }
      }
      if (this.graphType == "DashboardLineGraph") {

        let chart = am4core.create(this.chartdiv, am4charts.XYChart);
        // chart.dispose();
        chart.paddingRight = 20;
        chart.colors.list = [];
        if (this.colourList) {
          for (let index = 0; index < this.colourList.length; index++) {
            const element = this.colourList[index];
            chart.colors.list.push(am4core.color(element))
          }
        }
        chart.colors.list = [];
        if (this.colourList) {
          for (let index = 0; index < this.colourList.length; index++) {
            const element = this.colourList[index];
            chart.colors.list.push(am4core.color(element))
          }
        }
        chart.cursor = new am4charts.XYCursor();
        // Create axes
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        // chart.data = this.createLineSeries(timeSeries.result);
        for (var i = 0; i < this.timeseriesData.length; i++) {
          this.createLineSeries(this.timeseriesData[i]['data'], this.timeseriesData[i]['name'], chart);
        }
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.legend.scrollable = true;
        chart.legend.itemContainers.template.events.on("over", function (event) {
          this.processOver(event.target.dataItem.dataContext, chart);
        }, this)

        chart.legend.itemContainers.template.events.on("out", function (event) {
          this.processOut(event.target.dataItem.dataContext, chart);
        }, this)
        
        
        chart.responsive.rules.push({
          relevant: function(target) {
            if (target.pixelWidth <= 600) {
              return true;
            }
            return false;
          },
          state: function(target, stateId) {
            if (target instanceof am4charts.Legend) {
              var state = target.states.create(stateId);
              state.properties.position = "bottom";
              
              var labelState = target.labels.template.states.create(stateId);
              labelState.properties.maxWidth = 100;
              labelState.properties.truncate = true;
              return state;
            }
            return null;
          }
        });
      }
      if (this.graphType == "SlicedChart") {
        // Create chart instance
        let chart = am4core.create(this.graphWrapper.nativeElement, am4charts.SlicedChart);
        chart.data = this.timeseriesData;
        chart.colors.list = [];
        if (this.colourList) {
          for (let index = 0; index < this.colourList.length; index++) {
            const element = this.colourList[index];
            chart.colors.list.push(am4core.color(element))
          }
        }
        // Create axes
        let series = chart.series.push(new am4charts.FunnelSeries());
        series.colors.list = [];
        for (let index = 0; index < this.colourList.length; index++) {
          const element = this.colourList[index];
          series.colors.list.push(am4core.color(element))
        }
        series.colors.step = 1;
        series.dataFields.value = "value";
        series.dataFields.category = "name";
        series.alignLabels = true;

        series.labelsContainer.paddingLeft = 15;
        series.labelsContainer.width = 200;
        // chart.legend = new am4charts.Legend();
        // chart.legend.position = "left";
        // chart.legend.valign = "bottom";
        // chart.legend.margin(5,5,20,5);
      }
      if (this.graphType == "WordCloud") {
        // Create chart instance
        let chart = am4core.create(this.graphWrapper.nativeElement, am4plugins_wordCloud.WordCloud);
        let series = chart.series.push(new am4plugins_wordCloud.WordCloudSeries());
        series.colors.list = [];
        for (let index = 0; index < this.colourList.length; index++) {
          const element = this.colourList[index];
          series.colors.list.push(am4core.color(element))
        }
        series.accuracy = 4;
        series.step = 15;
        series.rotationThreshold = 0.7;
        series.maxCount = 50;
        series.minWordLength = 1;
        series.excludeWords = ["the", "and", "to", "our", "for", "his", "her", "with", "that", "your", "have", "yet", "here", "this", "but", "which", "with", "him", "their", "there", "who", "whom", "let", "these", "now", "than", "are"];
        series.labels.template.tooltipText = "{word}: {value}";
        series.fontFamily = "Courier New";
        series.maxFontSize = am4core.percent(30);
        series.data = this.timeseriesData;
        series.dataFields.word = "category";
        series.dataFields.value = "value";
        series.labels.template.tooltipText = "{word}:\n[bold]{value}[/]";
      }
      if (this.graphType == "GaugeChart") {
        // Create chart instance
        let chart = am4core.create(this.graphWrapper.nativeElement, am4charts.GaugeChart);
        chart.innerRadius = -15;

        var axis = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
        axis.min = this.timeseriesData.min;
        axis.max = this.timeseriesData.max;
        axis.strictMinMax = true;

        var colorSet = new am4core.ColorSet();

        var gradient = new am4core.LinearGradient();
        gradient.stops.push({ color: am4core.color("red") })
        gradient.stops.push({ color: am4core.color("yellow") })
        gradient.stops.push({ color: am4core.color("green") })

        axis.renderer.line.stroke = gradient;
        axis.renderer.line.strokeWidth = 30;
        axis.renderer.line.strokeOpacity = 1;

        axis.renderer.grid.template.disabled = true;

        if (this.timeseriesData.targettoAchieve) {
          let hand2 = chart.hands.push(new am4charts.ClockHand());
          hand2.innerRadius = am4core.percent(80);
          hand2.radius = am4core.percent(95);
          hand2.startWidth = 5;
          hand2.fill = am4core.color("#73b504");
          hand2.stroke = am4core.color("#73b504");
          hand2.value = this.timeseriesData.targettoAchieve;
        }

        var hand = chart.hands.push(new am4charts.ClockHand());
        hand.radius = am4core.percent(97);
        hand.showValue(this.timeseriesData.score)

      }
    });
  }

  // https://www.amcharts.com/demos/multiple-value-axes/#code
  generateLineChartData(timeSeriesData) {
    var chartData = [];
    var graphLabels: any[] = [];
    if (timeSeriesData.machineTypes) {
      graphLabels = timeSeriesData.machineTypes;
    }
    else {
      graphLabels = timeSeriesData.graphLabels;
    }
    for (var i = 0; i < timeSeriesData.labels.length; i++) {

      let tempchartData = {};
      tempchartData['date'] = timeSeriesData.labels[i];
      // console.log(timeSeriesData)
      if (timeSeriesData && graphLabels) {
        for (let j = 0; j < graphLabels.length; j++) {
          const element = graphLabels[j];
          tempchartData[element] = timeSeriesData.series[j][i]
        }

        chartData.push(tempchartData);
      }


    }
    return chartData;
  }
  createLineSeries(chartData, seriesName, chart) {
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = seriesName;
    series.dataFields.dateX = "date";
    series.name = seriesName;
    series.tooltipText = "{name}: [bold]{valueY}[/]";

    // Make bullets grow on hover
    let bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.strokeWidth = 2;
    bullet.circle.radius = 4;
    bullet.circle.fill = am4core.color("#fff");

    let bullethover = bullet.states.create("hover");
    bullethover.properties.scale = 1.3;

    let segment = series.segments.template;
    segment.interactionsEnabled = true;

    let hoverState = segment.states.create("hover");
    hoverState.properties.strokeWidth = 3;

    let dimmed = segment.states.create("dimmed");
    dimmed.properties.stroke = am4core.color("#dadada");

    segment.events.on("over", function (event) {
      this.processOver(event.target.parent.parent.parent);
    }, this);

    segment.events.on("out", function (event) {
      this.processOut(event.target.parent.parent.parent);
    }, this);

    series.data = chartData;
    return series;
  }
  processOver(hoveredSeries, chart) {
    hoveredSeries.toFront();

    hoveredSeries.segments.each(function (segment) {
      segment.setState("hover");
    })
    if (chart != undefined) {
      chart.series.each(function (series) {
        if (series != hoveredSeries) {
          series.segments.each(function (segment) {
            segment.setState("dimmed");
          })
          series.bulletsContainer.setState("dimmed");
        }
      });
    }

  }

  processOut(hoveredSeries, chart) {
    if (chart != undefined) {
      chart.series.each(function (series) {
        series.segments.each(function (segment) {
          segment.setState("default");
        })
        series.bulletsContainer.setState("default");
      });
    }
  }
  createAxisAndSeries(chart, field, name, opposite) {
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // series.yAxis.disabled = true;

    if (chart.yAxes.indexOf(valueAxis) != 0) {
      valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
    }

    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = field;
    series.dataFields.dateX = "date";
    series.strokeWidth = 2;
    series.yAxis = valueAxis;
    series.name = name;
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    // series.tensionX = 0;
    series.showOnInit = true;
    // series.calculatePercent = true;

    var interfaceColors = new am4core.InterfaceColorSet();
    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart.scrollbarX = scrollbarX;
    chart.scrollbarX.parent = chart.bottomAxesContainer;


    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 2;
    valueAxis.renderer.line.stroke = series.stroke;
    valueAxis.renderer.labels.template.fill = series.stroke;
    valueAxis.renderer.opposite = opposite;
  }
  // Create series
  createSeries(chart, field, name, type) {
    // Set up series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.name = name;
    series.dataFields.valueY = field;
    if(chart.data && chart.data[0] && chart.data[0]['category'] && this.isValidDate(chart.data[0]['category'])){
      series.dataFields.dateX = 'category';
      series.columns.template.width = am4core.percent(75);
    }else{
      series.dataFields.categoryX = "category";
      series.sequencedInterpolation = true;

      // Make it stacked
      series.stacked = true;
      // Configure columns
      series.columns.template.width = am4core.percent(75);
    }
    
    

    
    series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:12px]{categoryX}: {valueY}";
    // console.log(series.columns)
    // Add label
    // let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    // labelBullet.label.text = "{valueY}";
    // labelBullet.locationY = 0.5;
    // labelBullet.label.hideOversized = true;

    return series;
  }
  collapse(slice) {
    let _this = this //This is not accessible inside the each function cause it has this as it's own variable. Hence we defnie scope
    if (slice != undefined) {
      slice.dataItem.label.bent = false;
      slice.dataItem.label.radius = 10;

      if (slice.dataItem.sunburstDataItem.children) {
        slice.dataItem.sunburstDataItem.children.each(function (child) {
          child.seriesDataItem.component.setState("hidden");
          _this.collapse(child.seriesDataItem.slice);
        })
      }
    }
  }
  drillUp(slice, zoomOutButton, currentlySelected) {
    if (slice != undefined) {
      this.collapse(slice);
      let series = slice.dataItem.component;
      series.tooltip.disabled = false;
      series.dummyData = false;
      series.setState("default");

      series.slices.each(function (slice) {
        if (slice != event.target) {
          slice.dataItem.show();
        }
      })

      if (series.parentDataItem.seriesDataItem) {
        currentlySelected = series.parentDataItem.seriesDataItem.slice;
      }
      else {
        zoomOutButton.hide();
      }
    }

  }
  am4themes_piflowTheme(target) {
    if (target instanceof am4core.ColorSet) {
      target.list = [
        am4core.color("#049be4"),
        am4core.color("#ecac02"),
        am4core.color("#d4dd68"),
        am4core.color("#b667de"),
        am4core.color("#dc6779"),
        am4core.color("#dc9d67"),
        am4core.color("#1BA68D"),
        am4core.color("#E7DA4F"),
        am4core.color("#E77624"),
        am4core.color("#DF3520"),
        am4core.color("#64297B"),
        am4core.color("#232555")
      ];
    }
  }
  am4themes_peachpurple(target) {
    if (target instanceof am4core.ColorSet) {
      target.list = [
        am4core.color("#f9cdac"),
        am4core.color("#f3aca2"),
        am4core.color("#ee8b97"),
        am4core.color("#e96a8d"),
        am4core.color("#db5087"),
        am4core.color("#b8428c"),
        am4core.color("#983791"),
        am4core.color("#742796"),
        am4core.color("#9e79b8"),
        am4core.color("#9576aa"),
        am4core.color("#faf6f9"),
        am4core.color("#301243")
      ];
    }
  }
  isValidDate(d) {
    var timestamp = Date.parse(d);
    if (isNaN(timestamp) == false) {
      return true
    } else {
      return false
    }
  }
}
