import { Component, OnInit, Output, EventEmitter, Optional, Inject, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataModel, Field } from 'src/app/models/datamodel.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { commonKeys } from '../../models/constants';
import { DataSharingService } from 'src/app/service/shared.service';




@Component({
  selector: 'app-dynamic-form-modal',
  templateUrl: './dynamic-form-modal.component.html',
  styleUrls: ['./dynamic-form-modal.component.css'],
})
export class DynamicFormModalComponent implements OnInit, OnChanges {

  entityItem: any;
  map = {};
  index: any;
  field: any;
  initialField: any;
  showFieldsMap: any[];
  initialEvent: any;
  form: FormGroup;
  DataModelfields: any = {};
  showDoneButton: boolean = true;
  viewingNestedEntity: boolean = false;
  @Input() formData;
  @Output() emitted: EventEmitter<any> = new EventEmitter();
  @Output() onCloseEmitted: EventEmitter<any> = new EventEmitter();

  constructor(
    // public dialogRef: MatDialogRef<DynamicFormModalComponent>,
    private dataSharingService: DataSharingService

    // @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // dialogRef.disableClose = true;
    this.zIndexTop = this.dataSharingService.taskScreenZIndex
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    // if (changes.model && changes.model.previousValue != undefined) {
    // console.log(this.formData)
    // }
  }
  zIndexTop: number = 0;
  ngOnInit() {
    this.dataSharingService.taskScreenZIndex = this.dataSharingService.taskScreenZIndex +1;
    
    this.entityItem = this.formData.entityItem;
    this.index = this.formData.index;
    this.field = this.formData.field;
    this.showFieldsMap = [];
    this.initialField = this.formData.entityItem;
    this.initialEvent = this.formData.event;
    this.viewingNestedEntity = this.formData.viewingNestedEntity;
    this.form = new FormGroup({});
    if(this.field != undefined){
    this.updateInnerModel();
    }
  }


  updateInnerModel(field?, event?) {
    if (event && event._id) {
      // event["dialogRef"] = this.dialogRef;
      this.map["field"] = field;
      this.map["event"] = event;
      this.closeDialog(event)
      
    }
  }
  closeInnerModal(event){
      if(event.event == 'close'){
      this.closeModal()
    }
  }
  updateInnerModelList(field, event, index) {
    if (event && event._id) {
      // event["dialogRef"] = this.dialogRef;
      this.map["field"] = field
      this.map["event"] = event;
    }
    this.map["index"] = index;
    this.closeDialog(event);
  }


  closeDialog(field?: any) {
    let event = this.map["event"];
    if (event) {
      event.viewEntity = true;
      this.map["event"] = event;
      if (this.index != undefined) {
        this.map["list"] = true
        this.map["index"] = this.index;
      }
      else {
        this.map["list"] = false
      }
      event["map"] = this.map;
    }

  }

  closeModal() {
    this.onCloseEmitted.emit({ event: 'close', data: false, index: this.index });
    // this.dialogRef.close({ event: 'close', data: false,index:this.index});
  }
}