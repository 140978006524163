import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Observable } from 'rxjs';
import { EnvironmentData } from './environments.service';

@Injectable()
export class MessagingService {

    constructor(
        private angularFireMessaging: AngularFireMessaging,
        private httpClient: HttpClient,
        private environmentData: EnvironmentData
    ) {
        this.angularFireMessaging.messaging.subscribe(
            (_messaging) => {
                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            }
        );
    }

    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            token => {
                const envData = this.environmentData.getEnvData();
                const url = `${envData.rootUrl + envData.statemachineroot}console/user/registerdevice`;

                let formData = {
                    "registrationToken": token,
                    "deviceType": navigator.userAgent
                }

                this.httpClient.post<any>(
                    url,
                    formData,
                    {
                        observe: 'response',
                        reportProgress: true,
                        withCredentials: true
                    }
                ).subscribe(
                    (response: HttpResponse<any>) => { },
                    (err: HttpErrorResponse) => { }
                );
            },
            err => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }

    getFirebaseMessageSubscription(): Observable<any> {
        return this.angularFireMessaging.messages;
    }
}