import { BaseModel } from './base.model';
import { DataModel } from './datamodel.model';
import { UserHierarchy } from './user.model';
import { Notes } from './helper.model';

export class TaskFilter {
  processes: any[] = [];
	tags: any[] = [];
  groups: any[] = [];
  assignedUserIds: any[] = [];
	parameters: any = {};
	searchText: string;
	statusCd: string;
	subStatus: string;
	folder: string; // TASK_FOLDER
	type: string;
	pageNo: number;
	pageSize: number;
	scrollId: string;
	stateCd: string;
	payload: any = {};
	overdue: boolean;
	sortBy: string;
	sortDir: number;
	entityId: string;
	businessKey: string;
	slaUnitType: string;
	slaUnitRemaining: number = 1;
	slaRangeComparision: string;
	escalatedTask: boolean;
	fromTime: Date;
  toTime: Date;
  additionalFields: any = {};
  selectedDateRange:any;
  priorities: number[];
  sourceRefTypes: any[];
  reopen: boolean;
  
  constructor() {

  }

  isGroupAllowToFilter() {
    return this.folder && (this.folder == TASK_FOLDER.Group || this.folder == TASK_FOLDER.ALL )
  }

  isAssignedUserIdAllowToFilter() {
    return this.folder && (this.folder == TASK_FOLDER.Team || this.folder == TASK_FOLDER.ALL  )
  }

  isDefaultFilter(): boolean {
    return (!this.processes || this.processes.length == 0)
      && (!this.tags || this.tags.length == 0)
      && (!this.groups || this.groups.length == 0)
      && (!this.assignedUserIds || this.assignedUserIds.length == 0)
      && (!this.parameters || Object.keys(this.parameters).length == 0)
      && (!this.searchText || this.searchText.trim().length == 0)
      // && (!this.statusCd || this.statusCd.trim().length == 0)
      && (!this.subStatus || this.subStatus.trim().length == 0)
      // && (!this.folder || this.folder.trim().length == 0)
      && (!this.type || this.type.trim().length == 0)
      // && this.pageNo
      // && this.pageSize
      // && this.scrollId
      && (!this.stateCd || this.stateCd.trim().length == 0)
      && (!this.payload || Object.keys(this.payload).length == 0)
      && !this.overdue
      && (this.sortBy && this.sortBy == "startTime")
      && (this.sortDir && this.sortDir == -1)
      && (!this.entityId || this.entityId.trim().length == 0)
      && (!this.businessKey || this.businessKey.trim().length == 0)
      && (!this.slaUnitType || this.slaUnitType.trim().length == 0)
      && (this.slaUnitRemaining && this.slaUnitRemaining == 1)
      && (!this.slaRangeComparision || this.slaRangeComparision.trim().length == 0)
      && !this.escalatedTask
      && !this.fromTime
      && !this.toTime
      && (!this.additionalFields || Object.keys(this.additionalFields).length == 0)
      && (!this.selectedDateRange || Object.keys(this.additionalFields).length == 0)
      && (!this.priorities || Object.keys(this.priorities).length == 0)
      && (!this.sourceRefTypes || Object.keys(this.sourceRefTypes).length == 0);
  }
	
}
export class ChatFilter {
  agentIds: string[];
  sourceList: string[];
  searchText: string;
  bargedIn: boolean;
  pageNo: number;
  pageSize: number
  fromTime: Date;
  toTime: Date;
  onlyEngagedChats: boolean;
  statusCdList: string[];
  modeList: string[];
  episodeId: string;
  businessKey: string;
  sortList: SortBy[];
  selectedDateRange: any;
  missedExpressionCountGreaterThan: number;
  missedExpressionCountLessThan: number;

  constructor() {
    this.agentIds = null;  // added
    this.sourceList = null; // added
    this.searchText = null; // added
    this.bargedIn = true; // added
    this.pageNo = 0;
    this.pageSize = 10;
    this.fromTime = null; // added
    this.toTime = null; // added
    this.onlyEngagedChats = false; // added
    this.statusCdList = []; // skipped
    this.modeList = null; // skipped
    this.episodeId = null; // added
    this.businessKey = null; // added
    this.sortList = [
      new SortBy('episodeContext.missedExpressionCount', -1),
      new SortBy('episodeContext.lastUserMessageTime', -1)
    ]; // added
    this.selectedDateRange = null; // added
    this.missedExpressionCountGreaterThan = 0; // skipped
    this.missedExpressionCountLessThan = null; // skipped
  }

  isDefaultFilter(): boolean {
    return (!this.agentIds || this.agentIds.length == 0)
      && (!this.sourceList || this.sourceList.length == 0)
      && (!this.searchText || this.searchText.trim().length == 0)
      && this.bargedIn
      // && this.pageNo
      // && this.pageSize
      && !this.fromTime
      && !this.toTime
      && !this.onlyEngagedChats
      && (!this.statusCdList || this.statusCdList.length == 0)
      && (!this.modeList || this.modeList.length == 0)
      && (!this.episodeId || this.episodeId.trim().length == 0)
      && (!this.businessKey || this.businessKey.trim().length == 0)
      && SortBy.isDefaultSort(this.sortList)
      // && (!this.selectedDateRange || Object.keys(this.selectedDateRange).length == 0)
      && (!this.missedExpressionCountGreaterThan || this.missedExpressionCountGreaterThan == 0)
      && !this.missedExpressionCountLessThan;
  }
}

export class SortBy {
  sortBy: string;
  sortDir: 1 | -1;

  constructor(sortBy?: string, sortDir?: 1 | -1) {
    this.sortBy = sortBy ? sortBy : null;
    this.sortDir = sortDir ? sortDir : null;
  }

  static isDefaultSort(sortList: SortBy[]): boolean {
    if (sortList && sortList.length == 2) {
      const missedExpSort = sortList[0];
      const lastUserMsgTimeSort = sortList[1];

      return missedExpSort && lastUserMsgTimeSort && missedExpSort.sortDir && missedExpSort.sortDir == -1
        && lastUserMsgTimeSort.sortDir && lastUserMsgTimeSort.sortDir == -1
        && missedExpSort.sortBy && missedExpSort.sortBy == 'episodeContext.missedExpressionCount'
        && lastUserMsgTimeSort.sortBy && lastUserMsgTimeSort.sortBy == 'episodeContext.lastUserMessageTime';
    }

    return false;
  }
}
export class Chronology {
  id: string;
  calendarType: string;
}

export class DataPointAccess {
  dataPointName: string;
  dataPointLabel: string;
  writeAccess: boolean;
  readAcsess: boolean;
  hide: boolean;

  constructor(dataPointName?: string, dataPointLabel?: string, writeAccess?: boolean, readAcsess?: boolean, hide?: boolean) {
    this.dataPointName = dataPointName ? dataPointName : null;
    this.dataPointLabel = dataPointLabel ? dataPointLabel : null;
    this.writeAccess = writeAccess ? writeAccess : true;
    this.readAcsess = readAcsess ? readAcsess : true;
    this.hide = hide ? hide : false;
  }
}


export class Classifier {
  apiName: string;
  url: string;

  constructor() {
    this.apiName = '';
    this.url = '';
  }
}

export class Expression {
  value: string;

  constructor(value?: string) {
    this.value = value ? value : '';
  }
}

export class EventModel {
  eventCd: string;
  expressionList: Expression[];
  operand: string;
  sortPriority: number;

  constructor() {
    this.eventCd = '';
    this.expressionList = [];
    this.operand = '';
    this.sortPriority = 0;
  }
}

export class AllocationModel {
  allocationGroup: string;
  allocationType: string;
  groups: string[];
  api: string;
  allocatedUserCd: string;
  capabilities: string[];
  stickyStates: string[];
  emailerAPI: string;

  constructor() {
    this.allocationGroup = '';
    this.groups = [];
    this.allocationType = '';
    this.api = '';
    this.allocatedUserCd = '';
    this.capabilities = [];
    this.stickyStates = [];
    this.emailerAPI = '';
  }
}

export class ApiKeyExpressionMap {
  key: string;
  expression: string;

  constructor() {
    this.key = '';
    this.expression = '';
  }
}

export class Time {
  hour: string;
  minute: string;
  nano: string;
  second: string;
  dayOfMonth: string;
  dayOfWeek: string;
  dayOfYear: string;
  month: string;
  monthValue: string;
  year: string;
  chronology: Chronology;
}

export class Amount {
  typecd: string;
  amount: number;
  currency: string;
}

export class DocumentSubSet {
  documentName: string;
  fileName: string;
  mandatory: boolean;
  documentType: string;
  status: string;

  constructor(documentName?: string, fileName?: string, mandatory?: boolean, documentType?: string, status?: string) {
    this.documentName = documentName;
    this.fileName = fileName;
    this.mandatory = mandatory;
    this.documentType = documentType;
    this.status = status;
  }
}


export class CostModel {
  expression: string;
  costType: string;
  amount: number;
  apiCd: string;
  currency: string;

  constructor() {
    this.expression = '';
    this.amount = 0;
    this.costType = "FIXED";
    this.apiCd = '';
    this.currency = '';
  }
}

export class SubprocessData {
  parentProcessUpdateEvent: string;
  toTriggerMachineType: string;

  constructor() {
    this.parentProcessUpdateEvent = 'Closure';
    this.toTriggerMachineType = '';
  }
}

export class ManualAction {
  sequence: number;
  key: string;
  value: any;
  type: string;
  label: string;
  description: string;

  constructor(sequence?: number, key?: string, value?: any, type?: string, label?: string, description?: string) {
    this.sequence = sequence ? sequence : 0;
    this.key = key ? key : '';
    this.value = value ? value : '';
    this.type = type ? type : '';
    this.label = label ? label : '';
    this.description = description ? description : '';
  }
}

export class ApiResponse {
  responseCode: number;
  responseType: string;
  paramsToSelect: string;
  keyExpressionList: ApiKeyExpressionMap[];

  constructor(responseType: string, paramsToSelect: string) {
    this.responseCode = 0;
    this.responseType = responseType;
    this.paramsToSelect = paramsToSelect;
    this.keyExpressionList = [];
  }
}


export class TaskObject {
  responseList: ApiResponse[];
  body: any;

  constructor() {
    this.responseList = [];
    this.body = {};
  }
}

export class ConnectorConfig extends BaseModel {
  configName: string;
  configType: string;
  connectorInfoRef: string;
  configMap: any;
  connectorConfigRef: string;
  displayName: string;
  functionInstanceName: string;
  taskObject: TaskObject;
  taskConfig: boolean;

  constructor() {
    super();

    this.configName = '';
    this.configType = '';
    this.configMap = {};
    this.connectorConfigRef = "";
    this.displayName = "";
    this.functionInstanceName = "";
    this.connectorConfigRef = "";
    this.taskObject = new TaskObject();
    this.taskConfig = false;

  }
}


export class LabelValue {


  label: string;
  value: string;
  disabled: boolean;

  constructor(label?: string, value?: string, disabled?: boolean) {
    this.label = label ? label : null;
    this.value = value ? value : null;
    this.disabled = disabled ? disabled : false;
  }
}


export class DecisionTableHeader {
  label: String;
  value: String;
  requestType: String;
  disabled: boolean;

  constructor(label?: string, value?: string, requestType?: string, disabled?: boolean) {
    this.label = label ? label : null;
    this.value = value ? value : null;
    this.requestType = value ? requestType : null;
    this.disabled = disabled ? disabled : false;
  }
}

export class State extends BaseModel {
  machineType: string;
  machineLabel: string;
  entityId: string;
  stateCd: string;
  stageCd: string;
  stage: string;
  stateMachineInstanceModelId: string;
  assignedUserDisplayName: string;
  assignedUserGroupCd: string;
  assignedUserId: string;
  flagReason: string;
  assignedUserName: string;
  startTime: Time;
  endTime: Time;
  allocatedTime: Time;
  reservedTime: Time;
  payload: string;
  fromStateCd: string;
  customerId:string;
  parameters: Map<string, string>;
  predictedParameters: Map<string, string>;
  businessCost: Amount;
  errorMessageMap: any;
  headerParamList: string[];
  flagged: boolean;
  iterationLevel: number;
  autoAllocation: boolean;
  assignedDueToFlagged: boolean;
  stateEntryTypeCd: string;
  assignedVirtualAgentId: string;
  taskStatus: string = null;
  taskRemarks: string = null;
  documents: DocumentSubSet[] = [];
  notes: Notes[] = [];
  businessKey: string;
  taskTags: string[] = [];
  processStageCdList: string[] = [];
  slaEndTimeLong: number;
  startTimeLong: number;
  createdAtLong: number;
  slaTime: number;
  remainingTime: number;
  overdue: boolean;
  source: any[] = [];
  manualStateEntityAction: boolean;
  archivedBy: string = null;
  taskPriority: number;
  taskLabel:string;
  disableUITaskOperations:boolean;
  allowToReopen: boolean;
  processTag: string;
  hideTaskDetailsEntity:boolean;

  constructor() {
    super();
    this.notes = [];
    this.disableUITaskOperations = false;
    this.hideTaskDetailsEntity = false;
  }

}

export class StateStage {
  stage: string;
  order: any;
}

export class TaskTags {
  tags: Map<string, number>;
  process: string;
}

export class DataPointValidation {
  sequence: number;
  dataPointKey: string;
  expression: string;
  errorMessage: string;

  constructor(dataPointKey?: string) {
    this.sequence = 0;
    this.dataPointKey = dataPointKey ? dataPointKey : '';
    this.expression = '';
    this.errorMessage = '';
  }
}

export class TaskValidation {
  sequence: number;
  validationExpression: string;
  errorMessage: string;

  constructor(sequence?: number, validationExpression?: string, errorMessage?: string) {
    this.sequence = sequence ? sequence : 0;
    this.validationExpression = validationExpression ? validationExpression : null;
    this.errorMessage = errorMessage ? errorMessage : null;
  }
}


export class PredictiveTaskConfiguration extends BaseModel {

  input_Labels: Array<string>;
  output_Labels: Array<string>;
  number: number;
  probability: any;

  mode: "AUTO" | "SUPERVISED";

  constructor(input_Labels?: [''], output_Labels?: [''], number?: number, probability?: any) {
    super();
    this.input_Labels = input_Labels ? input_Labels : [''];
    this.output_Labels = output_Labels ? output_Labels : [''];
    this.number = number ? number : null;
    this.probability = probability ? probability : null;
    this.mode = "AUTO";

  }

}

export class TimelineStateAuditData extends BaseModel {
  stateCd: string;
  assignedUserGroupCd: string;
  assignedUserName: string;
  assignedUserDisplayName: string;
  assignedUserId: string;
  startTimeLong: number;
  endTimeLong: number;
  parameters: TimelineStateParameterData[];
  escalatedFlag: boolean;
  flagged: boolean;
  flagReason: string;
  assignedDueToFlagged: boolean;
  taskStatus: string;
  taskRemarks: string;
  documents: Document[];

  constructor() {
    super()
    this.stateCd = null;
    this.assignedUserGroupCd = null;
    this.assignedUserName = null;
    this.assignedUserDisplayName = null;
    this.assignedUserId = null;
    this.startTimeLong = null;
    this.endTimeLong = null;
    this.parameters = [];
    this.escalatedFlag = false;
    this.flagged = false;
    this.flagReason = null;
    this.assignedDueToFlagged = false;
    this.taskStatus = null;
    this.taskRemarks = null;
    this.documents = [];
  }
}

export class TimelineStateParameterData extends BaseModel {
  key: string;
  value: string;
}

export class Document extends BaseModel {
  flowInstanceId: string;
  stateInstanceId: string;
  documentName: string;
  fileName: string;
  userFileName: string;
  downloadFileUrl: string;
  fullDataUrl: string;
  fullFileUrl: string;
  url: string;
  mandatory: boolean;
  allowedFileTypes: string[];
  documentType: string;
  status: string;
  uploadTime: Date;
  createdTime: Date;
  updatedTime: Date;
  description: string;
  uploadTimeLong:any;

  constructor(flowInstanceId?: string, stateInstanceId?: string, documentName?: string, fileName?: string, userFileName?: string,
    downloadFileUrl?: string, fullDataUrl?: string, fullFileUrl?: string, url?: string, mandatory?: boolean, allowedFileTypes?: string[], documentType?: string, status?: string,
    uploadTime?: null, createdTime?: null, updatedTime?: null, description?: null
  ) {
    super();
    this.flowInstanceId = flowInstanceId ? flowInstanceId : null;
    this.stateInstanceId = stateInstanceId ? stateInstanceId : null;
    this.documentName = documentName ? documentName : null;
    this.fileName = fileName ? fileName : null;
    this.userFileName = userFileName ? userFileName : null;
    this.downloadFileUrl = downloadFileUrl ? downloadFileUrl : null;
    this.fullDataUrl = fullDataUrl ? fullDataUrl : null;
    this.url = url ? url : null;
    this.mandatory = mandatory ? mandatory : false;
    this.allowedFileTypes = allowedFileTypes ? allowedFileTypes : [];
    this.documentType = documentType ? documentType : null;
    this.status = status ? status : null;
    this.uploadTime = this.uploadTime ? uploadTime : null;
    this.createdTime = this.createdTime ? createdTime : null;
    this.description = this.description ? description : null;
  }
}



export class DataPoint {
  sequence: number;
  dataPointName: string;
  expression: string;
  dataPointLabel: string;
  description: string;
  dataType: string;
  inputSource: string[];
  validations: DataPointValidation[];
  operand: string;
  value: any;
  headerFlag: boolean;
  businessKeyFlag: boolean;
  businessMonitorKey: boolean;
  percentageChange: boolean;
  graphType: string;
  reportDataKey: boolean;
  childdataPoints: DataPoint[];
  referenceModel: string;
  lookupType: string;
  parentDataPointName: string;
  lookupEntityList: any[];

  constructor() {
    this.sequence = 0;
    this.dataPointName = '';
    this.expression = '';
    this.dataPointLabel = '';
    this.description = '';
    this.dataType = 'STRING';
    this.inputSource = [];
    this.validations = [];
    this.operand = 'AND';
    this.value = null;
    this.headerFlag = false;
    this.businessKeyFlag = false;
    this.graphType = null;
    this.businessKeyFlag = false;
    this.percentageChange = false;
    this.reportDataKey = false;
    this.childdataPoints = [];
    this.referenceModel = '';
    this.lookupType = '';
    this.parentDataPointName = '';
  }
}

export class SearchableDataPointResponse {
  machineType: string;
  name: string;
  label: string;
  inputSource: string[];
  additionalFieldName: string;
  dataType: string;
  referenceModel: string;
  lookupType: string;
  parentDataPointName: string;
  lookupEntityList: any[];
  parentDataPointCheckResult: 'UNCHECKED' | 'PRESENT' | 'ABSENT';
  selectedLookupValue: any;

  constructor() {
    this.parentDataPointCheckResult = 'UNCHECKED';
  }

}





export class StateModel {
  stateId: string;
  stateCd: string;
  initialState: boolean;
  endState: boolean;
  events: EventModel[];
  type: string;
  trigger: EventModel;
  classifiers: Classifier[];
  entryActionList: string[];
  apiConfigurationList: string[];
  ruleList: ApiKeyExpressionMap[];
  allocationModel: AllocationModel;
  costModel: CostModel;
  subprocessData: SubprocessData;
  manualActions: ManualAction[];
  mandatoryDataPoints: DataPoint[];
  timerUnitType: string;
  timerUnit: number;
  runAtDateExpression: string;
  runAtTimeExpression: string;
  taskConfig: ConnectorConfig[];
  taskConfigList: string[];
  connectorConfig: ConnectorConfig[];
  connectorConfigList: string[];
  reportFlag: boolean;
  businessMonitorFlag: boolean;
  virtualAgentId: string;
  statusList: LabelValue[];
  statusDisable: boolean;
  documentEvalExp: string;
  converseRequestExp: string;
  taskValidations: TaskValidation[];
  dataPointAccessList: DataPointAccess[];
  mwRouteCd: string;
  decisionTabelHeaders: DecisionTableHeader[];
  decisionTabelRuleList: ApiKeyExpressionMap[][];
  predictiveTaskConfiguration: PredictiveTaskConfiguration;
  predictiveTaskConfig_id: string;
  showDefaultDocument:boolean;

  constructor() {
    this.stateId = '';
    this.stateCd = '';
    this.initialState = false;
    this.endState = false;
    this.events = [];
    this.type = '';
    this.trigger = new EventModel();
    this.classifiers = [];
    this.entryActionList = [];
    this.apiConfigurationList = [];
    this.ruleList = [];
    this.allocationModel = new AllocationModel();
    this.costModel = new CostModel();
    this.subprocessData = new SubprocessData();
    this.manualActions = [];
    this.mandatoryDataPoints = [];
    this.timerUnit = 0;
    this.runAtDateExpression = '';
    this.runAtTimeExpression = '';
    this.taskConfig = [];
    this.taskConfigList = [];
    this.connectorConfig = [];
    this.connectorConfigList = [];
    this.reportFlag = false;
    this.businessMonitorFlag = false;
    this.virtualAgentId = '';
    this.statusList = [];
    this.statusDisable = false;
    this.documentEvalExp = null;
    this.converseRequestExp = null;
    this.taskValidations = [];
    this.dataPointAccessList = [];
    this.mwRouteCd = '';
    this.decisionTabelHeaders = [];
    this.decisionTabelRuleList = [];
    this.predictiveTaskConfiguration = new PredictiveTaskConfiguration();
    ////this.predictiveTaskConfig_id = '';
    // this.pta = new PredictiveTaskAggregatedInstance();
  }
}

export class Transition {
  sourceStateCd: string;
  targetStateCd: string;
  eventCd: string;

  constructor() {
    this.sourceStateCd = '';
    this.targetStateCd = '';
    this.eventCd = '';
  }
}

  export class GraphObject extends BaseModel {
    machineLabel: string;
    machineType: string;
    version: number;
    xml: string;
    processOwner: string;
    primaryEntity: string;
    dataPointConfigurationList: DataPoint[];
    states: StateModel[];
    transitions: Transition[];
    activeStateIdList: string[];
    closedStateIdList: string[];
    entity: DataModel;
    disableTaskAssignmentEmails: boolean;
    configStages:string[];
    masterStages:StateStage[];
    groupName: string;
  
    constructor() {
      super();
  
      this.machineLabel = '';
      this.machineType = '';
      this.processOwner = '';
      this.primaryEntity = '';
      this.version = 0;
      this.xml = '';
      this.dataPointConfigurationList = [];
      this.states = [];
      this.transitions = [];
      this.activeStateIdList = [];
      this.closedStateIdList = [];
      this.entity = new DataModel();
      this.disableTaskAssignmentEmails = false;
      this.configStages = [];
      this.masterStages = [];
      this.groupName = null;
    }
}



export class TaskDecision {

  TAB_ASSIGNED = 'ASSIGNED';
  TAB_UNASSIGNED = 'UNASSIGNED';
  TAB_FLAGGED = 'FLAGGED';


  isUpdateAllow(stateDetails: State, taskType: string) {
    if (taskType == this.TAB_ASSIGNED) {
      if (stateDetails && stateDetails.stateEntryTypeCd && stateDetails.stateEntryTypeCd == "VirtualAgentStateEntryAction") {
        return false;
      }
      return stateDetails.statusCd !== ('CLOSED' || 'ARCHIVE') && (stateDetails.assignedUserGroupCd === 'Personal' || stateDetails.assignedUserGroupCd === 'Public')
    }
  }

  isAllocateAllow(stateDetails: State, tasktype: string, users: UserHierarchy[],peers:any) {
    if (tasktype == this.TAB_ASSIGNED) {
      return stateDetails.statusCd !== ('CLOSED' || 'ARCHIVE') && (users.length > 0 || peers.length > 0)
    }
    if (tasktype == this.TAB_UNASSIGNED) {
      return stateDetails.statusCd !== ('CLOSED' || 'ARCHIVE') && (users.length > 0 || peers.length > 0)
    }
  }

  isReserveAllow(stateDetails: State, taskType: String) {
    if (taskType == this.TAB_ASSIGNED) {
      return stateDetails.statusCd !== ('CLOSED' || 'ARCHIVE') && stateDetails.assignedUserGroupCd !== 'Personal'
    }
    if (taskType == this.TAB_UNASSIGNED) {
      return stateDetails.statusCd !== ('CLOSED' || 'ARCHIVE') && stateDetails.assignedUserGroupCd !== 'Personal'
    }
  }

  isEscalteAllow(stateDetails: State, taskType: string, userHierarchy: UserHierarchy) {
    if (taskType == this.TAB_ASSIGNED) {
      return stateDetails.statusCd !== ('CLOSED' || 'ARCHIVE') && stateDetails.assignedUserGroupCd === 'Personal' && userHierarchy.parentUserId &&  userHierarchy.parentUserId.length > 0
    }
  }

  isFlagAllow(stateDetails: State, taskType: string, childUsers: UserHierarchy[]) {
    if (taskType == this.TAB_ASSIGNED) {
      return stateDetails.statusCd !== ('CLOSED' || 'ARCHIVE') && stateDetails.subStatus !== 'FLAGGED' && childUsers && childUsers.length > 0;
    }
  }

  isArchiveAllow(stateDetails: State, taskType: string) {
    if (taskType == this.TAB_ASSIGNED) {
      return stateDetails.statusCd === 'ACTIVE' && stateDetails.assignedUserGroupCd == 'Personal'
    }
  }

  isAssistAllow(stateDetails: State, taskType: string) {
    if (taskType == this.TAB_ASSIGNED) {
      return stateDetails.stateEntryTypeCd && stateDetails.stateEntryTypeCd === 'VirtualAgentStateEntryAction'
    }
  }
}

export class CampaignConfig {
  campaignName: string = "";
  displayName?: string = "";
  campaignType?: string = "";
  templateList?: CampaignInfo[] = [];
  campaignReference?: string = "";
}
export class CampaignInfo {
  templateName?: string = "";
  displayName?: string = "";
  linkedProcess?: number = 0;
  templateHTML?: string = "";
  uploadedFile?: any;
  lastSent?: string = "";
  cc?: string = null;

}

export class Task extends BaseModel {
  refId: string;
	refType: string;
	machineType: string;
	machineLabel: string;
	entityId: string;
	businessKey: string;
	stateCd: string;
	associatedEntityId: string;
	assignedUserGroupCd: string;
	assignedUserId: string;
	assignedUserName: string;
	assignedUserDisplayName: string;
	assignedVirtualAgentId: string;
  allocatedById: string;
  allocatedTime: Date;
  allocatedTimeLong: number;
  allocatedByDisplayUsername: string;
  startTime: Date;
  startTimeLong: number;
  endTime: Date;
  endTimeLong: number;
  slaEndTime: Date;
  slaEndTimeLong: number;
  reservedTime: Date;
  escalatedById: string;
  escalatedByDisplayUsername: string;
  escalatedTask: boolean;
  escalatedTime: Date;
  escalatedTimeLong: number
	slaLevel: number;
	flagged: boolean;
	parameters: any;
  taskTags: string[];
  cssClass: string;
  enableUpdate: boolean;
  enableReserve: boolean;
  overdue: boolean;
  checkForAllocate: boolean;
  source: any[];
  taskPriority: number;
  newTask: boolean;                       // Only being used in UI, doesn't exist in backend
  taskLabel:string;
  statusList:any[];
  processTag: string;
  hideTaskDetailsEntity:boolean;
  customerId:string;
  
  constructor() {
    super();

    this.newTask = false;
    this.hideTaskDetailsEntity = false;
  }

}

export enum TASK_FOLDER {
  ALL = "ALL",
  Public = "Public", 
  Personal = "Personal", 
  Group = "Group",
  Team = "Team",
  Peers = "Peers"
}