import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConversationService } from 'src/app/service/helper.service';
import { State } from 'src/app/models/tasks.model';
import { ChatMessage, Episode } from 'src/app/models/helper.model';
import { DomSanitizer } from '@angular/platform-browser';
import {EnvironmentData} from '../../service/environments.service';

@Component({
  selector: 'app-conversationtrail',
  templateUrl: './conversationtrail.component.html',
  providers: [ConversationService],
  styleUrls: ['./conversationtrail.component.scss']
})
export class ConversationtrailComponent implements OnInit {

  @Input() currentSelectedTaskDetails:State;
  @Output() closeEvent = new EventEmitter();
  

  private subscriptionChatMessages: Subscription;
  private subscriptionEpisode: Subscription;
  selectedState: State;
  selectedEpisode: Episode = null;
  chatMessageList: ChatMessage[];
  len : number;
  episodePresent:boolean = false;
  workbenchChatHistotyURL: any;
  
  constructor(
    private conversationService: ConversationService,
    private sanitizer: DomSanitizer,
    private environmentData: EnvironmentData
  ) { }

  ngOnInit() {
    this.selectedState = this.currentSelectedTaskDetails;
    this.getEpisode();
    //this.getChatMessages();
  }


  getEpisode() {
    let entityId = null;
    if(this.selectedState.source && this.selectedState.source.length > 0){
      for(let source of this.selectedState.source){
        if(source["referenceType"] == "Conversation"){
          entityId = source["referenceId"];
        }
        else{
          entityId = this.selectedState.entityId;
        }
      }
    }
    else{
      entityId = this.selectedState.entityId;
    }
    this.subscriptionEpisode = this.conversationService.getEpisode(entityId)
      .subscribe(episode => {
        if (episode && episode._id) {
          this.selectedEpisode = episode;
          this.episodePresent= true;
          this.workbenchChatHistotyURL = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.environmentData.getEnvData().workbenchBaseURL}${this.selectedEpisode.agentId}/${this.selectedEpisode._id}?mode=chatHistory&displaySideBar=false`);
        }
      });
  }

  closeSection(){
    this.closeEvent.emit();
  }

  ngOnDestroy() {
    if (this.subscriptionChatMessages && !this.subscriptionChatMessages.closed) {
      this.subscriptionChatMessages.unsubscribe();
    }
  }

}
