import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { Document } from 'src/app/models/tasks.model';
import { FileService } from 'src/app/service/shared.service';
import { ImagepdfviewerComponent } from '../imagepdfviewer/imagepdfviewer.component';
import { IMAGEVIEWER_CONFIG, ImageViewerConfig } from '@hallysonh/ngx-imageviewer';
import { DomSanitizer } from '@angular/platform-browser';



@Component({
  selector: 'app-inline-doc-viewer',
  templateUrl: './inline-doc-viewer.component.html',
  styleUrls: ['./inline-doc-viewer.component.css'],
  
})
export class InlineDocViewerComponent implements AfterViewInit, OnChanges, OnDestroy,AfterViewChecked {
  @Input() doc: Document
  @Input() ref
  @Input() index
  @Input() dimensions;
  downloadFileObjectUrl: any;
  
  @Output() addDocumentEvent = new EventEmitter();
  @Output() removeDocumentEvent = new EventEmitter<any>();
  @Output() uploadDocumentEvent = new EventEmitter<any>();
  @Output() updateDocumentEvent = new EventEmitter<any>();
  @Output() fileUploadPromptEmitter = new EventEmitter<any>();
  @Output() docNavigator = new EventEmitter<any>();

  private fileUploadPromptSubscription: Subscription;
  private onFileUploadPrompt: Subject<any>;
  dataFormat:any;
  bsModalRef: BsModalRef;
  
  @ViewChild('imagewrapper', { static: false }) wrapper: ElementRef;
  @ViewChild('viewerwrapper', { static: false }) viewerwrapper: ElementRef;
  @ViewChild('fileUploadButton', {static: false }) fileUploadButton: ElementRef<HTMLElement>;

  private _canvasDim = { width: 300, height: 600 };
  get canvasDim() {
    return this._canvasDim;
  }
  ngAfterViewInit() {
    this.updateCanvasDim();
    this.fileUploadPromptSubscription = this.onFileUploadPrompt.asObservable().subscribe(
      onPrompt => {
        let fileUploadButtonElement = this.fileUploadButton.nativeElement;
        fileUploadButtonElement.click();
      }
    );

    this.fileUploadPromptEmitter.emit(this.onFileUploadPrompt);
  }
  ngAfterViewChecked() {
    this.updateCanvasDim()
  }
  private updateCanvasDim() {
    const el = this.viewerwrapper && this.viewerwrapper.nativeElement ? this.viewerwrapper.nativeElement : null;
    const containerDiv = this.wrapper && this.wrapper.nativeElement ? this.wrapper.nativeElement:null;
    if (el && (containerDiv.offsetWidth !== this._canvasDim.width || containerDiv.offsetHeight !== this._canvasDim.height)) {
      const newDim = { width: containerDiv.offsetWidth - 2, height: 600 };
      setTimeout(() => this._canvasDim = newDim, 0);
    }
  }

  constructor(
    private modalService: BsModalService,
    private fileService: FileService,
    private sanitizer: DomSanitizer
  ) {
    this.onFileUploadPrompt = new Subject<any>();
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this.getDownloadFileObjectUrl();
  }

  ngOnDestroy() {
    this.fileUploadPromptEmitter.emit(null);

    if (this.fileUploadPromptSubscription && !this.fileUploadPromptSubscription.closed) {
      this.fileUploadPromptSubscription.unsubscribe();
    }
  }

  openModalWithComponent(doc,tempURL) {
    // console.log(document)
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: false,
      ignoreBackdropClick: true,
      initialState: {
        doc: doc,
        tempUrl:tempURL,
        title: 'Document Title'
      }
    };
    this.bsModalRef = this.modalService.show(ImagepdfviewerComponent, config);
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.event.subscribe(res => {
      // for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      //   this.modalService.hide(i);
      // }
      if (res.res ==2133) {
        this.onUpdateDocument(res.data);
      }
      const div =  document.querySelector('body');
        div.classList.remove('modal-open');
    });
  }

  onDocumentUploadForTask(event: Event, document: Document) {
    let map = {};
    map["event"] = event;
    map["document"] = document;
    this.uploadDocumentEvent.emit(map);
  }

  onNewDocumentAdd() {
    this.addDocumentEvent.emit();
  }

  onRemoveDocument(document: Document) {
    this.removeDocumentEvent.emit(document);
  }

  onUpdateDocument(document: Document) {
    this.updateDocumentEvent.emit(document);
  }

  getDownloadFileObjectUrl() {
    if (this.doc && this.doc.downloadFileUrl) {
      if (this.doc.downloadFileUrl.startsWith('http')) {
        this.downloadFileObjectUrl = this.doc.downloadFileUrl
      } else {
        this.fileService.download(this.doc.downloadFileUrl, true).subscribe(
          objectUrl => {
            if (objectUrl) {
              var type = (objectUrl.split(';')[0]).replace("data:","");
              if(type=='video/mp4'){
                this.dataFormat = type;
                fetch(objectUrl)
                .then(res => res.blob())
                .then(blob => {
                  var file = new Blob([blob], {type: type});
                  this.downloadFileObjectUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
                })
              }else if(type=='audio/mpeg'){
                this.dataFormat = type;
                fetch(objectUrl)
                .then(res => res.blob())
                .then(blob => {
                  var file = new Blob([blob], {type: type});
                  this.downloadFileObjectUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
                })
              }
              else if(type=='audio/x-wav'){
                this.dataFormat = type;
                fetch(objectUrl)
                .then(res => res.blob())
                .then(blob => {
                  var file = new Blob([blob], {type: type});
                  this.downloadFileObjectUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
                })
              }
              else if(type=='text/html'){
                this.dataFormat = type;
                fetch(objectUrl)
                .then(res => res.blob())
                .then(blob => {
                  this.downloadFileObjectUrl = blob['text']()
                })
              }
              else if (type == 'application/octet-stream' || type == 'application/pdf') {
                type = "application/pdf";
                this.dataFormat = type;
                fetch(objectUrl)
                .then(res => res.blob())
                .then(blob => {
                  var file = new Blob([blob], {type: type});
                  this.downloadFileObjectUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
                })
              }else{
                this.dataFormat = type;
                fetch(objectUrl)
                .then(res => res.blob())
                .then(blob => {
                  let FileNameParts = this.doc.userFileName.split(".");
                  let file;
                  if (FileNameParts.length>1) {
                    file = new File([blob], this.doc.userFileName.replace(/[^a-zA-Z0-9.]/g, ""),{ type: type })
                  } else{
                    file = new File([blob], "temp.jpeg",{ type: type })
                  }
                  this.downloadFileObjectUrl = (file);
                })
              }
              // this.downloadFileObjectUrl = objectUrl;
            }
          }
        );
      }
    }
  }

  downloadFile(doc: Document) {
    if (doc && doc.downloadFileUrl) {
      if (doc.downloadFileUrl.startsWith('http')) {
        window.open(doc.downloadFileUrl, '_blank');
      } else {
        this.fileService.download(doc.downloadFileUrl);
      }
    }
  }
  checkDocType(doc){
    let docArray =doc.userFileName.split(".");
    let allowedFIles = ['jpg','jpeg','png','pdf','jfif','pjpeg','pjp','gif','wav','mp3','mp4'];

    if(allowedFIles.indexOf(docArray[docArray.length-1]) == -1){
      return false;
    }
    return true;
  }
  navigateToDoc(direction){
    this.docNavigator.emit(direction)
  }
}
