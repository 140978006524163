import { Injectable } from '@angular/core';
import { NotificationService } from './shared.service';
import { Observable, Subject } from 'rxjs';
import { not } from '@angular/compiler/src/output/output_ast';
import { EnvironmentData } from './environments.service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private notifications = new Subject<any>();
  notificationObservable = this.notifications.asObservable();
  private dataFillModeFlag = new Subject<any>();
  dataFillModeObservable = this.dataFillModeFlag.asObservable();
  
  sendMail: boolean = false;
  sendedMail: boolean = false;
  message: boolean = false;

  constructor(
    private notificationService:NotificationService,
    private httpClient: HttpClient,
    private environmentData: EnvironmentData
  ) { }
  private httpHeaders = new HttpHeaders({'Content-Type': 'application/json' });
  toggleBlock:boolean=true;
  
  fetchNotifications(userId){
    this.notificationService.fetchPaginatedNotifications(0,15)
    .subscribe(notifications => {
      for(let not of notifications){
        if(not.entityType == "Task"){
          not.title = "Case Notification"
        }
      }
      this.notifications.next(notifications);
    // console.log(notifications)
    });
  }
  getCreditsRemaining(): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    const url = envData.rootUrl + envData.statemachineroot + envData.getCredits;
    this.httpClient.get<any[]>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response && response.body) {
            subject.next(response.body)
          } else {
            subject.error('Failed to get credits, please try again later');
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );
    return subject.asObservable();
  }
  sendSupportEmail(supportMessage: any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    //requestBody.clearCache = true;
    const subject = new Subject<any>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.supportMailEndpoint} `;
      this.httpClient.post<any>(
        url,
        supportMessage,
        {
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      ).subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );
    

    return subject.asObservable();
  }
  createSupportTicket(supportMessage:any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    //requestBody.clearCache = true;
    const subject = new Subject<any>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.ticketing} `;
      this.httpClient.post<any>(
        url,
        supportMessage,
        {
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      ).subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );
    

    return subject.asObservable();
  }
  updateSupportTicket(supportMessage:any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    //requestBody.clearCache = true;
    const subject = new Subject<any>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.ticketing} `;
      this.httpClient.put<any>(
        url,
        supportMessage,
        {
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      ).subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );
    

    return subject.asObservable();
  }
  addMessageToTicket(ticketid:string,supportMessage:any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    //requestBody.clearCache = true;
    const subject = new Subject<any>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.ticketing  + 'message/' + ticketid} `;
      this.httpClient.put<any>(
        url,
        supportMessage,
        {
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      ).subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );
    

    return subject.asObservable();
  }
  fetchTickets(pageNumber,noOfRecords): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    let url = envData.rootUrl + envData.statemachineroot + envData.ticketing + "page/" + pageNumber +","+ noOfRecords;
    this.httpClient.get<any[]>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response && response.body) {
            subject.next(response.body)
          } else {
            subject.error('Failed to get credits, please try again later');
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );
    return subject.asObservable();
  }
  fetchOpenTickets(pageNumber,noOfRecords): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    let url = envData.rootUrl + envData.statemachineroot + envData.ticketing + "open/" + pageNumber +","+ noOfRecords;
    this.httpClient.get<any[]>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response && response.body) {
            subject.next(response.body)
          } else {
            subject.error('Failed to get credits, please try again later');
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );
    return subject.asObservable();
  }
  fetchTicketById(ticketid): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    let url = envData.rootUrl + envData.statemachineroot + envData.ticketing + ticketid;
    this.httpClient.get<any[]>(
      url,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response && response.body) {
            subject.next(response.body)
          } else {
            subject.error('Failed to get credits, please try again later');
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );
    return subject.asObservable();
  }
  toggleDataFillMode(flag:boolean){
    this.dataFillModeFlag.next(flag)
  }
}
