import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicFormModalComponent } from '../dynamic-form-modal/dynamic-form-modal.component';



@Component({
  selector: 'entity-ref-modal',
  templateUrl: './entity-ref-modal.component.html',
})
export class EntityRefModalComponent implements OnInit{
  @Input() data: any;
  @Output() onCloseEmitted: EventEmitter<any> = new EventEmitter();
  @Output() onRefEntitySelected: EventEmitter<any> = new EventEmitter();

  entityRefField: any;
  p: number = 1;

  constructor() {
  }
  ngOnInit(): void {
    this.entityRefField = (this.data.data)
  }

  close(): void {
    // this.dialogRef.close();
    this.onCloseEmitted.emit(false)
  }

  openentityUpdateModal(item, index?): void {
    const initialState = {
      entityItem: item,
      title: item.label,
      index: index,
      modalId: new Date().getTime(),
    };
    // const dialogRef = this.dialog.open(DynamicFormModalComponent, {
    //   data: initialState
    // });
    this.onRefEntitySelected.emit(initialState)
  }

}