import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { User } from '../models/user.model';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UniversalUser } from '../service/shared.service';
import { CustomDashboard } from '../models/customDashboard.model';
import {EnvironmentData} from './environments.service';
import { CommonSearchModel } from '../models/shared.model';



@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private router: Router,
    private universalUser: UniversalUser,
    private httpClient: HttpClient,
    private environmentData: EnvironmentData
  ) { }


  fetch(configurationCode: string, dateRange: any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const body: any = {};
    body.params = {};
    body.params.startDate = dateRange.start.format('YYYY-MM-DD HH:mm:ss');
    body.params.endDate = dateRange.end.format('YYYY-MM-DD HH:mm:ss');

    const url = `${envData.rootUrl + envData.statemachineroot}/${configurationCode}`;

    this.httpClient.post<any>(
      url,
      body,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  dashboardData(body, getFullResult?: boolean): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    body["fromFlow"] = true;
    const url = `${envData.rootUrl +  envData.flowdashboard}`;
    // const url = "http://localhost:9093/api/integrations/dashboard";

    this.httpClient.post<any>(
      url,
      body,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          if (getFullResult) {
            subject.next(response.body);
          }
          else {
            subject.next(response.body.result);
          }
          
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  dashboard_summary(body): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    body['operation'] = "get_flow_dashboard_summary"
    body['dashboard'] = "flow"


    const url = `${envData.rootUrl + envData.flowdashboard}`;

    this.httpClient.post<any>(
      url,
      body,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body.result);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }


  task_statistics(body: any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    body['operation'] = "get_task_statistics"
    body['dashboard'] = "flow"


    const url = `${envData.rootUrl + envData.flowdashboard}`;

    this.httpClient.post<any>(
      url,
      body,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body.result);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

  agents_task_board(body: any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    body['operation'] = "get_agents_table"
    body['dashboard'] = "flow"


    const url = `${envData.rootUrl + envData.flowdashboard}`;

    this.httpClient.post<any>(
      url,
      body,
      {
        headers: this.httpHeaders,
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body) {
          subject.next(response.body.result);
        }
      },
      (err: HttpErrorResponse) => {
        // All errors are handled in ErrorInterceptor, no further handling required
        // Unless any specific action is to be taken on some error

        subject.error(err);
      }
    );

    return subject.asObservable();
  }

}

@Injectable()
export class CustomDashboardService {
  currentDashboard: CustomDashboard;
  constructor(private router: Router, private httpClient: HttpClient, private environmentData: EnvironmentData) { }
  // ADDITIONAL FUNCTIONS
  getSourceAggregationKeys(sourceType: string, sourceIdentifier: string, datatype: string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject();
    const url = `${envData.rootUrl + envData.interfaceService + envData.getFieldsbyDataType}`;
    const body = {
      "sourceType": sourceType,
      "sourceIdentifier": sourceIdentifier,
      "dataType": datatype
    }
    this.httpClient.post<any>(
      url,
      body,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          subject.next(response.body);
        },
        (error: HttpErrorResponse) => {
          subject.error(error);
        }
      );
    return subject.asObservable();
  }

  // COMPONENT
  getAllComponents(): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.interfaceService + envData.smCrudFunction}`;

    const operation = "READ_ALL";
    const body = {
      payload: {},
      operation: operation,
      collection: "customDashboardComponent"
    }

    this.httpClient.post<any>(
      url,
      body,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {

          subject.next(response.body);

        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getComponentListForDashboardId(dashboardId: string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.statemachineroot}` + "console/customdashboard/componentListByDashboardId/" + dashboardId;

    this.httpClient.get<any>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {

          subject.next(response.body);

        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }



  getComponentResult(companyId, payload: any, startTime?: string, endTime?: string, componentFilters?, dashboardId?: string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject();
    const url = `${envData.rootUrl + envData.statemachineroot}` + "console/customdashboard/component/result/" + dashboardId;
    if (startTime == undefined) {
      startTime = '2019-12-17T09:39:23.477Z'
    }
    if (endTime == undefined) {
      endTime = '2022-12-20T09:39:23.477Z'
    }
    const body = {
      "companyContext": {
        "companyId": companyId
      },
      "startTime": startTime,
      "endTime": endTime,
      "operation": "COMPONENT",
      "payload": payload
    }
    if (componentFilters && componentFilters.length > 0) {
      body['filters'] = componentFilters
    }
    this.httpClient.post<any>(
      url,
      body,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          subject.next(response.body);
        },
        (error: HttpErrorResponse) => {
          subject.error(error);
        }
      );
    return subject.asObservable();
  }

  // DASHBOARD
  getAllDashboards(): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.interfaceService + envData.smCrudFunction}`;

    const operation = "READ_ALL";
    const body = {
      payload: {},
      operation: operation,
      collection: "customDashboard"
    }

    this.httpClient.post<any>(
      url,
      body,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {

          subject.next(response.body);

        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  fetchAllDashboards(commonSearch: CommonSearchModel): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.statemachineroot}` +   "console/customdashboard/dashboard/search";

    this.httpClient.post<any>(
      url,
      commonSearch,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }
  getAllFilters(): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.interfaceService + envData.allFilters}`;

    const operation = "READ_ALL";
    const body = {
      "dataType": "text"
    }

    this.httpClient.post<any>(
      url,
      body,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {

          subject.next(response.body);

        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }
  setSharedDashboard(currentDashboard: any) {
    const envData = this.environmentData.getEnvData();
    this.currentDashboard = currentDashboard;
  }

  getSharedDashboard() {
    let tempSharedDashboard = null;
    if (this.currentDashboard) {
      tempSharedDashboard = JSON.parse(JSON.stringify(this.currentDashboard));
    }
    return tempSharedDashboard;
  }

  getDistinctValuesByField(fieldName: string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.interfaceService}` + "/api/custom_dashboard/distinctvalues";

    const body = {
      "fieldName": fieldName
    }

    this.httpClient.post<any>(
      url,
      body,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {

          subject.next(response.body);

        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getExcelFile(requestFilter: any,dashboardId : string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.statemachineroot}` + "interfaces/reports/analyticsreport/createExcelReport/" + dashboardId;
    const body = {
      "requestFilter": requestFilter,
      "timeZone" : Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    this.httpClient.post<any>(
      url,
      body,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {

          subject.next(response.body);

        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }
}