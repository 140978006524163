import { Component, Input, OnInit, TemplateRef, Output, EventEmitter } from '@angular/core';
import { OneViewService } from 'src/app/service/oneview.service';
import { Customer } from 'src/app/models/customer.model';
import { DatePipe } from '@angular/common';
import { CommunicationService } from 'src/app/service/shared.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatSnackBar } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-one-view-list',
  templateUrl: './one-view-list.component.html',
  styleUrls: ['./one-view-list.component.scss']
})
export class OneViewListComponent implements OnInit {
  @Input() detailsPage: boolean = false;
  @Input() selectedCustomerId: string;
  @Input() miniMode: boolean=false;
  @Output() selectedUserEvent = new EventEmitter<String[]>();
  @Output() selectedUserId = new EventEmitter<String>();
  pageNumber: number;
  fetchRecords: number;
  customerList: Customer[];
  customerCount: number;
  attributes: string[];
  searchText: string;
  selectedAttribute: string;
  searchOn: boolean = false;
  defaultSelected: any = {};
  selectedCustomers: Customer[] = [];
  customerSelectedForMerge: Customer;
  selectedCustomer: Customer;
  genders = ["Male", "Female", "Other"];
  timeZoneList = [
    {
      text: "LOCAL",
      value: "LOCAL"
    },
    {
      text: "UTC",
      value: "UTC"
    }
  ]
  newCustomerToggleFlag: boolean = false;
  dateRangeSelected: boolean = false;
  deleteCustomerMessage: string;

  bsModelDelete: BsModalRef;
  bsModalMerge: BsModalRef;

  defaultNotShow: boolean = true;

  constructor(private oneViewService: OneViewService,
    private datePipe: DatePipe,
    private communicationService: CommunicationService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService, private snackBar: MatSnackBar,
  ) {
    this.customerList = [];
    this.customerCount = 0;
    this.attributes = ["email", "mobileNumber", "phoneNumber", "name", "firstName", "lastName"]
    this.defaultSelected = {
      startDate: moment().subtract(6, 'days'),
      endDate: moment()
    };
    this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.route.snapshot.queryParamMap.get("mobileNumber")) {
          this.initFunctions();
        }
      }
    });
  }

  ngOnInit() {
    this.initFunctions()

  }
  initFunctions() {
    this.pageNumber = 0
    this.fetchRecords = 10;
    if (this.route.snapshot.queryParamMap.get("mobileNumber") != undefined) {
      this.searchText = this.route.snapshot.queryParamMap.get("mobileNumber")
      this.selectedAttribute = "mobileNumber";
      this.searchText = this.route.snapshot.queryParamMap.get("mobileNumber")
      this.searchCustomer()
    }else{
      this.getCustomerCount();
    }
  }
  getCustomerCount() {
    this.oneViewService.getCustomerCount()
      .subscribe(count => {
        if (count) {
          this.customerCount = count;
        }
        this.getCustomerList(1);
      });

  }

  getCustomerList(pageNumber, dateMap: any = null) {
    this.customerList =[];
    let escMap = {}
    let criteria = {}

    // if(dateMap == null  || dateMap == undefined){
    //   dateMap = {"$gte":this.defaultSelected.startDate.toISOString(),"$lte":this.defaultSelected.endDate.toISOString()};
    // }
    if (dateMap != null) {
      criteria["lastUpdatedAt"] = dateMap;
    }
    escMap['searchParams'] = [{ 'statusCd': 'ACTIVE' }];
    escMap["criteria"] = criteria;
    this.oneViewService.getCustomerList(pageNumber - 1, this.fetchRecords, escMap)
      .subscribe(customerResponse => {
        if (customerResponse) {
          if (this.detailsPage) {
            this.customerList = customerResponse.filter(({ _id }) => _id !== this.selectedCustomerId);
          }else{
            this.customerList = customerResponse
          }
        }

      });
  }

  changeSelection(attribute: string) {
    this.selectedAttribute = attribute;
  }

  removeOption() {
    this.selectedAttribute = null;
  }
  searchCustomeronEnter(keypressEvent) {
    if (keypressEvent.code == 'Enter') {
      if (this.searchText.length == 0) {
        this.removeSearch()
      }else{
        this.searchCustomer()
      }
      
    }
  }

  datesUpdated(range) {
    if (range.startDate != null && range.endDate != null) {
      this.dateRangeSelected = true;
      this.defaultNotShow = false;
      let fromDate = range.startDate.toISOString();
      let toDate = range.endDate.toISOString();
      let dateMap = { "$gte": fromDate, "$lte": toDate };
      this.getCustomerList(1, dateMap);


    }
  }

  onRemoveDateRange() {
    this.dateRangeSelected = false;
    this.defaultNotShow = true;
    // this.defaultSelected = {
    //   startDate:moment().subtract(6, 'days'),
    //   endDate: moment()
    // };
    this.getCustomerList(1)
  }

  searchCustomer() {
    let escMap = {}
    let criteria = {};
    this.customerList = [];
    criteria[this.selectedAttribute] = this.searchText;
    escMap["criteria"] = criteria;
    this.searchOn = true;
    if (this.selectedAttribute) {
      this.oneViewService.searchCustomer(escMap).subscribe(customerResponse => {
        this.customerList = customerResponse;
        this.customerCount = this.customerList.length;
      });
    }
    else {
      this.oneViewService.searchCustomerByText(this.searchText).subscribe(customerResponse => {
        this.customerList = customerResponse;
        this.customerCount = this.customerList.length;
      });
    }

  }

  removeSearch() {
    this.searchOn = false;
    this.searchText = '';
    this.selectedAttribute = '';
    this.getCustomerCount();
  }

  loadMore() {
    // this.pageNumber = this.pageNumber + 1;
    // this.getCustomerList()
  }

  getDateForView(date) {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }

  goToDetails(customer: Customer) {
    this.router.navigate(['/customer-details'], { queryParams: { customerId: customer._id } });
  }
  selectCustomer(customer: Customer){
    this.selectedUserId.emit(customer._id);
  }

  deleteCustomer() {
    if (this.selectedCustomers.length == 1) {
      this.oneViewService.deleteCustomer(this.selectedCustomers[0]._id).subscribe(customer => {

      });
      this.snackBar.open("Deleted successfully ", "Dismiss", {
        duration: 7000
      });
      this.closeDeleteModel();
      this.getCustomerCount();
    }
    else {
      let customerIds = Array.from(this.selectedCustomers, x => x._id)
      this.oneViewService.deleteMultipleCustomer(customerIds).subscribe(customer => {
        if (customer) {
          this.snackBar.open("Deleted successfully ", "Dismiss", {
            duration: 7000
          });
          this.closeDeleteModel();
          this.getCustomerCount();
        }
      });
    }
    this.selectedCustomers = [];
  }


  getName(customer: Customer): string {
    if (customer.name) {
      return customer.name
    }
    else if (customer.firstName && customer.middleName && customer.lastName) {
      return customer.firstName + " " + " " + customer.lastName;
    }
    else if (customer.firstName && customer.middleName) {
      return customer.firstName + " " + customer.middleName;
    }
    else if (customer.firstName && customer.lastName) {
      return customer.firstName + " " + customer.lastName;
    }
    else if (customer.firstName) {
      return customer.firstName;
    }
    else if (customer.lastName) {
      return customer.lastName;
    }
    else if (customer.email) {
      if (customer.email.includes("<")) {
        return customer.email.split('<')[0]
      }
      else {
        return customer.email.split('@')[0]
      }
    }
    else {
      return "(Name) Info Unavailable";
    }
  }

  selected(customer: Customer) {
    const index: number = this.selectedCustomers.indexOf(customer);
    if (index !== -1) {
      this.selectedCustomers.splice(index, 1);
    }
    else {
      this.selectedCustomers.push(customer);
    }
  }

  checkSelectedCustomer(customer: Customer) {
    const index: number = this.selectedCustomers.indexOf(customer);
    if (index !== -1 && this.selectedCustomers.length == 1) {
      return true;
    }
    else {
      return false;
    }
  }

  checkSelectedCustomerMerge(customer: Customer) {
    const index: number = this.selectedCustomers.indexOf(customer);
    if (index !== -1 && this.selectedCustomers.length > 1) {
      return true;
    }
    else {
      return false;
    }
  }

  onDeleteCustomer(templateRef: TemplateRef<any>, multiple: boolean = false) {
    if (!multiple) {
      this.deleteCustomerMessage = "Are you sure you want to delete the Customer?";
    }
    else {
      this.deleteCustomerMessage = "Are you sure you want to delete the selected Customers?";
    }
    this.bsModelDelete = this.modalService.show(templateRef, { class: 'modal-dialog modal-md modal-dialog-centered' });
  }

  mergeCustomer() {
    if (this.customerSelectedForMerge != null) {
      const index: number = this.selectedCustomers.indexOf(this.customerSelectedForMerge);
      if (index !== -1 && this.selectedCustomers.length > 1) {
        this.selectedCustomers.splice(index, 1);
      }
      let customerIds = Array.from(this.selectedCustomers, x => x._id);
      this.oneViewService.mergeCustomers(this.customerSelectedForMerge._id, customerIds).subscribe(customer => {
        if (customer) {
          this.customerSelectedForMerge = null;
          this.snackBar.open("Merged successfully ", "Dismiss", {
            duration: 7000
          });
          this.closeMergeModel();
          this.getCustomerCount();
          this.selectedCustomers = [];
        }
      });
    }
  }

  onMergeCustomers(templateRef: TemplateRef<any>, mergeCustomer: Customer) {
    this.customerSelectedForMerge = mergeCustomer;
    this.bsModalMerge = this.modalService.show(templateRef, { class: 'modal-dialog modal-md modal-dialog-centered' });
  }

  closeDeleteModel() {
    this.bsModelDelete.hide();
  }

  onMergeCustomer(templateRef: TemplateRef<any>,) {
    this.bsModalMerge = this.modalService.show(templateRef, { class: 'modal-dialog modal-md modal-dialog-centered' });
  }


  closeMergeModel() {
    this.customerSelectedForMerge = null;
    this.bsModalMerge.hide();
  }

  emitSelectedCustomers() {
    let customerIds = Array.from(this.selectedCustomers, x => x._id);
    this.selectedUserEvent.emit(customerIds);
  }
  newCustomerToggle() {
    this.newCustomerToggleFlag = true;
    this.selectedCustomer = new Customer();
  }
  createCustomer() {
    if (this.selectedCustomer.email) {
      this.oneViewService.updateCustomer(this.selectedCustomer).subscribe(customer => {
        if (customer) {
          this.selectedCustomer = customer;
          this.newCustomerToggleFlag = false;
          this.getCustomerCount();
        }
      });
    } else {
      this.snackBar.open("Please enter the email for the customer", "Dismiss", {
        duration: 5000
      });
    }

  }
  formFullName() {
    let tmp = "";
    if (this.selectedCustomer.firstName != null && this.selectedCustomer.firstName != "") {
      tmp += this.selectedCustomer.firstName;
    }
    if (this.selectedCustomer.middleName != null && this.selectedCustomer.middleName != "") {
      tmp = tmp + " " + this.selectedCustomer.middleName;
    }
    if (this.selectedCustomer.lastName != null && this.selectedCustomer.lastName != "") {
      tmp = tmp + " " + this.selectedCustomer.lastName;
    }
    this.selectedCustomer.name = tmp
  }
}
