import { Component, OnInit, OnDestroy, Input, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment';


@Component({
    selector: 'api-time-counter',
    templateUrl: './timecounter.component.html'
})
export class TimeCounterComponent implements OnInit, OnChanges {
    constructor(){}
    @Input()
    refDate: any;

    @Input()
    type: 'COUNTER' | 'TIMER'

    @Input()
    timerSecondsCount: any;

    @Output()
    onTimerElapsed: EventEmitter<any> = new EventEmitter<any>();

    enableHours = false;

    timerInterval: any;

    timerString: string = '';
    days: string = '00';
    hours: string = '00';
    minutes: string = '00';
    seconds: string = '00';

    ngOnChanges(changes: SimpleChanges) {
        // this.ngOnInit();
    }

    ngOnInit() {
        console.log(this.timerSecondsCount)
        if (this.type && this.type == 'TIMER') {
            if (!this.timerSecondsCount) {
                this.timerSecondsCount = 10;
            }

            if (this.timerSecondsCount > 3600) {
                this.enableHours = true;
            }

            this.calculateTimer();

            this.timerInterval = setInterval(() => {
                if (this.timerSecondsCount <= 0) {
                    return;
                }
                
                this.timerSecondsCount--;
                this.calculateTimer();
            }, 1000);
        } else {
            if (!this.refDate) {
                return "-";
            } else {
                setInterval(() => {
                    this.calculateRemainingTime(this.pyToJsDate(this.refDate) || moment(new Date()));
                }, 1000);
                // this.calculateRemainingTime(this.pyToJsDate(this.refDate));
                // console.log((), this.refDate)
            }
        }
    }

    pyToJsDate(dateString) {
        if (typeof dateString == 'object') {
            let tempDate = new Date();
            tempDate.setFullYear(dateString[0])
            tempDate.setMonth(dateString[1] - 1)
            tempDate.setDate(dateString[2])
            tempDate.setHours(dateString[3]+5)
            tempDate.setMinutes(dateString[4]+30)
            tempDate.setSeconds(dateString[5])
            return moment(tempDate);
        } else {
            return moment(new Date(+dateString));
        }
    }

    calculateRemainingTime(refDate: any) {
        let currentDate = moment(new Date());
        
        if (currentDate.diff(refDate, 'hours')) {
            this.enableHours = true;
        }
        this.hours = this.getDoubleDigitNumberString(currentDate.diff(refDate, 'hours') % 24);
        this.minutes = this.getDoubleDigitNumberString(currentDate.diff(refDate, 'minutes') % 60);
        this.seconds = this.getDoubleDigitNumberString(currentDate.diff(refDate, 'seconds') % 60);
    }

    calculateTimer() {
        const isoString = new Date(this.timerSecondsCount * 1000).toISOString();

        if (this.enableHours) {
            this.hours = isoString.substring(11, 13);
            this.minutes = isoString.substring(14, 16);
            this.seconds = isoString.substring(17, 19);
        } else {
            this.minutes = isoString.substring(14, 16);
            this.seconds = isoString.substring(17, 19);
        }

        if (this.timerInterval && (this.timerSecondsCount <= 0)) {
            clearInterval(this.timerInterval);
            this.onTimerElapsed.emit();
        }
    }

    getDoubleDigitNumberString(num: number): string {
        if (num) {
            if (num > 9) {
                return num.toString();
            } else {
                return "0" + num.toString();
            }
        }

        return '00';
    }
}
