import { v4 as uuid } from 'uuid';
import { Component, OnInit, OnDestroy, NgZone, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { REACTIVE_FORM_DIRECTIVES } from '@angular/forms'
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataSharingService, UniversalUser, CommunicationService } from '../../service/shared.service';
import { DataModelObject, FileService, EntiySharingService } from '../../service/shared.service';
//import { DataModelService } from '../../../../services/setup.service';
import { EntityService } from '../../service/entity.service';
import { DataModel, Field, ValidatorInstance, ExtractorInstance, Entity } from '../../models/datamodel.model';
import { CommonSearchModel } from '../../models/shared.model';
import { DataModelService } from '../../service/datamodel.service'
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ImagepdfviewerComponent } from '../../shared/imagepdfviewer/imagepdfviewer.component';
import { commonKeys } from '../../models/constants';
import { IMyDpOptions } from 'mydatepicker';
import { first, map } from 'rxjs/operators';
import { EnvironmentData } from 'src/app/service/environments.service';
import { AccessControlResponse, AccessControlRequest } from 'src/app/models/helper.model';
import { AccessControlService } from 'src/app/service/helper.service';
import { Location } from '@angular/common';
import { OneViewService } from 'src/app/service/oneview.service';
import { NotesForEntityComponent } from 'src/app/shared/notes-for-entity/notes-for-entity.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-add-activities-elements',
  templateUrl: './add-activities-elements.component.html',
  styleUrls: ['./add-activities-elements.component.css']
})
export class AddActivitiesElementsComponent implements OnInit, OnChanges, OnDestroy {
  piblockApiLoaded: boolean = false;
  piBlocksDivId: string;
  piBlocksCSSId: string;
  baseURL: string;
  userInfo: any;
  companyId: string;
  userAccessControlResponse: AccessControlResponse;  
  navigatedEvent: boolean = false;
  bsModalRef: BsModalRef;
  navigationSubscription: Subscription;
  //load from oneview
  entityId: string;
  entityName: string;
  entityLabel: string;
  customerId: string;
  selectedDataModel: any;
  dataModelName: string;
  onSamePage: boolean = true;
  currentPanel: string = '';
  viewEntity: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public communicationService: CommunicationService,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private universalUser: UniversalUser,
    private modalService: BsModalService,
    private environmentData: EnvironmentData,
    private accessControlService: AccessControlService,
    private _location: Location,
    private oneViewService:OneViewService,
    private entityService: EntityService,
    private dataModelService: DataModelService,
    private titleService: Title
  ) {
    this.titleService.setTitle("Forms - Wizard Mode")
    this.piBlocksDivId = "addActivitiesV2_" + this.generateObjectId();
    this.piBlocksCSSId = "addActivitiesV2CSS_" + this.generateObjectId();

    this.baseURL = this.environmentData.getEnvData().rootUrl;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.navigatedEvent = true;
        this.intFunctions();
      }
    });

  }
  ngOnInit() {
    if (!this.navigatedEvent) {
      this.intFunctions();
    }
  }

  ngOnDestroy() {
    this.onSamePage = false;
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    let elementJsFileBlock = document.getElementById(this.piBlocksDivId);
    if (elementJsFileBlock) {
      document.head.removeChild(elementJsFileBlock);
    }

    this.unloadPiBlocksCss();
  }
  intFunctions() {
    this.userInfo = this.universalUser.getUser();
    const envData = this.environmentData.getEnvData();
    let checker = document.createElement("pi-blocks").constructor !== HTMLElement;
    if (!checker) {
      this.loadScript("assets/element-pi-blocks.js");
      this.loadPiBlocksCss("assets/element-pi-blocks.css");
    } else {
      this.piblockApiLoaded = true;
    }
    this.companyId = this.universalUser.getUser().companyId;

    this.route.params.subscribe(params => {
      if (params["entityId"]) {
        this.entityId = params["entityId"];
        this.entityName = params["entityName"];
        let datamodel = this.communicationService.getDataModel();
        if(datamodel)
        this.entityLabel = datamodel['display'];
      }
    })
    this.route.queryParams.subscribe(queryparams => {
      if (queryparams["entityId"]) {
        this.communicationService.setAssocEntityDetails(null);
        this.entityId = queryparams["entityId"];
        this.entityName = queryparams["entityName"];
        this.entityLabel = queryparams["entityLabel"];
        this.customerId = queryparams["customerId"];
      }
    })
    if(this.entityId=='new')
    this.entityId = null;
    
    this.dataModelName = this.entityName;
    if(this.entityName && this.entityId){
      this.titleService.setTitle("Forms - " + this.entityName + "- Wizard Mode");
      this.getAssociatedEntity();
    }
    let accessControlReq = new AccessControlRequest();
    accessControlReq.componentType = "ENTITY";
    accessControlReq.componentName = this.entityName;
    accessControlReq.userId = this.universalUser.getUser()._id;
    this.accessControlService.getUserAccess(accessControlReq)
      .subscribe(
        accessControl => {
          this.userAccessControlResponse = accessControl;
        },
        error => {

        }
      );
    this.dataModelService.getDataModelByName(this.dataModelName).subscribe(
      response=>{
        this.selectedDataModel=(response)
        this.entityLabel = response.label
      }
    )
  }
  getAssociatedEntity() {
    this.entityService.getEntity(this.entityName, this.entityId).subscribe(entity => {
      if (entity) {
        this.viewEntity = entity;
        if(this.viewEntity.customerId){
          this.togglePanel("customer")
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
  }

  onChanges(): void {
  }



  goBack() {
    if (this.customerId) {
      this.router.navigate(['/customer-details'], { queryParams: { customerId: this.customerId } });

    }
    else {
      // this.router.navigateByUrl("/activities");
      this._location.back();
    }
  }

  onSaveOrSubmit(entity: any) {
    if (entity["type"] == 'onsubmit') {
      this.snackBar.open(this.selectedDataModel.label.toString() + ' has been submitted successfully', "Close", {
        duration: 5000
      })
      // this.router.navigateByUrl("/activities");
    }
    else if (entity["type"] == 'onsave') {
      this.snackBar.open(this.selectedDataModel.label.toString() + ' has been saved successfully', "Close", {
        duration: 5000
      })
    }
  }


  loadScript(scriptURL: string) {
    return new Promise((resolve, reject) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = this.piBlocksDivId;
      script.src = scriptURL;
      script.onload = () => {
        this.piblockApiLoaded = true;
        resolve({ script: scriptURL, loaded: true, status: 'Loaded' });
      };
      script.onerror = (error: any) => resolve({ script: scriptURL, loaded: false, status: 'Loaded' });
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }

  loadPiBlocksCss(linkUrl: string): void {
    const link = document.createElement('link');
    link.id = this.piBlocksCSSId;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = linkUrl;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  unloadPiBlocksCss(): void {
    let elementCSSLink = document.getElementById(this.piBlocksCSSId);
    if (elementCSSLink) {
      document.head.removeChild(elementCSSLink);
    }
  }
  generateObjectId() {
    let timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function() {
      return (Math.random() * 16 | 0).toString(16);
    }).toLowerCase();
  }
  selectedCustomerId: string = '';
  updateCustomerForDatamodel(customerId) {
    this.selectedDataModel.customerId = customerId;
    this.selectedCustomerId = customerId;
    let payload = {
      "customerId": customerId,
      "interactionType": "entity",
      "interactionMode": "Manual",
      "interactionId": this.entityId,
      "interactionName": this.selectedDataModel.name
    }
    this.oneViewService.linkCustomer(payload).subscribe(
      response=>{
        this.snackBar.open('Customer Linked Successfully', "Close", {
          duration: 2000
        })
      },
      error=>{
        console.error(error)
      }
    );
  }
  togglePanel(panelName) {
    if (this.currentPanel == panelName) {
      this.currentPanel = '';
    } else {
      this.currentPanel = panelName;
    }
  }
  openModalForNotes() {
    const initialState = {
      entityId: this.entityId,
      datamodelId: this.selectedDataModel._id,
      datamodelName: this.dataModelName
    };
    this.bsModalRef = this.modalService.show(NotesForEntityComponent, { initialState });
    this.bsModalRef.setClass('modal-lg-full')
    this.bsModalRef.content.closeBtnName = 'Close';
  }
}
