import { Component, OnInit, OnDestroy, Input, SimpleChanges, OnChanges } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';


@Component({
    selector: 'date-countdown',
    templateUrl: './datecountdown.component.html'
  })
  export class DateCountdownComponent implements OnInit, OnDestroy, OnChanges {

    @Input()
    referenceTime: number;

    @Input()
    refreshInterval: number = 1000 * 60;

    @Input()
    hideMinutes: boolean;

    currentTime: number;
    countdown: Subscription;

    ngOnChanges(changes: SimpleChanges) {
        this.ngOnInit();
    }
    
    ngOnDestroy(): void {
        if(this.countdown) {
            this.countdown.unsubscribe();
        }
    }

    ngOnInit() {
        this.currentTime = this.getCurrentTime();
        this.startCountdown();
    }

    getCurrentTime() {
        return new Date().getTime()
    }

    startCountdown() {
        if(this.countdown) {
            this.countdown.unsubscribe();
        }

        if(!this.refreshInterval) {
            return;
        }

        this.countdown = interval(this.refreshInterval).subscribe(() => {
            this.currentTime = this.getCurrentTime();
        })
    }
  }
  