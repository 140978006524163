import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild,  ElementRef, AfterViewInit } from '@angular/core';
import { State, TaskFilter, Task, SearchableDataPointResponse } from 'src/app/models/tasks.model';
import { FetchUserService } from 'src/app/service/userhierarchy.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as moment from 'moment';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { UserHierarchy, User } from 'src/app/models/user.model';
import { CommunicationService, TagsService } from 'src/app/service/shared.service';
import { EntityService } from 'src/app/service/entity.service';
import { CommonSearchModel } from 'src/app/models/shared.model';

@Component({
  selector: 'app-task-listing-full',
  templateUrl: './task-listing-full.component.html',
  styleUrls: ['./task-listing-full.component.scss']
})
export class TaskListingFullComponent implements OnInit, OnChanges, AfterViewInit {
  dropdownList = [];
  dropdownSettingsMultiple: IDropdownSettings = {};
  dropdownSettingsSingle: IDropdownSettings = {};
  entityLookupSettingsMultiple: IDropdownSettings = {};

  @Input() taskList: Task[] = [];
  assignedStates: any
  @Input() userName: any;
  @Input() processOptions: any = [];
  @Input() searchableDataPoints: SearchableDataPointResponse[] = [];
  searchableDataPointMap: Map<string, SearchableDataPointResponse> = new Map<string, SearchableDataPointResponse>();
  dataPointParentChildMap: Map<string, SearchableDataPointResponse[]> = new Map<string, SearchableDataPointResponse[]>();
  @Input() filterGroups: any = [];
  @Input() taskTags: any = [];
  @Input() taskFilter: TaskFilter;
  @Input() errorMsg: string;
  @Input() userOutput: UserHierarchy[];
  @Input() peersOutput: any[] = [];
  @Input() selectedFolderName: string; null;
  @Input() isSeparate: boolean; false;
  @Input() totalCount: number;
  @Input() assignedTaskPageNumber : number;
  @Input() unassignedTaskPageNumber : number;
  @Input() assignedTaskPageNumberTeam : number;
  @Input() flaggedTaskPageNumber : number;
  @Input() fetchRecords : number;

  @Output() selectedTask = new EventEmitter<Task>(false);
  @Output() loadMoreTasks = new EventEmitter<boolean>(false);
  @Output() emitSelectedBulkTasks = new EventEmitter<any>(false);
  @Output() closeFilter = new EventEmitter<any>(false);
  @Output() filterSubmit = new EventEmitter<any>(false);
  @Output() pageChange = new EventEmitter<any>();
  @Output() clearFilter = new EventEmitter<any>(false);
  @Output() userEvent = new EventEmitter<User>();
  @Output() bulkAllocate = new EventEmitter<string>();
  @Output() bulkUpdate = new EventEmitter<any>();

  recordsMessage: string = "";
  displayedColumns = ['task'];
  selectedBulk: State[] = [];
  selectAllForBulkAllocation: boolean;
  taskOperation:string;
  selectedCaseType:string = null;
  selectedCaseStage:string = null;
  updateAllowed:boolean;
  taskStatus:string;
  taskRemarks:string;
  statusList:any[];
  allTaskTags: any[] = [];


  selectedProcesses: any[];
  selectedGroups: any[];
  selectedTags: any[];
  selectedUsers: any[];
  selectedPriorities: any[];
  selectedSourceRefTypes: any[];

  allowUsersToAssignOption: any[] = [];
  tempUser: User;
  pageNo:number = 1;
  FOLDER_OPTION_ALL = "ALL";
  FOLDER_OPTION_GROUP = "GROUP";
  FOLDER_OPTION_ASSIGNED_ACTIVE = "ASSIGNED_ACTIVE";
  FOLDER_OPTION_ASSIGNED_CLOSED = "ASSIGNED_CLOSED";
  FOLDER_OPTION_TEAM_ACTIVE = "TEAM_ACTIVE";
  FOLDER_OPTION_TEAM_CLOSED = "TEAM_CLOSED";

  STATUS_LIST = [{label:"Open",value:"Open"},{label:"Pending",value:"Pending"},{label:"Closed",value:"Closed"},{label:"Resolved",value:"Resolved"},{label:"Waiting",value:"Waiting"}]
  FOLDER_STATUS_LIST = [{label:"Active",value:"ACTIVE"},{label:"Closed",value:"CLOSED"}];
  ALLOCATION_USER_TYPES = ["Team", "Peer"];
  TASK_OPERATIONS:any= [{ label: "Allocate Tasks", value: "Allocate" },
  { label: "Reserve Task and move to self bucket", value: "Reserve" },
  { label: "Submit and Complete", value: "Update" }]
  allocationUsers: any[] = [];
  allocationUserType: string = "Team";

  SLA_RANGE_COMPARISION_SOURCE: any = [
    { value: "GREATER_THAN", label: "Greater than" },
    { value: "LESS_THAN", label: "Less than" }
  ]
  SLA_UNIT_TYPES: any = [
    { value: "MINUTE", label: "Minute" },
    { value: "HOUR", label: "Hour" },
    { value: "DAY", label: "Day" },
    { value: "WEEK", label: "Week" },
    { value: "YEAR", label: "Year" }
  ]
  TASKS_SORT_COMBO = [
      { value: "startTime", label: "Created <i class='fa fa-arrow-down arrowSortRight'></i>", dir: -1 },
      { value: "startTime", label: "Created <i class='fa fa-arrow-up arrowSortRight'></i>", dir: 1 },
      { value: "slaEndTime", label: "Time Remaining <i class='fa fa-arrow-down arrowSortRight'></i>", dir: -1 },
      { value: "slaEndTime", label: "Time Remaining <i class='fa fa-arrow-up arrowSortRight'></i>", dir: 1 },
  ];
  TASKS_SORT_BY = [
    { value: "startTime", label: "Start Time" },
    { value: "slaEndTime", label: "SLA Time" }
  ];

  TASK_SORT_DIR = [
    { value: -1, label: "Descending" },
    { value: 1, label: "Ascending" }
  ]

  TASK_PRIORTIES = [
    { label: "High", value: 3 },
    { label: "Meduim", value: 2 },
    { label: "Low", value: 1 }
  ]

  SOURCE_LIST = [
    { label: "Email", value: "Email" },
    { label: "Chatbot", value: "Conversation" },
    { label: "Forms", value: "Entity" }
  ]

  FOLDER_SOURCE_LIST = [ 
    { value: "Personal", label: "Self" },
    { value: "Group", label: "Group" }
  ]

  advanceFilterOption: boolean;

  selectedDateRange: any = null;
  defaultNotShow:boolean = false
  constructor(
    private snackBar: MatSnackBar,
    private communicationService: CommunicationService,
    private tagService: TagsService,
    private entityService: EntityService,
    private fetchUserService: FetchUserService
  ) { }

  @ViewChild('loadMore', { static: true }) loadMoreDiv: ElementRef;
  ngOnInit() {
    this.getOperationList();
    this.getAllTaskTags();
    this.getPeers();
    if (this.taskFilter) {
      if (this.taskFilter.pageNo)
      {
        this.pageNo = this.taskFilter.pageNo + 1;
      }
      if(this.taskFilter.fromTime != null && this.taskFilter.toTime != null){
        if(this.taskFilter.fromTime +'' != 'Invalid Date' || this.taskFilter.toTime +'' != 'Invalid Date'){
          this.selectedDateRange = {
            startDate: this.taskFilter.fromTime,
            endDate: this.taskFilter.toTime
          }
          this.defaultNotShow = false;
        }else{
          this.defaultNotShow = false;
          this.selectedDateRange = null
        }
      }
    }
    if (this.userOutput && this.userOutput.length > 0) {
      this.allocationUserType = "Team";
      this.allocationUsers = this.userOutput;
    }
    else if (this.peersOutput && this.peersOutput.length > 0) {
      this.allocationUserType = "Peer";
      this.allocationUsers = this.peersOutput;
    }
    this.allowUsersToAssignOption = [];
    if (this.userOutput && this.userOutput.length > 0) {
      this.FOLDER_SOURCE_LIST.push({ value: "Team", label: "Team" });
      for (let heirarchy of this.userOutput) {
        this.allowUsersToAssignOption.push({ label: heirarchy.userName, value: heirarchy.userId });
      }
    }
    else {
      this.FOLDER_SOURCE_LIST.push({ value: "Peers", label: "Team" });
    }
    if (this.peersOutput && this.peersOutput.length > 0) {
      for (let heirarchy of this.peersOutput) {
        this.allowUsersToAssignOption.push({ label: heirarchy.userName, value: heirarchy.userId });
      }
    }
    if ((!this.selectedUsers || this.selectedUsers.length == 0) && this.taskFilter.assignedUserIds && this.taskFilter.assignedUserIds.length > 0) {
      this.selectedUsers = [];
      for (let user of this.allowUsersToAssignOption) {
        if (this.taskFilter.assignedUserIds.indexOf(user.value) != -1) {
          this.selectedUsers.push(user);
        }
      }
    }
    if ((!this.selectedProcesses || this.selectedProcesses.length == 0) && this.taskFilter.processes && this.taskFilter.processes.length > 0) {
      this.selectedProcesses = [];
      for (let proc of this.processOptions) {
        if (this.taskFilter.processes.indexOf(proc.value) != -1) {
          this.selectedProcesses.push(proc);
        }
      }
    }
    if ((!this.selectedTags || this.selectedTags.length == 0) && this.taskFilter.tags && this.taskFilter.tags.length > 0) {
      this.selectedTags = [];
      for (let tag of this.taskTags) {
        if (this.taskFilter.tags.indexOf(tag.value) != -1) {
          this.selectedTags.push(tag);
        }
      }
    }
    if ((!this.selectedGroups || this.selectedGroups.length == 0) && this.taskFilter.groups && this.taskFilter.groups.length > 0) {
      this.selectedGroups = [];
      for (let group of this.filterGroups) {
        if (this.taskFilter.groups.indexOf(group.value) != -1) {
          this.selectedGroups.push(group);
        }
      }
    }

    if ((!this.selectedPriorities || this.selectedPriorities.length ==0) && this.taskFilter.priorities && this.taskFilter.priorities.length > 0) {
      this.selectedPriorities = [];
      for(let priority of this.TASK_PRIORTIES) {
        if (this.taskFilter.priorities.indexOf(priority.value) != -1) {
          this.selectedPriorities.push(priority);
        }
      }
    }
    if ((!this.selectedSourceRefTypes || this.selectedSourceRefTypes.length == 0) && this.taskFilter.sourceRefTypes && this.taskFilter.sourceRefTypes.length > 0) {
      this.selectedSourceRefTypes = [];
      for (let source of this.SOURCE_LIST) {
        if (this.taskFilter.sourceRefTypes.indexOf(source.value) != -1) {
          this.selectedSourceRefTypes.push(source);
        }
      }
    }
    // Load Processes
    this.dropdownList = [];

    this.dropdownSettingsMultiple = {
      singleSelection: false,
      idField: 'value',
      textField: 'label',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownSettingsSingle = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.entityLookupSettingsMultiple = {
      singleSelection: false,
      idField: 'labelValue',
      textField: 'labelCode',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  getAllTaskTags() {
    this.allTaskTags = [];
    this.tagService.getAllTags()
      .subscribe (
        taskTags => {
          if (taskTags) {
            for (let taskTag of taskTags) {
              for (let key of Object.keys(taskTag.tags)) {
                this.allTaskTags.push(key);
              }
            }
            
          }
        }
      )
  }

  getOperationList(){
    if(this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE){

      this.TASK_OPERATIONS = [{ label: "Allocate Tasks", value: "Allocate" },
      { label: "Submit and Close", value: "Update" }]
    }
  }
  getPeers() {
    this.fetchUserService.getPeers()
      .subscribe(peers => {
        if (peers && peers.length > 0) {
          this.peersOutput = peers;
        }
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.taskList) {
      this.assignedStates = changes.taskList.currentValue;
    }
    if(changes.userOutput && this.allocationUserType == "Team"){
      this.userOutput = changes.userOutput.currentValue;
      this.allocationUsers = this.userOutput;
    }
    if(changes.searchableDataPoints){
      this.searchableDataPoints = changes.searchableDataPoints.currentValue;
      this.populateDynamicDropdownInSearchableDataPoints();
    }
    if(changes.peersOutput && this.allocationUserType == "Peer"){
      this.peersOutput = changes.peersOutput.currentValue;
      this.allocationUsers = this.peersOutput;
    }
    if(changes.totalCount){
      this.totalCount = changes.totalCount.currentValue;
    }
  }

  ngAfterViewInit() {
    this.getSearchableDataPoints();
    this.createMapOfSearchableDataPoints();
  }

  selectTask(state) {
    this.selectedTask.emit(state)
  }

  selectBulkAllocation(event) {
    if (this.taskList) {
      for (let task of this.taskList) {
        if(!this.checkifInUpdate(task)){
          task.checkForAllocate = event.target.checked;
        }
      }
    }


    this.assignedStates.data = this.taskList;
    // this.assignedStates = new MatTableDataSource(this.taskList);
    // this.assignedStates = changes.taskList.currentValue;
  }

  selectForBulk(event, state?: State) {
    if (state) {
      let pos = this.selectedBulk.map(item => item._id).indexOf(state._id);
      if (pos == -1) {
        this.selectedBulk.push(state);
      } else {
        this.selectedBulk.splice(pos, 1)
      }
    } else {
      if (event.target.checked == true) {
        this.selectedBulk = this.assignedStates.data;
      } else {
        this.selectedBulk = [];
      }
    }
  }

  allocateBulk() {
    const bulkAllocationSelections: Task[] = [];
    for (let task of this.taskList) {
      if (task.checkForAllocate)
        bulkAllocationSelections.push(task);
    }
    this.emitSelectedBulkTasks.emit(bulkAllocationSelections);
  }
  checkifAddedToBulk(state: State) {
    let pos = this.selectedBulk.map(item => item._id).indexOf(state._id);
    if (pos == -1) {
      return false;
    } else {
      return true;
    }
  }
  noRecordsFound() {
    if (this.assignedStates && this.assignedStates.length == 0) {
      this.recordsMessage = "No Records Found";
    }
    return this.recordsMessage;
  }
  hideAfterAt(str) {
    if (str) {
      return str.split("@")[0]
    }
    return str
  }
  applyFilter(filterValue: string) {
    this.assignedStates.filter = filterValue.trim().toLowerCase();
  }
  loadMore() {
    this.loadMoreTasks.emit(true)
  }
  pyToJsDate(daetString) {
    let tempDate = new Date();
    tempDate.setDate(daetString[2])
    tempDate.setFullYear(daetString[0])
    tempDate.setMonth(daetString[1] - 1)
    tempDate.setHours(daetString[3])
    tempDate.setMinutes(daetString[4])
    tempDate.setSeconds(daetString[5])
    tempDate.setMilliseconds(daetString[6])
    return (tempDate)
  }

  datesUpdated(event) {
    let tmp = JSON.parse(JSON.stringify(event))
    this.defaultNotShow = false;
    if(tmp.startDate!=null){
      this.taskFilter.fromTime = new Date(moment(tmp.startDate).format());
    }
    if(tmp.endDate!=null){
      this.taskFilter.toTime = new Date(moment(tmp.endDate).format());
    }
  }
  closeFilterFunction() {
    this.closeFilter.emit(true);
  }

  onClearFilter() {
    this.selectedProcesses = [];
    this.selectedGroups = [];
    this.selectedUsers = [];
    this.selectedPriorities = [];
    this.selectedSourceRefTypes = [];
    this.selectedDateRange = null;
    this.defaultNotShow = true;
    this.clearFilter.emit(true);
  }

  onFilterSubmit() {
    if (this.taskFilter.slaRangeComparision && !this.taskFilter.slaUnitType) {
      this.openSnackBar("error", "Select SLA Unit Type", "Close");
      return;
    }
    if (this.taskFilter.slaUnitType && !this.taskFilter.slaRangeComparision) {
      this.openSnackBar("error", "Select SLA Comparision", "Close");
      return;
    }
    if (this.selectedProcesses && this.selectedProcesses.length > 0) {
      const processes = [];
      for (let proc of this.selectedProcesses) {
        processes.push(proc.value);
      }
      this.taskFilter.processes = processes;
    }
    else {
      this.taskFilter.processes = [];
    }
    if (this.selectedGroups && this.selectedGroups.length > 0) {
      const groups = [];
      for (let group of this.selectedGroups) {
        groups.push(group.value)
      }
      this.taskFilter.groups = groups;
    }
    else {
      this.taskFilter.groups = [];
    }
    if (this.selectedPriorities && this.selectedPriorities.length > 0) {
      const priorities = [];
      for(let priority of this.selectedPriorities) {
        priorities.push(priority.value);
      }
      this.taskFilter.priorities = priorities;
    }
    if (this.selectedSourceRefTypes && this.selectedSourceRefTypes.length > 0) {
      const sourceRefTypes = [];
      for(let source of this.selectedSourceRefTypes) {
        sourceRefTypes.push(source.value);
      }
      this.taskFilter.sourceRefTypes = sourceRefTypes;
    }
    if (this.selectedUsers && this.selectedUsers.length > 0) {
      const userIds = [];
      for (let user of this.selectedUsers) {
        userIds.push(user.value);
      }
      this.taskFilter.assignedUserIds = userIds;
    }
    if (this.selectedTags && this.selectedTags.length > 0) {
      const tags = [];
      for (let tag of this.selectedTags) {
        tags.push(tag.value);
      }
      this.taskFilter.tags = tags;
    }
    // this.taskFilter.pageNo = 0;
    this.filterSubmit.emit(true);
  }
  searchAltered(event){
    if(event.target.value.length == 0){
      this.onClearFilter()
    }
  }
  openSnackBar(type, message, action) {
    let config: MatSnackBarConfig = new MatSnackBarConfig();
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.duration = 6500;
    if (type === 'error') {
      config.panelClass = ['errorMsg']
    }
    else {
      config.panelClass = ['success']
    }
    this.snackBar.open(message, action, config)
  }

  isBulkAllocationAllowed() {
    if (this.taskList) {
      for (let task of this.taskList) {
        if (task.checkForAllocate)
          return true;
      }
    }
    return false;
  }
  closeSection(){
    if (this.taskList) {
      for (let task of this.taskList) {
        task.checkForAllocate = false;
      }
      
    }
  }
  selectUserType(type: String) {
    if (type == "Team") {
      this.allocationUsers = this.userOutput;
    }
    else if (type == "Peer") {
      this.allocationUsers = this.peersOutput;
    }
  }

  sendUser(user: User) {
    this.userEvent.emit(user)
  }

  onBulkAllocation(type:string) {
    if(type == "Allocate"){
      if (this.allocationUserType == null || this.allocationUserType == undefined) {
        this.openSnackBar("error", "Select user type", "Close");
        return;
      }
      if (!this.tempUser || this.tempUser == null) {
        this.openSnackBar("error", "select member to which tasks need to be allocate", "Close");
        return;
      }
    }
    this.bulkAllocate.emit(type);
  }

  onBulkUpdate(){
    if (!this.taskRemarks) {
      this.openSnackBar("error", "Please add remarks", "Close");
      return;
    }
    let map = {};
    map['taskStatus'] = this.taskStatus;
    map['taskRemarks'] = this.taskRemarks;
    this.bulkUpdate.emit(map);
  }

  isAllocationAllow() {
    if (this.selectedFolderName == null)
      return false;
    return this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE || this.selectedFolderName == this.FOLDER_OPTION_GROUP || this.selectedFolderName == this.FOLDER_OPTION_TEAM_ACTIVE;
  }

  getTaskTitle() {
    if (this.selectedFolderName == null)
      return "Tasks";
    if (this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE)
      return "Active Cases";
    if (this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_CLOSED)
      return "Closed Cases";
    if (this.selectedFolderName == this.FOLDER_OPTION_GROUP)
      return "Group Cases";
    if (this.selectedFolderName == this.FOLDER_OPTION_TEAM_ACTIVE)
      return "Team Active Cases";
    if (this.selectedFolderName == this.FOLDER_OPTION_TEAM_CLOSED)
      return "Team Closed Cases";
    return "Cases";
  }
  selectSlaComparison(event) {
    // console.log(event)
  }

  getSearchableDataPoints() {
    if (!this.searchableDataPoints || this.searchableDataPoints.length == 0 || !this.selectedProcesses || this.selectedProcesses.length == 0) {
      return this.searchableDataPoints;
    }

    const searchableDataPoints: SearchableDataPointResponse[] = [];
    for (let selectProcess of this.selectedProcesses) {
      for (let dataPoint of this.searchableDataPoints) {
        if (dataPoint.machineType == selectProcess.value) {
          searchableDataPoints.push(dataPoint);
        }
      }
    }
    return searchableDataPoints;
  }

  createMapOfSearchableDataPoints() {
    if (this.searchableDataPoints && this.searchableDataPoints.length > 0) {
      let uniqueKey = null;

      for (const dataPoint of this.searchableDataPoints) {
        if (dataPoint && dataPoint.machineType && dataPoint.name) {
          uniqueKey = dataPoint.machineType + "_" + dataPoint.name;
          this.searchableDataPointMap.set(uniqueKey, dataPoint);
        }
      }

      for (const dataPoint of this.searchableDataPoints) {
        if (this.isParentDataPointPresent(dataPoint)) {
          uniqueKey = dataPoint.machineType + "_" + dataPoint.parentDataPointName;
          
          if (this.dataPointParentChildMap.has(uniqueKey)) {
            this.dataPointParentChildMap.get(uniqueKey).push(dataPoint);
          } else {
            this.dataPointParentChildMap.set(uniqueKey, [dataPoint]);
          }
        }
      }
    }
  }

  isTaskOverdue(task: Task) {
    if (!task || task == null)
      return false;
    if (this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE || this.selectedFolderName == this.FOLDER_OPTION_GROUP || this.selectedFolderName == this.FOLDER_OPTION_TEAM_ACTIVE) {
      let nowTime = moment(new Date());
      let stateTime = moment(task.slaEndTimeLong);
      if (stateTime.diff(nowTime, "seconds") < 0) {
        return true;
      } 
      else{
        return false;
      }
      
    }
    return false;
  }

  selectOperation(operation:string){
    this.selectedCaseStage = null;
    this.selectedCaseType = null;
    this.updateAllowed = true;
    this.taskOperation = operation;
    if(operation == "Update"){
      this.updateAllowed = this.validateBulkUpdate();   
    }
  }

  selectTaskStatus(status:string){
    this.taskStatus = status;
  }

  validateBulkUpdate(){
    let allowUpdate = false;
    for (let task of this.taskList) {
      if(task.checkForAllocate){
        if(this.selectedCaseType == null && this.selectedCaseStage==null){
          this.selectedCaseType = task.machineLabel;
          this.selectedCaseStage = task.stateCd;
          if(task.statusList){
            this.statusList = task.statusList;
          }
          else{
            this.statusList = this.STATUS_LIST;
          }
          allowUpdate = true;
        }
        else {
          if(this.selectedCaseType == task.machineLabel && this.selectedCaseStage == task.stateCd ){
            allowUpdate = true;
          }
          else{
            this.openSnackBar("error", "Please select Cases with same Case type and Case Stage, you can use the filter to do the same!! ", "Close");
            allowUpdate = false;
          }
        }
      }
    }
    return allowUpdate;
  }

  getTaskLabel(task:Task){
    if(task.taskLabel!=null && task.taskLabel!=undefined){
      return task.taskLabel;
    }
    else if(task.businessKey){
      return task.businessKey;
    }else{
      return task.machineLabel;
    }
  }

  checkFolderAndDecideType(){
    if(this.selectedFolderName == this.FOLDER_OPTION_TEAM_ACTIVE){
      this.onBulkAllocation('TeamLeadReserve');
    }
    else if(this.selectedFolderName == this.FOLDER_OPTION_GROUP){
      this.onBulkAllocation('Reserve');
    }
  }
  comboSortClick(sortEvent){
    this.taskFilter.sortBy = sortEvent.value;
    this.taskFilter.sortDir = sortEvent.dir;
    this.onFilterSubmit();
  }

  handlePageChange(pageNo){
    this.pageNo = pageNo;
    this.taskFilter.pageNo = pageNo -1;
    this.pageChange.emit(this.selectedFolderName);
  }
  checkifInUpdate(task){
    if(this.communicationService.bulkTaskUpdated!=null &&  this.communicationService.bulkTaskUpdated!=undefined){
      if(this.communicationService.bulkTaskUpdated.indexOf(task.entityId) > -1){
        return true;
      }
    }
    return false;
  }

  checkProgress(task:Task){
    if(this.checkifInUpdate(task)){
      this.openSnackBar("warning", "In Process tasks can't be selected", "Close");    
    }
  }
  
  populateDynamicDropdownInSearchableDataPoints() {
    if (this.searchableDataPoints && this.searchableDataPoints.length > 0) {
      for (const dataPoint of this.searchableDataPoints) {
        if (dataPoint && dataPoint.dataType && dataPoint.dataType == "ENTITY_LOOKUP" && dataPoint.referenceModel) {
          if (this.isParentDataPointPresent(dataPoint)) {
            const uniqueKey = dataPoint.machineType + "_" + dataPoint.parentDataPointName;
            const parentDataPoint = this.searchableDataPointMap.get(uniqueKey);

            if (this.taskFilter.additionalFields[parentDataPoint.additionalFieldName]) {
              this.fetchLookupForDataPoint(dataPoint, this.taskFilter.additionalFields[parentDataPoint.additionalFieldName]);
            } else {
              this.clearDataPointValueAndLookupData(dataPoint);
            }
          } else {
            this.fetchLookupForDataPoint(dataPoint);
          }
        }
      }
    }
  }

  isParentDataPointPresent(dataPoint: SearchableDataPointResponse): boolean {
    if (dataPoint && dataPoint.parentDataPointCheckResult != null && dataPoint.parentDataPointCheckResult != 'UNCHECKED') {
      return dataPoint.parentDataPointCheckResult == 'PRESENT';
    }

    if (dataPoint && dataPoint.machineType && dataPoint.parentDataPointName) {
      const uniqueKey = dataPoint.machineType + "_" + dataPoint.parentDataPointName;
      return this.searchableDataPointMap.has(uniqueKey);
    }

    return false;
  }

  fetchLookupForDataPoint(dataPoint: SearchableDataPointResponse, parentDataPointValue?: any[]): void {
    if (dataPoint) {
      const commonSearchModel = new CommonSearchModel();
      commonSearchModel.searchParams = [{}];
      commonSearchModel.returnFields = [];

      const searchParams = {};

      if (parentDataPointValue && parentDataPointValue.length > 0) {
        searchParams["parentValueCode"] = { "$in": parentDataPointValue };
      }

      if (dataPoint.lookupType && dataPoint.lookupType.trim().length > 0) {
        searchParams["lookupType"] = dataPoint.lookupType;
      }

      commonSearchModel.searchParams = [searchParams];

      this.entityService.getLookupEntities(dataPoint.referenceModel, commonSearchModel).subscribe(
        entityList => {
          if (entityList && entityList.length > 0) {
            dataPoint.lookupEntityList = entityList;
          } else {
            dataPoint.lookupEntityList = [];
          }
        },
        error => {
          dataPoint.lookupEntityList = [];
        }
      );
    }
  }

  clearDataPointValueAndLookupData(dataPoint: SearchableDataPointResponse): void {
    if (dataPoint) {
      dataPoint.lookupEntityList = [];
      dataPoint.selectedLookupValue = null;

      if (this.taskFilter && this.taskFilter.additionalFields && this.taskFilter.additionalFields[dataPoint.additionalFieldName]) {
        delete this.taskFilter.additionalFields[dataPoint.additionalFieldName];
      }
    }
  }

  onLookupSelect(dataPoint: SearchableDataPointResponse, value: any) {
    if (dataPoint && value) {
      const uniqueKey = dataPoint.machineType + "_" + dataPoint.name;

      let existingValue = this.taskFilter.additionalFields[dataPoint.additionalFieldName];

      if (!existingValue) {
        existingValue = [];
      }

      existingValue.push(value['labelValue']);
    }
  }

  onSelectionChange(dataPoint: SearchableDataPointResponse) {
    if (dataPoint) {
      const uniqueKey = dataPoint.machineType + "_" + dataPoint.name;

      if (dataPoint.selectedLookupValue && dataPoint.selectedLookupValue.length > 0) {
        const onlyValueArray = [];

        for (const value of dataPoint.selectedLookupValue) {
          if (value) {
            onlyValueArray.push(value['labelValue']);
          }
        }

        this.taskFilter.additionalFields[dataPoint.additionalFieldName] = onlyValueArray;
      } else {
        delete this.taskFilter.additionalFields[dataPoint.additionalFieldName];
      }

      this.clearChildDataPoint(uniqueKey, dataPoint);
    }
  }

  onBulkSelectionChange(dataPoint: SearchableDataPointResponse, allValues: any[]) {
    if (dataPoint) {
      const uniqueKey = dataPoint.machineType + "_" + dataPoint.name;

      if (allValues && allValues.length > 0) {
        const onlyValueArray = [];

        for (const value of allValues) {
          if (value) {
            onlyValueArray.push(value['labelValue']);
          }
        }

        this.taskFilter.additionalFields[dataPoint.additionalFieldName] = onlyValueArray;
      } else {
        delete this.taskFilter.additionalFields[dataPoint.additionalFieldName];
      }

      this.clearChildDataPoint(uniqueKey, dataPoint);
    }
  }

  clearChildDataPoint(uniqueKey: string, dataPoint: SearchableDataPointResponse) {
    if (this.dataPointParentChildMap.has(uniqueKey)) {
      for (const childDataPoint of this.dataPointParentChildMap.get(uniqueKey)) {
        if (childDataPoint) {
          this.clearDataPointValueAndLookupData(childDataPoint);
          this.fetchLookupForDataPoint(childDataPoint, this.taskFilter.additionalFields[dataPoint.additionalFieldName]);

          this.onSelectionChange(childDataPoint);
        }
      }
    }
  }
}
