import { Component, EventEmitter, OnInit, TemplateRef, OnChanges, SimpleChanges, ChangeDetectorRef, Output, Input, Inject, ViewChild } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ConnectorConfigService } from 'src/app/service/connector.service';
import { FileService, UniversalUser } from 'src/app/service/shared.service';
import { CampaignConfig, CampaignInfo, State, Document } from '../../models/tasks.model';
import { EmailPersister } from '../../models/helper.model';
import { EmailService } from 'src/app/service/helper.service';
import { ConnectorConfig } from '../../models/connector.model';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common'


import { CommonService } from 'src/app/service/common.service';
import { ChangeDetectionStrategy } from '@angular/compiler/src/compiler_facade_interface';
import { MatSnackBar } from '@angular/material';
import { TemplatesearchPipe } from '../templatesearch.pipe';
import { StateService } from 'src/app/service/state.service';
import { FetchUserService } from 'src/app/service/userhierarchy.service';
import { UserHierarchy } from 'src/app/models/user.model';
import { PreferenceService } from 'src/app/service/user.service';



interface FileReaderEventTarget extends EventTarget {
  result: string
}

interface FileReaderEvent extends Event {
  target: FileReaderEventTarget;
  getMessage(): string;
}
@Component({
  selector: 'app-adhocemail',
  templateUrl: './adhocemail.component.html',
  styleUrls: ['./adhocemail.component.scss']
})
export class AdhocEmailComponent implements OnInit {
  @Input() doc: Document
  title: string;
  email: any = {};
  emailTrail: any[];
  openMode: string = 'edit';
  mode: string;
  replyOption: boolean;
  stateInstance: State;
  templatesearch: TemplatesearchPipe;
  public event: EventEmitter<any> = new EventEmitter();
  @Output() closeEvent = new EventEmitter();
  closeBtnName: string;
  selectedValue: string;
  baseCampaignList: CampaignConfig[]; //this is used as master list of campaigns
  currentSelectedTaskDetails: any;
  campaignList: CampaignConfig[];
  campaignInfo: CampaignInfo;
  templateList: CampaignInfo[];
  fileObjectUrl: any;
  emailPersister: EmailPersister = new EmailPersister();
  showSelectedTemplate: boolean;
  typeList: any[];
  ckeConfig: any;
  conConfigList: any[] = [];
  selectedConnector: ConnectorConfig;
  showMandatory: boolean = false;
  emailTo: string;
  selectedFile: FormData;
  attachFile: FormData;
  attachedFileNames: string[] = [];
  fileLinks: string;
  attachedFileUrls: any[] = [];
  searchName: string;
  bsModalRef2: BsModalRef;
  bsModalRef3: BsModalRef;
  showLoaderOverlay = {
    taskListing: false,
    mainScreen: false,
  }
  emailfrom: string = "";
  emailCC: any = null;
  emailBCC: any = null;
  emailSubject: string;
  showTemplateList: boolean = false;
  showCC: boolean = false;
  showBCC: boolean = false;
  showOverlay: boolean = false;
  emailToTags: any[] = [];
  autoSuggestionEmails: string[] = [];
  userPrefAutoSuggestionEmails: string[] = [];
  emailHeader = "Email Details";

  EMAIL_CONFIG_FOR_TASK = "_emailConfigForTask";
  EMAIL_CONFIG_FOR_TASK_CONFIG_NAME = "configName";
  EMAIL_CONFIG_FOR_TASK_SUBJECT = "subject";
  EMAIL_CONFIG_FOR_TASK_TO_LIST = "toList";

  constructor(
    public datepipe: DatePipe,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private fileService: FileService,
    private connectorService: ConnectorConfigService,
    private emailService: EmailService,
    private commonService: CommonService,
    private cd: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private universalUser: UniversalUser,
    private sanitizer: DomSanitizer,
    private stateService: StateService,

    @Inject(PreferenceService) private prefService: PreferenceService

  ) {
    this.selectedFile = new FormData();
    this.attachFile = new FormData();
    this.templateList = [];
    this.campaignList = [];
  }

  ngOnInit() {
    // this.convertEmailAttrForTags();
    // Get All Templates
    if (this.openMode == 'forward') {
      if(!this.email){
        this.email = {};
      }
      this.email["to"] = "";
      this.email["from"] = "";
      this.email["cc"] = [];
      this.email["bcc"] = [];
    }
    this.getAutoSuggestionEmails()
    this.getAllTemplates(this.email); // this.email is undefined at this stage and is not used at all in the function
    if (this.email && this.email.dataServicePayload) {
      this.attachedFileUrls = this.email.dataServicePayload;
      this.email['tmpDisplay'] = this.sanitizeHTML(this.email.htmlText)
      for (let attachFileObj of this.attachedFileUrls) {
        this.attachedFileNames.push(attachFileObj.fileName);
      }
    }
    this.getConList();
  }
  templateButton() {
    this.showTemplateList = !this.showTemplateList;
    if (this.showTemplateList) {
      this.showSelectedTemplate = false;
    }
    else {
      this.showSelectedTemplate = true;
    }
  }
  openModal(template: TemplateRef<any>) {
    this.bsModalRef3 = this.modalService.show(template, { class: 'modal-md modal-dialog-centered', animated: false });
  }


  getAllTemplates(emailObj?) {
    // API to get all the company Related Campaigns
    this.connectorService.getAllEmailCampaigns()
      .subscribe(campaignList => {
        if (campaignList) {
          for (let con of campaignList) {
            if (con.campaignName) {
              this.campaignList.push(con)
            }
          }
          this.baseCampaignList = this.campaignList;
          this.typeList = this.campaignList.map(item => item.campaignType);
          for (let i = 0; i < this.campaignList.length; i++) {
            if (this.templateList.length == 0) {
              this.templateList = this.campaignList[i].templateList
            } else {
              this.templateList = this.templateList.concat(this.campaignList[i].templateList)
            }
          }
        }
      });


    // End of subscribe code
  }
  selectType(event) {
    this.selectedValue = event.target.value;
    this.campaignList = this.baseCampaignList.filter(item => item.campaignType == event.target.value);
    this.templateList = [];
    for (let i = 0; i < this.campaignList.length; i++) {
      if (this.templateList.length == 0) {
        this.templateList = this.campaignList[i].templateList
      } else {
        this.templateList = this.templateList.concat(this.campaignList[i].templateList)
      }
    }
    // console.log("template list selectType :: ::");
    // console.log(this.templateList);
  }
  onFileSelect(event) {
    const fileInputForm = new FormData();
    const file: File = event.target.files[0];
    fileInputForm.append('file', file, file.name);
    fileInputForm.append("fileName", file.name);
    this.attachFile = fileInputForm;
    this.attachedFileNames.push(file.name);
    this.uploadAttachFile(file.name);
    /*   fileInputForm.append("entityRef", fileNameToSaveAs); */
  }

  onRemoveAttachment(attachFileName: string) {
    const index = this.attachedFileNames.indexOf(attachFileName);
    if (index != -1) {
      this.attachedFileNames.splice(index, 1);
    }
    if (this.attachedFileUrls) {
      for (let i = 0; i < this.attachedFileUrls.length; i++) {
        if (this.attachedFileUrls[i].fileName == attachFileName) {
          this.attachedFileUrls.splice(i, 1);
        }
      }
    }
  }

  downloadFile(docName: Document) {
    for (let attachFileObj of this.attachedFileUrls) {
      if(attachFileObj.fileName == docName)
      {
        this.fileService.download(attachFileObj.downloadFileUrl);
      }
    }
  }

  setEmailProperties() {
    this.setEmailFrom();
    if (this.stateInstance && this.stateInstance.parameters && this.stateInstance.parameters[this.EMAIL_CONFIG_FOR_TASK] && this.stateInstance.parameters[this.EMAIL_CONFIG_FOR_TASK][this.EMAIL_CONFIG_FOR_TASK_SUBJECT]) {
      this.emailSubject = this.stateInstance.parameters[this.EMAIL_CONFIG_FOR_TASK][this.EMAIL_CONFIG_FOR_TASK_SUBJECT];
    }
    if (this.stateInstance && this.stateInstance.parameters && this.stateInstance.parameters[this.EMAIL_CONFIG_FOR_TASK] && this.stateInstance.parameters[this.EMAIL_CONFIG_FOR_TASK][this.EMAIL_CONFIG_FOR_TASK_TO_LIST]) {
      this.emailToTags = this.stateInstance.parameters[this.EMAIL_CONFIG_FOR_TASK][this.EMAIL_CONFIG_FOR_TASK_TO_LIST];
    }
  }

  selectConnector() {
    this.setEmailFrom();
  }

  setEmailFrom() {
    if (this.selectedConnector.configMap) {
      if (this.selectedConnector.configMap["from"]) {
        this.emailfrom = this.selectedConnector.configMap["from"];
      }
      
    }
    if (!this.emailfrom) {
      this.emailfrom = this.universalUser.getUser().email
    }
  }

  selectTemplate(template?) {
    if (this.selectedConnector) {
      let matchItems;
      if (this.email && this.email.to) {
        if (this.email.emailType == "Send from Piflow") {
          matchItems = this.email.to.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)
        } else if (this.email.from) {
          matchItems = this.email.from.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)
        }

        if (matchItems && matchItems.length > 0) {
          this.emailTo = matchItems.join(",")
        }
        else {
          this.emailTo = matchItems;
        }
        this.emailToTags = this.emailTo.split(",");

        this.emailCC = this.email.cc;
        this.emailBCC = this.email.bcc;
        if (this.emailCC != undefined && (this.emailCC != null || this.emailCC != '')) { this.showCC = false; }
        if (this.emailBCC != undefined && (this.emailBCC != null || this.emailBCC != '')) { this.showBCC = false; }
      }
      else {
        this.emailTo = "";
      }
      /* this.emailTo = this.email.to; */
      // Copy contents of file into the template HTML if present while looping for message trail else if create from scratch then initialize it to ""
      if (template != undefined) {
        // send using existing
        this.campaignInfo = JSON.parse(JSON.stringify(template));

        if (this.email && this.email.subject) {

          this.campaignInfo.displayName = this.email.subject;
        }
        else {
          this.campaignInfo.displayName = "";
        }

        this.fileService.view(template.uploadedFile.url).subscribe(
          (response) => {
            const reader = new FileReader();
            reader.addEventListener('loadend', (e: FileReaderEvent) => {
              const text = e.target.result;
              if (text) {
                template.templateHTML = text.replace(/(?:\r\n|\r|\n)/g, '<div style="max-height:5px;height:5px; overflow:hidden">&nbsp;</div>');
              } else {
                template.templateHTML = '';
              }

              // template.displayName = "Re: " + template.displayName;
              if (this.emailTrail.length > 0) {
                template.templateHTML += "<p style='font-weight:bold;'>--------------EMAIL TRAIL -------------</p>"
                for (let emailIndex = 1; emailIndex < this.emailTrail.length; emailIndex++) {
                  if (this.emailTrail && this.emailTrail[emailIndex] && this.emailTrail[emailIndex].htmlText){
                    this.emailTrail[emailIndex].htmlText = this.emailTrail[emailIndex].htmlText.replace(/(?:\r\n|\r|\n)/g, '<p>&nbsp;</p>')
                  }
                }
              }
              for (let emailIndex = 0; emailIndex < this.emailTrail.length; emailIndex++) {
                const element = this.emailTrail[emailIndex];


                if (element.to) {
                  template.templateHTML += "<p>To:" + element.to + "</p>";
                }
                if (element.cc) {
                  template.templateHTML += "<p>Cc:" + element.cc + "</p>";
                }
                if (element.bcc) {
                  template.templateHTML += "<p>Bcc:" + element.bcc + "</p>";
                }
                if (element.from) {
                  template.templateHTML += "<p>From:" + element.from + "</p>"
                }
                if (element.sendDate) {
                  template.templateHTML += "<p> Sent On:" + new Date(element.sendDate) + "</p>"
                }
                if (element.htmlText) {
                  template.templateHTML += element.htmlText;
                }

                template.templateHTML += "<p>---------------- Email Trail----------------------</p>"

              }

              this.campaignInfo = template;
              this.showSelectedTemplate = true;
              this.showTemplateList = !this.showTemplateList;
            });
            reader.readAsText(response);
          },
          (error) => { }
        )
      } else {
        // create your own
        this.campaignInfo = new CampaignInfo();
        if (this.email) {
          this.campaignInfo.displayName = this.email.subject;
        }
        else {
          this.campaignInfo.displayName = " ";
        }
        this.campaignInfo.templateHTML = "";
        // this.campaignInfo.templateHTML += "<p style='font-weight:bold;'></p>"
        for (let emailIndex = 0; emailIndex < this.emailTrail.length; emailIndex++) {
          const element = this.emailTrail[emailIndex];
          // && element.subject.toLowerCase().indexOf('escalation')==-1
          if (element.emailType.toLowerCase().indexOf('escalation') == -1) {
            if (element.htmlText && element.emailType != "SYSTEM") {
              this.campaignInfo.templateHTML += "<br><p>--------------------Email Trail------------------</p>"
              if (element.from) {
                this.campaignInfo.templateHTML += "<p>From:" + element.from + "</p>"
              }
              if (element.to) {
                this.campaignInfo.templateHTML += "<p>To:" + element.to + "</p>";
              }
              if (element.cc) {
                this.campaignInfo.templateHTML += "<p>Cc:" + element.cc + "</p>";
              }
              if (element.bcc) {
                this.campaignInfo.templateHTML += "<p>Bcc:" + element.bcc + "</p>";
              }

              if (element.emailTime) {
                this.campaignInfo.templateHTML += "<p> Sent On:" + this.datepipe.transform(new Date(element.emailTime.$date), 'dd-MM-yyyy hh:mm'); + "</p>"
              }

              if (element.emailType == "Send from Piflow") {
                this.campaignInfo.templateHTML += "<br>" + "<br>" + element.htmlText;
              }
              else {
                this.campaignInfo.templateHTML += element.htmlText;
              }

            } else {
              this.campaignInfo.templateHTML += this.parseEmailHtml(element.htmlText);
            }
          } else {
            // console.log("Escalation")

          }

        }
        this.showSelectedTemplate = true;
      }
    }
    else if (this.selectedConnector === undefined) {
      this.openSnackBar("Connector is mandatory", "Close");
    }
    this.emailHeader = "Email Details";

  }
  selectTemplateFromEditor(template?) {
    if (this.selectedConnector) {
      let matchItems;
      if (this.email && this.email.to) {

        if (this.email.emailType == "Send from Piflow") {
          matchItems = this.email.to.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)
        } else {
          matchItems = this.email.from.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)
        }
        if (matchItems.length > 0) {
          this.emailTo = matchItems.join(",")
        }
        else {
          this.emailTo = matchItems;
        }
        this.emailToTags = this.emailTo.split(",");

      }
      else {
        this.emailTo = "";
      }
      this.emailCC = this.email.cc;
      this.emailBCC = this.email.bcc;
      this.emailSubject = this.email.subject;
      /* this.emailTo = this.email.to; */

      // Copy contents of file into the template HTML if present while looping for message trail else if create from scratch then initialize it to ""
      if (template != undefined) {
        // send using existing
        this.campaignInfo = JSON.parse(JSON.stringify(template));

        if (this.email && this.email.subject) {

          this.campaignInfo.displayName = this.email.subject;
        }
        else {
          this.campaignInfo.displayName = "";
        }

        this.fileService.view(template.uploadedFile.url).subscribe(
          (response) => {
            const reader = new FileReader();
            reader.addEventListener('loadend', (e: FileReaderEvent) => {
              const text = e.target.result;
              template.templateHTML = text;
              template.displayName = "Re: " + template.displayName;
              template.templateHTML += "<p style='font-weight:bold;'>--------------EMAIL TRAIL -------------</p>"
              for (let emailIndex = 1; emailIndex < this.emailTrail.length; emailIndex++) {
                if (this.emailTrail[emailIndex].htmlText) {
                  this.emailTrail[emailIndex].htmlText = this.emailTrail[emailIndex].htmlText.replace(/(?:\r\n|\r|\n)/g, '<br>')
                }

              }

              for (let emailIndex = 0; emailIndex < this.emailTrail.length; emailIndex++) {
                const element = this.emailTrail[emailIndex];


                if (element.to) {
                  template.templateHTML += "<p>To:" + element.to + "</p>";
                }
                if (element.cc) {
                  template.templateHTML += "<p>Cc:" + element.cc + "</p>";
                }
                if (element.bcc) {
                  template.templateHTML += "<p>Bcc:" + element.bcc + "</p>";
                }
                if (element.from) {
                  template.templateHTML += "<p>From:" + element.from + "</p>"
                }
                if (element.sendDate) {
                  template.templateHTML += "<p> Send On:" + new Date(element.sendDate) + "</p>"
                }
                if (element.htmlText) {
                  template.templateHTML += element.htmlText;
                }
                template.templateHTML += "<p>--------------------------------------</p>"

              }

              this.campaignInfo = template;
              // this.showSelectedTemplate = true;
            });
            reader.readAsText(response);
          },
          (error) => { }
        )
      }
    }
    else if (this.selectedConnector === undefined) {
      this.openSnackBar("Connector is mandatory", "Close");
    }
  }
  getConList() {
    this.emailHeader = "Loading please wait ....";
    this.connectorService.getAllCons()
      .subscribe(conConfigList => {
        if (conConfigList) {
          for (let con of conConfigList) {
            if (!con.taskConfig && con.configType != null && con.configType == 'email') {
              this.conConfigList.push(con)
            }
          }
        }
        if (this.stateInstance && this.stateInstance.parameters && this.stateInstance.parameters[this.EMAIL_CONFIG_FOR_TASK] && this.stateInstance.parameters[this.EMAIL_CONFIG_FOR_TASK][this.EMAIL_CONFIG_FOR_TASK_CONFIG_NAME]) {
          let pos = this.conConfigList.map(item => item.configName == this.stateInstance.parameters[this.EMAIL_CONFIG_FOR_TASK][this.EMAIL_CONFIG_FOR_TASK_CONFIG_NAME]).indexOf(true);
          if (pos > -1) {
            this.selectedConnector = this.conConfigList[pos];
          }
        }
        if (!this.selectedConnector && this.conConfigList != null || this.conConfigList != undefined) {
          for (let con of this.conConfigList) {
            if (con.defaultConfig && con.configMap.from != null && con.configMap.from === (this.universalUser.getUser().email)) {
              this.selectedConnector = con;
            }
          }
        }
        if (!this.selectedConnector) {
          let pos = this.conConfigList.map(item => item.defaultConfig).indexOf(true);
          if (pos > -1) {
            this.selectedConnector = this.conConfigList[pos];
          }
        }
        if (this.selectedConnector) {
          this.setEmailProperties();
          this.selectTemplate();
        }
        else {
          this.showSelectedTemplate = false
        }
      });
  }
  sendMail() {
    this.showOverlay = true;
    if (this.bsModalRef3) {
      this.bsModalRef3.hide();
    }
    // let textToWrite = document.getElementById('textAreaForNewTempalte').innerHTML;
    let textFileAsBlob = new Blob([this.campaignInfo.templateHTML], { type: 'text/plain' });
    // FileSaver.saveAs(textFileAsBlob,  "temp.html");
    this.campaignInfo.templateName = this.campaignInfo.displayName;

    var fileNameToSaveAs = new Date().getTime() + ".html";
    const input = new FormData();

    input.append("file", textFileAsBlob);
    input.append("fileName", fileNameToSaveAs);
    this.selectedFile = input;
    this.uploadFileForTaskConfig(fileNameToSaveAs);

  }

  uploadFileForTaskConfig(fileNameToSaveAs) {
    this.emailTo = this.emailToTags.join(",");
    if (this.emailTo == null || this.emailTo == undefined || this.emailTo == "") {
      this.bsModalRef.hide();
      this.openSnackBar("Mail not sent!!Please make sure that the recepient is not empty or you have pressed enter after filling it", "Close")
      return
    }
    if (this.selectedFile) {
      this.bsModalRef.hide();
      this.openSnackBar("Sending email please wait....", "Close")
      this.selectedFile.append("functionInstanceName", "emailTemplate");
      this.selectedFile.append("entityType", "templateUplaod");
      this.selectedFile.append("entityRef", this.universalUser.getUser()._id);
      this.fileService.upload(this.selectedFile)

        .subscribe(
          response => {
            if (response && response["url"] && response["fileName"]) {
              // this.showOverlay = false;
              this.sendEmailFromEditor(response["url"]);
            }
          }, (error) => {
            if (error && error.error && error.error.error && error.error.error.message) {
              this.snackBar.open(error.error.error.message, "Dismiss", {
                duration: 10000
              });
            }
          }
        );
    }
  }
  uploadAttachFile(fileNameToSaveAs) {
    this.attachFile.append("functionInstanceName", "emailTemplate");
    this.attachFile.append("entityType", "templateUplaod");
    this.attachFile.append("entityRef", fileNameToSaveAs);

    this.fileService.upload(this.attachFile)

      .subscribe(
        response => {

          if (response && response["url"] && response["fileName"]) {
            this.attachedFileUrls.push(response);
            this.fileLinks = response["url"];
          }
        }, (error) => {
          if (error && error.error && error.error.error && error.error.error.message) {
            this.snackBar.open(error.error.error.message, "Dismiss", {
              duration: 10000
            });
          }
        });
  }

  getValidEmailIds(emailIds: any) {
    if (!emailIds)
      return null;
    const rxp = /<([^}]+)>/g;
    if (typeof emailIds == "string") {
      emailIds = emailIds.split(",")
    }
    var validEmailIds: string[] = [];
    for (let emailId of emailIds) {
      if (!emailId)
        continue;
      var found: boolean = false;
      var currMatch = null;
      while (currMatch = rxp.exec(emailId.trim())) {
        validEmailIds.push(currMatch[1]);
        found = true;
      }
      if (!found)
        validEmailIds.push(emailId);
    }
    if (!validEmailIds || validEmailIds.length == 0)
      return null;
    return validEmailIds.join(",");

  }

  sendEmailFromEditor(url: string) {
    const stateInstance = this.stateInstance;
    this.emailToTags = this.spliceAndReplace(this.emailToTags);
    this.emailTo = this.emailToTags.join(",");
    this.emailCC = this.spliceAndReplace(this.emailCC);
    this.emailBCC = this.spliceAndReplace(this.emailBCC);
    let payload = {
      "from": this.emailfrom,
      "to": this.emailTo,
      "cc": this.getValidEmailIds(this.emailCC),
      "bcc": this.getValidEmailIds(this.emailBCC),
      "subject": this.emailSubject,
      "PARAM": {},
      "connectorConfigRef": this.selectedConnector.functionInstanceName,
      "templateUrl": url,
      "attachFileUrl": this.attachedFileUrls,
      "entityId": this.stateInstance.entityId,
      "sentByUserName": this.universalUser.getUser().username,
      "emailType": "Send from Piflow",
      "refType": "FLOW",
      "refName": this.stateInstance.machineType
    }
    if (this.email && this.email.referenceEntityId)
      payload["referenceEntityId"] = this.email.referenceEntityId;
    this.connectorService.sendAdhocEmail(payload).subscribe(
      (response) => {
        if (this.email) {
          this.emailPersister.entityId = this.email.entityId;
        }
        else {
          this.emailPersister.entityId = this.currentSelectedTaskDetails.entityId;
        }

        this.setEmailsForAutoSuggestions();

        this.emailPersister.to = this.emailTo;
        this.emailPersister.cc = this.emailCC;
        this.emailPersister.bcc = this.emailBCC;
        // this.emailPersister.from = this.selectedConnector.configMap["from"];
        this.emailPersister.from = this.emailfrom;
        this.emailPersister.subject = this.campaignInfo.templateName;
        this.emailPersister.htmlText = this.campaignInfo.templateHTML;
        this.emailPersister.emailType = "Send from Piflow"
        this.emailPersister.sentByUserName = this.universalUser.getUser().username;
        this.emailPersister.emailTime = {};
        this.emailPersister.emailTime.$date = new Date().getTime();
        this.emailPersister.dataServicePayload = this.attachedFileUrls;
        this.emailPersister.attachments = this.attachedFileUrls;
        this.emailPersister.referenceEntityId = this.email.referenceEntityId;
        if (stateInstance && stateInstance._id) {
          this.stateService.setFirstResponsetimeById(stateInstance._id)
            .subscribe(
              response => {
              }
            )
        }

        if (this.email) {
          this.commonService.message = false;
          this.commonService.sendMail = false;

        }
        else {
          this.commonService.message = true;
        }

        this.event.emit(true);


      },
      (error) => {
        this.bsModalRef.hide();
        this.openSnackBar("Something went wrong while sending email please contact your Sysytem administrator", "Close");
      }
    )
  }
  closeModal(templateRef: TemplateRef<any>) {
    if (this.openMode == 'edit') {
      this.bsModalRef2 = this.modalService.show(templateRef, { class: 'modal-dialog modal-md modal-dialog-centered', animated: false });
    } else {
      this.bsModalRef.hide();
      // this.bsModalRef.close({ event: 'close', data: false,index:this.index});
      this.commonService.message = false;
    }
  }
  onConfirm() {
    this.bsModalRef2.hide();
    this.bsModalRef.hide();
    this.commonService.message = false;


  }
  onCancel() {
    this.bsModalRef2.hide();

  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 10000,
      verticalPosition: 'top',
      horizontalPosition: 'right',

    });
  }

  forward() {
    this.openMode = "forward";
    this.emailToTags = [];
    this.email.bcc = [];
    this.email.cc = [];
  }





  sanitizeHTML(htmlText) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlText);
  }
  parseEmailHtml(htmlText: string) {
    if (htmlText) {
      let domParser = new DOMParser();
      var doc = domParser.parseFromString(htmlText, 'text/html');
      if (htmlText.indexOf("<style") > -1 || htmlText.indexOf("<table") > -1) {
        htmlText = doc.documentElement.innerHTML;
      } else {
        htmlText = doc.documentElement.textContent;
      }
      return (htmlText);
    }

  }
  toggleFields(fieldName, emailField) {
    if (this[emailField] == null)
      this[emailField] = '';

    this[fieldName] = !this[fieldName]
  }

  setEmailsForAutoSuggestions() {
    let currId = this.returnByuserPrefKeys(this.universalUser.getUser()._id + "autoSuggestionEmails", "_id");
    let allEmails = [];
    allEmails.push(...this.emailToTags, ...this.emailCC, ...this.emailBCC);
    for (let email of allEmails) {
      if (!this.autoSuggestionEmails.includes(email) && !this.userPrefAutoSuggestionEmails.includes(email)) {
        this.userPrefAutoSuggestionEmails.push(email);
      }
    }
    this.userPrefAutoSuggestionEmails = this.cleanArray(this.userPrefAutoSuggestionEmails);
    if (this.userPrefAutoSuggestionEmails.length > 0) {
      this.prefService.setUserPref("email", "adhocemail", this.universalUser.getUser()._id + "autoSuggestionEmails", [], currId, this.userPrefAutoSuggestionEmails).subscribe(
        (response) => {
          let currUserPref = this.universalUser.getUserPref();
          if (currId && currId != null) {
            let tmp = currUserPref.map(item => item._id).indexOf(currId);
            currUserPref[tmp] = response
          } else {
            currUserPref.push(response)
          }
          this.universalUser.setUserPref(currUserPref);
        },
        (error) => {
          console.log("Save user preferences -> ", error)
        }
      );
    }
  }

  getAutoSuggestionEmails() {
    if (this.universalUser.getDefaultAutoSuggestEmails() && this.universalUser.getDefaultAutoSuggestEmails().length > 0) {
      this.autoSuggestionEmails = this.universalUser.getDefaultAutoSuggestEmails();
    }
    this.userPrefAutoSuggestionEmails = this.returnByuserPrefKeys(this.universalUser.getUser()._id + "autoSuggestionEmails", "autoSuggestEmails");
    this.autoSuggestionEmails = this.autoSuggestionEmails.concat(this.userPrefAutoSuggestionEmails);
    this.autoSuggestionEmails = [...new Set(this.cleanArray(this.autoSuggestionEmails))];
  }


  returnByuserPrefKeys(identifier, returnKey?: string) {
    let currUserPref = this.universalUser.getUserPref();
    if (currUserPref && currUserPref.length > 0) {
      let tmp = currUserPref.map(item => item.identifier).indexOf(identifier);
      if (tmp > -1) {
        if (returnKey == "_id") {
          return currUserPref[tmp]._id;
        }
        else if (returnKey == "autoSuggestEmails") {
          if (currUserPref[tmp]["preferences"] && currUserPref[tmp]["preferences"]["autoSuggestEmails"]) {
            return currUserPref[tmp]["preferences"]["autoSuggestEmails"];
          }
          else {
            return [];
          }
        }
      }
      else {
        if (returnKey == "autoSuggestEmails")
          return [];

        else if (returnKey == "_id")
          return null;
      }
    }
    if (returnKey == "autoSuggestEmails")
      return [];

    else if (returnKey == "_id")
      return null;
  }

  cleanArray(arrayToClean) {
    const cleanedArray = [];
    arrayToClean.forEach((val) => {
      if (val !== null && val != undefined && val != "") {
        cleanedArray.push(val);
      }
    });

    return cleanedArray;
  }

  spliceAndReplace(emails: any[]) {
    if (emails && emails.length > 0) {
      for (let email of emails) {
        if (email.value) {
          const index = emails.indexOf(email);
          emails.splice(index, 1, email.value);
        }
      }
      return emails;
    }
    else {
      return [];
    }
  }
  onPaste(event: ClipboardEvent, campaignInfo) {
    // event.preventDefault();
    // let contentToPaste = "";
    // console.log(event.clipboardData.getData('text/html'))
    // if (event.clipboardData.getData('text/html')) {
    //   contentToPaste = event.clipboardData.getData('text/html');
    // } else {
    //   contentToPaste = event.clipboardData.getData('text');
    // }
    // if (!campaignInfo.templateHTML)
    //   campaignInfo.templateHTML = "";
    // this.editor.focus();
    // if (contentToPaste == "") {
    //   let items = (event.clipboardData).items;
    //   for (let index in items) {
    //     let item = items[index];
    //     if (item.kind === 'file') {
    //       event.preventDefault();
    //       let strPlace = "{{" + new Date().getTime() + "_ReplaceString}}";
    //       this.editor.editorService.insertHtml(strPlace);
    //       let blob = item.getAsFile();
    //       let reader = new FileReader();
    //       reader.onload = function (fileEvent) {
    //         let valuePositionSplitArray = campaignInfo.templateHTML.split(strPlace);
    //         if (valuePositionSplitArray[1] == undefined) valuePositionSplitArray.push("");
    //         let newValue = valuePositionSplitArray[0] + "<img src=" + fileEvent.target["result"] + ">" + valuePositionSplitArray[1];
    //         newValue.replace("undefined", "");
    //         campaignInfo.templateHTML = newValue
    //       }; // data url!
    //       reader.readAsDataURL(blob);
    //     }
    //   }
    // } else {
    //   this.addPastedValueToTemplateText(event, campaignInfo, contentToPaste)
    // }
  }

  addPastedValueToTemplateText(event, campaignInfo, contentToPaste) {
    event.preventDefault();
    let strPlace = "{{" + new Date().getTime() + "_ReplaceString}}";
    // this.editor.editorService.insertHtml(strPlace);
    let valuePositionSplitArray = campaignInfo.templateHTML.split(strPlace);
    if (valuePositionSplitArray[1] == undefined) valuePositionSplitArray.push("");
    let newValue = valuePositionSplitArray[0] + contentToPaste + valuePositionSplitArray[1];
    newValue.replace("undefined", "");
    this.campaignInfo.templateHTML = newValue
  }
  highlight(template) {
    if (!this.searchName) {
      return this.sanitizer.bypassSecurityTrustHtml(template.templateHTML);
    }

    return this.sanitizer.bypassSecurityTrustHtml(template.templateHTML.replace(new RegExp(this.searchName, "gi"), match => {
      return '<span class="highlightText">' + match + '</span>';
    }));

  }
}
