import { Pipe, PipeTransform } from '@angular/core';
import {EnvironmentData} from '../service/environments.service';

@Pipe({
  name: 'escapeScript'
})
export class EscapeScriptPipe implements PipeTransform {

  private blackListedEndPoints = null;
  private blackListedCharacters: any[] = null;

  constructor(private environmentData: EnvironmentData) {
    this.environmentData.getEnvDataObservable().subscribe(
      envData => {
        this.blackListedEndPoints = [
          envData.datamodelurl,
          envData.datamodelsaveurl,
          envData.entityurl,
          envData.entitrefurl,
          envData.entitysaveurl,
          envData.entitysubmiturl,
          envData.lookupurl,
          envData.reflookupurl,
          envData.getrefentity
        ];

        this.blackListedCharacters = [
          { regex: new RegExp('%3C', 'ig'), value: '<' },
          { regex: new RegExp('U+003C', 'ig'), value: '<' },
          { regex: new RegExp('0x3C', 'ig'), value: '<' },
          { regex: new RegExp('0x003C', 'ig'), value: '<' },
          { regex: new RegExp('0x0000003C', 'ig'), value: '<' },
          { regex: new RegExp('&#60;', 'ig'), value: '<' },
          { regex: new RegExp('&#x3C;', 'ig'), value: '<' },
          { regex: new RegExp('&lt;', 'ig'), value: '<' },
    
          { regex: new RegExp('%3E', 'ig'), value: '>' },
          { regex: new RegExp('U+003E', 'ig'), value: '>' },
          { regex: new RegExp('0x3E', 'ig'), value: '>' },
          { regex: new RegExp('0x003E', 'ig'), value: '>' },
          { regex: new RegExp('0x0000003E', 'ig'), value: '>' },
          { regex: new RegExp('&#62;', 'ig'), value: '>' },
          { regex: new RegExp('&#x3E;', 'ig'), value: '>' },
          { regex: new RegExp('&gt;', 'ig'), value: '>' }
        ];
      }
    );
    

    
  }

  transform(s: any): any {
    if (typeof s !== 'string') {
      return '';
    }

    this.blackListedCharacters.forEach(item => {
      s = s.replace(item.regex, item.value);
    });

    return s.split(/<\/?[^>]*>/).join("").trim();
  }

  escapeRecursively(payload: any) {
    if (payload) {
      if (payload instanceof String || typeof payload == 'string') {
        return this.transform(payload);
      } else if (payload instanceof Array) {
        for (let index = 0; index < payload.length; index++) {
          payload[index] = this.escapeRecursively(payload[index]);
        }
      } else if (Object.keys(payload).length > 0) {
        for (let key of Object.keys(payload)) {
          payload[key] = this.escapeRecursively(payload[key]);
        }
      }
    }

    return payload;
  }

  getBlackListedEndPoints(): string[] {
    return this.blackListedEndPoints;
  }

}
