import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { Document, State } from '../../models/tasks.model'
import { environment} from '../../../environments/environment';
import { v4 as uuid } from 'uuid';
import { FileService } from 'src/app/service/shared.service';
import {EnvironmentData} from '../../service/environments.service';



@Component({
  selector: 'app-remarks',
  templateUrl: './remarks.component.html',
  styleUrls: ['./remarks.component.css']
})
export class RemarksComponent implements OnInit {

  @Input() assignedTaskDdetails:State;
  @Input() documentsForState:any;
  @Input() statusList:any;

  @Output() docUploadEvent = new EventEmitter();
  @Output() docRemoveEvent = new EventEmitter();
  @Output() closeEvent = new EventEmitter();

  constructor(
    private fileService: FileService,
    private environmentData: EnvironmentData
  ) { }

  ngOnInit() {
  }

  /**
   * TODO:
   * 
   * This function hasn't been used anywhere.
   * Please make sure that it's download mechanism is updated before using it.
   * 
   * The old mechanism, where the file URL is opened in a new window won't work.
   * The file must be downloaded in a BLOB format by firing an API and reconstructed in order to successfully download it.
   * 
   * Please contact Swami for more clarity (swami@automatapi.com / 9664563210)
   */

  getDownloadUrl(document: Document) {
    if (document && document.downloadFileUrl) {
      return this.environmentData.getEnvData().rootUrl + this.environmentData.getEnvData().interfaceService + document.downloadFileUrl;
    }
    return null;
  }

  onNewRemarksDocuments(stateInstanace: State) {
    const newDoc = new Document();
    newDoc.stateInstanceId = stateInstanace._id;
    newDoc.flowInstanceId = stateInstanace.stateMachineInstanceModelId;
    newDoc.documentType = "USER_REMARKS";
    newDoc.documentName = uuid();
    if (!this.documentsForState[stateInstanace._id]) {
      this.documentsForState[stateInstanace._id] = [];
    }
    this.documentsForState[stateInstanace._id].push(newDoc);
  }

  getRemarksDocuments(stateInstanace: State) {
    const remarksDocs: Document[] = [];
    if (this.documentsForState && this.documentsForState[stateInstanace._id]) {
      for (let doc of this.documentsForState[stateInstanace._id]) {
        if (doc.documentType &&  doc.documentType == "USER_REMARKS") {
          remarksDocs.push(doc);
        }
      }
    }
    return remarksDocs;
  }

  getOtherThanRemarksDocuments(stateInstanace: State) {
    const docs: Document[] = [];
    if (this.documentsForState && this.documentsForState[stateInstanace._id]) {
      for (let doc of this.documentsForState[stateInstanace._id]) {
        if (doc.documentType &&  doc.documentType != "USER_REMARKS") {
          docs.push(doc);
        }
      }
    }
    return docs;
  }

  onRemoveDocument(document:Document){
    this.docRemoveEvent.emit(document);
  }

  onDocumentUploadForTask(event:Event,document:Document){
    let map ={};
    map["event"] = event;
    map["document"] = document;
    this.docUploadEvent.emit(map);
  }

  closeSection(){
    this.closeEvent.emit();
  }
  capAndSpace(string){
    return capitalize(string.split(/(?=[A-Z])/).join(" "))
  }

  downloadFile(doc: Document) {
    if (doc && doc.downloadFileUrl) {
      if (doc.downloadFileUrl.startsWith('http')) {
        window.open(doc.downloadFileUrl, '_blank');
      } else {
        this.fileService.download(doc.downloadFileUrl);
      }
    }
  }

}
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}