import { Injectable } from '@angular/core';
import { Observable, Subject} from 'rxjs';
import { User } from '../models/user.model';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {Router} from '@angular/router';
import { UniversalUser } from '../service/shared.service';
import { State,GraphObject, DocumentSubSet, Document } from '../models/tasks.model';
import { CommonSearchModel } from '../models/shared.model';
import {EnvironmentData} from './environments.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {
    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(
      private router: Router,
      private universalUser: UniversalUser,
      private httpClient: HttpClient,
      private environmentData: EnvironmentData
    ) { }


    save(state:State,documents?: DocumentSubSet[], activeStateInsatnceId?: string){
      const envData = this.environmentData.getEnvData();
      const map = {};
      if (documents && documents.length >= 0) {
        state.documents = documents;
      }
      const subject = new Subject<State>();
      const url = `${envData.rootUrl + envData.statemachineroot + envData.statesaveurl}`;
    
      this.httpClient.put<State>(
        url,
        state,
        {
          headers: this.httpHeaders,
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      ).subscribe(
        (response: HttpResponse<State>) => {
          if (response.body) {
            subject.next(response.body);
          } else {
            subject.next();
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error
  
          subject.error(err);
        }
      );
  
      return subject.asObservable();
    }

    saveWithMetaData(state:State, documentList: Document, entityMap?: any){
      const envData = this.environmentData.getEnvData();
      const body = { documentList: documentList, stateInstance: state, entityMap: entityMap, entityName: (entityMap ? entityMap._entityName : null) };
      const subject = new Subject<State>();
      const url = `${envData.rootUrl + envData.statemachineroot}` + "console/state/saveWithMetaData";
    
      this.httpClient.put<State>(
        url,
        body,
        {
          headers: this.httpHeaders,
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      ).subscribe(
        (response: HttpResponse<State>) => {
          if (response.body) {
            subject.next(response.body);
          } else {
            subject.next();
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error
  
          subject.error(err);
        }
      );
  
      return subject.asObservable();
    }

    validateState(stateInstanceId: string){
      const envData = this.environmentData.getEnvData();
      const subject = new Subject<State>();
      const url = `${envData.rootUrl + envData.statemachineroot}` + "console/statemachine/validateParams/" + stateInstanceId;
    
      this.httpClient.get<State>(
        url,
        {
          headers: this.httpHeaders,
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      ).subscribe(
        (response: HttpResponse<State>) => {
          if (response.body) {
            subject.next(response.body);
          } else {
            subject.next();
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error
  
          subject.error(err);
        }
      );
  
      return subject.asObservable();
    }

    update(machineType: string, entityId: string, param: any, taskStatus?: string, taskRemarks?: string,documents?: Document[], activeStateInsatnceId?: string, taskPriority?: number): Observable<State> {
      const envData = this.environmentData.getEnvData();  
      const subject = new Subject<State>();
    
        const map = {};
        map['param'] = JSON.stringify(param);
        map['payload'] = '{}';
       
       
        if (taskStatus != null && taskStatus.trim().length > 0) {
          map['TASK_STATUS'] = taskStatus;
        }
        if (taskRemarks != null && taskRemarks.trim().length > 0) {
          map['TASK_REMARKS'] = taskRemarks;
        }
    
        if (documents && documents.length > 0) {
          map["DOCUMENTS"] = documents;
        }
        if (activeStateInsatnceId) {
          map["ACTIVE_STATE_INSTANCE_ID"] = activeStateInsatnceId;
        }

        if (taskPriority) {
          map["TASK_PRIORITY"] = taskPriority;
        }
    
        const url = `${envData.rootUrl + envData.statemachineroot + envData.updatestatemachineurl}/${machineType}/${entityId}`;
    
        this.httpClient.put<any>(
          url,
          map,
          {
            headers: this.httpHeaders,
            observe: 'response',
            reportProgress: true,
            withCredentials: true
          }
        ).subscribe(
          (response: HttpResponse<State>) => {
            if (response.body) {
              subject.next(response.body);
            } else {
              subject.next();
            }
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
    
            subject.error(err);
          }
        );
    
        return subject.asObservable();
      }

    reopen(machineType: string, entityId: string, stateInstanceId: string, param: any): Observable<State> {
      const envData = this.environmentData.getEnvData();  
      const subject = new Subject<State>();
    
      const map = {};
      map['param'] = JSON.stringify(param);
      map['payload'] = '{}';
      const url = `${envData.rootUrl}` + "/flow/console/statemachine/reopenWithState/" + machineType + "/" + entityId + "/" + stateInstanceId;
  
      this.httpClient.put<any>(
        url,
        map,
        {
          headers: this.httpHeaders,
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      ).subscribe(
        (response: HttpResponse<State>) => {
          if (response.body) {
            subject.next(response.body);
          } else {
            subject.next();
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error
  
          subject.error(err);
        }
      );
  
      return subject.asObservable();
    }

      updateBulkTask(machineType: string, entityIds: string[], param: any = {}, taskStatus: string, taskRemarks: string): Observable<State> {
        const envData = this.environmentData.getEnvData();  
        const subject = new Subject<State>();
      
          const map = {};

          map['param'] = JSON.stringify(param);
          map['payload'] = '{}';
          if(entityIds!=null && entityIds.length > 0){
            map["entityIds"] = entityIds;
          }
         
          if (taskStatus != null && taskStatus.trim().length > 0) {
            map['TASK_STATUS'] = taskStatus;
          }
          if (taskRemarks != null && taskRemarks.trim().length > 0) {
            map['TASK_REMARKS'] = taskRemarks;
          }
      
         
          const url = `${envData.rootUrl + envData.statemachineroot + envData.bulkupdate}/${machineType}`;
      
          this.httpClient.post<any>(
            url,
            map,
            {
              headers: this.httpHeaders,
              observe: 'response',
              reportProgress: true,
              withCredentials: true
            }
          ).subscribe(
            (response: HttpResponse<State>) => {
              if (response.body) {
                subject.next(response.body);
              } else {
                subject.next();
              }
            },
            (err: HttpErrorResponse) => {
              // All errors are handled in ErrorInterceptor, no further handling required
              // Unless any specific action is to be taken on some error
      
              subject.error(err);
            }
          );
      
          return subject.asObservable();
        }

      validateParams(machineType: string, entityId: string, param: any = {}, taskStatus: string, taskRemarks: string){
        const envData = this.environmentData.getEnvData();  
        const subject = new Subject<State>();
      
          const map = {};

          map['param'] = JSON.stringify(param);
          map['payload'] = '{}';
                  
          if (taskStatus != null && taskStatus.trim().length > 0) {
            map['TASK_STATUS'] = taskStatus;
          }
          if (taskRemarks != null && taskRemarks.trim().length > 0) {
            map['TASK_REMARKS'] = taskRemarks;
          }
      
         
          const url = `${envData.rootUrl + envData.statemachineroot + envData.validateParams}/${machineType}/${entityId}`;
      
          this.httpClient.post<any>(
            url,
            map,
            {
              headers: this.httpHeaders,
              observe: 'response',
              reportProgress: true,
              withCredentials: true
            }
          ).subscribe(
            (response: HttpResponse<State>) => {
              if (response.body) {
                subject.next(response.body);
              } else {
                subject.next();
              }
            },
            (err: HttpErrorResponse) => {
              // All errors are handled in ErrorInterceptor, no further handling required
              // Unless any specific action is to be taken on some error
      
              subject.error(err);
            }
          );
      
          return subject.asObservable();
      }
        
      getDataPointconfiguration(commonSearchModel: CommonSearchModel,flowInstanceId?:string): Observable<GraphObject> {

        const envData = this.environmentData.getEnvData();
        const subject = new Subject<GraphObject>();
        const url = `${envData.rootUrl + envData.statemachineroot + envData.graphobjectDataPoints + "/" + flowInstanceId}`;
        this.httpClient.post<GraphObject>(
          url,
          commonSearchModel,
          {
            headers: this.httpHeaders,
            observe: 'response',
            reportProgress: true,
            withCredentials: true
          }
        ).subscribe(
          (response: HttpResponse<GraphObject>) => {
            if (response.body) {
              subject.next(response.body);
            }
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
    
            subject.error(err);
          }
        );
        return subject.asObservable();
        }


      getDataPointconfigurationFromFlowInstanceId(flowInstanceId: string): Observable<GraphObject> {
        
        const envData = this.environmentData.getEnvData();
        const subject = new Subject<GraphObject>();
        const url = `${envData.rootUrl + envData.statemachineroot + envData.graphobjectbyflowinstanceid + "/" + flowInstanceId}`;
        this.httpClient.get<GraphObject>(
          url,
          {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
          }
        ).subscribe(
          (response: HttpResponse<GraphObject>) => {
            if (response.body) {
              subject.next(response.body);
            }
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
    
            subject.error(err);
          }
        );
        return subject.asObservable();
        }

        saveArchivedState(state: State): Observable<State> {

          const envData = this.environmentData.getEnvData();
          const subject = new Subject<State>();
      
          // console.log(state._id)
      
          const url = `${envData.rootUrl + envData.statemachineroot + envData.savearchivestate}`;
      
          this.httpClient.post<State>(
            url,
            state,
            {
              headers: this.httpHeaders,
              observe: 'response',
              reportProgress: true,
              withCredentials: true
            }
          ).subscribe(
            (response: HttpResponse<State>) => {
              if (response.body) {
                subject.next(response.body);
              }
            },
            (err: HttpErrorResponse) => {
              // All errors are handled in ErrorInterceptor, no further handling required
              // Unless any specific action is to be taken on some error
      
              subject.error(err);
            }
          );
      
          return subject.asObservable();
        }


        getStateInstanceById(_id: string): Observable<State> {

          const envData = this.environmentData.getEnvData();
          const subject = new Subject<State>();
          const url = `${envData.rootUrl + envData.statemachineroot + envData.statebyid}` + _id;
      
          this.httpClient.get<State>(
            url,
            {
              observe: 'response',
              reportProgress: true,
              withCredentials: true
            }
          ).subscribe(
            (response: HttpResponse<State>) => {
              if (response.body) {
                subject.next(response.body);
              }
            },
            (err: HttpErrorResponse) => {
              // All errors are handled in ErrorInterceptor, no further handling required
              // Unless any specific action is to be taken on some error
      
              subject.error(err);
            }
          );
      
          return subject.asObservable();
        }

        getDocumentsForState(state: State) {

          const envData = this.environmentData.getEnvData();
          const subjecr = new Subject<Document[]>();
          const url = `${envData.rootUrl + envData.statemachineroot + envData.getDocuments + "/" + state._id }`;
          this.httpClient.get<Document[]>(
            url,
            {
              observe: 'response',
              reportProgress: true,
              withCredentials: true
            }
          ).subscribe(
            (response: HttpResponse<Document[]>) => {
              if (response.body) {
                subjecr.next(response.body);
              }
            },
            (err: HttpErrorResponse) => {
              subjecr.error(err);
            }
          );
          return subjecr.asObservable();
        }

    saveDocument(document: Document){
      const envData = this.environmentData.getEnvData();
      const subject = new Subject<Document>();
      const url = `${envData.rootUrl + envData.statemachineroot + envData.saveDocument}`;
    
      this.httpClient.post<Document>(
        url,
        document,
        {
          headers: this.httpHeaders,
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      ).subscribe(
        (response: HttpResponse<Document>) => {
          if (response.body) {
            subject.next(response.body);
          } else {
            subject.next();
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error
  
          subject.error(err);
        }
      );
  
      return subject.asObservable();
    }

    deleteDocument(document: Document){
      const envData = this.environmentData.getEnvData();
      const subject = new Subject<Document>();
      const url = `${envData.rootUrl + envData.statemachineroot + envData.saveDocument}` + "/" + document._id;
    
      this.httpClient.delete<Document>(
        url,
        {
          headers: this.httpHeaders,
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      ).subscribe(
        (response: HttpResponse<Document>) => {
          if (response.body) {
            subject.next(response.body);
          } else {
            subject.next();
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error
  
          subject.error(err);
        }
      );
  
      return subject.asObservable();
    }

        getTimelineForFlow(flowId: string): Observable<any> {
          const envData = this.environmentData.getEnvData();

          const subject = new Subject<any>();
          const url = `${envData.rootUrl + envData.statemachineroot + envData.timeline}${flowId}`;
        
          this.httpClient.get<any>(
            url,
            {
              observe: 'response',
              reportProgress: true,
              withCredentials: true
            }
          ).subscribe(
            (response: HttpResponse<any>) => {
              if (response.body) {
                subject.next(response.body);
              }
            },
            (err: HttpErrorResponse) => {
              // All errors are handled in ErrorInterceptor, no further handling required
              // Unless any specific action is to be taken on some error
        
              subject.error(err);
            }
          );
        
          return subject.asObservable();
        }
        getTimelineStateInstanceId(stateInstanceId: string): Observable<any> {
          const envData = this.environmentData.getEnvData();

          const subject = new Subject<any>();
          const url = `${envData.rootUrl + envData.statemachineroot + envData.timelineDetails}${stateInstanceId}`;
        
          this.httpClient.get<any>(
            url,
            {
              observe: 'response',
              reportProgress: true,
              withCredentials: true
            }
          ).subscribe(
            (response: HttpResponse<any>) => {
              if (response.body) {
                subject.next(response.body);
              }
            },
            (err: HttpErrorResponse) => {
              // All errors are handled in ErrorInterceptor, no further handling required
              // Unless any specific action is to be taken on some error
        
              subject.error(err);
            }
          );
        
          return subject.asObservable();
        }
        getTimelineTaskId(taskId: string): Observable<any> {
          const envData = this.environmentData.getEnvData();

          const subject = new Subject<any>();
          const url = `${envData.rootUrl + envData.statemachineroot + "console/state/getTimeLine/"}${taskId}`;
        
          this.httpClient.get<any>(
            url,
            {
              observe: 'response',
              reportProgress: true,
              withCredentials: true
            }
          ).subscribe(
            (response: HttpResponse<any>) => {
              if (response.body) {
                subject.next(response.body);
              }
            },
            (err: HttpErrorResponse) => {
              // All errors are handled in ErrorInterceptor, no further handling required
              // Unless any specific action is to be taken on some error
        
              subject.error(err);
            }
          );
        
          return subject.asObservable();
        }
        getStages(graphs: any): Observable<any> {
          const envData = this.environmentData.getEnvData();
          const subject = new Subject<any>();
          const url = `${envData.rootUrl + envData.statemachineroot + envData.getStagesService}`;
          this.httpClient.post<any>(
            url,
            graphs,
            {
              headers: this.httpHeaders,
              observe: 'response',
              reportProgress: true,
              withCredentials: true
            }
          ).subscribe(
            (response: HttpResponse<any>) => {
              if (response.body) {
                subject.next(response.body);
              }
            },
            (err: HttpErrorResponse) => {
              // All errors are handled in ErrorInterceptor, no further handling required
              // Unless any specific action is to be taken on some error
      
              subject.error(err);
            }
          );
      
          return subject.asObservable();
          }

   getAssociatedStateForEntity (entityId,machineType):Observable<any>{
    const envData = this.environmentData.getEnvData();
      const subject = new Subject<any>();
          const url = `${envData.rootUrl + envData.statemachineroot + envData.getactivemanualstate +  entityId + "/" + machineType }`;
          this.httpClient.get<any>(
            url,
            {
              observe: 'response',
              reportProgress: true,
              withCredentials: true
            }
          ).subscribe(
            (response: HttpResponse<any>) => {
              if (response.body) {
                subject.next(response.body);
              }
            },
            (err: HttpErrorResponse) => {
              subject.error(err);
            }
          );
          return subject.asObservable();
        }


    getActiveManualStateFromFlow (flowId:string):Observable<any>{
      const envData = this.environmentData.getEnvData();
      const subject = new Subject<any>();
          const url = `${envData.rootUrl + envData.statemachineroot + envData.activemanualstateflow +  flowId}`;
          this.httpClient.get<any>(
            url,
            {
              observe: 'response',
              reportProgress: true,
              withCredentials: true
            }
          ).subscribe(
            (response: HttpResponse<any>) => {
              if (response.body) {
                subject.next(response.body);
              }
            },
            (err: HttpErrorResponse) => {
              subject.error(err);
            }
          );
          return subject.asObservable();
        }

    changeTaskPriority(_id: string, fromTaskPriority: number, toTaskPriority: number, priorityChangedByUserId: string):Observable<any>{
      const envData = this.environmentData.getEnvData();
      const subject = new Subject<any>();
      const url = `${envData.rootUrl + envData.statemachineroot}` + "console/state/changetaskpriority/" + _id + "/" + fromTaskPriority + "/" + toTaskPriority + "/" + priorityChangedByUserId;
      this.httpClient.get<any>(
        url,
        {
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      ).subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );
      return subject.asObservable();
    }
    
    setFirstResponsetimeById(_id: string):Observable<State>{
      const envData = this.environmentData.getEnvData();
      const subject = new Subject<State>();
      const url = `${envData.rootUrl + envData.statemachineroot}` + "console/state/setfirstresponsetime/" + _id;
      this.httpClient.get<State>(
        url,
        {
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      ).subscribe(
        (response: HttpResponse<State>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );
      return subject.asObservable();
    }
        
}