import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CustomDashboardComponent, Filter } from 'src/app/models/customDashboard.model';
import { CustomDashboardService } from 'src/app/service/dashboard.service';
import { UniversalUser } from 'src/app/service/shared.service';

@Component({
  selector: 'app-funnel-chart-dashboard',
  templateUrl: './funnel-chart-dashboard.component.html',
  styleUrls: ['./funnel-chart-dashboard.component.css']
})
export class FunnelChartDashboardComponent implements OnInit, OnChanges {
  @Input() startTime: string;
  @Input() endTime: string;
  @Input() selectedComponent: CustomDashboardComponent;
  @Input() selectedDashboardId: string;
  @Input() colourList:string[] =[];
  currentResults: any = [];
  chartDiv: string;
  @Input() componentFilters: Filter[] = [];

  constructor(
    public cd: ChangeDetectorRef,
    private customDashboardService: CustomDashboardService,
    private universalUser: UniversalUser
  ) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedComponent && changes.selectedComponent.currentValue) {
      this.chartDiv = changes.selectedComponent.currentValue._id;
    }
    if (changes && changes.endTime && changes.endTime.currentValue != undefined) {
      this.endTime = changes.endTime.currentValue;
    }
    if (changes && changes.startTime && changes.startTime.currentValue != undefined) {
      this.startTime = changes.startTime.currentValue;
    }
    if (changes && changes.componentFilters && changes.componentFilters.currentValue != undefined) {
      this.componentFilters = changes.componentFilters.currentValue;
    }
    this.getResults(this.startTime, this.endTime, this.componentFilters)
    
  }
  isValidDate(d) {
    var timestamp = Date.parse(d);

    if (isNaN(timestamp) == false) {
      return true
    } else {
      return false
    }
  }
  replaceExtras(val) {
    let tmp = val.replace(/./g, "");
    tmp = tmp.replace("payload", "");
    tmp = tmp.replace("keyword", "");
    tmp = tmp.replace("parameters", "");
    return (tmp);
  }

  getResults(startTime, endTime, filters) {
    this.customDashboardService.getComponentResult(this.universalUser.getUser().companyId, this.selectedComponent, startTime,endTime,filters, this.selectedDashboardId).subscribe(
      (response) => {
        if (response && response.length > 0) {
          // console.log(response)
          // Keys to Render
          let keys = Object.keys(response[0]);
          if(keys.indexOf('category')>-1)
          keys.splice(keys.indexOf('category'),1);
          if(keys.indexOf('subCategory')>-1)
          keys.splice(keys.indexOf('subCategory'),1);
          if(keys.indexOf('description')>-1)
          keys.splice(keys.indexOf('description'),1);
          for (let index = 0; index < response.length; index++) {
            const element = response[index];
            
            if (element['category']) {
              let temp = {
                category: element['category']
                // value: element['value']
              }
              for (let j = 0; j < keys.length; j++) {
                const keyEl = keys[j];
                temp['value'] = element[keyEl];
              }
              this.currentResults.push(temp)
            }
          }

          this.cd.detectChanges();
          
        }

      },
      (error) => {
        console.error(error)

      }
    );
  }
  getLabel(selectedComponent){
    return selectedComponent.label
  }
}
