import { Component, OnInit,Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-stage',
  templateUrl: './stage.component.html',
  styleUrls: ['./stage.component.scss']
})
export class StageComponent implements OnInit, OnChanges {

  @Input() stagesMap:any = {};

  stages:string[] = []
  currentStage:string;
  active:boolean = false;
  constructor() { }
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this.stages = this.stagesMap.stages;
    this.currentStage = this.stagesMap.currentStage;
  }

  ngOnInit() {
    // console.log("-------------------");
    this.stages = this.stagesMap.stages;
    this.currentStage = this.stagesMap.currentStage;
  }

  checkForActiveClosed(stage:string){
    if(stage == this.currentStage){
      // console.log("============");
      this.active = false;
      return 'current';
    }
    else{
      // if(!this.active){
      //   return 'true';
      // }
      // else{
      //   return 'false';
      // }
      let currPos = this.stages.indexOf(this.currentStage);
      let stagePos = this.stages.indexOf(stage);
      if(currPos > stagePos){
        return 'complete';
      }else{
        return 'disabled'
      }
    }
  }

}
