import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Entity } from '../models/datamodel.model';
import {EnvironmentData} from './environments.service';
import { AnalysisComponentList } from 'aws-sdk/clients/ec2';

@Injectable({
  providedIn: 'root'
})
export class EntityService {
  constructor(
    private httpClient: HttpClient,
    private environmentData: EnvironmentData
  ) { }

  getParameterizedEntityList(entityName: string, pageNumber: any, fetchRecords: any, entitySearchOperation: any): Observable<Entity[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Entity[]>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.entityurl + entityName + '/findForListing/'}${pageNumber},${fetchRecords}`;
    this.httpClient.post<Entity[]>(
      url,
      entitySearchOperation,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<Entity[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getEntityList(entityName: string, pageNumber: any, fetchRecords: any): Observable<Entity[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Entity[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.entityurl + entityName + '/findAll/'}${pageNumber},${fetchRecords}`;

    this.httpClient.get<Entity[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<Entity[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getEntityCount(entityName: string, entitySearchOperation: any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Entity[]>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.entityurl + entityName + '/count'}`;
    this.httpClient.post<any>(
      url,
      entitySearchOperation,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  saveEntity(entity?: Entity, map?: any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Entity>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.entityurl}${entity.name}`;
    this.httpClient.post<any>(
      url,
      map,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  saveEntityByName(entityName: string, map: any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Entity>();
    const url = `${envData.rootUrl + envData.statemachineroot + envData.entityurl}${entityName}`;
    this.httpClient.post<any>(
      url,
      map,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }


  submitEntity(entity?: Entity, map?: any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.entityurl + entity.name + '/submit'}`;

    this.httpClient.post<Entity>(
      url,
      map,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }


  updateEntity(entity?: Entity, map?: any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.entityurl + entity.name + '/update'}`;

    this.httpClient.post<any>(
      url,
      map,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  deleteEntityById(entityName: string, _id: string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.entityurl}` + "deletebyid/" + entityName + "/" + _id ;

    this.httpClient.delete<any>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          subject.next(response.body);
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }


  getEntity(entityName: string, id?: string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.entityurl + entityName + "/" + id}`;

    this.httpClient.get<any>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  duplicateEntity(entityName: string, id?: string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.entityurl + "duplicateEntity/" + entityName + "/" + id}`;

    this.httpClient.get<any>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getEntityLookupRef(referenceModel: string, id: any) {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.entityurl + envData.entitrefurl + referenceModel + "/" + id}`;

    this.httpClient.get<any>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getLookupEntities(referenceModel: string, map?: any): Observable<Entity[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Entity[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.entityurl + envData.lookupurl + referenceModel}`;

    this.httpClient.post<any>(
      url,
      map,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getRefLookupEntities(referenceModel: string, map?: any): Observable<Entity[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Entity[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.entityurl + envData.reflookupurl + referenceModel}`;

    this.httpClient.post<any>(
      url,
      map,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getReferenceEntity(referenceModel: string, map?: any): Observable<Entity[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Entity[]>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.entityurl + envData.getrefentity + referenceModel}`;

    this.httpClient.post<any>(
      url,
      map,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getLookupRefIds(referenceModel: string): Observable<any[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any[]>();

    const url = `${envData.rootUrl}` + "/flow/console/entity/lookupRef/findAllIds/" + referenceModel;

    this.httpClient.get<AnalysisComponentList[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );

    return subject.asObservable();
  }



  evaluateMvel(entityName: string, payload: any): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Entity>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.entityurl + envData.evaluateMvel + entityName}`;
    this.httpClient.post<any>(
      url,
      payload,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getPiExtractMetaData(idList){
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Entity[]>();
    let map ={
      "_ids":idList
    }
    const url = `${envData.rootUrl + envData.piExtractMeta}`;
    this.httpClient.post<any>(
      url,
      map,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getPiExtractMetaDataV2(entityId, dataModelName){
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Entity[]>();
    let map ={
      "piExtractedDataResponsesMap":[{
        "entityId":entityId,
        "dataModelName":dataModelName
      }]
    }
    const url = `${envData.rootUrl + '/flow/interfaces/api/automatons/piExtract/getextracteddata'}`;
    this.httpClient.post<any>(
      url,
      map,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );

    return subject.asObservable();
  }
  getPiExtractByBoundingBox(payload){
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<Entity[]>();
    payload["algorithmName"] ="azureFormRecognizer";
    
    const url = `${envData.rootUrl + '/flow/interfaces/api/automatons/piExtract/base64rawresponse'}`;
    this.httpClient.post<any>(
      url,
      payload,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  bulkSave(entityName : String , jsonData : any ) : Observable<any> { 
   const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    
    const url = `${envData.rootUrl + envData.statemachineroot + envData.entityurl}` + "bulkSave/" + entityName;
    console.log(url);
    if (jsonData) {
      this.httpClient.post<any>(
        url,
        jsonData,
        {
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      ).subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );
    } else {
      subject.error('File is null or empty');
    }
    return subject.asObservable();
  }

  bulkUpdateEntities(payload): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();

    const url = `${envData.rootUrl + envData.statemachineroot + envData.entityurl + 'bulkOperation'}`;

    this.httpClient.post<any>(
      url,
      payload,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }
  /** 
  *  This section checks Validation for entire Entity
  */
  getValidationForEntityId(entityId:string): Observable<any[]> {
    const subject = new Subject<any[]>();
    if(entityId){
      const envData = this.environmentData.getEnvData();
      const url = envData.rootUrl + "/flow/console/validation/" +entityId + "/0/50";

      this.httpClient.get<any[]>(
        url,
        {
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      )
        .subscribe(
          (response: HttpResponse<any[]>) => {
            if (response.body) {
              subject.next(response.body);
            }
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error

            subject.error(err);
          }
        );
    }else{
      subject.error(false);
    }
    return subject.asObservable();
  }
  getValidationCountForEntityId(entityId:string): Observable<any[]> {
    const subject = new Subject<any[]>();
    if(entityId){
      const envData = this.environmentData.getEnvData();
      const url = envData.rootUrl + "/flow/console/validation/count/" +entityId;

      this.httpClient.get<any[]>(
        url,
        {
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      )
        .subscribe(
          (response: HttpResponse<any[]>) => {
            if (response.body) {
              subject.next(response.body);
            }
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error

            subject.error(err);
          }
        );
    }else{
      subject.error(false);
    }
    return subject.asObservable();
  }
  /**
   * This section is for Getting not required field that are shown for a Datamodel
   */
  getEntityCommonFields(): Observable<any[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any[]>();

    const url = `${envData.rootUrl}` + "/flow/console/entity/getEntityCommonFields";

    this.httpClient.get<string[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  
}