import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { DataModel } from '../models/datamodel.model';

@Injectable({
  providedIn: 'root'
})
export class ImportService {
 


  constructor() { }

  public onDocumentUpload(event): Promise<any> {
     return new Promise((resolve, reject) => {
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          try {
            const binaryData = e.target.result;
            const workbook = XLSX.read(binaryData, { type: 'binary' });

            const sheetName = workbook.SheetNames[0];
            const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

            resolve(jsonData);
          } catch (error) {
            console.error('Error reading Excel file:', error);
            reject(error);
          }
        };

        reader.readAsBinaryString(file);
      } else {
        reject('No file selected');
      }
    });
  }
  
  downloadTemplate(selectedDataModel: DataModel): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const fields = selectedDataModel.fields;
        const fieldNames = fields.map(field => field.name);
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([fieldNames]);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const fileName = selectedDataModel.label+".xlsx";
        XLSX.writeFile(wb, fileName);
        resolve();
      } catch (error) {
        console.error('Error creating Excel template:', error);
        reject(error);
      }
    });
  }
}