declare var closeModal: any;
import { v4 as uuid } from 'uuid';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { Agent, Episode } from '../models/conversation.model';
import { ChatMessage } from '../models/helper.model';
import { ChatFilter, SortBy } from '../models/tasks.model';
import { AgentService } from '../service/agent.service';
import { ConversationService, ScrollService } from '../service/helper.service';
import { SnackBarService, UniversalUser } from '../service/shared.service';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { EnvironmentData } from '../service/environments.service';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements OnInit, OnDestroy {

  private static readonly SOURCE_WORKBENCH = {'label': 'Workbench', 'value': 'AUTO'};
  private static readonly SOURCE_WHATSAPP = {'label': 'WhatsApp', 'value': 'WHATSAPP'};

  private static readonly SORT_BY_USER_ACTIVITY_AND_MISSED_COUNT = {'label': 'User Activity & Missed Count', 'value': ['episodeContext.missedExpressionCount', 'episodeContext.lastUserMessageTime']};
  private static readonly SORT_BY_MISSED_COUNT = {'label': 'Missed Count', 'value': ['episodeContext.missedExpressionCount']};
  private static readonly SORT_BY_USER_ACTIVITY = {'label': 'User Activity', 'value': ['episodeContext.lastUserMessageTime']};
  private static readonly SORT_BY_START_TIME = {'label': 'Start Time', 'value': ['startTime']};
  private static readonly SORT_BY_UPDATED_TIME = {'label': 'Updated Time', 'value': ['modifiedTime']};
  
  private static readonly SORT_DIR_ASENDING = {'label': 'Ascending', 'value': 1};
  private static readonly SORT_DIR_DESCENDING = {'label': 'Descending', 'value': -1};

  dropdownSettingsMultiple: IDropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true
  };

  dropdownSettingsSingle: IDropdownSettings = {
    singleSelection: true,
    idField: 'value',
    textField: 'label',
    allowSearchFilter: true
  };

  sourceMaster = [
    ConversationComponent.SOURCE_WORKBENCH,
    ConversationComponent.SOURCE_WHATSAPP
  ];

  sortByMaster = [
    ConversationComponent.SORT_BY_USER_ACTIVITY_AND_MISSED_COUNT,
    ConversationComponent.SORT_BY_MISSED_COUNT,
    ConversationComponent.SORT_BY_USER_ACTIVITY,
    ConversationComponent.SORT_BY_START_TIME,
    ConversationComponent.SORT_BY_UPDATED_TIME
  ];

  sortDirMaster = [
    ConversationComponent.SORT_DIR_ASENDING,
    ConversationComponent.SORT_DIR_DESCENDING
  ]

  agentMaster = [];
  selectedSourceList: any[];
  selectedAgentList: any[];
  selectedSortBy: any;
  selectedSortDir: any;

  chatFilter: ChatFilter = new ChatFilter();
  tempFilter: ChatFilter = new ChatFilter();

  quikSearchText: string;
  agentMessage: string;
  episodeList: Episode[];
  selectedEpisode: Episode;
  exitstingEpisode: Episode;
  chatMessageList: ChatMessage[];
  agentList: Agent[];
  loading;
  pollBargeInEpisode: boolean = true;
  pollEpisodeChat: boolean = true;
  episodePollingFrequency = 15000;
  chatPollingFrequency = 3000;
  fetchingEpisodeDetails: boolean = false;
  fetchingEpisodeChat: boolean = false;
  pageSize: number = 50;
  p: number = 1;
  setAutoHide: boolean = true;
  autoHide: number = 2000;
  showChatFilter:boolean = false;
  conversationCount: number = 0;

  episodeCountOptions;
  episodeCountData;
  customerId: string;
  viewMode: boolean = false;
  bsModalRef: BsModalRef;
  refreshing: boolean = false;
  sendBuffering: boolean = false;
  noMoreEpisodes: boolean = false;

  whatsAppTemplateText:string;
  enableTaskAutoRefresh: boolean = false;
  selectedEpisodeLastUserMessageTime: any = null;

  private subscriptionFetchAgents: Subscription;
  private subscriptionBargeIn: Subscription;
  private subscriptionChatMessages: Subscription;
  private subscriptionEpisodeDetails: Subscription;
  private subscriptionSaveEpisode: Subscription;
  private subscriptionSendAgentMessage: Subscription;
  private subscriptionWhatsAppTemplatedMessage: Subscription;
  private subscriptionAutoRefresh: Subscription;

  // websocket
  private stompClient: any = null;

  constructor(
    private conversationService: ConversationService,
    private agentService: AgentService,
    private universalUser: UniversalUser,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private modalService: BsModalService,
    private router: Router,
    private scrollService: ScrollService,
    private snackBarService: SnackBarService,
    private environmentData: EnvironmentData
  ) {
    this.quikSearchText = '';
    this.agentMessage = '';
    this.loading = false;
    this.episodeList = [];
    this.selectedEpisode = null;
    this.exitstingEpisode = null;
    this.chatMessageList = [];
  }
  
  ngOnInit() {
    // this.connectToWebSocket();
    this.setDefaultSortSettings();

    let episodeId = this.route.snapshot.paramMap.get('episodeId');
    if (!episodeId) {
      this.route.queryParams.subscribe(params => {
        episodeId = params["entityId"];
        this.customerId = params["customerId"];
      })
    }

    if (episodeId && this.customerId) {
      this.viewMode = true;
      this.getEpisode(episodeId);
    } else if (episodeId && !this.customerId) {
      this.showEpisodeDetails(episodeId, true);
    } else {
      this.getEpisodesApplicableForBargeIn(true);
    }

    this.getChatMessages(true);
    this.fetchAllAgents();

    this.enableTaskAutoRefresh = true;
    setTimeout(() => {
      this.startAutoRefresh();
    }, this.episodePollingFrequency);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    this.ngOnDestroy();

    /**
     * Just in case if you want to confirm the action with a prompt.
     * 
     * // let result = confirm("Are you sure, you want to leave this page?");
     * // if (result) {
     * //   console.log('window being refreshed', event);
     * //   this.ngOnDestroy();
     * // } else {
     * //   event.returnValue = false;  // Stay on the same page
     * // }
     */
  }

  /**
   * Not needed, since the scenario has been handled in beforeUnloadHandler
   * 
   * // @HostListener('window:unload', ['$event'])
   * // unloadHandler(event) {
   * //   console.log('window being closed', event);
   * //   this.ngOnDestroy();
   * // }
   */

  ngOnDestroy(): void {
    this.enableTaskAutoRefresh = false;
    this.pollBargeInEpisode = false;
    this.pollEpisodeChat = false;
    this.bargeOut();

    if (this.subscriptionFetchAgents && !this.subscriptionFetchAgents.closed) {
      this.subscriptionFetchAgents.unsubscribe();
    }
    if (this.subscriptionChatMessages && !this.subscriptionChatMessages.closed) {
      this.subscriptionChatMessages.unsubscribe();
    }
    if (this.subscriptionEpisodeDetails && !this.subscriptionEpisodeDetails.closed) {
      this.subscriptionEpisodeDetails.unsubscribe();
    }
    if (this.subscriptionBargeIn && !this.subscriptionBargeIn.closed) {
      this.subscriptionBargeIn.unsubscribe();
    }
    if (this.subscriptionSaveEpisode && !this.subscriptionSaveEpisode.closed) {
      this.subscriptionSaveEpisode.unsubscribe();
    }
    if (this.subscriptionSendAgentMessage && !this.subscriptionSendAgentMessage.closed) {
      this.subscriptionSendAgentMessage.unsubscribe();
    }
    if (this.subscriptionWhatsAppTemplatedMessage && !this.subscriptionWhatsAppTemplatedMessage.closed) {
      this.subscriptionWhatsAppTemplatedMessage.unsubscribe();
    }
    if (this.subscriptionAutoRefresh && !this.subscriptionAutoRefresh.closed) {
      this.subscriptionAutoRefresh.unsubscribe();
    }
    this.disconnectFromWebSocket();
  }

  connectToWebSocket() {
    // console.log("Initialize WebSocket Connection");
    const envData = this.environmentData.getEnvData();
    const webSocketEndPoint: string = envData.rootUrl + "/flow/websocket/converse";
    let ws = new SockJS(webSocketEndPoint);
    this.stompClient = Stomp.over(ws);
    const _this = this;
    this.stompClient.connect({username: this.universalUser.getUser().username}, function (frame) {
        _this.stompClient.subscribe("/user/queue/tobargedinagent", (receivedEvent) => {
          // console.log("on message received ", receivedEvent);
          if (receivedEvent && receivedEvent.body) {
            const body = JSON.parse(receivedEvent.body);
            if (body.messages) {
              _this.appendToChatMessages(body.messages);
            }
          }
            
        });
        _this.stompClient.reconnect_delay = 2000;
    }, this.errorCallBack);
  }

  errorCallBack(error) {
    console.log("errorCallBack -> " + error)
    setTimeout(() => {
        // console.log("try...");
        this.connectToWebSocket();
    }, 5000);
  }

  disconnectFromWebSocket() {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
  }

  startAutoRefresh() {
    if (!this.enableTaskAutoRefresh) {
      return;
    }

    
    if (!this.chatFilter || this.chatFilter.isDefaultFilter()) {
      let autoRefreshFilter: ChatFilter = new ChatFilter();

      this.subscriptionAutoRefresh = this.conversationService.countConversations(autoRefreshFilter)
        .subscribe(
          newConversationCount => {
            if (this.conversationCount < newConversationCount) {
              autoRefreshFilter.pageNo = 0;
              autoRefreshFilter.pageSize = newConversationCount - this.conversationCount;

              this.subscriptionAutoRefresh = this.conversationService.searchConversations(autoRefreshFilter)
                .subscribe(
                  conversationList => {
                    this.appendAutoRefreshTasks(conversationList);
                    if (!this.episodeList || this.episodeList.length == 0) {
                      this.episodeList = [];
                    }
                  },
                  error => {

                  }
                );
            }
          },
          error => {

          }
        );
    }

    setTimeout(() => {
      this.startAutoRefresh();
    }, this.episodePollingFrequency);
  }

  appendAutoRefreshTasks(episodes: Episode[]) {
    if (!episodes || episodes.length == 0) {
      return;
    }

    let newEpisodeCount = 0;
    
    for (let episode of episodes.reverse()) {
      episode['isActive'] = false;

      let duplicateEpisode = false;
      let insertionIndex = -1;

      for (let episodeIndex = 0; episodeIndex < this.episodeList.length; episodeIndex++) {
        const tempEpisode = this.episodeList[episodeIndex];

        if (tempEpisode && tempEpisode._id) {
          if (tempEpisode._id == episode._id) {
            duplicateEpisode = true;
            insertionIndex = episodeIndex;
            break;
          } else {
            if (episode.episodeContext.missedExpressionCount >= tempEpisode.episodeContext.missedExpressionCount) {
              if (![null, undefined].includes(episode.episodeContext.lastUserMessageTime) && ![null, undefined].includes(tempEpisode.episodeContext.lastUserMessageTime)) {
                if (episode.episodeContext.lastUserMessageTime['$date'] >= tempEpisode.episodeContext.lastUserMessageTime['$date']) {
                  insertionIndex = episodeIndex;
                  break;
                }
              } else if (![null, undefined].includes(episode.modifiedTime) && ![null, undefined].includes(tempEpisode.modifiedTime)) {
                if (episode.modifiedTime['$date'] >= tempEpisode.modifiedTime['$date']) {
                  insertionIndex = episodeIndex;
                  break;
                }
              }
            }

            /*
            if (this.selectedSortBy[0] == ConversationComponent.SORT_BY_USER_ACTIVITY_AND_MISSED_COUNT) {
              if (![null, undefined].includes(episode.episodeContext.missedExpressionCount) && ![null, undefined].includes(tempEpisode.episodeContext.missedExpressionCount)) {
                if (SortBy.isDescendingSort(this.chatFilter.sortList)) {
                  if (episode.episodeContext.missedExpressionCount >= tempEpisode.episodeContext.missedExpressionCount) {
                    if (![null, undefined].includes(episode.episodeContext.lastUserMessageTime) && ![null, undefined].includes(tempEpisode.episodeContext.lastUserMessageTime)) {
                      if (episode.episodeContext.lastUserMessageTime['$date'] >= tempEpisode.episodeContext.lastUserMessageTime['$date']) {
                        insertionIndex = episodeIndex;
                        break;
                      }
                    } else if (![null, undefined].includes(episode.modifiedTime) && ![null, undefined].includes(tempEpisode.modifiedTime)) {
                      if (episode.modifiedTime['$date'] >= tempEpisode.modifiedTime['$date']) {
                        insertionIndex = episodeIndex;
                        break;
                      }
                    }
                  }
                } else {
                  if (episode.episodeContext.missedExpressionCount < tempEpisode.episodeContext.missedExpressionCount) {
                    if (![null, undefined].includes(episode.episodeContext.lastUserMessageTime) && ![null, undefined].includes(tempEpisode.episodeContext.lastUserMessageTime)) {
                      if (episode.episodeContext.lastUserMessageTime['$date'] < tempEpisode.episodeContext.lastUserMessageTime['$date']) {
                        insertionIndex = episodeIndex;
                        break;
                      }
                    } else if (![null, undefined].includes(episode.modifiedTime) && ![null, undefined].includes(tempEpisode.modifiedTime)) {
                      if (episode.modifiedTime['$date'] < tempEpisode.modifiedTime['$date']) {
                        insertionIndex = episodeIndex;
                        break;
                      }
                    }
                  }
                }
              }
            } else if (this.selectedSortBy[0] == ConversationComponent.SORT_BY_USER_ACTIVITY) {
              if (![null, undefined].includes(episode.episodeContext.lastUserMessageTime) && ![null, undefined].includes(tempEpisode.episodeContext.lastUserMessageTime)) {
                if (SortBy.isDescendingSort(this.chatFilter.sortList)) {
                  if (episode.episodeContext.lastUserMessageTime['$date'] >= tempEpisode.episodeContext.lastUserMessageTime['$date']) {
                    insertionIndex = episodeIndex;
                    break;
                  }
                } else {
                  if (episode.episodeContext.lastUserMessageTime['$date'] < tempEpisode.episodeContext.lastUserMessageTime['$date']) {
                    insertionIndex = episodeIndex;
                    break;
                  }
                }
              } else if (![null, undefined].includes(episode.modifiedTime) && ![null, undefined].includes(tempEpisode.modifiedTime)) {
                if (SortBy.isDescendingSort(this.chatFilter.sortList)) {
                  if (episode.modifiedTime['$date'] >= tempEpisode.modifiedTime['$date']) {
                    insertionIndex = episodeIndex;
                    break;
                  }
                } else {
                  if (episode.modifiedTime['$date'] < tempEpisode.modifiedTime['$date']) {
                    insertionIndex = episodeIndex;
                    break;
                  }
                }
              }
            } else if (this.selectedSortBy[0] == ConversationComponent.SORT_BY_MISSED_COUNT) {
              if (![null, undefined].includes(episode.episodeContext.missedExpressionCount) && ![null, undefined].includes(tempEpisode.episodeContext.missedExpressionCount)) {
                if (SortBy.isDescendingSort(this.chatFilter.sortList)) {
                  if (episode.episodeContext.missedExpressionCount >= tempEpisode.episodeContext.missedExpressionCount) {
                    insertionIndex = episodeIndex;
                    break;
                  }
                } else {
                  if (episode.episodeContext.missedExpressionCount < tempEpisode.episodeContext.missedExpressionCount) {
                    insertionIndex = episodeIndex;
                    break;
                  }
                }
              }
            } else if (this.selectedSortBy[0] == ConversationComponent.SORT_BY_START_TIME) {
              if (![null, undefined].includes(episode.startTime) && ![null, undefined].includes(tempEpisode.startTime)) {
                if (SortBy.isDescendingSort(this.chatFilter.sortList)) {
                  if (episode.startTime['$date'] >= tempEpisode.startTime['$date']) {
                    insertionIndex = episodeIndex;
                    break;
                  }
                } else {
                  if (episode.startTime['$date'] < tempEpisode.startTime['$date']) {
                    insertionIndex = episodeIndex;
                    break;
                  }
                }
              }
            } else if (this.selectedSortBy[0] == ConversationComponent.SORT_BY_UPDATED_TIME) {
              if (![null, undefined].includes(episode.modifiedTime) && ![null, undefined].includes(tempEpisode.modifiedTime)) {
                if (SortBy.isDescendingSort(this.chatFilter.sortList)) {
                  if (episode.modifiedTime['$date'] >= tempEpisode.modifiedTime['$date']) {
                    insertionIndex = episodeIndex;
                    break;
                  }
                } else {
                  if (episode.modifiedTime['$date'] < tempEpisode.modifiedTime['$date']) {
                    insertionIndex = episodeIndex;
                    break;
                  }
                }
              }
            }
            */
          }
        }
      }

      if (insertionIndex > -1) {
        if (!duplicateEpisode) {
          newEpisodeCount++;
          episode.newEpisode = true;

          this.reCheckBargedInProperties(episode);

          this.episodeList.splice(insertionIndex, 0, episode);
        } else {
          this.episodeList[insertionIndex] = episode;
        }
      } else {
        newEpisodeCount++;
        episode.newEpisode = true;

        this.episodeList.push(episode);
      }
    }

    if (newEpisodeCount > 0) {
      let message = newEpisodeCount + (newEpisodeCount == 1 ? ' new conversation available' : ' new conversations available');
      this.conversationCount += newEpisodeCount;
      this.snackBarService.openSnackBar(message, 'success');
    }
  }

  reCheckBargedInProperties(episode: Episode) {
    if (this.selectedEpisode && this.selectedEpisode._id == episode._id) {
      if (!this.isEpisodeBargeable(this.selectedEpisode) && this.isEpisodeBargeable(episode)) {
        this.whatsAppTemplateText = null;
        this.agentMessage = '';
      } else if ((this.isEpisodeBargeable(this.selectedEpisode) && !this.isEpisodeBargeable(episode)) || (!this.isEpisodeBargeable(this.selectedEpisode) && !this.whatsAppTemplateText)) {
        this.getDomainByAgentId().subscribe(
          templatedMessagePresent => {
            if (templatedMessagePresent) {
              this.agentMessage = this.whatsAppTemplateText;
            } else {
              if (this.bargedIntoSelectedEpisode()) {
                this.bargeOut();
                this.agentMessage = '';
                this.snackBarService.openSnackBar("It's been more than 24 hours since user's last activity. WhatsApp channel for this conversation has been disabled.", 'error');
              }
            }
          }, error => {
            if (this.bargedIntoSelectedEpisode()) {
              this.bargeOut();
              this.agentMessage = '';
              this.snackBarService.openSnackBar("It's been more than 24 hours since user's last activity. WhatsApp channel for this conversation has been disabled.", 'error');
            }
          }
        );
      }
    }
  }

  getEpisode(episodeId: string) {
    if (!episodeId || episodeId.trim().length == 0) {
      return;
    }

    if ((!this.selectedEpisode || this.selectedEpisode._id != episodeId) && this.episodeList && this.episodeList.length > 0) {
      let markedCurrentEpisodeInactive = false;
      let markedNewEpisodeActive = false;

      for (let episode of this.episodeList) {
        if (this.selectedEpisode && episode && episode._id && episode._id == this.selectedEpisode._id) {
          episode['isActive'] = false;
          markedCurrentEpisodeInactive = true;
          episode['newEpisode'] = false;
        }
        
        if (episodeId && episode && episode._id && episode._id == episodeId) {
          episode['isActive'] = true;
          episode.newEpisode = false;
          markedNewEpisodeActive = true;
        }

        if (markedCurrentEpisodeInactive && markedNewEpisodeActive) {
          break;
        }
      }
    }

    this.subscriptionBargeIn = this.conversationService.getEpisode(episodeId)
      .subscribe(
        episode => {          
          if (!this.selectedEpisode || this.selectedEpisode._id != episode._id) {
            this.agentMessage = '';
            this.whatsAppTemplateText = null;
            this.selectedEpisode = episode;
            this.chatMessageList = [];
            this.getChatMessages();
          } else {
            this.reCheckBargedInProperties(episode);
            this.selectedEpisode = episode;
          }

          if (this.episodeList && this.episodeList.length > 0) {
            let swapIndex = -1;

            for (let index = 0; index < this.episodeList.length; index++) {
              const tempEpisode = this.episodeList[index];

              if (tempEpisode && tempEpisode._id && episode._id && episode._id == tempEpisode._id) {
                swapIndex = index;
                episode['isActive'] = tempEpisode['isActive'] ? tempEpisode['isActive'] : false;
                break;
              }
            }

            if (swapIndex > -1) {
              this.episodeList[swapIndex] = episode;
            }
          }
        },
        error => {

        }
      )
  }

  getEpisodesApplicableForBargeIn(initFlag?: boolean) {
    this.refreshing = true;
    if (!this.pollBargeInEpisode) {
      return;
    }

    if (this.subscriptionBargeIn && !this.subscriptionBargeIn.closed) {
      this.subscriptionBargeIn.unsubscribe();
    }

    if (this.subscriptionAutoRefresh && !this.subscriptionAutoRefresh.closed) {
      this.subscriptionAutoRefresh.unsubscribe();
    }

    if (initFlag) {
      this.episodeList = [];

      this.conversationService.countConversations(this.chatFilter).subscribe(
        episodeCount => {
          this.conversationCount = episodeCount;
        }, error => {

        }
      );
    }

    this.subscriptionBargeIn = this.conversationService.searchConversations(this.chatFilter)
      .subscribe(
        episodeList => {
          this.noMoreEpisodes = episodeList && episodeList.length == 0;

          if (episodeList) {
            let episodePresent = false;
            
            for (let episode of episodeList) {
              if (initFlag) {
                episode['isActive'] = false;
              }
              
              if (this.exitstingEpisode && this.exitstingEpisode._id && episode && episode._id && episode._id == this.exitstingEpisode._id) {
                episodePresent = true;
              }
            }

            if (this.exitstingEpisode && this.exitstingEpisode._id && !episodePresent) {
              this.episodeList.push(this.exitstingEpisode);

              if (initFlag) {
                this.exitstingEpisode['isActive'] = true;
                this.getEpisode(this.exitstingEpisode._id);
              }
            }

            this.episodeList = this.episodeList.concat(episodeList);

            if (initFlag && !this.exitstingEpisode && this.episodeList.length > 0) {
              this.episodeList[0]['isActive'] = true;
              this.getEpisode(this.episodeList[0]._id);
            }
          }

          this.refreshing = false;
        },
        error => {
          this.refreshing = false;
        }
      );
  }

  fetchAllAgents() {
    this.subscriptionFetchAgents = this.conversationService.getAllAgents().subscribe(
      agentList => {
        if (agentList && agentList.length > 0) {
          this.agentList = agentList;
          let isBargeable = false;
          for (const agent of agentList) {
            if (agent) {
              if(agent.uiComponent.isBargeable){
                isBargeable = true;
                break;
             }

             if (isBargeable) {
              let agentDropDownItem = {
                'label': agent.name,
                'value': agent._id
              };

              this.agentMaster.push(agentDropDownItem);
            } else {
              this.router.navigateByUrl("/dashboard");
              this.snackBarService.openSnackBar("There is no conversation for this account");
            }
            }
          }
        }
      }, error => {

      }
    );
  }

  getAgentNameById(agentId: string) {
    if (agentId && agentId.trim().length > 0) {
      for (const agent of this.agentList) {
        if (agent && agent._id && agent._id == agentId) {
          return agent.name;
        }
      }
    }

    return null;
  }

  shouldDisplayLoadMore() {
    return this.episodeList && this.episodeList.length > 0 && !this.noMoreEpisodes;
  }

  loadMore() {
    this.chatFilter.pageNo++;
    this.getEpisodesApplicableForBargeIn();
  }

  showEpisodeDetails(episodeId: string, viewMode?: boolean) {
    this.fetchingEpisodeDetails = true;

    this.subscriptionEpisodeDetails = this.conversationService.getEpisode(episodeId)
      .subscribe(
        episode => {
          if (episode) {
            this.selectedEpisode = episode;

            this.exitstingEpisode = episode;
            this.getChatMessages();
          }

          // this.scrollToView('episodeDetailsCard');
          this.fetchingEpisodeDetails = false;
        },
        error => {
          // Do something with it
          this.fetchingEpisodeDetails = false;
        }
      );
  }

  appendToChatMessages(messages: ChatMessage[]) {
    if (!this.bargedIntoSelectedEpisode || !this.selectedEpisode) {
      return;
    }
    this.chatMessageList = this.chatMessageList.concat(messages);
    let tempMessageList = Object.assign(Array<ChatMessage>(), this.chatMessageList);
    
    for (const tempMessage of tempMessageList) {
      if (tempMessage && tempMessage.to == 'AUTO') {
        if (!this.selectedEpisodeLastUserMessageTime || this.selectedEpisodeLastUserMessageTime['$date'] != tempMessage.messageTime['$date']) {
          this.selectedEpisodeLastUserMessageTime = tempMessage.messageTime;

          if (this.selectedEpisode && this.selectedEpisode.episodeContext && this.selectedEpisode.episodeContext.lastUserMessageTime
            && this.selectedEpisode.episodeContext.lastUserMessageTime['$date'] < tempMessage.messageTime['$date']) {
            this.getEpisode(this.selectedEpisode._id);
          }
        }

        break;
      }
    }
    this.scrollToView('chatWindowBottomDiv');
  }

  getChatMessages(refreshCall?: boolean) {
    if (refreshCall) {
      if (!this.pollEpisodeChat) {
        return;
      } else if (this.pollEpisodeChat && !this.bargedIntoSelectedEpisode()) {
        setTimeout(() => {
          this.getChatMessages(true);
        }, this.chatPollingFrequency);
  
        return;
      }
    } else {
      if (!this.selectedEpisode) {
        return;
      }
    }

    if (this.subscriptionChatMessages && !this.subscriptionChatMessages.closed) {
      this.subscriptionChatMessages.unsubscribe();
    }

    if (!refreshCall) {
      this.fetchingEpisodeChat = true;
    }

    this.subscriptionChatMessages = this.conversationService.getChat(this.selectedEpisode._id)
      .subscribe(
        chatMessages => {
          if (chatMessages && chatMessages.length >= this.chatMessageList.length) {
            if (chatMessages.length > this.chatMessageList.length && this.bargedIntoSelectedEpisode()) {
              this.scrollToView('chatWindowBottomDiv');
            }

            this.chatMessageList = chatMessages;
          }

          if (this.chatMessageList && this.chatMessageList.length > 0) {
            let tempMessageList = Object.assign(Array<ChatMessage>(), this.chatMessageList);
            tempMessageList.reverse();

            for (const tempMessage of tempMessageList) {
              if (tempMessage && tempMessage.to == 'AUTO') {
                if (!this.selectedEpisodeLastUserMessageTime || this.selectedEpisodeLastUserMessageTime['$date'] != tempMessage.messageTime['$date']) {
                  this.selectedEpisodeLastUserMessageTime = tempMessage.messageTime;

                  if (refreshCall && this.selectedEpisode && this.selectedEpisode.episodeContext && this.selectedEpisode.episodeContext.lastUserMessageTime
                    && this.selectedEpisode.episodeContext.lastUserMessageTime['$date'] < tempMessage.messageTime['$date']) {
                    this.getEpisode(this.selectedEpisode._id);
                  }
                }

                break;
              }
            }
          }

          this.reCheckBargedInProperties(this.selectedEpisode);

          if (refreshCall) {
            setTimeout(() => {
              this.getChatMessages(true);
            }, this.chatPollingFrequency);
          }

          this.fetchingEpisodeChat = false;
        },
        error => {
          // Do something with it
          this.fetchingEpisodeChat = false;
        }
      );
  }

  getSafeHTML(inlineHtml: string) {
    if (inlineHtml) {
      return this.sanitizer.bypassSecurityTrustHtml(inlineHtml);
    }
    return '';
  }

  requestFocus(divId: string) {
    if (divId) {
      setTimeout(() => {
        const htmlElement = document.getElementById(divId);
        if (htmlElement) {
          htmlElement.focus();
        }
      }, 10);
    }
  }


  hasUserAlreadyBargedIn() {
    let bargedIn = false;

    for (let episode of this.episodeList) {
      if (episode && episode._id && episode.bargedInAgentId) {
        bargedIn = true;
        break;
      }
    }
    return bargedIn;
  }

  bargedIntoSelectedEpisode() {
    return this.hasUserAlreadyBargedIn() && this.selectedEpisode && this.selectedEpisode.bargedInAgentId && this.selectedEpisode.bargedInAgentId === this.universalUser.getUser()._id;
  }

  checkAndBargeIn() {
    if (this.isEpisodeBargeable(this.selectedEpisode)) {
      this.bargeIn();
    } else {
      this.getDomainByAgentId().subscribe(
        templatedMessagePresent => {
          if (templatedMessagePresent) {
            this.agentMessage = this.whatsAppTemplateText;
            this.bargeIn();
          } else {
            this.agentMessage = '';
            this.snackBarService.openSnackBar("It's been more than 24 hours since user's last activity. WhatsApp channel for this conversation has been disabled.", 'error');
          }
        }, error => {
          this.agentMessage = '';
          this.snackBarService.openSnackBar("It's been more than 24 hours since user's last activity. WhatsApp channel for this conversation has been disabled.", 'error');
        }
      );
    }
  }

  bargeIn() {
    if (this.selectedEpisode && this.selectedEpisode._id) {
      const tempEpisode = JSON.parse(JSON.stringify(this.selectedEpisode));

      for (let episode of this.episodeList) {
        if (episode && episode._id && this.selectedEpisode._id === episode._id) {
          episode.bargedInAgentId = this.universalUser.getUser()._id;
          tempEpisode.bargedInAgentId = this.universalUser.getUser()._id;
          break;
        }
      }

      this.conversationService.engageConversation(this.selectedEpisode._id, true).subscribe(
        engagedEpisode => {
          if (engagedEpisode && engagedEpisode._id) {
            this.selectedEpisode = engagedEpisode;
          }

          this.pollBargeInEpisode = true;
          this.fetchingEpisodeChat = false;
        },
        error => {
          // Do something with it
          this.pollBargeInEpisode = true;
          this.fetchingEpisodeChat = false;
        }
      );
    }
  }

  bargeOut() {
    if (this.selectedEpisode && this.selectedEpisode._id) {
      this.whatsAppTemplateText = null;
      this.agentMessage = '';

      const tempEpisode = JSON.parse(JSON.stringify(this.selectedEpisode));

      for (let episode of this.episodeList) {
        if (episode && episode._id && this.selectedEpisode._id === episode._id) {
          episode.bargedInAgentId = null;
          tempEpisode.bargedInAgentId = null;
          break;
        }
      }

      this.conversationService.engageConversation(this.selectedEpisode._id, false).subscribe(
        disengagedEpisode => {
          if (disengagedEpisode && disengagedEpisode._id) {
            this.selectedEpisode = disengagedEpisode;
          }

          this.pollBargeInEpisode = true;
          this.fetchingEpisodeChat = false;
        },
        error => {
          // Do something with it
          this.pollBargeInEpisode = true;
          this.fetchingEpisodeChat = false;
        }
      );
    }
  }

  getDomainByAgentId() {
    const subject = new Subject<any>();

    let domainId = null;

    if (this.selectedEpisode.domainId && this.selectedEpisode.domainId.trim().length > 0) {
      domainId = this.selectedEpisode.domainId;
    } else if (this.agentList && this.agentList.length > 0) {
      for (const agent of this.agentList) {
        if (agent && agent._id && agent.domainId && this.selectedEpisode.agentId == agent._id) {
          domainId = agent.domainId;
        }
      }
    }

    if (domainId) {
      this.subscriptionWhatsAppTemplatedMessage = this.conversationService.getWhatsAppTemplate(domainId)
        .subscribe(
          domain => {
            if (domain && domain.whatsAppTemplates) {
              this.whatsAppTemplateText = domain.whatsAppTemplates[this.selectedEpisode.langSelected];
              this.agentMessage = this.whatsAppTemplateText;

              subject.next(true);
            } else {
              subject.next(false);
            }
          }, error => {
            subject.error(error);
          }
        );
    } else {
      subject.next(false);
    }

    return subject.asObservable();
  }

  closeEpisode(selectedEpisode: Episode): void {
    if (selectedEpisode) {
      this.whatsAppTemplateText = null;
      this.agentMessage = '';

      this.bsModalRef.hide();
      selectedEpisode.bargedInAgentId = null;
      selectedEpisode.episodeClosureAgentId = this.universalUser.getUser()._id;
      selectedEpisode.modifiedTime = new Date();
      selectedEpisode.statusCd = "CLOSED";

      this.conversationService.closeConversation(this.selectedEpisode._id).subscribe(
        closedEpisode => {
          if (closedEpisode && closedEpisode.statusCd) {
            let closedEpisodeIndex = -1;

            for (let index = 0; index < this.episodeList.length; index++) {
              const episode = this.episodeList[index];

              if (episode && closedEpisode && closedEpisode._id == episode._id) {
                closedEpisodeIndex = index;
                break;
              }
            }

            if (closedEpisodeIndex > -1) {
              this.episodeList.splice(closedEpisodeIndex, 1);
            }

            this.openSnackBar("Conversation cleared successfully");
            this.selectedEpisode = null;
          }
        }, error => {

        }
      );
    }
  }

  openSnackBar(message) {
    let config: MatSnackBarConfig = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'center';
    config.duration = this.setAutoHide ? this.autoHide : 0;

    if (message.indexOf("Error while saving episode") > -1) {
      config.panelClass = ['errorMsg'];
    } else {
      config.panelClass = ['success'];
    }

    this.snackBar.open(message, "", config);
  }

  checkAndSendMessage() {
    if (!this.isValueValid(this.agentMessage)) {
      alert("Please do not enter '<' '>' characters")
      return;
    }

    if (this.isEpisodeBargeable(this.selectedEpisode) || (this.whatsAppTemplateText && this.agentMessage && this.whatsAppTemplateText == this.agentMessage)) {
      this.sendAgentMessage();
    } else {
      this.sendBuffering = true;

      this.getDomainByAgentId().subscribe(
        templatedMessagePresent => {
          this.sendBuffering = false;

          if (templatedMessagePresent) {
            this.agentMessage = this.whatsAppTemplateText;
          } else {
            this.bargeOut();
            this.agentMessage = '';
            this.snackBarService.openSnackBar('Templated message not found to be sent via WhatsApp channel; disengaging the conversation.', 'error');
          }
        }, error => {
          this.sendBuffering = false;
          this.bargeOut();
          this.agentMessage = '';
          this.snackBarService.openSnackBar('Templated message not found to be sent via WhatsApp channel; disengaging the conversation.', 'error');
        }
      );
    }
  }

  sendAgentMessage() {
    if (this.bargedIntoSelectedEpisode()) {
      const messagePayload = {
        "agentId": this.selectedEpisode.agentId,
        "episodeId": this.selectedEpisode._id,
        "messageText": this.agentMessage,
        "conversationId": this.selectedEpisode.conversationId,
        "from_user": this.universalUser.getUser()._id
      };

      const newMessage = new ChatMessage();
      newMessage.agentId = this.selectedEpisode.agentId;
      newMessage.episodeId = this.selectedEpisode._id;
      newMessage.messageText = messagePayload.messageText;
      newMessage.conversationId = this.selectedEpisode.conversationId;
      newMessage.from = "AUTO";
      newMessage.messageTime = new Date();

      this.chatMessageList.push(newMessage);
      this.scrollToView('chatWindowBottomDiv');

      if (this.subscriptionChatMessages && !this.subscriptionChatMessages.closed) {
        this.subscriptionChatMessages.unsubscribe();
      }

      this.subscriptionSendAgentMessage = this.conversationService.sendAgentMessage(messagePayload)
        .subscribe(
          response => {
            // Do something with it, if needed
          },
          error => {
            // Do something with it
          }
        );
    }

    if(this.whatsAppTemplateText == null || this.whatsAppTemplateText == undefined) {
      this.agentMessage = '';
    }
    
    this.requestFocus('agentInput');
  }

  isValueValid(s: any) {
    if (typeof s !== 'string') {
      return true;
    }
    const pattern = new RegExp(/<\/?[^>]*>/);
    return !pattern.test(s);
  }

  getEpisodeStartTimeDiff(episode: Episode) {
    if (episode && episode.startTime) {
      return this.timeDifference(new Date().getTime(), episode.startTime['$date']);
    }

    return '';
  }

  getEpisodeLastUserActivityDiff(episode: Episode) {
    if (episode && episode.episodeContext && episode.episodeContext.lastUserMessageTime) {
      return this.timeDifference(new Date().getTime(), episode.episodeContext.lastUserMessageTime['$date']);
    } else if (episode && episode.modifiedTime) {
      return this.timeDifference(new Date().getTime(), episode.modifiedTime['$date']);
    }

    return '';
  }

  timeDifference(current, previous) {
    let msPerMinute = 60 * 1000;
    let msPerHour = msPerMinute * 60;
    let msPerDay = msPerHour * 24;

    let elapsed = current - previous;

    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + ' seconds ago';
    }

    else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + ' minutes ago';
    }

    else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + ' hours ago';
    }

    else {
      const diff = Math.round(elapsed / msPerDay);

      if (diff == 1) {
        return ' ' + diff + ' days ago';
      } else {
        // return ' ' + new Date(previous).toLocaleString(undefined, {hour12: true}).toUpperCase();
        return ' ' + new Date(previous).toLocaleDateString();
      }
    }
  }

  openModal(divName) {
    this.bsModalRef = this.modalService.show(divName)
  }

  closeModal() {
    this.bsModalRef.hide()
  }

  openNewWindowAndPrint(chatMessageList) {
    // var win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top="+(screen.height-400)+",left="+(screen.width-840));
    // win.document.body.innerHTML = "HTML";
  }

  goBack() {
    if (this.customerId) {
      this.router.navigate(['/customer-details'], { queryParams: { customerId: this.customerId } });
    } else {
      this.router.navigateByUrl('/task');
    }
  }

  scrollToView(elementId: string) {
    setTimeout(() => {
      this.scrollService.triggerScrollTo(elementId);
    }, 10);
  }

  isEpisodeBargeable(episode: Episode) {
    let canBargeIn = false;

    if (episode) {
      if (episode.source && episode.source == 'WHATSAPP') {
        if (episode.episodeContext && episode.episodeContext.lastUserMessageTime && Object.keys(episode.episodeContext.lastUserMessageTime).length > 0) {
          const lastUserMessageTime = episode.episodeContext.lastUserMessageTime;
          canBargeIn = ((new Date().getTime() - lastUserMessageTime['$date']) < (1000 * 60 * 60 * 24));
        } else if (episode.modifiedTime && Object.keys(episode.modifiedTime).length > 0) {
          const modifiedTime = episode.modifiedTime;
          canBargeIn = ((new Date().getTime() - modifiedTime['$date']) < (1000 * 60 * 60 * 24));
        }
      } else {
        canBargeIn = true;
      }
    }

    return canBargeIn;
  }

  getUserFriendlyChannelName(channel: string) {
    if (channel && channel.trim().length > 0) {
      if (channel == 'WHATSAPP') {
        return 'WhatsApp';
      }
    }

    return 'Workbench';
  }

  showFilter(){
    this.showChatFilter = !this.showChatFilter;

    if (this.showChatFilter) {
      this.tempFilter = Object.assign(new ChatFilter, this.chatFilter);
    }
  }

  onClearFilter() {
    this.tempFilter = new ChatFilter();
    this.selectedSourceList = [];
    this.selectedAgentList = [];
    this.setDefaultSortSettings(true);
  }

  onQuickSearch() {
    if (!this.chatFilter) {
      this.chatFilter = new ChatFilter();
    }

    if (!this.quikSearchText || this.quikSearchText.trim().length == 0) {
      this.quikSearchText = '';
    }

    this.chatFilter.searchText = this.quikSearchText;
    this.chatFilter.pageNo = 0;

    this.selectedEpisode = null;
    this.chatMessageList = [];
    this.getEpisodesApplicableForBargeIn(true); 
  }

  onFilterSubmit() {
    this.tempFilter.sourceList = [];
    if (this.selectedSourceList && this.selectedSourceList.length > 0) {
      for(let source of this.selectedSourceList) {
        this.tempFilter.sourceList.push(source.value);
      }
    }
    
    this.tempFilter.agentIds = [];
    if (this.selectedAgentList && this.selectedAgentList.length > 0) {
      for(let agent of this.selectedAgentList) {
        this.tempFilter.agentIds.push(agent.value);
      }
    }

    this.tempFilter.sortList = [];
    this.setDefaultSortSettings();
    for (const sortOption of this.selectedSortBy[0]['value']) {
      this.tempFilter.sortList.push(new SortBy(sortOption, this.selectedSortDir[0].value));
    }

    this.tempFilter.searchText = this.tempFilter.searchText && this.tempFilter.searchText.trim().length > 0 ? this.tempFilter.searchText : '';
    this.chatFilter = this.tempFilter;
    this.quikSearchText = this.chatFilter.searchText;
    this.tempFilter = null;

    if (this.showChatFilter) {
      this.showFilter();
    }

    this.chatFilter.pageNo = 0;
    this.selectedEpisode = null;
    this.chatMessageList = [];
    this.getEpisodesApplicableForBargeIn(true);
  }

  setDefaultSortSettings(forced?: boolean) {
    if (forced || !this.selectedSortBy || Object.keys(this.selectedSortBy).length == 0) {
      this.selectedSortBy = [ConversationComponent.SORT_BY_USER_ACTIVITY_AND_MISSED_COUNT];
    }
    if (forced || !this.selectedSortDir) {
      this.selectedSortDir = [ConversationComponent.SORT_DIR_DESCENDING];
    }
  }

  datesUpdated(event) {
    let tmp = JSON.parse(JSON.stringify(event))
    
    if (tmp.startDate || tmp.endDate) {
      this.tempFilter.fromTime = new Date(moment(tmp.startDate).format());
      this.tempFilter.toTime = new Date(moment(tmp.endDate).format());

      this.tempFilter.selectedDateRange = {
        'startDate': moment(tmp.startDate),
        'endDate': moment(tmp.endDate)
      };
    }
  }
}
