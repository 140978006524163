import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit,TemplateRef,ChangeDetectorRef,NgZone } from '@angular/core';
import { Customer } from 'src/app/models/customer.model';
import { CommunicationService, DataSharingService, UniversalUser } from 'src/app/service/shared.service';
import { CustomerInteraction } from 'src/app/models/customer.model';
import { OneViewService } from 'src/app/service/oneview.service';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { StateService } from 'src/app/service/state.service';
import { MatSnackBar } from '@angular/material';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DataModelService } from 'src/app/service/datamodel.service';
import { CommonSearchModel } from 'src/app/models/shared.model';
import { DialerService } from 'src/app/service/dialer.service';



@Component({
  selector: 'app-one-view-details',
  templateUrl: './one-view-details.component.html',
  styleUrls: ['./one-view-details.component.scss']
})
export class OneViewDetailsComponent implements OnInit {
  currentTab:string="Interactions";
  selectedCustomer:Customer;
  customerId:string;
  interactionsPage:number;
  activitiesPage:number;
  processesPage:number;
  conversationPage:number;
  fetchRecords:number;
  interactionsList:any;
  totalInteractions:number=0;
  totalActivities:number=0;
  totalProcesses:number=0;
  totalConversations:number=0;
  activityList:CustomerInteraction[];
  processList:CustomerInteraction[];
  conversationList:CustomerInteraction[];
  customerName:string;
  isEditFlag:boolean=false;
  bsModelDelete: BsModalRef;
  bsModalMerge:BsModalRef;
  showPerAddress:boolean = true;
  isMergeFlag:boolean = false;
  selectedCustomers:string[] = [];

  timeZoneList=[
   {
     text:"LOCAL",
     value:"LOCAL"
   },
   {
     text:"UTC",
     value:"UTC"
   }
  ]

  genders:string[];

  constructor(private communicationService:CommunicationService,
    private oneViewService:OneViewService,
    private datePipe:DatePipe,
    private router:Router,
    private route:ActivatedRoute,
    private stateService:StateService,
    private snackBar:MatSnackBar,
    private modalService: BsModalService,
    private ngZone:NgZone, 
    private dataModelService: DataModelService,
    private dataSharingService: DataSharingService,
    private universalUser: UniversalUser,
    public dialerService: DialerService
  )
     { 
      this.selectedCustomer = new Customer();
      this.interactionsList = [];
      this.activityList = [];
      this.processList = [];
      this.conversationList = [];
      this.genders = ["Male","Female","Other"];
    }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
        this.customerId = params["customerId"];
        this.getCustomer();
        this.getDataModelList();
    })
    this.interactionsPage = 1;
    this.activitiesPage = 1;
    this.processesPage = 1;
    this.conversationPage = 1;
    this.fetchRecords = 10;
    this.getInteractionCount();
  
  }

  getInteractions(pagenumber){
   this.oneViewService.getInteractionsDateWise(this.interactionsPage,this.fetchRecords,this.customerId)
    .subscribe(interactionList => {
      if (interactionList) {
        let interactions = interactionList.reverse();
        for (let index = 0; index < interactions.length; index++) {
          const datesList = interactions[index];
          for (const key in datesList) {
          if (Object.prototype.hasOwnProperty.call(datesList, key)) {
            const element = datesList[key];
            for (let index = 0; index < element.length; index++) {
              const type = element[index];
              if (type.interactionType=='entity') {
                this.dataModelService.getDataModelByName(type.interactionName).subscribe(
                  (response) => {
                    if(response){
                      type.interactionLabel =  response.label
                    }else{
                      type.interactionLabel = type.interactionName
                    }
                  },
                  (error) => {
                    type.interactionLabel = type.interactionName
                  }
                )
              }else[
                type.interactionLabel =type.interactionName 
              ]
            }
            
          }
        }
        }
        this.interactionsList = interactions
        
      }
    });
  }

  getInteractionCount(){
    this.oneViewService.getInteractionsCount(this.customerId)
    .subscribe(interactionCount => {
      if (interactionCount) {
        this.totalInteractions =  this.interactionsList.length;
        this.totalActivities = interactionCount["activityCount"];
        this.totalConversations = interactionCount["conversationCount"];
        this.totalProcesses = interactionCount["processCount"];
      }
      this.getInteractions(0);
    });
  }

  getKey(interaction){
    return interaction["key"];
  }

  loadTab(tabName){
    this.currentTab = tabName;
    if(tabName == "Interactions"){
      this.interactionsPage=1;
      this.getInteractions(1)
    }
    if(tabName == "Activity"){
      this.activitiesPage=1;
      this.getActivities(1)
    }
    if(tabName == "Process"){
      this.processesPage=1;
      this.getProcesses(1)
    }
    if(tabName == "Conversations"){
      this.conversationPage=1;
      this.getConversations(1)
    }
  }

  getActivities(pageNumber){
    let escMap = {}
    let criteria = {}
    let sort = {}
    criteria["interactionType"] = "entity";
    criteria["customerId"] = this.selectedCustomer._id;
    escMap["criteria"] = criteria;
    let loopDone:boolean = false;
    this.oneViewService.getInteractions(this.activitiesPage,this.fetchRecords,escMap)
    .subscribe(activityList => {
      if (activityList.length > 0) {
        for (let index = 0; index < activityList.length; index++) {
          const element = activityList[index];
          this.dataModelService.getDataModelByName(element.interactionName).subscribe(
            (response) => {
              if(response){
                element.interactionLabel =  response.label
              }else{
                element.interactionLabel = element.interactionName
              }
            },
            (error) => {
              element.interactionLabel = element.interactionName
            }
          )
           
        }
        this.activityList = activityList;

      }
    });
  }
  getProcesses(pageNumber){
    let escMap = {}
    let criteria = {}
    let sort = {}
    criteria["interactionType"] = "flow";
    criteria["customerId"] = this.selectedCustomer._id;
    escMap["criteria"] = criteria;
    this.oneViewService.getInteractions(this.activitiesPage,this.fetchRecords,escMap)
    .subscribe(activityList => {
      if (activityList.length > 0) {
        this.processList = activityList;
      }
    });
  }

  getConversations(pageNumber){
    let escMap = {}
    let criteria = {}
    let sort = {}
    criteria["interactionType"] = "episode";
    criteria["customerId"] = this.selectedCustomer._id;
    escMap["criteria"] = criteria;
    this.oneViewService.getInteractions(this.activitiesPage,this.fetchRecords,escMap)
    .subscribe(activityList => {
      if (activityList.length > 0) {
        this.conversationList = activityList;
      }
    });
  }

  goToIneraction(interaction:CustomerInteraction){
    if(interaction.interactionType == "flow"){
      this.stateService.getActiveManualStateFromFlow(interaction.interactionId).subscribe(state =>{
        if(state && state["stateInstanceId"]){
          this.router.navigate(['/task'], { queryParams: { entityId: state["stateInstanceId"],customerId:interaction.customerId } });
  
        }
        else{
          this.snackBar.open("There is no active manual state for this Process or the Process has ended ", "Dismiss", {
            duration: 10000
          });
        }
      })
    }
    else if(interaction.interactionType == "entity"){
      this.router.navigate(['/add-activities'], { queryParams: { entityId: interaction.interactionId,entityName: interaction.interactionName,entityLabel: interaction.interactionLabel, customerId: interaction.customerId } });
    }
    else if(interaction.interactionType == "episode"){
      this.router.navigate(['/conversation'], { queryParams: { entityId: interaction.interactionId, customerId: interaction.customerId } });
    }
  }

  getCustomer(){
    this.oneViewService.getCustomer(this.customerId)
    .subscribe(customer => {
      if (customer) {
        this.selectedCustomer = customer;
        this.customerName = this.getName();
      }
    });
  }

 
  getDateForView(date){
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }

  getTimeForView(date){
    return this.datePipe.transform(date, 'HH:mm');
  }

  getName():string{
    if(this.selectedCustomer.name){
      return this.selectedCustomer.name
    }
    else if(this.selectedCustomer.firstName &&  this.selectedCustomer.middleName && this.selectedCustomer.lastName){
      return this.selectedCustomer.firstName + " " +  " " + this.selectedCustomer.lastName;
    }
    else if(this.selectedCustomer.firstName &&  this.selectedCustomer.middleName){
      return this.selectedCustomer.firstName + " " + this.selectedCustomer.middleName;
    }
    else if(this.selectedCustomer.firstName && this.selectedCustomer.lastName){
      return this.selectedCustomer.firstName + " " + this.selectedCustomer.lastName;
    }
    else if(this.selectedCustomer.firstName){
      return this.selectedCustomer.firstName;
    }
    else if(this.selectedCustomer.lastName){
      return this.selectedCustomer.lastName;
    }
    else if(this.selectedCustomer.email){
      if(this.selectedCustomer.email.includes("<")){
        return this.selectedCustomer.email.split('<')[0]
      }
      else{
        return this.selectedCustomer.email.split('@')[0]
      }
     
    }
    else{
      return "(Name) Info Unavailable";
    }
  }

  toggleEditCustomer(){
    this.isEditFlag = !this.isEditFlag;
  }
  
  toggleMergeCustomer(){
    this.isMergeFlag = !this.isMergeFlag;
  }
  formFullName(){
    let tmp = "";
    if (this.selectedCustomer.firstName!=null && this.selectedCustomer.firstName!="") {
      tmp += this.selectedCustomer.firstName;
    }
    if (this.selectedCustomer.middleName!=null && this.selectedCustomer.middleName!="") {
      tmp = tmp + " " + this.selectedCustomer.middleName;
    }
    if (this.selectedCustomer.lastName!=null && this.selectedCustomer.lastName!="") {
      tmp = tmp + " " +  this.selectedCustomer.lastName;
    }
    this.selectedCustomer.name = tmp
  }
  updateCustomer(){
    this.oneViewService.updateCustomer(this.selectedCustomer).subscribe( customer=>{
      if(customer){
        this.selectedCustomer = customer;
        this.isEditFlag = false;
      }
    });
  }  
  addressChange(value){
    this.ngZone.run(() => {
      this.selectedCustomer.permenantAddSameAsCurrent = value;
      this.showPerAddress = value;
    });
    
  }
  
  genderChangeSelection(value){
    if(value){
      this.selectedCustomer.gender = value;
    }
  }

  deleteCustomer(){
    this.oneViewService.deleteCustomer(this.selectedCustomer._id).subscribe( customer=>{
      
    });
    this.snackBar.open("Deleted successfully ", "Dismiss", {
      duration: 10000
    });
    this.closeDeleteModel();
    this.router.navigateByUrl("/customer-list");
  }

  

 

  goBack(){
    this.router.navigateByUrl("/customer-list");
  }

  onDeleteCustomer(templateRef: TemplateRef<any>,) {
    this.bsModelDelete =  this.modalService.show(templateRef, { class: 'modal-dialog modal-md modal-dialog-centered'});
  }

  closeDeleteModel(){
    this.bsModelDelete.hide();
  }


  onMergeCustomers(customerIds:string[],templateRef: TemplateRef<any>) {
    this.selectedCustomers = customerIds;
    this.bsModalMerge =  this.modalService.show(templateRef, { class: 'modal-dialog modal-md modal-dialog-centered'});
  }

  onMergeCustomer(templateRef: TemplateRef<any>,) {
    this.bsModalMerge =  this.modalService.show(templateRef, { class: 'modal-dialog modal-md modal-dialog-centered'});
  }
  

  closeMergeModel(){
    this.bsModalMerge.hide();
  }

  mergeCustomer(){
      this.oneViewService.mergeCustomers(this.customerId,this.selectedCustomers).subscribe( customer=>{
        if(customer){
          this.snackBar.open("Merged successfully ", "Dismiss", {
            duration: 7000
          });
          this.closeMergeModel();
          this.toggleMergeCustomer();
          this.getCustomer();
        }
      });
  }

  dataModelList: any[]=[];
  getDataModelList() {
    const commonsearchModel = new CommonSearchModel();
    commonsearchModel.searchParams = [{ 'statusCd': 'ACTIVE' }];
    commonsearchModel.returnFields = ['label', 'version', 'statusCd', 'name', 'subEntity', 'type'];
    this.dataModelService.getDataModelList(commonsearchModel)
      .subscribe(list => {
        if (list != null && list.length > 0) {
          for (let item of list) {
            this.dataModelList.push({ display: item.label, value: item.name, id: item._id, name: item.name, statusCd: item.statusCd })
          }

        }
      });
  }
  addDataModelByUser(datamodel){
    this.communicationService.setDataModelName(datamodel.name);
    this.communicationService.setSelectedDataModelId(datamodel.id)
    this.router.navigate(['/add-activities'], { queryParams: { customerId: this.customerId } });
    /*
    companyId: "5d6625dce48d91571849c3c4"
    createdAt: 1626951673668
    createdBy: "Admin"
    createdUserId: "teamB@automatapi.com"
    customerId: "60f94ff946e0fb0001adcde8"
    interactionId: "60f94ff946e0fb0001adcde9"
    interactionLabel: "All Field Types" 
    interactionName: "allFieldTypes"
    interactionType: "entity"
    lastUpdatedAt: 1626951673668
    lastUpdatedBy: "Admin"
    lastUpdatedUserId: "teamB@automatapi.com"
    _id: "60f94ff946e0fb0001adcdea"
    */ 
  }

  generateLink(selectedCustomer: Customer) {
    if (this.dialerService.isConfigPresent() && (selectedCustomer.mobileNumber != null || selectedCustomer.phoneNumber != null)) {
      return 'dialler';
    } else if (!this.dialerService.isConfigPresent() && (selectedCustomer.mobileNumber != null || selectedCustomer.phoneNumber != null)) {
      return 'tel'
    } else {
      return 'default'
    }
  }
  callFromDialler(selectedCustomer: Customer) {
    let callRequest = {};
    if (selectedCustomer.mobileNumber != null) {
      callRequest = {
        "mobile_number": selectedCustomer.mobileNumber,
        "userId": this.universalUser.getUser()._id
      }
    } else {
      callRequest = {
        "mobile_number": selectedCustomer.phoneNumber,
        "userId": this.universalUser.getUser()._id
      }

    }
    this.communicationService.diallerCallCustomer(callRequest).subscribe(
      (response) => { 
        if(response && response.success == true){
          this.snackBar.open("Call Sucessfully placed. You will receive the call on your Dialler", "Dismiss", {
            duration: 10000
          });
        }else{
          this.snackBar.open(response.message, "Dismiss", {
            duration: 10000
          });
        }
      },
      (error) => { console.log(error) }
    );
  }
}
