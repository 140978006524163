import { BaseModel } from './base.model';

export class Customer extends BaseModel{
    source:string;
    notificationConsent:boolean;
    profilePercentage:any;
    name:string;//(Fullname)
    firstName:string;
    middleName:string;
    lastName:string;
    nickName:string;
    profileUrl:string;
    pictureUrl:string;
    websiteUrl:string;
    email:string;
    emailVerified:boolean;
    gender:string;
    birthDate:string;
    timeZone:string;
    locale:string;
    phoneNumber:string;
    mobileNumber:string;
    phoneNumberVerified:boolean;
    mobileNUmberVerified:boolean;
    permenantAddress:string;
    currentAddress:string;
    permenantAddSameAsCurrent:boolean;
    numberOfInteractions:number;


  constructor() {
    super();

    this.source = null;
    this.notificationConsent = null;
    this.profilePercentage = null;
    this.name = null;
    this.firstName = null;
    this.middleName = null;
    this.lastName = null;
    this.nickName = null;
    this.profileUrl = null;
    this.pictureUrl = null;
    this.websiteUrl = null;
    this.email = null;
    this.emailVerified = false;
    this.gender = null;
    this.birthDate = null;
    this.timeZone = null;
    this.locale = null;
    this.phoneNumber = null;
    this.mobileNumber = null;
    this.phoneNumberVerified = false;
    this.mobileNUmberVerified = false;
    this.permenantAddress = null;
    this.currentAddress = null;
    this.permenantAddSameAsCurrent = false;
    this.numberOfInteractions = 0;
    
  }
}

export class CustomerInteraction extends BaseModel{
    customerId:string;
	interactionType:any;
	interactionId:string;
	interactionName:string;
  interactionLabel:string;

    constructor(){
        super();
        this.customerId = null;
        this.interactionId = null;
        this.interactionType = null;
        this.interactionName = null;
        this.interactionLabel ='';
    }
}