import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TaskComponent } from './task/task.component';
import { ActivitiesComponent } from './activities/activities.component';
import { ConversationComponent } from './conversation/conversation.component';
import { LoginComponent } from './login/login.component';
import { MainLayoutComponent } from './_layout/main-layout/main-layout.component';


import { AuthGuard, AntiAuthGuard, DeactivateGuard } from './service/shared.service';
import { AddActivitiesComponent } from './activities/add-activities/add-activities.component';
import { BusinessDashboardComponent } from './business-dashboard/business-dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { CustomDashboardListComponent } from './custom-dashboard-list/custom-dashboard-list.component';
import { CustomDshbrdComponent } from './custom-dashboard/custom-dashboard.component';
import { OneViewListComponent } from './one-view/one-view-list/one-view-list.component';
import { OneViewDetailsComponent } from './one-view/one-view-details/one-view-details.component';
import { SeparateTaskListingComponent } from './task/separate-task-listing/separate-task-listing.component';
import { ManualTaskComponent } from './task/manualTask.component';
import { AddActivitiesElementsComponent } from './activities/add-activities-elements/add-activities-elements.component';
import { PiFormComponent } from './piForm/piForm.component';
import { UserMappingComponent } from './user-mapping/user-mapping.component';
import { FormListingComponent } from './activities/form-listing/form-listing.component';
import { APiTicketingComponent } from './ticketing/ticketing.component';
import { APiNotificationPageComponent } from './notification-page/notification-page.component';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    canActivate: [AntiAuthGuard],
    component: LoginComponent
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      { path: 'dashboard', canActivate: [AuthGuard], component: DashboardComponent },
      { path: 'businessoutlook', canActivate: [AuthGuard], component: BusinessDashboardComponent },
      { path: 'workspace', canActivate: [AuthGuard], component: SeparateTaskListingComponent },
      { path: 'workspace2', loadChildren: './workspace/workspace.module#WorkspaceModule' },
      { path: 'task', canActivate: [AuthGuard], component: ManualTaskComponent , runGuardsAndResolvers:'always'},
      { path: 'task/:entityId', canActivate: [AuthGuard], component: ManualTaskComponent },
      { path: 'profile', canActivate: [AuthGuard], component: UserProfileComponent },
      { path: 'ticketing', canActivate: [AuthGuard], component: APiTicketingComponent },
      { path: 'notification-page', canActivate: [AuthGuard], component: APiNotificationPageComponent },
      { path: 'mapping', canActivate: [AuthGuard], component: UserMappingComponent },
      // children: [
      //   {path: 'task/:entityId', canActivate:[AuthGuard],component: TaskComponent},
      // ]},
      { path: 'activities', component: ActivitiesComponent },
      { path: 'forms/:dataModelName/:dataModelId', component: FormListingComponent },
      { path: 'add-activixties', canActivate: [AuthGuard], component: AddActivitiesComponent, runGuardsAndResolvers:'always'  },
      { path: 'form-detail/:entityName/:entityId', canActivate: [AuthGuard], component: AddActivitiesComponent, runGuardsAndResolvers:'always'  },
     // { path: 'add-activities/:entityId/:entityName/:customerId', canActivate: [AuthGuard], component: AddActivitiesComponent, runGuardsAndResolvers:'always'  },
     { path: 'add-activities-v2', canActivate: [AuthGuard], component: AddActivitiesElementsComponent,runGuardsAndResolvers:'always'},
     { path: 'form-detail-v2/:entityName/:entityId', canActivate: [AuthGuard], component: AddActivitiesElementsComponent,runGuardsAndResolvers:'always'},
      { path: 'conversation', component: ConversationComponent },
      { path: 'dashboard-list', canActivate: [AuthGuard], component: CustomDashboardListComponent },
      { path: 'custom-dashboard', canActivate: [AuthGuard], component: CustomDshbrdComponent },
      
      { path: 'customer-list', canActivate: [AuthGuard], component: OneViewListComponent },
      { path: 'customer-details', canActivate: [AuthGuard], component: OneViewDetailsComponent },
      { path: 'pi-forms', canActivate: [AuthGuard], component: PiFormComponent}
    ]
  },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [LoginComponent]