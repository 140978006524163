import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CustomDashboardComponent, Filter } from 'src/app/models/customDashboard.model';
import { CustomDashboardService } from 'src/app/service/dashboard.service';
import { UniversalUser } from 'src/app/service/shared.service';

@Component({
  selector: 'app-line-chart-dashboard',
  templateUrl: './line-chart-dashboard.component.html',
  styleUrls: ['./line-chart-dashboard.component.css']
})
export class LineChartDashboardComponent implements OnChanges {
  @Input() startTime: string;
  @Input() endTime: string;
  @Input() selectedComponent: CustomDashboardComponent;
  @Input() selectedDashboardId: string;
  @Input() componentFilters: Filter[] = [];
  @Input() colourList: string[] = [];

  currentResults: any;

  chartDiv: string;
  constructor(
    private customDashboardService: CustomDashboardService,
    private universalUser: UniversalUser
  ) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedComponent && changes.selectedComponent.currentValue) {
      this.chartDiv = changes.selectedComponent.currentValue._id;

    }
    if (changes && changes.endTime && changes.endTime.currentValue != undefined) {
      this.endTime = changes.endTime.currentValue;
    }
    if (changes && changes.startTime && changes.startTime.currentValue != undefined) {
      this.startTime = changes.startTime.currentValue;
    }
    if (changes && changes.componentFilters && changes.componentFilters.currentValue != undefined) {
      this.componentFilters = changes.componentFilters.currentValue;
    }
    this.getResults(this.startTime, this.endTime, this.componentFilters)


  }
  removeExtra(arr) {
    for (const key in arr) {
      if (Object.prototype.hasOwnProperty.call(arr, key)) {
        const element = arr[key];
        if (key == 'subCategory') {
          if (element.length == 0) {
            delete arr['subCategory']
          } else {
            for (let index = 0; index < element.length; index++) {
              const el = element[index];
              this.removeExtra(el)
            }
          }
        }
      }
    }
  }
  getResults(startTime, endTime, filters) {
    this.customDashboardService.getComponentResult(this.universalUser.getUser().companyId, this.selectedComponent, startTime, endTime, filters, this.selectedDashboardId).subscribe(
      (response) => {
        if (response && response.length > 0) {
          let series = [];
          let seriesData = [];
          let keyArray = [];
          let tmpSeries = {}
          if (response[0].subCategory && response[0].subCategory.length > 0) {
            let aggregationList = [];
            for (const key in response[0]) {
              if (Object.prototype.hasOwnProperty.call(response[0], key)) {
                // const element = response[0][key];
                if (key != 'category' && key != 'subCategory' && key != 'description') {
                  aggregationList.push(key)
                }
              }
            }

            for (let iDate = 0; iDate < response.length; iDate++) {
              let temp = {};
              const dtEl = response[iDate];
              delete dtEl['description'];
              temp['date'] = dtEl.category;
              for (let jKeyys = 0; jKeyys < dtEl.subCategory.length; jKeyys++) {
                let element = dtEl.subCategory[jKeyys];
                for (let aggListInd = 0; aggListInd < aggregationList.length; aggListInd++) {
                  temp[element['category']] = element[aggregationList[aggListInd]];
                }
                if (keyArray.indexOf(element['category']) == -1) {
                  keyArray.push(element['category'])
                }
              }
              seriesData.push(temp);
            }
            seriesData.sort(function (a, b) {
              var keyA = new Date(a.date),
                keyB = new Date(b.date);
              // Compare the 2 dates
              if (keyA < keyB) return -1;
              if (keyA > keyB) return 1;
              return 0;
            })
            //This is for putting null values for series points
            for (let keyInd = 0; keyInd < keyArray.length; keyInd++) {
              const el = keyArray[keyInd];
              tmpSeries[el] = {};
              tmpSeries[el]['name'] = el;
              tmpSeries[el]['data'] = [];
              for (let j = 0; j < seriesData.length; j++) {
                let seriesEach = seriesData[j];
                if (!seriesEach[el]) {
                  seriesEach[el] = 0;
                }
                let tmp = {};
                tmp['date'] = seriesEach.date;
                tmp[el] = seriesEach[el];

                tmpSeries[el]['data'].push(tmp)
              }
            }
            for (const key in tmpSeries) {
              if (Object.prototype.hasOwnProperty.call(tmpSeries, key)) {
                let individualSeries = tmpSeries[key];
                series.push(individualSeries)
              }
            }
            this.currentResults = series;
          } else {
            for (const key in response[0]) {
              if (Object.prototype.hasOwnProperty.call(response[0], key)) {
                const el = response[0][key];
                if (key != 'category' && key != 'subCategory' && key != 'description') { keyArray.push(key) }
              }
            }
            for (let index = 0; index < response.length; index++) {
              const element = response[index];
              for (let keyInd = 0; keyInd < keyArray.length; keyInd++) {
                const singleKey = keyArray[keyInd];
                let temp = {};
                temp['date'] = this.getonlyDate(element.category);
                if (element[singleKey]) {
                  temp[singleKey] = element[singleKey];
                } else {
                  temp[singleKey] = null;
                }
                if (seriesData[keyInd] == undefined) {
                  seriesData[keyInd] = []
                }
                seriesData[keyInd].push(temp);
                series[keyInd] = {
                  name: singleKey
                }
              }
            }
            for (let keyInd = 0; keyInd < keyArray.length; keyInd++) {
              series[keyInd]['data'] = seriesData[keyInd];
            }
            for (let i = 0; i < series.length; i++) {
              series[i].data.sort(function (a, b) {
                var keyA = new Date(a.date),
                  keyB = new Date(b.date);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
              });
            }
            for (let i = 0; i < series.length; i++) {

              let dateSeries = [];
              for (let j = 0; j < series[i].data.length; j++) {
                let Keys = Object.keys(series[0]['data'][0])
                Keys.splice(Keys.indexOf("date"), 1);
                if (series[i]['data'][j] && series[i]['data'][j - 1]) {
                  // if (this.getonlyDate(series[i]['data'][j].date) == this.getonlyDate(series[i]['data'][j - 1].date)) {
                  //   let pos = dateSeries.map(item => item.date).indexOf(this.getonlyDate(series[i]['data'][j].date));
                  //   for (let k = 0; k < Keys.length; k++) {
                  //     let el = Keys[k];
                  //     if (dateSeries[pos] && dateSeries[pos][el]) {
                  //       dateSeries[pos][el] += (+series[i]['data'][j][el])
                  //     }
                  //   }
                  // } else {
                  //   let tmp = {};
                  //   for (let k = 0; k < Keys.length; k++) {
                  //     let el = Keys[k];
                  //     tmp['date'] = this.getonlyDate(series[i]['data'][j].date);
                  //     tmp[el] = (+series[i]['data'][j][el]);
                  //   }
                  //   dateSeries.push(tmp)
                  // }
                  let tmp = {};
                  for (let k = 0; k < Keys.length; k++) {
                    let el = Keys[k];
                    tmp['date'] = this.getonlyDate(series[i]['data'][j].date);
                    tmp[el] = (+series[i]['data'][j][el]);
                  }
                  dateSeries.push(tmp)
                } else {
                  dateSeries.push(series[i]['data'][j])
                }
              }
              let dateSeries2 = [];
              let groupedDate = this.groupBy(dateSeries,'date');
              let aggListPos = this.selectedComponent.aggregations.map(item=>item.attributeLabel).indexOf(series[i].name);
              if (aggListPos> -1) {
                // Group and performa aggregation
                let operation = this.selectedComponent.aggregations[aggListPos].aggregationOperation;
                let seriesLabe = this.selectedComponent.aggregations[aggListPos].attributeLabel;
                for (const key in groupedDate) {
                  if (Object.prototype.hasOwnProperty.call(groupedDate, key)) {
                    const elementArray = groupedDate[key];
                    let dateValue = 0;
                    switch (operation) {
                      case 'count':
                        for (let index = 0; index < elementArray.length; index++) {
                          const element = elementArray[index][seriesLabe];
                          dateValue += element;
                        }
                        break;
                      case 'avg':
                        for (let index = 0; index < elementArray.length; index++) {
                          const element = elementArray[index][seriesLabe];
                          dateValue += element;
                        }
                        dateValue = dateValue/elementArray.length;
                        break;
                    
                      default:
                        for (let index = 0; index < elementArray.length; index++) {
                          const element = elementArray[index][seriesLabe];
                          dateValue += element;
                        }
                        break;
                    }
                    let tmp2 = {
                      date:key
                    }
                    tmp2[seriesLabe] = dateValue;
                    dateSeries2.push(tmp2)
                  }
                }
                series[i].data = JSON.parse(JSON.stringify(dateSeries2))
              }else{
                series[i].data = JSON.parse(JSON.stringify(dateSeries))
              }
            }
            
            this.currentResults = series;
          }

        }
      },
      (error) => {
        console.error(error)

      }
    );  
  }
  getonlyDate(dt) {
    var date = new Date(dt);
    return (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear());
  }
  groupBy(array, key) {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  }
  getLabel(selectedComponent){
    return selectedComponent.label
  }
}
