import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DataTableModule } from "angular-6-datatable";
import { ArchwizardModule } from 'angular-archwizard';
import { DataTablesModule } from 'angular-datatables';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MyDatePickerModule } from 'mydatepicker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TagInputModule } from 'ngx-chips';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MomentModule } from 'ngx-moment';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxPaginationModule } from 'ngx-pagination';
import { ActivitiesComponent } from './activities/activities.component';
import { AddActivitiesComponent } from './activities/add-activities/add-activities.component';
import { DynamicFormModalComponent } from './activities/dynamic-form-modal/dynamic-form-modal.component';
import { DynamicFormComponent } from './activities/dynamic-form/dynamic-form.component';
import { EntityRefModalComponent } from './activities/entity-ref-modal/entity-ref-modal.component';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { BusinessDashboardComponent } from './business-dashboard/business-dashboard.component';
import { ConversationComponent } from './conversation/conversation.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { AngularMaterialModule } from './material.module';
import { AuthInterceptor } from './service/auth.interceptor';
import { AuthService } from './service/auth.service';
import { CommonService } from './service/common.service';
import { ConnectorConfigService } from './service/connector.service';
import { CustomDashboardService, DashboardService } from './service/dashboard.service';
import { BusinessDashboardService } from './service/business-dashboard.service'
import { EntityService } from './service/entity.service';
import { EscapeScriptInterceptor } from './service/escape-script.interceptor';
import { LoaderInterceptor } from './service/loader-interceptor.service';
import { LoaderService } from './service/loader.service';
import { CommunicationService, DataModelObject, FileService, GraphService, NotificationService, TagsService } from './service/shared.service';
import { StateService } from './service/state.service';
import { TaskService } from './service/task.service';
import { AllocateTaskToUser, FetchUserService } from './service/userhierarchy.service';
import { AdhocEmailComponent } from './shared/adhocemail/adhocemail.component';
import { CapitalizePipe, SearchFilterPipe } from './shared/custompipes.pipe';
import { DateCountdownComponent } from './shared/datecountdown/datecountdown.component';
import { DateRangePickerComponent } from './shared/daterangepicker';
import { ImagepdfviewerComponent } from './shared/imagepdfviewer/imagepdfviewer.component';
import { MyLoaderComponent } from './shared/my-loader/my-loader.component';
import { ScrollEndDirective } from './shared/scroll-end.directive';
import { SharedModule } from './shared/shared.module';
import { AgentAsistComponent } from './task/agent-asist/agent-asist.component';
import { ConversationtrailComponent } from './task/conversationtrail/conversationtrail.component';
import { DatapointsComponent } from './task/datapoints/datapoints.component';
import { DocumentsComponent } from './task/documents/documents.component';
import { EmailtrailComponent } from './task/emailtrail/emailtrail.component';
import { HelpersectionComponent } from './task/helpersection/helpersection.component';
import { NotesComponent } from './task/notes/notes.component';
import { RemarksComponent } from './task/remarks/remarks.component';
import { StageComponent } from './task/stage/stage.component';
import { TagComponent } from './task/tag/tag.component';
import { TaskListingComponent } from './task/task-listing/task-listing.component';
import { TaskComponent } from './task/task.component';
import { TeamListComponent } from './task/team-list/team-list.component';
import { TimelineComponent } from './task/timeline/timeline.component';
import { MainLayoutComponent } from './_layout/main-layout/main-layout.component';
// import { DatapointsComponent } from './task/datapoints/datapoints.component'
// import { ScrollEndDirective } from './shared/scroll-end.directive';
// import {AutocompleteLibModule} from 'angular-ng-autocomplete';
// import {MatExpansionModule} from '@angular/material/expansion';
// import { DynamicFormModalComponent } from './activities/dynamic-form-modal/dynamic-form-modal.component';
// import { EntityRefModalComponent } from './activities/entity-ref-modal/entity-ref-modal.component';

// import { MatPaginatorModule } from '@angular/material';


// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatDialogModule } from '@angular/material/dialog';
// import { MatInputModule } from '@angular/material/input';
// import { MatButtonModule } from '@angular/material/button';
// import {NgxPaginationModule} from 'ngx-pagination';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AngularSplitModule } from 'angular-split';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import {MatTabsModule} from '@angular/material/tabs';
import { CustomDshbrdComponent } from './custom-dashboard/custom-dashboard.component';
import { CustomDashboardListComponent } from './custom-dashboard-list/custom-dashboard-list.component';
import { PreferenceService } from './service/user.service';
import { ComponentLoaderComponent } from './shared/component-loader/component-loader.component';
import { NgxDatatableModule } from '@tusharghoshbd/ngx-datatable';
import { ManualTaskComponent } from './task/manualTask.component';
import { TaskListingFullComponent } from './task/task-listing-full/task-listing-full.component';

import { OneViewListComponent } from './one-view/one-view-list/one-view-list.component';
import { OneViewDetailsComponent } from './one-view/one-view-details/one-view-details.component';
import { SeparateTaskListingComponent } from './task/separate-task-listing/separate-task-listing.component';
import { AgentService } from './service/agent.service';
import { OneViewService } from './service/oneview.service';
import { AsyncPipe, DatePipe } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { DataModelService } from './service/datamodel.service';
import { MultiTenantService } from './service/multi-tenant.service';
import { ocrComponent } from './shared/ocr-comp/ocrComp.component';
import { AddActivitiesElementsComponent } from './activities/add-activities-elements/add-activities-elements.component';
import { PiFormComponent } from './piForm/piForm.component';
import { ApplicationService } from './service/application.service';
import { ocrFieldTrackComponent } from './shared/ocr-fieldTrack/ocr-fieldTrack.component';
import { USPService } from './service/usp.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { OneViewComponent } from './task/one-view/one-view.component';
import { UserMappingComponent } from './user-mapping/user-mapping.component';
import { FormListingComponent } from './activities/form-listing/form-listing.component';
import { SingleMailComponent } from './shared/single-mail/single-mail.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MessagingService } from './service/messaging.service';
export let options: Partial<IConfig> | (() => Partial<IConfig>);


import { AngularFireMessagingModule } from '@angular/fire/messaging'
import { AngularFireModule } from '@angular/fire';
import { APiTicketingComponent } from './ticketing/ticketing.component';
import { JoyrideModule } from 'ngx-joyride';
import { APiNotificationPageComponent } from './notification-page/notification-page.component';
import { ocrFieldTrackLLMComponent } from './shared/ocr-fieldTrack-llm/ocr-fieldTrack-llm.component';
import { OrderByPipe } from './shared/orderBy.pipe';
import { NgxCaptchaModule, NgxCaptchaService } from '@binssoft/ngx-captcha';


@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    DashboardComponent,
    TaskComponent,
    ManualTaskComponent,
    ActivitiesComponent,
    ConversationComponent,
    LoginComponent,
    MainLayoutComponent,
    AgentAsistComponent,
    DocumentsComponent,
    HelpersectionComponent,
    TimelineComponent,
    RemarksComponent,
    TagComponent,
    TeamListComponent,
    AddActivitiesComponent,
    StageComponent,
    DynamicFormComponent,
    DynamicFormModalComponent,
    EntityRefModalComponent,
    DateCountdownComponent,
    CapitalizePipe,
    SearchFilterPipe,
    MyLoaderComponent,
    EmailtrailComponent,
    ConversationtrailComponent,
    NotesComponent,
    TaskListingComponent,
    TaskListingFullComponent,
    DatapointsComponent,
    ScrollEndDirective,
    BusinessDashboardComponent,
    UserProfileComponent,
    CustomDshbrdComponent,
    CustomDashboardListComponent,
    OneViewListComponent,
    OneViewDetailsComponent,
    SeparateTaskListingComponent,
    AddActivitiesElementsComponent,
    PiFormComponent,
    OneViewComponent,
    UserMappingComponent,
    FormListingComponent,
    APiTicketingComponent,
    APiNotificationPageComponent,
    OrderByPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ArchwizardModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    DataTablesModule,
    DataTableModule,
    MomentModule,
    SharedModule,
    TagInputModule,
    CarouselModule,
    MyDatePickerModule,
    MatSnackBarModule,
    NgxDaterangepickerMd.forRoot(),
    FeatherModule.pick(allIcons),
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    NgxMaskModule.forRoot(),
    AngularMaterialModule,
    BsDropdownModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    AccordionModule.forRoot(),
    AngularEditorModule,
    AutocompleteLibModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatPaginatorModule,
    NgxPaginationModule,
    AngularSplitModule.forRoot(),
    TimepickerModule.forRoot(),
    MatTabsModule,
    NgxDatatableModule,
    InfiniteScrollModule,
    ScrollToModule.forRoot(),
    NgSelectModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    JoyrideModule.forRoot(),
    NgxCaptchaModule
  ],
  providers: [CommonService, AuthService, TaskService, StateService, FetchUserService, BsModalRef,
    AllocateTaskToUser, TagsService, GraphService, FileService, CommunicationService,
    NotificationService, DashboardService, BusinessDashboardService, EntityService, DataModelObject, 
    LoaderService, ConnectorConfigService, CustomDashboardService,PreferenceService,AgentService,
    OneViewService,DatePipe,DataModelService, MultiTenantService, ApplicationService, USPService,MessagingService,AsyncPipe,NgxCaptchaService,
    { provide: HTTP_INTERCEPTORS, useClass: EscapeScriptInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }],
  bootstrap: [AppComponent],
  entryComponents: [
    ImagepdfviewerComponent,
    ocrComponent,
    ocrFieldTrackComponent,
    ocrFieldTrackLLMComponent,
    DynamicFormComponent,
    DynamicFormModalComponent,
    EntityRefModalComponent,
    AdhocEmailComponent,
    SingleMailComponent
  ],
  exports: [
    FeatherModule,
    DataTablesModule,
    DataTableModule,
    SharedModule,
    OneViewComponent,
    OrderByPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
