import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentData {
    private subject = new Subject<any>();
    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    private envData: any = null;
    constructor(private httpClient: HttpClient) { }
    fetchEnvData(): Observable<any> {
        const url = './assets/env.json';
        this.httpClient.get(
            url,
            {
                headers: this.httpHeaders,
                observe: 'response',
                reportProgress: true,
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                this.envData = response.body;
                this.subject.next(response.body);
            },
            (err: HttpErrorResponse) => {
                this.subject.error(err);
            }
        )
        return this.subject.asObservable();
    }
    getEnvData() {
        return this.envData;
    }

    getEnvDataObservable(): Observable<any>{
        return this.subject.asObservable();
    }
}