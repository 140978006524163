import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CustomDashboardComponent, Filter } from 'src/app/models/customDashboard.model';
import { CustomDashboardService } from 'src/app/service/dashboard.service';
import { UniversalUser } from 'src/app/service/shared.service';

@Component({
  selector: 'app-pie-chart-dashboard',
  templateUrl: './pie-chart-dashboard.component.html',
  styleUrls: ['./pie-chart-dashboard.component.css']
})
export class PieChartDashboardComponent implements OnChanges {
  @Input() startTime: string;
  @Input() endTime: string;
  @Input() selectedComponent: CustomDashboardComponent;
  @Input() selectedDashboardId: string;
  currentResults: any;
  @Input() componentFilters: Filter[] = [];
  @Input() colourList: string[] = [];

  chartDiv: string;

  constructor(
    private customDashboardService: CustomDashboardService,
    private universalUser: UniversalUser
  ) { }

  ngOnInit() {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedComponent && changes.selectedComponent.currentValue) {
      this.chartDiv = changes.selectedComponent.currentValue._id;

    }
    if (changes && changes.endTime && changes.endTime.currentValue != undefined) {
      this.endTime = changes.endTime.currentValue;
    }
    if (changes && changes.startTime && changes.startTime.currentValue != undefined) {
      this.startTime = changes.startTime.currentValue;
    }
    if (changes && changes.componentFilters && changes.componentFilters.currentValue != undefined) {
      this.componentFilters = changes.componentFilters.currentValue;
    }
    this.getResults(this.startTime, this.endTime, this.componentFilters)



  }
  removeExtra(arr) {
    delete arr['description'];
    for (const key in arr) {
      if (Object.prototype.hasOwnProperty.call(arr, key)) {
        const element = arr[key];
        if (key == 'subCategory') {
          if (element.length == 0) {
            delete arr['subCategory']
          } else {
            for (let index = 0; index < element.length; index++) {
              const el = element[index];
              this.removeExtra(el)
            }
          }
        }
      }
    }
  }
  getResults(startTime, endTime, filters) {
    this.customDashboardService.getComponentResult(this.universalUser.getUser().companyId, this.selectedComponent, startTime, endTime, filters, this.selectedDashboardId).subscribe(
      (response) => {
        this.currentResults = [];
        if (response && response.length > 0) {
          for (let index = 0; index < response.length; index++) {
            const element = response[index];
            this.removeExtra(element)
            for (const key in element) {
              if (Object.prototype.hasOwnProperty.call(element, key)) {
                if(key != 'category'){
                  element['value'] =element[key]
                  // delete element[key];
                }
              }
            }
          }
          this.currentResults = response;
        }

      },
      (error) => {
        console.error(error)

      }
    );
  }
  getLabel(selectedComponent){
    return selectedComponent.label
  }
}
