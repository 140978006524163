export class BusinessDashboardRequest {
    operation: string;
    machineType: string;
    machineTypes: string[];
    timeZone: string;
    startTime: string;
    endTime: string;
    groupName: string;
    companyContext: any;

    constructor() {
        this.operation = null;
        this.machineType = null;
        this.machineTypes = [];
        this.timeZone = null;
        this.startTime = null;
        this.endTime = null;
        this.groupName = null;
    }
}

export class BusinessDashboardResult {
    groupName: string;
    totalProcessCount: number;
    processCountPercentageChange: number;
    predictedTotalProcessCount: number;

    totalSuccessProcessCount: number;
    successProcessCountPercentageChange: number;
    predictedSuccessProcessCount: number;

    totalFailedProcessCount: number;
    failedProcessCountPercentageChange: number;
    predictedFailedProcessCount: number;

    processTotalRevenue: number;

    avgHandlingTime: string;
    avgHandlingTimePercentageChange: number;
    predicatedAvgHandlingTimeChange: string;

    noOfAgents: number;
    noOfAgentsPercentageChange: number;
    predictedNoOfAgents: number;

    totalProcessCost: number;

    resourceUtilizationPercentageApplicable: boolean = true;
    resourceUtilizationPercentage: number;
    resourceUtilizationPercentageChange: number;
    predictedResourceUtilizationPercentage: number;

    constructor() {

    }
}